import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { ContentEditableEvent } from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import PiContentEditable from "../../../components/PiContentEditable";
import PiEditMenu from "../../../components/PiEditMenu";
import { IAnswer } from "../../../models/Quiz";
import { IWordQuiz } from "../../../models/interactions/WordQuiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import WordSpinnerTextfield from "../word_spinner_quiz/WordSpinnerTextfield";
import "./WordQuizBody.css";
import WordQuizSentenceSegment from "./WordQuizSentenceSegment";
export default function WordQuizBody() {
  const quiz = useSelector(selectSelectedElement) as IWordQuiz;
  const dispatch = useDispatch();
  const { getValue, changeValue, getNewKey } = useTrainingLocalization();
  const [isEditingSentence, setIsEditingSentence] = useState<boolean>(false);

  const [sentenceIndices, setSentenceIndices] = useState<number[]>([]);
  const [x, setXPos] = useState<number>(0);
  const [y, setYPos] = useState<number>(0);

  const [chunks, setChunks] = useState<IAnswer[]>([]);

  useEffect(() => {
    generateChunks();
  }, [getValue(quiz.formattedSentence)]);

  useEffect(() => {
    // calculate avg pos
    var parent = document.getElementById("wordquiz-root");
    var elem = document.getElementById(
      "wordquiz-" + sentenceIndices[sentenceIndices.length - 1]
    );

    if (elem) {
      var prect = parent!.getBoundingClientRect();
      var rect = elem!.getBoundingClientRect();

      setXPos(((rect.x - prect.x + rect.width * 0.5) / prect.width) * 100);
      setYPos(((rect.y - prect.y) / prect.height) * 100);
    }
  }, [sentenceIndices]);

  const generateChunks = () => {
    var sentence = getValue(quiz.formattedSentence);

    // Use a regular expression with capturing groups to split and capture the parts
    const partsWithBraces: string[] = sentence.split(/(\{[^{}]*\})/);
    const newChunks: IAnswer[] = [];

    for (var i = 0; i < partsWithBraces.length; i++) {
      if (i % 2 != 0) {
        var answer: IAnswer = {
          id: uuidv4(),
          correct: false,
          text: partsWithBraces[i],
        };

        answer.correct = true;
        newChunks.push(answer);
      } else {
        // split that text
        var txt = partsWithBraces[i].split(" ");
        txt.forEach((str) => {
          if (str !== "") {
            newChunks.push({
              id: uuidv4(),
              correct: false,
              text: str,
            });
          }
        });
      }
    }

    setChunks(newChunks);
  };

  const registerCorrectAnswer = () => {
    var split = chunks.map((chunk) => chunk.text);

    // get smallest index
    // get biggest index
    var si = Math.min(...sentenceIndices);
    var sb = Math.max(...sentenceIndices);

    split[si] = "{" + split[si];
    split[sb] = split[sb] + "}";

    changeValue(quiz.formattedSentence, split.join(" "));

    setSentenceIndices([]);
  };

  const onChangeContentEditable = (newValue: string) => {
    changeValue(quiz.formattedSentence, newValue);
  };

  const onBlurContentEditable = (innerText: string) => {
    // replace shitty ampersants
    changeValue(quiz.formattedSentence, innerText);

    setIsEditingSentence(false);
  };

  const getSplitSentenceView = () => {
    return chunks.map((chunk, index) => {
      return (
        <WordQuizSentenceSegment
          key={"wordquiz-" + index}
          id={"wordquiz-" + index}
          text={chunk.text}
          onClick={() => {
            onSentenceSegmentClick(index);
          }}
          highlight={sentenceIndices.find((i) => i === index) !== undefined}
          isCorrect={chunk.correct}
        />
      );
    });
  };

  const onSentenceSegmentClick = (index: number) => {
    // toggle
    if (sentenceIndices.find((i) => i === index)) {
      setSentenceIndices(sentenceIndices.filter((i) => i !== index));
    } else {
      // if not neighboring elem, clear array
      var isNeigh = false;
      for (var i = 0; i < sentenceIndices.length; i++) {
        var diff = Math.abs(sentenceIndices[i] - index);

        if (diff <= 1) {
          isNeigh = true;
          break;
        }
      }

      if (isNeigh) {
        setSentenceIndices([...sentenceIndices, index]);
      } else {
        setSentenceIndices([index]);
      }
    }
  };

  const contentEditable = (
    <PiContentEditable
      innerHtml={getValue(quiz.formattedSentence)}
      onChange={onChangeContentEditable}
      onBlur={onBlurContentEditable}
    />
  );

  const staticDisplay = (
    <div>
      {chunks.length >= 1 && getSplitSentenceView()}
      {(chunks.length === 0 || chunks[0].text === "") && (
        <span
          className="wordquiz-placeholder"
          onClick={() => setIsEditingSentence(true)}
        >
          Type a sentence
        </span>
      )}
      <IconButton
        className="wordquiz-edit-button"
        onClick={() => setIsEditingSentence(true)}
      >
        <EditOutlinedIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </div>
  );

  const contentDisplay = isEditingSentence ? contentEditable : staticDisplay;

  //#region Wrong answers
  const addWrongAnswer = () => {
    var answer: IAnswer = {
      id: uuidv4(),
      correct: false,
      text: getNewKey(),
    };

    const answers = [...quiz.answers];
    answers.push(answer);

    const copy: IWordQuiz = { ...quiz, answers: answers };
    dispatch(updateSelectedInteraction(copy));
  };

  const deleteWrongAnswer = (id: string) => {
    var filteredAnswers = quiz.answers.filter((a) => a.id !== id);
    const copy: IWordQuiz = { ...quiz, answers: filteredAnswers };
    dispatch(updateSelectedInteraction(copy));
  };
  const wrongAnswerArray = quiz.answers.map((answer, index) => {
    return (
      <WordSpinnerTextfield
        key={index}
        alignment="left"
        text={getValue(answer.text)}
        placeholder="Answer"
        notifyChangeSentence={() => {}}
        highlight={answer.correct}
        canDelete
        onDelete={() => deleteWrongAnswer(answer.id)}
        onChange={(text) => changeValue(answer.text, text)}
      />
    );
  });

  const addWrongAnswerButton = (
    <div className="wordquizbody-add-wrong-answer">
      <PiBasicQuizAddButton onClick={addWrongAnswer} />
    </div>
  );
  //#endregion

  //#region bottom answers
  const correctAnswerArray = chunks
    .filter((chunk) => chunk.correct)
    .map((chunk, index) => {
      return (
        <WordSpinnerTextfield
          key={index}
          alignment="left"
          text={chunk.text.replace("{", "").replace("}", "")}
          placeholder="Answer"
          notifyChangeSentence={() => {}}
          highlight={chunk.correct}
          canDelete
          onDelete={() => {
            var chunkref = chunks.find((c) => c.id === chunk.id);
            var ci = chunks.indexOf(chunkref!);
            var chunksCopy = [...chunks];
            chunksCopy[ci] = {
              ...chunksCopy[ci],
              correct: false,
              text: chunksCopy[ci].text.replace("{", "").replace("}", ""),
            };
            setChunks(chunksCopy);

            // also update source sentence
            var formatted = chunksCopy.map((c) => c.text).join(" ");
            changeValue(quiz.formattedSentence, formatted);
          }}
          onChange={(text) => {
            var chunkref = chunks.find((c) => c.id === chunk.id);
            var ci = chunks.indexOf(chunkref!);
            var chunksCopy = [...chunks];
            chunksCopy[ci] = { ...chunksCopy[ci], text: "{" + text + "}" };
            setChunks(chunksCopy);

            // also update source sentence
            var formatted = chunksCopy.map((c) => c.text).join(" ");
            changeValue(quiz.formattedSentence, formatted);
          }}
        />
      );
    });
  //#endregion

  return (
    <>
      <div id={"wordquiz-root"} className="wordquizbody-root">
        <div className="wordquizbody-background">
          <div className="wordquizbody-content">{contentDisplay}</div>
        </div>

        <div className="wordquizbody-wrong-answers">
          {correctAnswerArray} {wrongAnswerArray}
        </div>
        {addWrongAnswerButton}
      </div>
      <div
        style={{
          position: "absolute",
          top: y + "%",
          left: x + "%",
          display:
            sentenceIndices.length === 0 || isEditingSentence
              ? "none"
              : "block",
          transform: "translate(-25%, -8px)",
        }}
      >
        <PiEditMenu
          buttonSettings={[
            {
              icon: <CheckIcon fontSize="inherit" color="inherit" />,
              onClick: () => {
                registerCorrectAnswer();
              },
            },
          ]}
        />
      </div>
    </>
  );
}
