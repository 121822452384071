import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../app/slices/GlobalSlice";
export default function PiLoadingBackdrop() {
  const isLoading = useSelector(selectIsLoading);

  return (
    <Backdrop aria-label="PiLoadingBackdrop" open={isLoading} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 100 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
