import {useSelector} from "react-redux";
import {selectAllPropPresets, selectSelectedTake3D} from "../../../app/slices/TrainingSlice";
import {useMemo} from "react";
import InteractionPickerAccordion from "../InteractionPickerAccordion";
import usePropHelper from "../../../hooks/PropHelper";
import {InteractionDefintion} from "../InteractionDefinitions";
import InteractionPickerButton, {DynamicInteractionPickerButton} from "../InteractionPickerButton";

export default function InteractionPickerPropsAccordions() {
    const take = useSelector(selectSelectedTake3D);

    const {getInvolvedPropInteractions,getUsedPropByInstanceId} = usePropHelper();
const propPresets = useSelector(selectAllPropPresets);

    const getPickerAccordion = (propStateId: string, title: string, interactionDefinitions: InteractionDefintion[]) => {
        return (<InteractionPickerAccordion key={propStateId} title={title} interactionDefinitions={[]} onClose={() => {
        }}>
            {interactionDefinitions.map((interactionDef: InteractionDefintion, index: number) => {
                return <DynamicInteractionPickerButton key={index} interactionDefinition={interactionDef} onClose={() => {
                }} propStateId={propStateId}/>;
            })}
        </InteractionPickerAccordion>);
    }

    const accordionsMapped = useMemo(() => {
        if (!take) return;
        const defs = getInvolvedPropInteractions(take);
        
        const accordions = [];

        for (const propStateId in defs) {
            const interactions = defs[propStateId]; // interaction of the prop
            const propDto = getUsedPropByInstanceId(propStateId); // the used prop that contains the DTO + metadata
            const name = propPresets.find((p) => p.id === propStateId)?.name; // name of the prop preset
            
            if (propDto) {
                const title = propDto.propContent.name + " (" + propStateId + ")";
                accordions.push(getPickerAccordion(propStateId, name ?? "no preset found", interactions));
            }
        }
        return accordions;
    }, [take])


    return <div className={"h-full w-full "}>{accordionsMapped}</div>
}