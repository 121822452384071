import CloseIcon from "@mui/icons-material/Close";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import React, { useEffect, useRef, useState } from "react";
import PiEditMenu from "../../../components/PiEditMenu";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import "./WordSpinnerTextfield.css";
interface WordSpinnerTextfieldProps {
  text: string;
  placeholder: string;
  alignment: "left" | "center" | "right";
  highlight?: boolean;
  error?: boolean;
  notifyChangeSentence?: () => void;
  onChange: (text: string) => void;
  canDelete?: boolean;
  onDelete?: () => void;
  canToggle?: boolean;
  isToggled?: boolean;
  onToggle?: () => void;
}

export default function WordSpinnerTextfield(props: WordSpinnerTextfieldProps) {
  const { text, placeholder, onChange, alignment, highlight, error, notifyChangeSentence, canDelete, onDelete, canToggle, isToggled, onToggle } = { ...props };
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { getValue, changeValue } = useTrainingLocalization();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const getAlignmentClass = () => {
    if (alignment === "left") return "wordspinnertextfield-left";
    else if (alignment === "center") return "wordspinnertextfield-center";
    else if (alignment === "right") return "wordspinnertextfield-right";
  };

  useEffect(() => {
    autoGrow();
  }, [text]);

  const autoGrow = () => {
    if (inputRef.current) {
      var minWidth = 128;

      inputRef.current.style.width = "0";

      var desiredWidth = inputRef.current.scrollWidth;
      if (text.length === 0) desiredWidth = minWidth;

      inputRef.current.style.width = desiredWidth + "px";
    }
  };

  const onFocus = () => {
    if (canDelete) {
      setIsFocused(true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const focusedElement = event.relatedTarget || document.activeElement;

    if (!parentRef?.current?.contains(focusedElement)) {
      setIsFocused(false);
    }
  };

  const deleteButton = (
    <div
      className="wordspinnertextfield-delete-button"
      tabIndex={1}
      onClick={() => {
        setIsFocused(false);
        onDelete?.();
      }}
    >
      <CloseIcon />
    </div>
  );

  const toggleButton = (
    <div className="wordspinnertextfield-toggle-button" onClick={onToggle}>
      {!isToggled && <ContactSupportIcon color="inherit" fontSize="inherit" />}
      {isToggled && <FormatSizeIcon color="inherit" fontSize="inherit" />}
    </div>
  );

  const editMenu = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <ContactSupportIcon color="inherit" fontSize="inherit" />,
          onClick: onToggle!,
          highlight: isToggled,
        },
        {
          icon: <DeleteIcon color="inherit" fontSize="inherit" />,
          onClick: () => {
            setIsFocused(false);
            onDelete?.();
          },
          highlight: false,
        },
      ]}
    />
  );

  const editMenuNoToggle = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <DeleteIcon color="inherit" fontSize="inherit" />,
          onClick: () => {
            setIsFocused(false);
            onDelete?.();
          },
          highlight: false,
        },
      ]}
    />
  );

  return (
    <div ref={parentRef} tabIndex={-1} className={"wordspinnertextfield-root " + getAlignmentClass()} onFocus={onFocus} onBlur={handleBlur}>
      <input
        className={"wordspinnertextfield " + (highlight ? "wordspinnertextfield-highlight " : "") + (error ? "wordspinnertextfield-error" : "")}
        placeholder={placeholder}
        ref={inputRef}
        value={text}
        onChange={(e) => {
          onChange(e.target.value);
          if (notifyChangeSentence) notifyChangeSentence();
        }}
      />
      {isFocused && !canToggle && canDelete && <div className="wordspinnertextfield-toggle">{editMenuNoToggle}</div>}
      {isFocused && canToggle && <div className="wordspinnertextfield-toggle">{editMenu}</div>}
    </div>
  );
}
