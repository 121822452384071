import axios from "axios";
var BASE_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

interface LocalizationResult {
  translations: TranslationData[];
}

interface TranslationData {
  text: string;
  to: string;
}

export class TranslationService {
  static async TranslateTexts(token: string, texts: string[], from: string, to: string) {
    var path = "/translate";
    var url = BASE_URL + path;

    var body = {
      languageFrom: from,
      languageTo: to,
      texts: texts,
    };

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    var result: string[] = [];
    await axios
      .post<string[]>(url, body, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response));

    console.log(result);
    return result;
  }
}
