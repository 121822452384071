import React, { useContext, useEffect } from "react";
import { OnboardingContext } from "./OnboardingContext";
import { OnboardingService } from "./OnboardingService";

export interface AutoOnboardingProps {
  context: string;
}

export default function AutoOnboarding(props: AutoOnboardingProps) {
  const { state, setState } = useContext(OnboardingContext);

  useEffect(() => {
    const visited = OnboardingService.HasVisited(props.context);
    if (!visited) {
      setState((prevState) => ({
        ...prevState,
        context: props.context,
      }));
    }
  }, []);

  useEffect(() => {
    if (state.context !== props.context) return;

    const visited = OnboardingService.HasVisited(props.context);

    if (!visited) {
      OnboardingService.SetVisited(props.context);

      setState((prevState) => ({
        ...prevState,
        run: true,
        onboardingActive: true,
      }));
    }
  }, [state.context]);

  return <></>;
}
