import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { useSelector } from "react-redux";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import React, { useMemo } from "react";
import {
  selectPropById,
  selectProps,
  selectUsedProps,
} from "../../../app/slices/PropLibrarySlice";
import { RootState } from "../../../app/Store";
import { PropTakeState } from "../../../models/PropTakeState";
import usePropHelper from "../../../hooks/PropHelper";

// includes props that are not spawned yet & props that are attached
export default function AllPropsDropdown(props: {
  label: string;
  selectedPropStateId: string;
  onClick: (propStateId: string) => void;
}) {
  const { label, selectedPropStateId, onClick } = { ...props };
  const { getIsPropAttachedToActor } = usePropHelper();

  const allPropPresets = useSelector(selectAllPropPresets);
  const selectedPropPreset = allPropPresets.find(
    (prop) => prop.id === selectedPropStateId
  );

  const propDropdownItems = allPropPresets.map((prop) => {
    return (
      <PiInteractionDropdown.Item
        key={prop.id}
        onClick={() => onClick(prop.id)}
      >
        <div className={"flex justify-center gap-4 w-full"}>
          <div className={"my-auto truncate"}>
            {allPropPresets.find((p) => p.id === prop.id)?.name}
          </div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const propDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>
            {selectedPropPreset?.name ?? "No Prop"}
          </div>
        </div>
      </PiInteractionDropdown.Trigger>
      {propDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{propDropdown}</>;
}
