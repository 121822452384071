import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L, { Coords } from "leaflet";
import "leaflet/dist/leaflet.css";
import UsePiTileLayer from "./PiTileLayer.hook";
import { useSelector } from "react-redux";
import {
  selectCachedLeafletData,
  selectCurrentEnvironmentId
} from "../../app/slices/EnvironmentSlice";

class PiTileLayer extends L.TileLayer {
  fetchTileUrl: (
    coords: L.Coords,
    cache: Record<string, LeafletData>
  ) => Promise<string>;
  cache: Record<string, LeafletData>;

  constructor(
    urlTemplate: string,
    fetchTileUrl: (
      coords: L.Coords,
      cache: Record<string, LeafletData>
    ) => Promise<string>,
    cache: Record<string, LeafletData>,
    options?: L.TileLayerOptions
  ) {
    super(urlTemplate, options);
    this.fetchTileUrl = fetchTileUrl;
    this.cache = cache;
  }

  setCache(cache: Record<string, LeafletData>) {
    this.cache = cache;
  }

  protected createTile(coords: L.Coords, done: L.DoneCallback): HTMLElement {
    //return super.createTile(coords, done);
    const tile = document.createElement("img");

    tile.onload = () => {
      done(undefined, tile);
    };

    tile.onerror = () => {
      done(new Error("Tile loading error"), tile);
    };

    this.fetchTileUrl(coords, this.cache)
      .then((url) => {
        tile.src = url;
      })
      .catch(() => {
        done(new Error("Tile URL fetching error"), tile);
      });

    return tile;
  }
}

export default function PiTileLayerComponent({
  urlTemplate,
}: {
  urlTemplate: string;
}) {
  const { fetchTileUrl } = UsePiTileLayer();

  const map = useMap();
  const environmentId = useSelector(selectCurrentEnvironmentId);
  const cachedLeafletData = useSelector(selectCachedLeafletData);

  const [layer, setLayer] = useState<PiTileLayer | null>(null);

  useEffect(() => {
    const layer = new PiTileLayer(
      urlTemplate,
      fetchTileUrl,
      cachedLeafletData,
      { noWrap: true }
    );
    map.addLayer(layer);
    setLayer(layer);
    return () => {
      map.removeLayer(layer);
    };
  }, [map, environmentId]);

  useEffect(() => {
    layer?.setCache(cachedLeafletData);
  }, [cachedLeafletData]);

  return null;
}
