import React from "react";
import { useNavigate } from "react-router-dom";
import "./PiMenuButton.css";
import classNames from "classnames";

interface PiInteractionButtonProps {
  text: string;
  id?: string;
  icon?: React.ReactNode;
  link?: string;
  doHighlight?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  label?: string;
}

export default function PiInteractionButton(props: PiInteractionButtonProps) {
  const navigate = useNavigate();

  const onClick = () => {
    if (props.disabled) return; // ignore if button is disabled

    if (props.link) {
      navigate(props.link);
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  const label = (
    <div className="pimenu-button-label">
      <div className="pimenu-button-label-content">{props.label}</div>
    </div>
  );

  return (
    <button
      id={props.id}
      className={classNames({
        "flex overflow-x-hidden overflow-y-hidden align-middle text-left h-[46px] pl-[4px] font-14 cursor-pointer w-[250px] rounded-[5px] select-none m-2": true,
        "bg-interaction-text-selectedBackground": props.doHighlight,
        "hover:bg-interaction-text-hoverBackground": true,
        "bg-white  pointer-events-none text-interaction-disabled":
          props.disabled,
      })}
      onClick={() => onClick()}
    >
      {props.icon && (
        <div
          className={classNames({
            "mx-2 text-[20px] my-auto flex": true,
            "text-creator-primary ": props.doHighlight,
          })}
        >
          {props.icon}
        </div>
      )}
      <div
        className={classNames({
          "my-auto overflow-hidden whitespace-nowrap text-ellipsis text-[14px] ml-1 pointer-events-none":
            true,
          "text-creator-primary": props.doHighlight,
        })}
      >
        {props.text}
      </div>
      {props.label && label}
    </button>
  );
}
