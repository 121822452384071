import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../Store";

interface AuthenticationState {
  email: string;
  jwt: string;
  organizationIDs: string[];
  authenticated: boolean;
  userID: string;
  refreshToken: string;
}

const initialState: AuthenticationState = {
  email: localStorage.getItem("email") || "",
  jwt: localStorage.getItem("jwt") || "",
  organizationIDs: [],
  authenticated: false,
  userID: localStorage.getItem("userID") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
};

// authentication slice
export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setAuthenticationSuccess: (state, action) => {
      state.email = action.payload.email;
      state.jwt = action.payload.jwt;
      state.userID = action.payload.userID;
      state.organizationIDs = action.payload.organizationIDs;
      state.refreshToken = action.payload.refreshToken;

      // add jwt, userID, email & refreshtoken to localstorage because we need it to re-authenticate
      localStorage.setItem("jwt", state.jwt);
      localStorage.setItem("userID", state.userID);
      localStorage.setItem("email", state.email);
      localStorage.setItem("refreshToken", state.refreshToken);
    },
    setReauthenticationSuccess: (state, action) => {
      state.jwt = action.payload.jwt;
      state.organizationIDs = action.payload.organizationIDs;
      state.userID = action.payload.userID;
      state.refreshToken = action.payload.refreshToken;

      localStorage.setItem("jwt", state.jwt);
      localStorage.setItem("userID", state.userID);
      localStorage.setItem("refreshToken", state.refreshToken);
    },
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.authenticated = action.payload;
    },
    setLogout: (state) => {
      state.jwt = "";
      state.organizationIDs = [];
      state.userID = "";
      state.refreshToken = "";
      state.authenticated = false;
      state.email = "";

      localStorage.removeItem("jwt");
      localStorage.removeItem("userID");
      localStorage.removeItem("email");
      localStorage.removeItem("refreshToken");
    },
  },
});

// reducers
export const { setAuthenticationSuccess, setReauthenticationSuccess, setLogout, setIsAuthenticated } = authenticationSlice.actions;

// selectors
export const selectUsername = (state: RootState) => state.authentication.email;
export const selectAuthenticated = (state: RootState) => state.authentication.authenticated;
export const selectToken = (state: RootState) => state.authentication.jwt;
export const selectRefreshToken = (state: RootState) => state.authentication.refreshToken;
export default authenticationSlice.reducer;
