import React from "react";
import {PiIconProps} from "./Icons";

export default function PiQuizOutlineIcon(props: PiIconProps) {

    const {width, height, className} = props;

    return (<svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 -960 960 960" width={width} className={className}>
        <path
            d="M566.696-382.61q17 0 28.5-11.5t11.5-28.5q0-17-11.5-28.5t-28.5-11.5q-17 0-28.5 11.5t-11.5 28.5q0 17 11.5 28.5t28.5 11.5Zm.275-112.391q10.725 0 19.225-7.717 8.5-7.717 12.196-20.197 3.131-9.825 8.131-18.259 5-8.435 14.478-16.913 31.434-30.435 42.717-49.5 11.283-19.065 11.283-40.935 0-46.13-29.718-73.065-29.717-26.935-80.403-26.935-31.927 0-57.403 14.5t-41.476 39.935q-4.435 9-.172 20t15.172 16q10 4 20 1t17-13q8-11 20.782-17.218 12.783-6.217 26.566-6.217 23.521 0 37.586 12.717 14.066 12.718 14.066 32.587 0 14.273-8.5 27.017-8.5 12.743-39.5 42.114-13.478 13.608-20.326 26.674-6.848 13.065-9.413 28.978-1.566 11.034 6.734 19.734 8.3 8.7 20.975 8.7ZM331.784-233.782q-41.005 0-69.504-28.498-28.498-28.499-28.498-69.504v-466.434q0-41.005 28.498-69.503 28.499-28.498 69.504-28.498h466.434q41.005 0 69.503 28.498 28.498 28.498 28.498 69.503v466.434q0 41.005-28.498 69.504-28.498 28.498-69.503 28.498H331.784Zm0-98.002h466.434v-466.434H331.784v466.434ZM161.782-63.781q-41.005 0-69.503-28.498-28.498-28.498-28.498-69.503v-515.435q0-20.387 14.246-34.694 14.246-14.307 34.544-14.307 20.298 0 34.755 14.307 14.456 14.307 14.456 34.694v515.435h515.435q20.387 0 34.694 14.246 14.307 14.246 14.307 34.544 0 20.298-14.307 34.754-14.307 14.457-34.694 14.457H161.782Zm170.002-734.437v466.434-466.434Z"/>
    </svg>);
}