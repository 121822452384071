import { useSelector } from "react-redux";
import QuizPromptPreview from "../QuizPromptPreview";
import { IAnswer, IFormQuiz } from "../../../models/Quiz";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import ImageButtonQuizAnswer from "./ImageButtonQuizAnswer";
import { useDispatch } from "react-redux";
import { GetNewGuid } from "../../../helpers/GuidHelper";
import ImageButtonQuizAddAnswerButton from "./ImageButtonQuizAddAnswerButton";

export default function ImageButtonQuizPreview() {
  const interaction = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onAddAnswer = () => {
    const newAnswers = [
      ...interaction.answers,
      {
        id: GetNewGuid(),
        text: "",
        assetId: "",
        correct: false,
      },
    ];
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        answers: newAnswers,
      } as IFormQuiz)
    );
  };

  const onAnswerChange = (answer: IAnswer) => {
    const newAnswers = interaction.answers.map((a) => {
      if (a.id === answer.id) {
        return answer;
      }
      if (answer.correct) return { ...a, correct: false };

      return a;
    });

    dispatch(
      updateSelectedInteraction({
        ...interaction,
        answers: newAnswers,
      } as IFormQuiz)
    );
  };

  const onAnswerDelete = (answer: IAnswer) => {
    const newAnswers = interaction.answers.filter((a) => a.id !== answer.id);
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        answers: newAnswers,
      } as IFormQuiz)
    );
  };

  const titlePrompt = (
    <QuizPromptPreview
      value={getValue(interaction.title)}
      onChange={(newValue: string) => changeValue(interaction.title, newValue)}
    />
  );

  const answersMapped = interaction.answers.map((answer) => {
    return (
      <ImageButtonQuizAnswer
        key={answer.id}
        answer={answer}
        onChange={onAnswerChange}
        onDelete={() => onAnswerDelete(answer)}
        canDelete={interaction.answers.length > 2}
      />
    );
  });

  const addAnswerButton = (
    <ImageButtonQuizAddAnswerButton onClick={onAddAnswer} />
  );

  return (
    <div className="relative w-full h-full bg-black bg-opacity-60 backdrop-blur-sm">
      {titlePrompt}
      <div className="w-full flex gap-[16px] justify-center absolute bottom-[64px]">
        {answersMapped}
        {interaction.answers.length < 5 && addAnswerButton}
      </div>
    </div>
  );
}
