import {PropInteractionDTO} from "../../dto/PropInteractionDTO";

export default function PropLibraryInteractionLabel(props: { interactionName: string }) {
    const {interactionName} = {...props};

    return <div className={"flex gap-[10px] h-[36px] px-[10px] rounded-full border-2 border-solid border-creator-text-faded"}>
        <div className={"my-auto font-medium text-creator-text-sub"}>
            {interactionName}
        </div>
    </div>
}