import {configureStore} from "@reduxjs/toolkit";
import assetReducer from "./slices/AssetsSlice";
import authenticationReducer from "./slices/AuthenticationSlice";
import environmentReducer from "./slices/EnvironmentSlice";
import globalSlice from "./slices/GlobalSlice";
import interactionPickerReducer from "./slices/InteractionPickerSlice";
import metadataReducer from "./slices/MetadataSlice";
import projectsReducer from "./slices/ProjectsSlice";
import trainingLocalizationReducer from "./slices/TrainingLocalizationSlice";
import trainingReducer from "./slices/TrainingSlice";
import userReducer from "./slices/UserSlice";
import propLibraryReducer from "./slices/PropLibrarySlice";

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        user: userReducer,
        projects: projectsReducer,
        assets: assetReducer,
        training: trainingReducer,
        trainingLocalization: trainingLocalizationReducer,
        interactionPicker: interactionPickerReducer,
        global: globalSlice,
        environment: environmentReducer,
        metadata: metadataReducer,
        propLibrary: propLibraryReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
