import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addActor } from "../../app/slices/TrainingSlice";
import { Gender, GenderNeutralNames, ProfilePictures } from "../../models/Actor";
import ActorPresetList from "./ActorPresetList";
import "./ActorPresetsColumn.css";
import PiButton from "../../components/PiButton";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import React from "react";

export default function ActorPresetsColumn() {
  const dispatch = useDispatch();
  const onAddNewActor = () => {

    const name = GenderNeutralNames[Math.floor(Math.random() * GenderNeutralNames.length)];

    dispatch(addActor({ id: uuidv4(), profilePicture: Math.floor(Math.random() * ProfilePictures.length), workingName: name, gender: Gender.random }));
  };

  const addActorButton = (
    <div className="px-[18px]">
      <PiButton variant="piBlue" onClick={onAddNewActor}>
        <div className={"h-full flex justify-center mx-auto justify-items-center"}>
          <div className={"flex gap-[8px] my-auto text-[24px]"}>
            <PersonAddAltIcon className={""} fontSize={"inherit"}/>
            <div className={"text-[14px] my-auto"}>Add Actor</div>
          </div>
        </div>
      </PiButton>
    </div>
  );

  return (
    <div className="flex flex-col gap-y-[10px] max-h-[100%]">
      <ActorPresetList/>
      {addActorButton}
    </div>
  );
}
