import axios from "axios";
import { CharacterPreset } from "../models/CharacterPreset";
import { Outfit } from "./../models/Outfit";
export class MetadataService {
  static async GetOutfits(): Promise<Outfit[]> {
    var result: Outfit[] = [];

    await axios.get<Outfit[]>("/outfits/Outfits.json").then((response) => {
      result = response.data;
    });
    return result;
  }

  static async GetCharacterPresets(): Promise<CharacterPreset[]> {
    var result: CharacterPreset[] = [];

    await axios.get<CharacterPreset[]>("/presets/presets.json").then((response) => {
      result = response.data;
    });
    return result;
  }
}
