import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PiMenuButton from "../menu/PiMenuButton";
import "./PiChangeLanguageDialog.css";
interface PiChangeLanguageDialogProps {
  open: boolean;
  onConfirm: () => void;
}

interface Language {
  short: string;
  full: string;
}

export default function PiChangeLanguageDialog(props: PiChangeLanguageDialogProps) {
  const { t, i18n } = useTranslation();

  const languages: Language[] = [
    { short: "en", full: "English" },
    { short: "nl", full: "Nederlands" },
    { short: "es", full: "Español" },
    { short: "fr", full: "Français" },
    { short: "de", full: "Deutsch" },
  ];
  const buttons = languages.map((lang: Language, index: number) => {
    return <PiMenuButton text={lang.full} onClick={() => onChangeLanguage(lang.short)} key={index} />;
  });

  function onChangeLanguage(language: string) {
    i18n.changeLanguage(language);
    localStorage.setItem("editorLanguage", language);
    props.onConfirm();
  }

  function onClose() {
    props.onConfirm();
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={"xs"}
      fullWidth={true}
      PaperProps={{
        style: {
          minHeight: "15vh",
          maxHeight: "75vh",
        },
      }}
    >
      <DialogTitle>
        <div>{t("changeLanguage")}</div>
        <IconButton className="pi-changelanguage-close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{buttons}</DialogContent>
    </Dialog>
  );
}
