import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, ButtonGroup } from "@mui/material";
import React from "react";
import PiPopper, { PiPopperMenuProps } from "./PiPopper";
import "./PiSplitButton.css";
export interface PiSplitButtonData {
  text: string;
  icon: JSX.Element;
  onClick: () => void;
}

interface PiSplitButtonProps {
  options: PiSplitButtonData[];
  variant: "piBlueOutlined" | "piRedOutlined";
  selectedIndex: number;
  onChangeSelectedIndex: (newIndex: number) => void;
  disableDropdown?: boolean;
  disabled?: boolean;
}

export default function PiSplitButton(props: PiSplitButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const popperOptions: PiPopperMenuProps[] = props.options.map((option, index) => {
    var r: PiPopperMenuProps = {
      text: option.text,
      onClick: (index) => {
        props.onChangeSelectedIndex(index);
        setOpen(false);
      },
    };
    return r;
  });

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    props.onChangeSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div id="pi-split-button">
      <ButtonGroup ref={anchorRef} aria-label="split button" disabled={props.disabled}>
        <Button aria-label="pisplitbutton-main-action" variant={props.variant} onClick={props.options[props.selectedIndex].onClick}>
          <div className="pisplitbutton-main-icon"> {props.options[props.selectedIndex].icon}</div>
          <div className="pisplitbutton-main-text">{props.options[props.selectedIndex].text}</div>
        </Button>
        <Button
          id="pi-split-button-dropdown"
          variant={props.variant}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="pisplitbutton-dropdown-button"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={props.disableDropdown}
        >
          <KeyboardArrowDownIcon className="pisplitbutton-icon" />
        </Button>
      </ButtonGroup>
      {/* <Popper
        sx={{
          zIndex: 1,
          width: "200px",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        aria-label="pisplitbutton-popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option, index) => (
                    <MenuItem aria-label={option.text} key={option.text} disabled={index === 2} selected={index === props.selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
      <PiPopper anchorRef={anchorRef} onClose={() => setOpen(false)} open={open} selectedIndex={props.selectedIndex} items={popperOptions} width={"230px"} />
    </div>
  );
}
