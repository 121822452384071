import {GetNewGuid} from "../helpers/GuidHelper";

export default function PiInteractionCarouselDots(props: { selectedIndex: number, totalDots: number }) {
    const {selectedIndex, totalDots} = {...props};

    const getMappedDots = () => {
        const dotsMapped: JSX.Element[] = [];

        for (let i = 0; i < totalDots; i++) {
            if (i === selectedIndex) {  
                dotsMapped.push(<SelectedDot key={i}/>);
            } else {
                dotsMapped.push(<NormalDot key={i} />);
            }
        }

        return dotsMapped;
    }

    return <div className={"flex w-full h-[24px] gap-[8px]"}>
        {getMappedDots()}
    </div>
}

function NormalDot() {
    return <div className={"my-auto bg-interaction-primary-faded w-[16px] h-[16px] rounded-full"}></div>
}

function SelectedDot() {
    return <div className={"my-auto bg-interaction-primary w-[20px] h-[20px] rounded-full"}></div>;
}