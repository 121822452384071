import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentTrainingPageNumber, setTrainingFilter } from "../../app/slices/ProjectsSlice";
import { BreadcrumbItem } from "../../components/PiBreadcrumbs";
import { useGetTrainings } from "../../hooks/GetTrainingsHook";
import ProjectsTable from "../dashboard_project_table/ProjectsTable";
import AutoOnboarding from "../onboarding/AutoOnboarding";
import OnboardingButton from "../onboarding/OnboardingButton";
import "../pages/page.css";
import "./Dashboard.css";
import DashboardHeader from "./DashboardHeader";
export default function DashboardPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    // clear stuff
    // clear projects table
    dispatch(setTrainingFilter(""));
    dispatch(setCurrentTrainingPageNumber(1));
  }, []);

  // should this code be here?
  const [] = useGetTrainings(true);

  const breadcrumbItems: BreadcrumbItem[] = [{ name: "Play It - Creator" }, { name: t("dashboard") }];

  function newTrainingClicked() {
    navigate("/newTraining");
  }

  return (
    <>
      <div className="page-root">
        <div className="page-header">
          <DashboardHeader subtitle={t("welcome")} breadcrumbs={breadcrumbItems}>
            <Button id="newtraining-button" variant="piBlue" onClick={newTrainingClicked}>
              {t("newTraining")}
            </Button>
          </DashboardHeader>
        </div>
        <div className="page-content page-content-margin">
          <ProjectsTable />
        </div>
      </div>
      <AutoOnboarding context="dashboard" />
      <OnboardingButton context="dashboard" position="fixed" />
    </>
  );
}
