import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetSupportedFiletypes } from "../../app/slices/AssetsSlice";
import { AssetKey } from "../../dto/AssetDTO";
import { useUploadVariant } from "../../hooks/UploadVariantHooks";
import "./VariantFileDropzone.css";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";

interface VariantFileDropzoneProps {
  id: string;
  assetKey: AssetKey;
}

export default function VariantFileDropzone(props: VariantFileDropzoneProps) {
  const { id, assetKey } = { ...props };
  const [dragActive, setDragActive] = React.useState(false);
  const { t } = useTranslation();
  const supportedFileTypes = GetSupportedFiletypes(assetKey);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { handleFiles } = useUploadVariant();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  // handle drag events
  const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      supportedFileTypes.includes(e.dataTransfer.files[0].type)
    ) {
      // at least one file has been dropped so do something
      //handleFiles(e.dataTransfer.files);

      handleFiles(id, supportedFileTypes, e.dataTransfer.files[0]);
    } else {
      // not supported file type -> open dialog
      setIsDialogOpen(true);
    }
  };

  const handleFileInput = function (e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files?.length > 0) {
      //handleFiles(e.target.files);
      handleFiles(id, supportedFileTypes, e.target.files[0]);
    }
  };

  const openFileExplorer = () => {
    if (!isDialogOpen) inputRef.current?.click();
  };

  const classname = dragActive
    ? "variantfiledropzone-root variantfiledropzone-dragactive"
    : "variantfiledropzone-root";

  return (
    <div
      className={classname}
      onDragEnter={handleDrag}
      onClick={openFileExplorer}
    >
      <div className="variantfiledropzone-icon">
        <AddIcon fontSize="inherit" color="inherit" />
      </div>
      <div className="variantfiledropzone-text">
        <span className="variantfiledropzone-text-highlight">
          {t("browse_file")}{" "}
        </span>
        <span>{t("or_drag_and_drop")}</span>
      </div>

      {dragActive && (
        <div
          className="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}

      <input
        className="filedropzone-hide-input"
        ref={inputRef}
        type="file"
        onChange={handleFileInput}
        accept={supportedFileTypes.toString()}
      />

      <PiConfirmationDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="Unsupported filetype"
        text="File does not match variant file type"
        cancelText="OK"
        confirmButtonVariant="piRed"
        confirmText="OK"
        onConfirm={() => setIsDialogOpen(false)}
        height="225px"
        hideCancelButton
      />
    </div>
  );
}
