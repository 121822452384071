import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticated, selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentTrainingPage, selectTrainingFilter, selectTrainingPageSize, selectTrainingSortBy, setCurrentTrainingPage, setTotalPages } from "../app/slices/ProjectsSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { TrainingDetails, getTrainingDetailsFromDTO } from "../models/TrainingDetails";
import { TrainingService } from "../services/TrainingService";

export const useGetTrainings = (autoRefresh: boolean) => {
  var dispatch = useDispatch();
  var isAuthenticated = useSelector(selectAuthenticated);
  var orgId = useSelector(selectCurrentOrganization)?.id;
  var token = useSelector(selectToken);
  var filter = useSelector(selectTrainingFilter);
  var sortBy = useSelector(selectTrainingSortBy);
  var currentPage = useSelector(selectCurrentTrainingPage);
  var pageSize = useSelector(selectTrainingPageSize); // this will always remain the same

  useEffect(() => {
    if (isAuthenticated && autoRefresh) {
      getTrainings();
    }
  }, [isAuthenticated, filter, sortBy, currentPage, orgId]);

  const getTrainings = async () => {
    var result = await TrainingService.GetTrainings(orgId!, token, filter, pageSize, currentPage, sortBy);
    var trainings: TrainingDetails[] = [];

    // mapping dto to model
    result.trainings.forEach((dto) => {
      trainings.push(getTrainingDetailsFromDTO(dto));
    });

    dispatch(setCurrentTrainingPage(trainings));
    dispatch(setTotalPages(result.totalPages));
  };

  return [getTrainings] as const;
};
