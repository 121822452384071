import { MovieOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  selectSelectedElementId,
  setSelectedInteraction,
  setSelectedTake,
} from "../../app/slices/TrainingSlice";
import { InteractionType } from "../../models/interactions/Interaction";
import { AllInteractions } from "../interaction_picker/InteractionDefinitions";
import { useInteractionPickerIcons } from "../interaction_picker/InteractionPickerIconHooks";
import { useSelector } from "react-redux";
import classNames from "classnames";

export interface AdvancedSearchResulFieldProps {
  objectId: string;
  matchingText: string;
  query: string;
  isHidden: boolean;
  takeName?: string;
  interactionType?: InteractionType | null;
  profilePicture?: string;
  resultId: string;
  onSelect: (resultId: string) => void;
  isSelected: boolean;
}

export default function AdvancedSearchResultField(
  props: AdvancedSearchResulFieldProps
) {
  const {
    matchingText,
    query,
    objectId,
    interactionType,
    takeName,
    isHidden,
    profilePicture,
    resultId,
    isSelected,
    onSelect,
  } = { ...props };

  const dispatch = useDispatch();
  const [getIcon] = useInteractionPickerIcons();
  const [before, queryString, after] = splitStringAroundQuery(
    matchingText,
    query
  );

  // Find the interaction definition based on the interaction type
  const interactionDef = interactionType
    ? AllInteractions.find((def) => def.type === interactionType)
    : null;

  const handleClick = () => {
    onSelect(resultId);
    interactionDef
      ? dispatch(setSelectedInteraction(objectId))
      : dispatch(setSelectedTake(objectId));
  };

  const icon = interactionDef ? (
    <div className="text-[22px]">{getIcon(interactionDef)}</div>
  ) : (
    <MovieOutlined fontSize="inherit" color="inherit" />
  );

  const MAX_LENGTH = 20; // Adjust this value based on the allowed character length for the field

  const truncateAroundQueryString = (
    before: string,
    queryString: string,
    after: string,
    maxLength: number
  ) => {
    const queryStringLength = queryString.length;
    const allowedLength = maxLength - queryStringLength;

    // Check if no truncation is needed
    if (before.length + after.length <= allowedLength) {
      return { truncatedBefore: before, truncatedAfter: after };
    }

    const halfAllowed = Math.floor(allowedLength / 2);
    let truncatedBefore =
      before.length > halfAllowed
        ? "..." + before.slice(before.length - halfAllowed)
        : before;
    let truncatedAfter =
      after.length > halfAllowed ? after.slice(0, halfAllowed) + "..." : after;

    return { truncatedBefore: truncatedBefore, truncatedAfter: truncatedAfter };
  };

  // Ensure that no spaces are inserted during rendering
  const renderResult = (before: string, queryString: string, after: string) => {
    return (
      <>
        <span className="truncate">{before.replace(/ /g, "\u00A0")}</span>
        <span className="bg-creator-primary-washed text-creator-primary whitespace-nowrap">
          {queryString.replace(/ /g, "\u00A0")}
        </span>
        <span className="truncate">{after.replace(/ /g, "\u00A0")}</span>
      </>
    );
  };

  const { truncatedBefore, truncatedAfter } = truncateAroundQueryString(
    before,
    queryString,
    after,
    MAX_LENGTH
  );

  const view = (
    <button
      className={classNames({
        "box-border relative flex w-full items-center h-[50px] max-w-[292px] pb-[10px] pl-[17px] pt-[10px] pr-[17px] border-b-[1px] border-creator-border hover:bg-creator-primary-washed":
          true,
        "bg-creator-childActive": isSelected,
      })}
      onClick={handleClick}
    >
      <div className="flex items-center h-full w-full text-[12px] font-normal text-creator-text-sub text-ellipsis">
        <div className="flex-shrink-0 text-[22px]">{icon}</div>
        <div className={`${profilePicture ? "ml-[10px]" : ""} `}>
          {profilePicture ? getProfilePicture(profilePicture) : ""}
        </div>
        <div className="flex flex-col items-start ml-2">
          <div className="text-[10px] font-normal">{takeName}</div>
          <div className="flex flex-row w-full ">
            {renderResult(truncatedBefore, queryString, truncatedAfter)}
          </div>
        </div>
      </div>
    </button>
  );

  return <>{isHidden ? "" : view}</>;
}

function splitStringAroundQuery(
  mainString: string,
  queryString: string
): [string, string, string] {
  const queryIndex = mainString
    .toLowerCase()
    .indexOf(queryString.toLowerCase());

  if (queryIndex === -1) {
    return [mainString, "", ""]; // Query not found
  }

  const beforeQuery = mainString.substring(0, queryIndex);
  const matchingQuery = mainString.substring(
    queryIndex,
    queryIndex + queryString.length
  );
  const afterQuery = mainString.substring(queryIndex + queryString.length);

  return [beforeQuery, matchingQuery, afterQuery];
}

function getProfilePicture(profilePicture: string) {
  if (profilePicture === "player") {
    const playerIcon = (
      <div className="flex items-center justify-center font-medium bg-creator-primary w-9 h-9 text-interaction-text-inverted text-16 rounded-circle">
        P
      </div>
    );

    return playerIcon;
  } else {
    const npcIcon = (
      <img
        className="flex flex-col items-center object-contain border-2 min-w-9 min-h-9 max-w-9 max-h-9 rounded-circle border-creator-primary"
        src={profilePicture}
        alt="icon"
      />
    );

    return npcIcon;
  }
}
