import React, { createContext, useEffect, useState } from "react";
import Joyride, { ACTIONS, STATUS, Step } from "react-joyride";
import { OnboardingService } from "./OnboardingService";
import { OnboardingTooltip } from "./OnboardingTooltip";

export interface IOnboardingState {
  run: boolean;
  steps?: Step[];
  onboardingActive?: boolean;
  context?: string;
}

export const onboardingState: IOnboardingState = {
  run: true,
  steps: [],
  onboardingActive: true,
};

interface OnboardingContextProps {
  state: IOnboardingState;
  setState: React.Dispatch<React.SetStateAction<IOnboardingState>>;
}

interface OnboardingProviderProps {
  children: React.ReactNode;
}

export const OnboardingContext = createContext<OnboardingContextProps>({
  state: onboardingState,
  setState: () => {},
});

export const OnboardingProvider: React.FC<OnboardingProviderProps> = ({ children }) => {
  const [state, setState] = useState<IOnboardingState>(onboardingState);

  const handleCallback = (data: any) => {
    const { status, action } = data;
    if (status === STATUS.FINISHED || action === ACTIONS.CLOSE) {
      setState((prevState) => ({
        ...prevState,
        run: false,
        onboardingActive: false,
      }));
    }
  };

  useEffect(() => {
    if (state.context === undefined) return;

    setState((prevState) => ({
      ...prevState,
      steps: OnboardingService.GetSteps(state.context as string),
    }));
  }, [state.context]);

  return (
    <OnboardingContext.Provider value={{ state, setState }}>
      {children}
      <>
        {state.onboardingActive && (
          <Joyride
            steps={state.steps as []}
            continuous={true}
            callback={handleCallback}
            run={state.run}
            scrollToFirstStep={false}
            scrollDuration={0}
            showProgress={true}
            showSkipButton={false}
            disableOverlay={false}
            disableScrolling={true}
            spotlightClicks={false}
            scrollOffset={0}
            spotlightPadding={0}
            tooltipComponent={OnboardingTooltip}
            styles={{
              options: {
                zIndex: 2000,
              },
            }}
          />
        )}
      </>
    </OnboardingContext.Provider>
  );
};
