import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { AssetDetailsDTO } from "../../../dto/AssetDTO";
import { IAnswer } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import "./ConnectQuizAnswer.css";
export interface ConnectQuizAnswerProps {
  answer: IAnswer;
  side: "A" | "B";
  isSelected: boolean;
  onConnectionClick: (answer: IAnswer, side: "A" | "B") => void;
  canDelete: boolean;
  onDelete: (answer: IAnswer, side: "A" | "B") => void;
  horizontal?: boolean;
  onAssetSelect?: (answer: IAnswer, side: "A" | "B", Asset: AssetDetailsDTO) => void;
}

export default function ConnectQuizAnswer(props: ConnectQuizAnswerProps) {
  const { getValue, changeValue } = useTrainingLocalization();
  const { answer, side, isSelected, onConnectionClick, canDelete, onDelete, horizontal } = { ...props };

  const rootClassVert = side === "A" ? "connectquizanswer-root connectquizanswer-root-a" : "connectquizanswer-root connectquizanswer-root-b";
  const textfieldClass = side === "A" ? "connectquizanswer-textfield connectquizanswer-textfield-a" : "connectquizanswer-textfield connectquizanswer-textfield-b";

  const dotButton = <button className={answer.id + " connectquizanswer-button " + (isSelected ? "connectquizanswer-selected" : "")} onClick={() => onConnectionClick(answer, side)}></button>;
  const textfield = <input className={textfieldClass} placeholder="Answer" value={getValue(answer.text)} onChange={(e) => changeValue(answer.text, e.target.value)} />;
  const deleteButton = (
    <div className="connectquizanswer-delete-button" onClick={() => onDelete(answer, side)}>
      <CloseIcon color="inherit" fontSize="inherit" />
    </div>
  );

  const rootclasses = !horizontal ? rootClassVert : "connectquizanswer-root-horz";

  const horzAnswer = (
    <div className="connectquizanswer-horizontal-side-a">
      <div className="connectquizanswer-horizontal-content">
        <input
          className="connectquizanswer-textfield connectquizanswer-textfield-b connectquizanswer-textfield-horizontal"
          placeholder="Answer"
          value={getValue(answer.text)}
          onChange={(e) => changeValue(answer.text, e.target.value)}
        />
        {canDelete && deleteButton}
      </div>
      {dotButton}
    </div>
  );

  const vert =
    side === "A" ? (
      <>
        {canDelete && deleteButton}
        {textfield}
        {dotButton}
      </>
    ) : (
      <>
        {dotButton}
        {textfield}
        {canDelete && deleteButton}
      </>
    );

  const horz = side === "A" ? horzAnswer : horzAnswer;

  const body = !horizontal ? vert : horz;

  return <div className={rootclasses}>{body}</div>;
}
