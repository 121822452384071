import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import {emotionBubbleStylesMapped} from "../../../models/interactions/EmotionBubble";
import classNames from "classnames";
import {GetNewGuid} from "../../../helpers/GuidHelper";

export default function EmotionDropdown(props: { onChange: (emotion: string) => void }) {

    const {onChange} = {...props};

    const options = emotionBubbleStylesMapped.map((emotion) => {
        return <DropdownMenu.Item className={classNames({
            "h-[46px] flex gap-2 px-[8px] py-[6px] rounded-[5px] cursor-pointer": true,
            "hover:bg-interaction-primary-selected hover:outline-none": true,
        })
        } onClick={() => onChange(emotion.name)} key={emotion.name}>
            <img className={"max-w-[36px] max-h-[36px]"} src={emotion.path} alt={emotion.name}/>
            <div className={"my-auto text-interaction-primary font-medium text-[14px]"}>{emotion.displayName}</div>
        </DropdownMenu.Item>
    })

    return <div className={"w-full"}>
        <DropdownMenu.Root>
            <DropdownMenu.Trigger className={classNames({
                "w-full": true,
                "focus:outline-none": true
            })}>
                <div className={"text-[28px] text-interaction-primary"}>
                    <AddReactionOutlinedIcon fontSize={"inherit"}/>
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align={"center"} className={"w-[250px] bg-white shadow-md rounded-[5px] p-[8px]"}>
                {options}
                <DropdownMenu.Arrow className={"fill-white"}/>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    </div>
}