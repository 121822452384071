import {useDispatch, useSelector} from "react-redux";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {IDialogQuiz} from "../../../models/interactions/DialogQuiz";
import PiBasicQuizAnswerV2 from "../components/v2/PiBasicQuizAnswerV2";
import {IAnswer} from "../../../models/Quiz";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import React from "react";
import PiBasicQuizAddAnswerButtonV2 from "../components/v2/PiBasicQuizAddAnswerButtonV2";
import {GetNewGuid} from "../../../helpers/GuidHelper";
import {useTrainingLocalization} from "../../trainingLocalization/TrainingLocalizationHooks";


export default function DialogQuizPreviewBody() {
    const dispatch = useDispatch();
    const dialog = useSelector(selectSelectedElement) as IDialogQuiz;
    const {getNewKey} = useTrainingLocalization();

    const onChangeDialog = (field: keyof IDialogQuiz, value: any) => {
        dispatch(updateSelectedInteraction({...dialog, [field]: value} as IDialogQuiz));
    };

    const onAddAnswer = () => {
        const newAnswers = [...dialog.answers, {id: GetNewGuid(), correct: false, text: getNewKey()}];
        onChangeDialog("answers", newAnswers);
    }

    const onDeleteAnswer = (answer: IAnswer) => {
        const newAnswers = dialog.answers.filter(a => a.id !== answer.id);
        onChangeDialog("answers", newAnswers);
    }

    const onChangeAnswer = (newAnswer: IAnswer) => {

        const setToFalse = newAnswer.correct;

        dispatch(updateSelectedInteraction({
            ...dialog,
            answers: dialog.answers.map(answer => answer.id === newAnswer.id ? newAnswer : {...answer, correct: setToFalse ? false : answer.correct})
        } as IDialogQuiz));
    }

    const answers = dialog.answers.map((answer) => {
        return <PiBasicQuizAnswerV2 key={answer.id} answer={answer} onAnswerChange={onChangeAnswer} canDelete={dialog.answers.length > 2} onDelete={() => onDeleteAnswer(answer)}/>
    });

    return <div className={"w-full h-full flex gap-x-[14px]"}>
        <div className={"w-[250px] relative align-top"}>
            <div className={"h-[70%] piLg:h-[80%] flex"}>
                <img className={"object-contain object-top"} src={"/dialog_quiz/dialog_quiz_npc.png"} alt={"npc"}/>
            </div>
            <div className={"absolute w-full bottom-0"}>
                <MessageBubbleActorDropdown label={"Actor"} selectedTargetActorId={dialog.playerGuid} onClick={(actorId) => onChangeDialog("playerGuid", actorId)}/>
            </div>
        </div>

        <div className={"w-full h-full flex flex-col relative gap-[15px]"}>
            {answers}
            <div className={"absolute w-full bottom-0"}>
                <PiBasicQuizAddAnswerButtonV2 onClick={onAddAnswer} disabled={dialog.answers.length >= 5}/>
            </div>
        </div>
    </div>
}