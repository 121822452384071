import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../app/slices/GlobalSlice";
import { LanguageData, selectAvailableLanguagesNotAdded } from "../../app/slices/TrainingLocalizationSlice";
import { selectTrainingName } from "../../app/slices/TrainingSlice";
import PiDialog from "../../components/PiDialog";
import PiMenuButton from "../menu/PiMenuButton";
import { useAddLanguage } from "./AddLanguageHook";
import "./TrainingLocalizationDialog.css";
import TrainingLocalizationTable from "./TrainingLocalizationTable";
import { useTranslation } from "react-i18next";

interface TrainingLocalizationDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function TrainingLocalizationDialog(props: TrainingLocalizationDialogProps) {
  const { open, onClose } = { ...props };
  const [isAddLanguageOpen, setIsAddLanguageOpen] = useState<boolean>(false);
  const [addNewLanguage] = useAddLanguage();
  const isLoading = useSelector(selectIsLoading);
  const {t} = useTranslation();

  const trainingName = useSelector(selectTrainingName);
  const availableLanguages = useSelector(selectAvailableLanguagesNotAdded);

  const header = (
    <div className="traininglocalizationpicker-header">
      <span>{t("translations")}</span>
      <span className="traininglocalizationpicker-title">| {trainingName}</span>
    </div>
  );
  const body = (
    <div className="traininglocalizationdialog-body">
      {isLoading && (
        <div className="traininglocalizationdialog-spinner">
          <CircularProgress size="5%" />
        </div>
      )}
      {!isLoading && (
        <>
          <div className="traininglocalizationdialog-scroller playit-scrollbar">
            <TrainingLocalizationTable />
          </div>
          <div className="traininglocalizationdialog-add-button">
            <Button variant="piBlue" onClick={() => setIsAddLanguageOpen(true)}>
              {t("add_language")}
            </Button>
          </div>
        </>
      )}
    </div>
  );

  const onAddLanguage = (lang: LanguageData) => {
    setIsAddLanguageOpen(false);
    addNewLanguage(lang);
    // dispatch(addLocalizedLanguage(lang));
  };

  const availableLanguageButtons = availableLanguages.map((lang, index) => {
    return <PiMenuButton key={index} text={lang.full} onClick={() => onAddLanguage(lang)} />;
  });
  const addLanguageHeader = <div className="traininglocalizationpicker-header">Add Language</div>;
  const addLanguageBody = <div className="traininglocalizationdialog-add-language-dialog">{availableLanguageButtons}</div>;

  return (
    <div>
      <PiDialog open={open} onClose={onClose} width="90vw" height="90vh" header={header} body={body} />
      <PiDialog open={isAddLanguageOpen} onClose={() => setIsAddLanguageOpen(false)} width="30vw" height="50vh" header={addLanguageHeader} body={addLanguageBody} />
    </div>
  );
}
