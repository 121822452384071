import { useDispatch, useSelector } from "react-redux";
import { selectEditingAsset, selectSupportedVideoTypes, updateAssetInCurrentFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetDetailsDTO } from "../dto/AssetDTO";
import { AssetService } from "../services/AssetService";
import { useFileValidation } from "./FileValidationHook";

export const useUploadVariant = () => {
  const org = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const editingAsset = useSelector(selectEditingAsset);
  const { IsVideoValid } = useFileValidation();

  const supportedVideoTypes = useSelector(selectSupportedVideoTypes);

  const handleFiles = async (assetId: string, supportedFiletypes: string[], file: File) => {
    if (supportedFiletypes.includes(file.type)) {
      // if it's not a video or it is a video and it's valid -> upload the new variant
      if (!supportedVideoTypes.includes(file.type) || (supportedVideoTypes.includes(file.type) && (await IsVideoValid(file)))) {
        uploadVariant(assetId, file);
      } else {
        console.log("video is too long");
      }
    } else {
      console.log("file not supported");
    }
  };

  const uploadVariant = async (assetId: string, file: File) => {
    if (!org) return;

    // add loading variant card
    var cp: AssetDetailsDTO | undefined = structuredClone(editingAsset);
    if (cp) {
      cp.variants = [{ id: "loading", downloadUri: "", blobPath: "", hash: "", variant: "", variantId: "" }, ...cp.variants!];
      dispatch(updateAssetInCurrentFolder(cp));
    }

    // 1. POST - variant
    await AssetService.CreateVariant(org.id, token, assetId, file);
    // 2. GET - asset
    const asset = await AssetService.GetAsset(org.id, token, assetId);

    // 3. dispatch -> updateAsset
    if (asset) {
      dispatch(updateAssetInCurrentFolder(asset));
    }

    // 4. maybe set uploaded variant as selected?
  };

  return { uploadVariant, handleFiles } as const;
};
