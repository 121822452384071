import React from "react";
import ActorPresetEditor from "../actor_presets/ActorPresetEditor";
import ActorStatePropertiesColumn from "../actor_state_properties_column/ActorStatePropertiesColumn";
import InteractionPropertiesColumn from "../interaction_properties_column/InteractionPropertiesColumn";
import Take3DPropertiesColumn from "../take_properties_column/Take3DPropertiesColumn";
import Take360PropertiesColumn from "../take_properties_column/Take360PropertiesColumn";
import PropPresetEditor from "../prop_presets/PropPresetEditor";

export interface PropertiesColumnColumnContent {
    GetContent: () => JSX.Element;
}

export const ActorPresetPropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <ActorPresetEditor/>;
    },
};

export const InteractionPropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <InteractionPropertiesColumn/>;
    },
};

export const Take3DPropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <Take3DPropertiesColumn/>;
    },
};

export const Take360PropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <Take360PropertiesColumn/>;
    },
};


export const ActorStatePropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <ActorStatePropertiesColumn/>;
    },
};
export const PropPresetPropertiesColumnContent: PropertiesColumnColumnContent = {
    GetContent: function (): JSX.Element {
        return <PropPresetEditor/>;
    },
};
