import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import React, { useState } from "react";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import "./PiBasicGraphicsQuizAnswer.css";
import PiBasicGraphicsQuizAnswerFooter from "./PiBasicGraphicsQuizAnswerFooter";
import PiOrderGraphicsQuizAnswerFooter from "../order_quiz/PiOrderGraphicsQuizAnswerFooter";
import { PiQuizAnswerProps } from "./PiBasicQuizAnswer";
import { useMediumAssetPreview } from "../../../hooks/MediumAssetPreviewHook";
import PiImageAssetPreview from "./PiImageAssetPreview";

export default function PiBasicGraphicsQuizAnswer(props: PiQuizAnswerProps) {
  const {
    answer,
    quizType,
    handleAnswerChange,
    getValue,
    changeValue,
    handleDeleteAnswer,
    answersLength,
    canDelete,
    hideBottom,
    disableInput,
  } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    handleAnswerChange(answer.id, "assetId", asset.assetId);
  };

  const closeButton = canDelete ? (
    <div
      className="pibasicgraphicsquizanswer-close"
      onClick={() => handleDeleteAnswer(answer.id)}
    >
      <ClearIcon fontSize="inherit" color="inherit" />
    </div>
  ) : (
    <></>
  );

  const bottomV2 =
    quizType === "basic_quiz" ? (
      <div className="pibasicgraphicsquizanswer-bottom-v2">
        <PiBasicGraphicsQuizAnswerFooter {...props} />
      </div>
    ) : quizType === "order_quiz" ? (
      <div className="pibasicgraphicsquizanswer-bottom-v2">
        <PiOrderGraphicsQuizAnswerFooter {...props} />
      </div>
    ) : null;

  return (
    <div className="pibasicgraphicsquizanswer-root">
      {closeButton}
      <PiImageAssetPreview assetId={answer.assetId} setIsOpen={setIsOpen} />
      {!hideBottom && bottomV2}
      <FileExplorerDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type={AssetKey.Image}
        onSelect={onSelect}
      />
    </div>
  );
}
