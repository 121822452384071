import AddIcon from "@mui/icons-material/Add";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import React, {forwardRef, useEffect, useMemo, useState} from "react";
import {Actor, ProfilePictures} from "../../models/Actor";
import "./InteractionPointPreview.css";
import L from "leaflet";
import {Marker, Popup, useMap} from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import PiBoxIcon from "../../icons/PiBoxIcon";
import {PropMetadata} from "../../dto/PropMetadata";
import {TranslatedPropDTO} from "../../dto/TranslatedPropDTO";
import {UsedProp} from "../../models/UsedProp";

export interface InteractionPointPreviewProps {
    strategy: InteractionPointPreviewStrategy;
    x: number;
    y: number;
    // onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    onClick?: (event: MouseEvent) => void;
    actor?: Actor;
    prop?: UsedProp;
    disabled?: boolean;
    popoverContent?: React.ReactNode;
}

export interface InteractionPointPreviewStrategy {
    getView: (imgUrl?: string) => React.ReactNode;
    getStyle: (xProc: string, yProc: string, disabled?: boolean) => {};
    getClassnames: (disabled?: boolean) => string;
}

export const InteractionPointStrategy: InteractionPointPreviewStrategy = {
    getView() {
        return (
            <>
                <AddIcon fontSize="inherit" color="inherit"/>
            </>
        );
    },
    getStyle(xProc: string, yProc: string) {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "var(--pi-key-blue)",
            "--marker-border-color": "var(--pi-key-blue)",
            "--marker-font-color": "white",
        };
    },

    getClassnames() {
        return "bg-creator-primary text-white";
    }
};

export const NpcPointStrategy: InteractionPointPreviewStrategy = {
    getView() {
        return (
            <>
                <PermIdentityOutlinedIcon fontSize="inherit" color="inherit"/>
            </>
        );
    },
    getStyle(xProc: string, yProc: string, disabled?: boolean) {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "white",
            "--marker-border-color": "var(--pi-disabled)",
            "--marker-font-color": "var(--pi-text-color-1)",
            opacity: disabled ? "0.5" : "1",
        };
    },
    getClassnames(disabled?: boolean) {
        return classNames({"bg-white b-creator-primary border-2 border-solid border-creator-primary-disabled text-creator-text": true, "opacity-50": disabled});
    }
};

export const SlatePointStrategy: InteractionPointPreviewStrategy = {
    getView() {
        return (
            <>
                <MovieCreationOutlinedIcon fontSize="inherit" color="inherit"/>
            </>
        );
    },
    getStyle(xProc: string, yProc: string) {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "var(--pi-key-blue)",
            "--marker-border-color": "var(--pi-key-blue)",
            "--marker-font-color": "white",
        };
    },
    getClassnames() {
        return "bg-creator-primary text-white";
    }
};

export const ActorPointStrategy: InteractionPointPreviewStrategy = {
    getView(imgUrl?: string) {
        return (
            <>
                <img style={{width: "32px", height: "32px"}} src={imgUrl} alt="pfp"/>
            </>
        );
    },
    getStyle(xProc: string, yProc: string, disabled?: boolean) {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "var(--pi-game-blue)",
            "--marker-border-color": "var(--pi-game-blue)",
            "--marker-font-color": "white",
            opacity: disabled ? "0.5" : "1",
        };
    }, getClassnames(disabled?: boolean) {
        return classNames({"bg-interaction-primary": true, "opacity-50": disabled});
    }
};

export const PlayerPointStrategy: InteractionPointPreviewStrategy = {
    getView(imgUrl?: string) {
        return (
            <>
                <div className="interactionpointpreview-node-player">P</div>
            </>
        );
    },
    getStyle(xProc: string, yProc: string, disabled?: boolean) {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "var(--pi-key-blue)",
            "--marker-border-color": "var(--pi-key-blue)",
            "--marker-font-color": "white",
            opacity: disabled ? "0.5" : "1",
        };
    }, getClassnames(disabled?: boolean) {
        return classNames({"text-white bg-creator-primary": true, "opacity-50": disabled});
    }
};

export const CameraPointStrategy: InteractionPointPreviewStrategy = {
    getView: function (imgUrl?: string | undefined): React.ReactNode {
        return <CameraAltOutlinedIcon fontSize="inherit" color="inherit"/>;
    },
    getStyle: function (xProc: string, yProc: string): {} {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "white",
            "--marker-border-color": "var(--pi-disabled)",
            "--marker-font-color": "black",
            fontSize: "24px",
        };
    }, getClassnames() {
        return "bg-white text-creator-text border-2 border-solid border-creator-primary-disabled"
    }
};

export const SelectedCameraPointStrategy: InteractionPointPreviewStrategy = {
    getView: function (imgUrl?: string | undefined): React.ReactNode {
        return <CameraAltOutlinedIcon fontSize="inherit" color="inherit"/>;
    },
    getStyle: function (xProc: string, yProc: string): {} {
        return {
            "--marker-x": xProc,
            "--marker-y": yProc,
            "--marker-background-color": "var(--pi-key-blue)",
            "--marker-border-color": "var(--pi-key-blue)",
            "--marker-font-color": "white",
            fontSize: "24px",
        };
    }, getClassnames() {
        return "bg-creator-primary border-2 border-solid border-creator-primary text-white"
    }
};

export const EmptyPropPointStrategy: InteractionPointPreviewStrategy = {
    getView: function (imgUrl?: string | undefined): React.ReactNode {
        return <PiBoxIcon className={"fill-creator-text"} width={24} height={24}/>
    },
    getStyle: function (xProc: string, yProc: string, disabled?: boolean | undefined): {} {
        throw new Error("Function not implemented.");
    },
    getClassnames: function (disabled?: boolean | undefined): string {
        return classNames({"bg-white b-creator-primary border-2 border-solid border-creator-primary-disabled text-creator-text": true, "opacity-50": disabled});
    }
};

export const PropPointStrategy: InteractionPointPreviewStrategy = {
    getView: function (imgUrl?: string | undefined): React.ReactNode {
        return <img src={imgUrl} style={{width: "32px", height: "32px", borderRadius: "100%"}} alt="prop" className={"object-contain"}/>
    },
    getStyle: function (xProc: string, yProc: string, disabled?: boolean | undefined): {} {
        throw new Error("Function not implemented.");
    },
    getClassnames: function (disabled?: boolean | undefined): string {
        return classNames({"bg-white border-2 border-solid border-creator-primary": true, "opacity-50": disabled});
    }
}

const InteractionPointPreview = forwardRef<HTMLDivElement, InteractionPointPreviewProps>((props, ref) => {
    const {strategy, x, y, onClick, actor, prop, disabled, popoverContent} = {...props};
    const popupRef = React.useRef<L.Popup>(null);
    const position: any = [y, x];
    const [latLang, setLatLang] = useState<any>(position);

    const map = useMap();
    useEffect(() => {
        const z = 512; // size

        const xPixels = z * x;
        const yPixels = z * y;
   
        setLatLang( map.unproject([xPixels, yPixels], 1));

    }, [map]);


    const imageUrl = useMemo(() => {
        if (actor)
            return ProfilePictures[actor.profilePicture];

        if (prop)
            return prop.propContent.downloadUri;

        return undefined;
    }, [actor, prop]);

    const icon = new L.DivIcon({
        className: "",
        html: ReactDOMServer.renderToString(<div
            className={"interactionpointpreview-node-icon " + strategy.getClassnames(disabled)}>{strategy.getView(imageUrl)}</div>),
    });

    const onPopoverClick = () => {
        if (popupRef.current) {
            popupRef.current.close();
        }
    }

    return (
        <Marker position={latLang} icon={icon} eventHandlers={{click: (e) => onClick?.(e.originalEvent)}}>
            {popoverContent && <Popup ref={popupRef} closeButton={false} closeOnClick autoClose>
                <div onClick={onPopoverClick}>{popoverContent}</div>
            </Popup>}
        </Marker>
    );
});

export default InteractionPointPreview;
