import {TranslatedPropCategoryDTO} from "../../dto/TranslatedPropCategoryDTO";

export default function PropLibraryCategoryLabel(props: { category: TranslatedPropCategoryDTO }) {
    const {category} = {...props};

    function hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const rgb = hexToRgb(category.hexColor);

    return <div style={{backgroundColor: `rgba(${rgb!.r}, ${rgb!.g}, ${rgb!.b}, 0.1)`}} className={"flex gap-[10px] h-[36px] px-[10px] rounded-full"}>
        <img className={"my-auto w-[24px] h-[24px]"} src={category.downloadUri} alt={category.name}/>
        <div style={{color: category.hexColor}} className={"my-auto font-medium"}>
            {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
        </div>
    </div>
}