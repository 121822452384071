import {useSelector} from "react-redux";
import {selectAllPropPresets, selectSelectedTake3D} from "../../app/slices/TrainingSlice";
import PropPresetPopoverCard from "./PropPresetPopoverCard";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, {useMemo} from "react";
import classNames from "classnames";
import {current} from "@reduxjs/toolkit";
import usePropHelper from "../../hooks/PropHelper";

export default function PropPresetPopover(props: {
    canDelete: boolean,
    onClick: (instanceId: string, propId: string) => void,
    onDelete: () => void,
    includedGrabbedProps?: boolean
}) {
    const {canDelete, onClick, onDelete, includedGrabbedProps} = {...props};
    const allProps = useSelector(selectAllPropPresets);
    const currentTake = useSelector(selectSelectedTake3D);
    const propStates = useMemo(() => {
        return currentTake?.props ?? [];
    }, [currentTake])
    const {getIsPropAttachedToActor} = usePropHelper();

    const propsFiltered = useMemo(() => {
       return allProps.filter(prop => {
            const pState = propStates.find(p => p.instanceId === prop.id);
            let show = true

            if (pState !== undefined && getIsPropAttachedToActor(pState) && !includedGrabbedProps) show = false; 
            
            if (show) return prop;
        });
    }, [allProps, currentTake, propStates])

    const allPropsMapped = propsFiltered.map((prop) => {
        return <PropPresetPopoverCard key={prop.id} prop={prop} onClick={() => onClick(prop.id, prop.propId)}/>
    });

    const deleteButton = (
        <div className="border-t-2 border-interaction-primary-washed">
            <div className={classNames({
                "h-[42px] flex gap-[10px] items-center text-[14px] m-2 rounded-[5px] cursor-pointer": true,
                "text-interaction-disabled": !canDelete,
                "text-interaction-secondary hover:bg-interaction-text-hoverBackground": canDelete,
            })} onClick={onDelete}>
                <DeleteOutlineOutlinedIcon className={"text-[24px]"} color="inherit"/>
                <span>Delete</span>
            </div>
        </div>
    );


    return <div className={"flex flex-col gap-[10px]"}>
        {allPropsMapped.length > 0 && allPropsMapped}
        {allPropsMapped.length === 0 && <div className={"flex w-full py-[8px] px-[10px] cursor-not-allowed text-[14px] text-creator-text-faded"}>No props available</div>}
        {deleteButton}</div>
}