import { arrayMove } from "@dnd-kit/sortable";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ContentEditableEvent } from "react-contenteditable";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectCurrentLanguage } from "../../../app/slices/TrainingLocalizationSlice";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import PiContentEditable from "../../../components/PiContentEditable";
import PiDragButton from "../../../components/PiDragButton";
import PiEditMenu from "../../../components/PiEditMenu";
import { IAnswer } from "../../../models/Quiz";
import { IWordSpinnerQuiz } from "../../../models/interactions/WordSpinnerQuiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import WordQuizSentenceSegment from "../WordQuiz/WordQuizSentenceSegment";
import PiBasicQuizAddButtonSmall from "../components/PiBasicQuizAddButtonSmall";
import "./WordSpinnerQuizBody.css";
import PiDragContainer, {
  ContainerStyle,
} from "../../../components/PiDragContainer";

export default function WordSpinnerQuizBody() {
  const getRawSentence = (): string => {
    var sentence: string = getValue(quiz.formattedSentence);
    const correctAnswer = quiz.answers.find((answer) => answer.correct);

    if (correctAnswer) {
      sentence = sentence.replace("{0}", `{${getValue(correctAnswer.text)}}`);
    }

    return sentence;
  };

  const quiz = useSelector(selectSelectedElement) as IWordSpinnerQuiz;
  const dispatch = useDispatch();
  const { getValue, changeValue, getNewKey } = useTrainingLocalization();
  const [isEditingSentence, setIsEditingSentence] = useState<boolean>(false);
  const [isSentenceChanged, setIsSentenceChanged] = useState<boolean>(false);
  const [editedSentence, setEditedSentence] = useState<string>("");
  const currLang = useSelector(selectCurrentLanguage);

  const [sentenceIndices, setSentenceIndices] = useState<number[]>([]);
  const [x, setXPos] = useState<number>(0);
  const [y, setYPos] = useState<number>(0);

  const [chunks, setChunks] = useState<IAnswer[]>([]);

  useEffect(() => {
    setEditedSentence(getRawSentence());
  }, [quiz.id, currLang]);

  useEffect(() => {
    generateChunks();
    reformatSentence();
  }, [editedSentence]);

  useEffect(() => {
    if (!isEditingSentence && isSentenceChanged) {
      setIsSentenceChanged(false);
      findCorrectAnswer();
    }
  }, [isEditingSentence]);

  const reformatSentence = () => {
    changeValue(
      quiz.formattedSentence,
      editedSentence.replace(/(?<=\{)[^{}]*(?=\})/, "0")
    );
  };

  useEffect(() => {
    // calculate avg pos
    var parent = document.getElementById("wordspinnerquiz-root");
    var elem = document.getElementById(
      "wordspinnerquiz-" + sentenceIndices[sentenceIndices.length - 1]
    );

    if (elem) {
      var prect = parent!.getBoundingClientRect();
      var rect = elem!.getBoundingClientRect();

      setXPos(((rect.x - prect.x + rect.width * 0.5) / prect.width) * 100);
      setYPos(((rect.y - prect.y) / prect.height) * 100);
    }
  }, [sentenceIndices]);

  const generateChunks = () => {
    var sentence = editedSentence;

    // Use a regular expression with capturing groups to split and capture the parts
    const partsWithBraces: string[] = sentence.split(/(\{[^{}]*\})/);
    const newChunks: IAnswer[] = [];

    for (var i = 0; i < partsWithBraces.length; i++) {
      if (i % 2 != 0) {
        var answer: IAnswer = {
          id: uuidv4(),
          correct: false,
          text: partsWithBraces[i],
        };

        answer.correct = true;
        newChunks.push(answer);
      } else {
        // split that text
        var txt = partsWithBraces[i].split(" ");
        txt.forEach((str) => {
          if (str !== "") {
            newChunks.push({
              id: uuidv4(),
              correct: false,
              text: str,
            });
          }
        });
      }
    }

    setChunks(newChunks);
  };

  //#region Correct Answer
  const registerCorrectAnswer = () => {
    var split = chunks.map((chunk) => chunk.text.replace(/[{}]/g, ""));

    // get smallest index
    // get biggest index
    var si = Math.min(...sentenceIndices);
    var sb = Math.max(...sentenceIndices);

    const correctAnswer: string = split.slice(si, sb + 1).join(" ");
    const correctAnswerExists = quiz.answers.find((a) => a.correct);

    split[si] = "{" + split[si];
    split[sb] = split[sb] + "}";

    setEditedSentence(split.join(" "));
    setSentenceIndices([]);

    if (!correctAnswerExists) addCorrectAnswer(correctAnswer);
    else {
      const answers = [...quiz.answers];
      answers.forEach((answer, index) =>
        answer.correct ? changeValue(answers[index].text, correctAnswer) : ""
      );

      const copy: IWordSpinnerQuiz = { ...quiz, answers: answers };
      dispatch(updateSelectedInteraction(copy));
    }
  };

  const setAllAnswersToFalse = () => {
    let answers: IAnswer[] = [...quiz.answers];
    answers = answers.map((answer) => {
      return {
        ...answer,
        correct: false,
      };
    });

    dispatch(
      updateSelectedInteraction({
        ...quiz,
        answers: answers,
      } as IWordSpinnerQuiz)
    );
  };

  const findCorrectAnswer = () => {
    //Is there a match after editing the sentence?
    const match = editedSentence.match(/\{(.*?)\}/);
    if (!match) {
      setAllAnswersToFalse();
      return;
    }

    const correctAnswerExists = quiz.answers.find((a) => a.correct);
    if (!correctAnswerExists) addCorrectAnswer(match[1]);
    else {
      const answers = [...quiz.answers];
      answers.forEach((answer, index) =>
        answer.correct ? changeValue(answers[index].text, match[1]) : ""
      );

      const copy: IWordSpinnerQuiz = { ...quiz, answers: answers };
      dispatch(updateSelectedInteraction(copy));
    }

    const firstOpenBraceIndex = editedSentence.indexOf("{");
    const firstCloseBraceIndex = editedSentence.indexOf("}");

    var editedSentenceLocal = editedSentence;

    // Replace all curly braces with empty string
    editedSentenceLocal = editedSentenceLocal.replace(/{|}/g, "");

    const sentencePart1 = editedSentenceLocal.slice(0, firstOpenBraceIndex);
    const sentencePart2 = editedSentenceLocal.slice(firstCloseBraceIndex - 1);

    // Add the first pair of curly braces back
    editedSentenceLocal =
      sentencePart1 +
      "{" +
      editedSentenceLocal.slice(firstOpenBraceIndex, firstCloseBraceIndex - 1) +
      "}" +
      sentencePart2;

    setEditedSentence(editedSentenceLocal);
  };

  const addCorrectAnswer = (answerText: string) => {
    var answer: IAnswer = {
      id: uuidv4(),
      correct: true,
      text: getNewKey(),
    };
    changeValue(answer.text, answerText);

    const answers = [...quiz.answers];
    answers.push(answer);

    const copy: IWordSpinnerQuiz = { ...quiz, answers: answers };
    dispatch(updateSelectedInteraction(copy));
  };

  const onToggleCorrectAnswer = (currentIndex: number) => {
    var answers = [...quiz.answers];
    answers = answers.map((answer, mappingIndex) => {
      return {
        ...answer,
        correct: mappingIndex === currentIndex,
      };
    });

    var correctAnswerText = getValue(answers[currentIndex].text);

    var updatedSentence = editedSentence.replace(
      /{(.*?)}/g,
      "{" + correctAnswerText + "}"
    );
    setEditedSentence(updatedSentence);

    const copy: IWordSpinnerQuiz = { ...quiz, answers: answers };
    dispatch(updateSelectedInteraction(copy));
  };

  let correctChunk = chunks.find((c) => c.correct);

  //#endregion

  //#region SentenceView
  const onChangeContentEditable = (newValue: string) => {
    setIsSentenceChanged(true);
    setEditedSentence(newValue);
  };

  const onBlurContentEditable = (innerText: string) => {
    setIsSentenceChanged(true);
    setEditedSentence(innerText);
    setIsEditingSentence(false);
  };

  const onSentenceSegmentClick = useCallback(
    (index: number) => {
      const isNeighbor = sentenceIndices.some((i) => Math.abs(i - index) <= 1);
      setSentenceIndices(isNeighbor ? [...sentenceIndices, index] : [index]);
    },
    [sentenceIndices]
  );

  const getSplitSentenceView = () => {
    return chunks.map((chunk, index) => {
      return (
        <div className={"wordspinnerquizbody-content"} key={chunk.id}>
          {chunk.correct ? (
            <ArrowDropUpIcon fontSize="inherit" color="inherit" />
          ) : (
            ""
          )}
          <WordQuizSentenceSegment
            key={"wordspinnerquiz-" + index}
            id={"wordspinnerquiz-" + index}
            text={chunk.text}
            onClick={() => onSentenceSegmentClick(index)}
            highlight={sentenceIndices.includes(index)}
            isCorrect={chunk.correct}
          />
          {chunk.correct ? (
            <ArrowDropDownIcon fontSize="inherit" color="inherit" />
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const contentEditable = (
    <div style={{ display: "flex" }}>
      <PiContentEditable
        innerHtml={editedSentence}
        onChange={onChangeContentEditable}
        onBlur={onBlurContentEditable}
      />
      <IconButton
        className="wordspinnerquiz-finished-button"
        onClick={() => {
          setIsEditingSentence(false);
        }}
      >
        <CheckIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </div>
  );

  const staticDisplay = (
    <div className="wordspinnerquizbody-sentenceview">
      {chunks.length >= 1 && getSplitSentenceView()}
      {(chunks.length === 0 || chunks[0].text === "") && (
        <span
          className="wordspinnerquiz-placeholder"
          onClick={() => setIsEditingSentence(true)}
        >
          Type a sentence
        </span>
      )}
      <IconButton
        className="wordspinnerquiz-edit-button"
        onClick={() => setIsEditingSentence(true)}
      >
        <EditOutlinedIcon fontSize="inherit" color="inherit" />
      </IconButton>
    </div>
  );

  const contentDisplay = isEditingSentence ? contentEditable : staticDisplay;

  //#endregion

  //#region Wrong Answer
  const addWrongAnswer = () => {
    const allowNewAnswer: boolean =
      quiz.answers.findIndex((answer) => answer.correct) === -1
        ? quiz.answers.length < 5
        : quiz.answers.length < 6;
    if (!allowNewAnswer) return;

    var answer: IAnswer = {
      id: uuidv4(),
      correct: false,
      text: getNewKey(),
    };

    const answers = [...quiz.answers];
    answers.push(answer);

    const copy: IWordSpinnerQuiz = { ...quiz, answers: answers };
    dispatch(updateSelectedInteraction(copy));
  };

  const deleteAnswer = (id: string) => {
    var filteredAnswers = quiz.answers.filter((a) => a.id !== id);
    const copy: IWordSpinnerQuiz = { ...quiz, answers: filteredAnswers };
    dispatch(updateSelectedInteraction(copy));
  };

  const addWrongAnswerButton = (
    <PiBasicQuizAddButtonSmall onClick={addWrongAnswer} />
  );
  //#endregion

  //#region Handle Answers

  const onDeleteAnswer = (currentAnswerId: string, isCorrect: boolean) => {
    if (isCorrect) {
      var chunkref = chunks.find((c) => c.correct);
      var ci = chunks.indexOf(chunkref!);
      var chunksCopy = [...chunks];
      chunksCopy[ci] = {
        ...chunksCopy[ci],
        correct: false,
        text: chunksCopy[ci].text.replace("{", "").replace("}", ""),
      };
      setChunks(chunksCopy);

      // also update source sentence
      var formatted = chunksCopy.map((c) => c.text).join(" ");
      setEditedSentence(formatted);
    }

    deleteAnswer(currentAnswerId);
  };

  const onChangeAnswer = (
    text: string,
    answer: IAnswer,
    isCorrect: boolean
  ) => {
    if (isCorrect) {
      var chunkref = chunks.find((c) => c.correct);
      var ci = chunks.indexOf(chunkref!);
      var chunksCopy = [...chunks];
      chunksCopy[ci] = { ...chunksCopy[ci], text: "{" + text + "}" };
      setChunks(chunksCopy);

      // also update source sentenceNO,
      var formatted = chunksCopy.map((c) => c.text).join(" ");
      setEditedSentence(formatted);
    }

    changeValue(answer.text, text);
  };

  const answerArray = quiz.answers.map((answer, index) => {
    return (
      <PiDragButton
        uniqueId={answer.id}
        isDragging={false}
        key={answer.id}
        text={
          answer.correct && correctChunk
            ? correctChunk.text.replace("{", "").replace("}", "")
            : getValue(answer.text)
        }
        placeholder="Answer"
        notifyChangeSentence={() => {}}
        highlight={answer.correct}
        canDelete
        canToggle={correctChunk != null}
        isToggled={answer.correct}
        onToggle={() => onToggleCorrectAnswer(index)}
        onDelete={() => onDeleteAnswer(answer.id, answer.correct)}
        onChange={(text) => onChangeAnswer(text, answer, answer.correct)}
      />
    );
  });

  //#endregion

  function onHandleDragEnd(prevIndex: number, newIndex: number) {
    var copy = [...quiz.answers];
    copy = arrayMove(copy, prevIndex, newIndex);

    var updatedQuiz = { ...quiz };
    updatedQuiz.answers = copy;

    dispatch(updateSelectedInteraction(updatedQuiz));
  }

  return (
    <>
      <div id={"wordspinnerquiz-root"} className="wordspinnerquizbody-root">
        <div className="wordspinnerquizbody-background">{contentDisplay}</div>
        <PiDragContainer
          onHandleDragEnd={onHandleDragEnd}
          containerElements={answerArray}
          uniqueIds={quiz.answers.map((a) => a.id)}
          containerStyle={ContainerStyle.Grid}
        />
        {addWrongAnswerButton}
      </div>
      <div
        className={`wordspinnerquiz-edit-menu ${
          sentenceIndices.length === 0 || isEditingSentence ? "hidden" : ""
        }`}
        style={{ top: `${y}%`, left: `${x}%` }}
      >
        <PiEditMenu
          buttonSettings={[
            {
              icon: <CheckIcon fontSize="inherit" color="inherit" />,
              onClick: () => {
                registerCorrectAnswer();
              },
            },
          ]}
        />
      </div>
    </>
  );
}
