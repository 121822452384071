import {Viewport3DLayerProps} from "./Viewport3DEnvironmentMetadata";
import PropPointPreview from "./PropPointPreview";

export default function Viewport3DPropLayer(props: Viewport3DLayerProps) {
    const {currentTakeLocation, takePositions, isCameraSelected} = {...props};

    const propPreviews = takePositions.map((point) => {
        if(point.prop_positions) return point.prop_positions.map((propPos) => {
            return <PropPointPreview key={propPos.id} x={propPos.position.x} y={propPos.position.y} positionId={propPos.id} disabled={isCameraSelected}/>;
        });
    });

    return <>{currentTakeLocation && propPreviews}</>;
}