import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  selectSelectedTake3D,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { ISetPropState } from "../../../models/interactions/SetPropState";
import usePropHelper from "../../../hooks/PropHelper";
import PropDropdown from "../grab/PropDropdown";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import PiInteractionStringDropdown from "../../../components/PiInteractionStringDropdown";
import AllPropsDropdown from "../show_ui_prop/AllPropsDropdown";

export default function SetPropStatePreviewBody() {
  const dispatch = useDispatch();
  const interaction = useSelector(selectSelectedElement) as ISetPropState;
  const take = useSelector(selectSelectedTake3D);
  const { getUsedPropByInstanceId } = usePropHelper();

  const usedProp = useMemo(() => {
    if (!interaction.propId) return null;
    return getUsedPropByInstanceId(interaction.propId);
  }, [interaction.propId]);

  const onChangePropState = (item: string) => {
    const propstate = usedProp?.metadata.prop_states.find(
      (p) => p.name === item
    );
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        propState: item,
        waitForCustomEvent: propstate?.waitForCustomEvent ?? false,
      } as ISetPropState)
    );
  };

  const onChangeProp = (propId: string) => {
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        propId: propId,
        propState: "",
      } as ISetPropState)
    );
  };

  const propDropdown = (
    <AllPropsDropdown
      label="Prop"
      selectedPropStateId={interaction.propId}
      onClick={onChangeProp}
    />
  );

  const propStateDropdown = (
    <PiInteractionStringDropdown
      items={usedProp?.metadata.prop_states.map((p) => p.name) ?? []}
      selectedItem={interaction.propState}
      label="Prop State"
      onClick={onChangePropState}
    />
  );

  return (
    <div className="flex flex-col w-full h-full gap-[24px]">
      {propDropdown}
      {propStateDropdown}
    </div>
  );
}
