import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthenticated, selectToken } from "../app/slices/AuthenticationSlice";
import { addSupportedLanguages } from "../app/slices/TrainingLocalizationSlice";
import { TrainingService } from "../services/TrainingService";

export const useGetLanguages = () => {
  const authenticated = useSelector(selectAuthenticated);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authenticated) {
      getLanguages();
    }
  }, []);

  const getLanguages = async () => {
    const languages = await TrainingService.GetLanguages(token);
    dispatch(addSupportedLanguages(languages));
  };

  return [getLanguages] as const;
};
