import { useDispatch, useSelector } from "react-redux";
import { removeAssetFromCurrentFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export default function useDeleteAsset() {
  const currentOrganization = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const deleteAsset = async (assetId: string) => {
    var success = false;
    if (currentOrganization) {
      success = await AssetService.DeleteAsset(currentOrganization.id, token, assetId);
    }

    if (success) {
      // remove asset from folder
      dispatch(removeAssetFromCurrentFolder(assetId));
    }
  };

  return [deleteAsset] as const;
}
