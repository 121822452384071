import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import HideUIPropPreview from "../../features/previews/hide_ui_prop/HideUIPropPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface IHideUIProp extends Interaction {}

export const HideUIPropInteraction = (): InteractionFactoryEntity => {
  const view = <HideUIPropPreview />;

  const create = (getKeys: (amount: number) => string[]): IHideUIProp => {
    return {
      id: GetNewGuid(),
      name: "Hide UI Prop",
      type: InteractionType.HideUIProp,
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IHideUIProp => {
    return interaction as IHideUIProp;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IHideUIProp => {
    return create(getKeys);
  };

  const filter = (interaction: Interaction): string[] => {
    return [interaction.name];
  };

  return {
    View: view,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Filter: filter,
  };
};
