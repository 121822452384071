import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import PiScrollArea from "./PiScrollArea";


type TriggerProps = React.ComponentProps<typeof PiInteractionDropdownTrigger>;
type ItemProps = React.ComponentProps<typeof PiInteractionDropdownItem>;
type PiInteractionDropdownContentProps = React.ComponentProps<typeof PiInteractionDropdownContent>;

type TriggerType = React.ComponentType<TriggerProps>;

function PiInteractionDropdownTrigger(props: { children: JSX.Element | JSX.Element[] | string }) {
    return <div>{props.children}</div>
}

type PiInteractionDropdownProps = {
    children: [React.ReactElement<TriggerProps>, React.ReactElement<ItemProps>[]]; // An array with exactly two React elements of specific types
    label?: string;
};

export function PiInteractionDropdown(props: PiInteractionDropdownProps) {
    const ref = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState<number>(1000);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (ref.current) {
            var w = ref.current?.offsetWidth.toString() + "px";
            setWidth(ref.current?.offsetWidth);
        }
    }, [ref.current?.offsetWidth]);

    const label = props.label && <div className={"text-interaction-primary text-base font-bold h-[30px]"}>{props.label}</div>;

    return (
        <div ref={ref} className={"w-full h-[98px]"}>
            {label}
            <DropdownMenu.Root onOpenChange={setIsOpen}>
                <DropdownMenu.Trigger
                    className={"w-full h-[68px] p-4 flex align-middle bg-interaction-softBackground border-solid border-interaction-primary border-b-2 focus:outline-none hover:bg-interaction-primary-washed"}>
                    <div className={"my-auto text-interaction-primary text-interaction text-interaction-base font-medium w-full"}>
                        {props.children[0]}
                    </div>
                    <div className={classNames(
                        {
                            "my-auto ml-auto text-interaction-primary": true,
                            "transition duration-100 ease-in-out": true,
                            "transform rotate-180": isOpen,
                            "transform rotate-0": !isOpen,
                        })}>
                        <ArrowDropDownIcon color={"inherit"}/>
                    </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content style={{width: width + "px"}}
                                      className={"py-[6px] gap-[6px] z-40 bg-white shadow-2xl border-2 border-interaction-primary-washed rounded-[10px] transform" +
                                          " -translate-y-2"}>
                        <PiScrollArea variant={"piInteractionBlue"} maxHeight={200}>
                            {props.children[1]}
                        </PiScrollArea>
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </div>);
}

function PiInteractionDropdownItem(props: { children: string | JSX.Element | JSX.Element[], onClick?: () => void }) {
    return (<DropdownMenu.Item
        className={"flex-1 h-[46px] rounded-[5px] bg-white text-interaction-primary text-interaction-base cursor-pointer hover:bg-interaction-primary-selected flex mx-[6px] px-[8px] border-none" +
            " outline-none font-medium"} onClick={props.onClick}>
        <div className={"my-auto text-interaction-small truncate"}>{props.children}</div>
    </DropdownMenu.Item>);
}

function PiInteractionDropdownContent(props: { children: React.ReactElement<ItemProps>, width: number }) {
    return (<DropdownMenu.Content className={classNames({
        [`w-[${props.width}px]`]: true,
        "z-40 bg-white shadow-2xl border-2 border-interaction-primary-washed rounded-[10px]": true,
        "transform -translate-y-2": true,
    })}>
        {props.children}
    </DropdownMenu.Content>)
}


PiInteractionDropdown.Item = PiInteractionDropdownItem;
PiInteractionDropdown.Trigger = PiInteractionDropdownTrigger;
