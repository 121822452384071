import React, { ForwardedRef, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "./../../../app/slices/TrainingSlice";
import { arrayMove } from "@dnd-kit/sortable";
import PiDragContainer, { ContainerStyle } from "../../../components/PiDragContainer";

import { IConversation } from "../../../models/interactions/Conversation";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import { IEmotionBubble } from "../../../models/interactions/EmotionBubble";
import { GetMessageStrategy } from "./MessageBubbleStrategy";



interface ConversationPreviewMessageViewProps {
  onDetailsSelect: (message: (IMessageBubble | IEmotionBubble)) => void;
}

const ConversationPreviewMessageView = forwardRef<HTMLDivElement, ConversationPreviewMessageViewProps>(({ onDetailsSelect }, ref: ForwardedRef<HTMLDivElement>) => {
  const conversation = useSelector(selectSelectedElement) as IConversation;
  const dispatch = useDispatch();


  const msgs = conversation?.messages.map((msg) => {
    return GetMessageStrategy(msg).getPreview(msg, () => onDetailsSelect(msg));
  });

  function onHandleDragEnd(prevIndex: number, newIndex: number) {
    var originalMessages = [...conversation.messages];

    const newConversation = { ...conversation };
    newConversation.messages = arrayMove(originalMessages, prevIndex, newIndex);

    dispatch(updateSelectedInteraction(newConversation));
  }

  return (
    <div className="flex flex-col flex-grow gap-[10px] p-4 pl-0 pr-0 border-b-2 border-interaction-primary-washed clip pit-scrollbar overflow-y-auto" ref={ref} >
      <PiDragContainer
        onHandleDragEnd={onHandleDragEnd}
        containerElements={msgs}
        uniqueIds={conversation.messages.map(m => m.id)}
        containerStyle={ContainerStyle.Default} />
    </div>
  );
});

export default ConversationPreviewMessageView;
