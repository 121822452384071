import { useEffect, useRef } from "react";
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  reorderInteractions,
  reorderTakes,
  selectSelectedTakeId,
  selectTakes3D,
  updateSelectedTake,
} from "../../app/slices/TrainingSlice";
import Take3DCard from "./Take3DCard";
import "./Timeline3DTakesList.css";

export default function Timeline3DTakesList() {
  const takes = useSelector(selectTakes3D);
  const selectedTake = useSelector(selectSelectedTakeId);
  const dispatch = useDispatch();
  const takeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (selectedTake && takeRefs.current[selectedTake]) {
      takeRefs.current[selectedTake]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedTake]);

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined
  ) => ({
    cursor: "pointer",
    ...draggableStyle,
  });

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.type === "take3d") {
      dispatch(
        reorderTakes({
          startIndex: result.source.index,
          endIndex: result.destination.index,
        })
      );
    } else if (result.type === "interaction3d") {
      dispatch(
        reorderInteractions({
          startTakeId: result.source.droppableId,
          destinationTakeId: result.destination.droppableId,
          startIndex: result.source.index,
          endIndex: result.destination.index,
        })
      );
    }
  }

  function ForceExpand(takeId: string) {
    var take = takes.find((take) => take.id === takeId);
    console.log(takeId);
    if (take && !take.isExpanded) {
      console.log("take found");
      var updatedTake = { ...take };
      updatedTake.isExpanded = true;
      dispatch(updateSelectedTake(updatedTake));
    }
  }

  function onDragUpdate(dragUpdate: DragUpdate) {
    if (dragUpdate.destination) {
      ForceExpand(dragUpdate.destination.droppableId);
    }
  }

  const takesViewDnd = (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="take3d" type="take3d">
        {(provided, snapshot) => {
          return (
            <div
              className={"flex flex-col gap-[10px]"}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {takes.map((take, index) => (
                <Draggable key={take.id} draggableId={take.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={(el) => {
                        provided.innerRef(el); // Assign the ref for drag-and-drop
                        takeRefs.current[take.id] = el; // Store in custom ref
                      }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Take3DCard
                        key={index}
                        take={take}
                        index={index}
                        isSelected={selectedTake === take.id}
                        dragHandleProps={provided.dragHandleProps}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );

  return (
    <div
      className={
        "flex flex-col h-[calc(100%-102px)] overflow-y-auto pit-creator-scrollbar px-[18px] pt-[18px]"
      }
    >
      {takesViewDnd}
    </div>
  );
}
