import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import React from "react";
import "./PiImageAssetPreview.css";
import { useMediumAssetPreview } from "../../../hooks/MediumAssetPreviewHook";

//Asset id of image asset 
//Clickable callback for opening file explorer dialog
interface PiImageAssetPreviewProps {
  assetId: string|undefined;
  setIsOpen: (isOpen: boolean) => void;
}

export default function PiImageAssetPreview(props: PiImageAssetPreviewProps) {
  const {assetId, setIsOpen} = {...props};
  const { getAssetPreview } = useMediumAssetPreview(assetId);

  const selectImageBody = (
    <div className="piimageassetpreview-select-image" onClick={() => setIsOpen(true)}>
      <AddPhotoAlternateOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const imgDisplay = (
    <div className="piimageassetpreview-image-display">
      <img src={getAssetPreview()} />
      <div className="piimageassetpreview-change-image" onClick={() => setIsOpen(true)}>
        <ImageSearchOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
    </div>
  );

  return (
    <div className="piimageassetpreview-root">
      {assetId ? imgDisplay : selectImageBody}
    </div>
  );
}
