import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectInteractionWarnings,
    selectSelectedElement,
    updateSelectedInteractionProperty
} from "../../app/slices/TrainingSlice";
import Interaction from "../../models/interactions/Interaction";
import {InteractionProperty} from "../../models/InteractionProperty";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import "./InteractionPropertiesColumn.css";
import InteractionPropertyView from "./InteractionPropertyView";
import useValidation from "../validation/Validation.hooks";
import InteractionWarningsView from "../validation/InteractionWarningsView";

export default function InteractionPropertiesColumn() {
    const interaction = useSelector(selectSelectedElement) as Interaction;
    const dispatch = useDispatch();

    const {getInteractionWarnings} = useValidation();
    const allWarnings = useSelector(selectInteractionWarnings);
    const warnings = useMemo(() => {
        return allWarnings[interaction.id]
    } , [allWarnings, interaction])

    const onChange = (interactionProperty: InteractionProperty) => {
        dispatch(updateSelectedInteractionProperty(interactionProperty));
    };

    const views = interaction?.properties?.map((property, index) => {
        return <InteractionPropertyView key={index} interactionProperty={property} onChange={onChange}/>;
    });

    return (
        <div className="interactionpropertiescontent-root">
            <PropertiesColumnHeader text={interaction?.name}/>
            <div className="px-[14px] py-[20px]">
                {warnings?.length > 0 && <InteractionWarningsView warnings={warnings}/>}
                {views}
            </div>

        </div>
    );
}
  