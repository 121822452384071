import Interaction from "../../models/interactions/Interaction";
import { getInteractionFromFactory } from "../interaction_factory/InteractionFactory";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import { useDispatch, useSelector } from "react-redux";
import {
  addInteractionWarning,
  initializeValidation,
  selectActors,
  selectSelectedElement,
  selectSelectedElementIsInteraction,
  selectSelectedTake,
  selectSelectedTake3D,
  selectTakes,
  selectTrainingType,
  TrainingType,
} from "../../app/slices/TrainingSlice";
import { useEffect } from "react";
import {
  selectIsLocalizationInitialized,
  selectTrainingLocalizationState,
} from "../../app/slices/TrainingLocalizationSlice";

export default function useValidation() {
  const { getValue } = useTrainingLocalization();
  // const takes = useSelector(selectTakes3D);
  const initialized = useSelector(selectIsLocalizationInitialized);
  const dispatch = useDispatch();

  const locState = useSelector(selectTrainingLocalizationState);
  const selectedInteraction = useSelector(selectSelectedElement);
  const isInteraction = useSelector(selectSelectedElementIsInteraction);
  const trainingType = useSelector(selectTrainingType);
  const currentTake360 = useSelector(selectSelectedTake);
  const currentTake3D = useSelector(selectSelectedTake3D);
  const actors = useSelector(selectActors);

  const takes = useSelector(selectTakes);

  useEffect(() => {
    if (!initialized) return;

    dispatch(initializeValidation(validateArrayOfTakes()));
  }, [initialized]);

  useEffect(() => {
    updateWarningsForSelectedInteraction();
  }, [selectedInteraction, isInteraction, locState]);

  const updateWarningsForSelectedInteraction = () => {
    if (!isInteraction || selectedInteraction === undefined) return;
    const interaction = selectedInteraction as Interaction;
    const warnings = getInteractionWarnings(selectedInteraction as Interaction);
    dispatch(
      addInteractionWarning({ interactionId: interaction.id, warnings })
    );
  };

  const getInteractionWarnings = (interaction: Interaction): string[] => {
    const factoryEntity = getInteractionFromFactory(interaction.type);
    if (!factoryEntity) return [];

    const take =
      trainingType === TrainingType.Training3D ? currentTake3D : currentTake360;
    if (!take) return [];

    return [
      ...new Set(factoryEntity.Validate?.(interaction, getValue, take, actors)),
    ];
  };

  const validateArrayOfTakes = () => {
    const validatedInteractions: Record<string, string[]> = {};
    takes.forEach((take) => {
      take.interactions.forEach((interaction) => {
        validatedInteractions[interaction.id] =
          getInteractionWarnings(interaction);
      });
    });

    return validatedInteractions;
  };

  return {
    getInteractionWarnings,
    validateArrayOfTakes,
    updateWarningsForSelectedInteraction,
  };
}
