import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import {useSelector} from "react-redux";
import {selectSelectedElement} from "../../../app/slices/TrainingSlice";
import {IDialogQuiz} from "../../../models/interactions/DialogQuiz";
import {useTrainingLocalization} from "../../trainingLocalization/TrainingLocalizationHooks";
import DialogQuizPreviewBody from "./DialogQuizPreviewBody";

export default function DialogQuizPreview() {

    const {getValue, changeValue} = useTrainingLocalization();
    const dialog = useSelector(selectSelectedElement) as IDialogQuiz;

    const title = <PiInteractionTitlePreview value={getValue(dialog.title)} onChange={(newValue) => changeValue(dialog.title, newValue)} placeholder={"Enter your Title"}/>;

    const body = <DialogQuizPreviewBody/>;

    return <PiPopupWindowPreview title={title} body={body}/>
}