import InfoIcon from '@mui/icons-material/Info';


export default function InteractionWarningsView(props: { warnings: string[] }) {

    const {warnings} = {...props};

    const warningsMapped = warnings.map(warning => {
        return <li key={warning} className={"list-disc text-[14px] text-creator-text-sub"}>{warning}</li>
    })
    return <div
        className={"w-full p-[16px] flex flex-col gap-[8px] rounded-[5px] border-2 border-solid border-warning-600 bg-warning-50"}>
        <div className={"text-warning-600 flex gap-[8px]"}>
            <InfoIcon className={"my-auto"} color={"inherit"}/>
            <div className={"my-auto font-medium text-[16px]"}>Warning</div>
        </div>
        <ul className={"px-[24px]"}>
            {warningsMapped}
        </ul>

    </div>;

}