import React from "react";
import Timeline3DContent from "../Timeline3D/Timeline3DContent";
import TrainingTimeline360Content from "../training_timeline/TrainingTimeline360Content";
import Viewport3D from "../viewport3d/Viewport3D";
import TrainingBubbleViewport from "./TrainingBubbleViewport";
import TrainingPreviewBar from "./TrainingPreviewBar";

export interface TrainingEditorStrategy {
  getPreview: (selectedTake: boolean) => React.ReactNode;
  getTimeline: () => React.ReactNode;
}

export const TrainingEditorNotSet: TrainingEditorStrategy = {
  getPreview(selectedTake) {
    return <></>;
  },
  getTimeline() {
    return <></>;
  },
};

export const TrainingEditor360: TrainingEditorStrategy = {
  getPreview: (selectedTake: boolean) => {
    return selectedTake ? (
      <>
        <TrainingBubbleViewport />
        <TrainingPreviewBar />
      </>
    ) : (
      <></>
    );
  },
  getTimeline: () => {
    return <TrainingTimeline360Content />;
  },
};

export const TrainingEditor3D: TrainingEditorStrategy = {
  getPreview: (selectedTake: boolean) => {
    return (
      <>
        <Viewport3D />
      </>
    );
  },
  getTimeline: () => {
    return (
      <>
        <Timeline3DContent />
      </>
    );
  },
};
