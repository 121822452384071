export const useFileValidation = () => {
  const maxVideoLength: number = Number(process.env.REACT_APP_PLAYIT_MAX_VIDEO_LENGTH);

  async function IsVideoValid(file: File) {
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(file);

    const duration = await new Promise<number>((resolve) => {
      videoElement.addEventListener("loadedmetadata", () => {
        resolve(videoElement.duration);
      });
    });

    return duration <= maxVideoLength;
  }

  return { IsVideoValid };
};
