import AddIcon from "@mui/icons-material/Add";
import React from "react";
import "./PiBasicQuizAddButton.css";

interface PiBasicQuizAddButtonProps {
  onClick: () => void;
}

export default function PiBasicQuizAddButton(props: PiBasicQuizAddButtonProps) {
  return (
    <div className="pibasicquizaddbutton-root" onClick={() => props.onClick()}>
      <div className="pibasicquizaddbutton-icon">
        <AddIcon fontSize="inherit" color="inherit" />
      </div>

      <div className="pibasicquizaddbutton-text">Add Answer</div>
    </div>
  );
}
