import {PiInteractionDropdown} from "../../components/PiInteractionDropdown";
import ActorAvatar from "../../components/actors/ActorAvatar";
import {CapitalizeFirstLetter} from "../../helpers/StringHelper";

export default function DynamicInteractionStringDropdown(props: {label: string, value:string, values: string[], onChange: (newValue: string) => void}) {
    const {label, value, values, onChange} = {...props};

    const dropdownItems = values.map((value) => {
        return <PiInteractionDropdown.Item key={value} onClick={() => onChange(value)}>
            <div className={"flex justify-center gap-4 w-full"}>
                <div className={"my-auto truncate"}>{value}</div>
            </div>
        </PiInteractionDropdown.Item>;
    });

    const dropdown = <PiInteractionDropdown label={CapitalizeFirstLetter(label)}>
        <PiInteractionDropdown.Trigger>
            <div className={"flex gap-x-4 w-full"}>
                <div className={"my-auto truncate"}>{value ?? "No Value"}</div>
            </div>
        </PiInteractionDropdown.Trigger>
        {dropdownItems}
    </PiInteractionDropdown>;
    
    return <>
        {dropdown}
    </>;
}