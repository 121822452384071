import React, { useState } from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PiAccountDialog from "./PiAccountPopover";
import PiSwitchOrganizationDialog from "./PiSwitchOrganizationDialog";
import { useSelector } from "react-redux";
import {
  selectCurrentOrganization,
  selectUsername,
} from "../../app/slices/UserSlice";
import "./PiAccountButton.css";
import PiChangeLanguageDialog from "../i18n/PiChangeLanguageDialog";
interface PiUserButtonProps {
  isMenuExpanded: boolean;
}

export default function PiUserButton(props: PiUserButtonProps) {
  const [openAccountDialog, setOpenAccountDialog] = useState(false);
  const [openSwitchOrganizationDialog, setOpenOrganizationDialog] =
    useState(false);
  const [openChangeLanguageDialog, setOpenChangeLanguageDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const username = useSelector(selectUsername);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const id = "PiUserButton";

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (!openSwitchOrganizationDialog && !openChangeLanguageDialog) {
      setOpenAccountDialog(!openAccountDialog);
      setAnchorEl(event.currentTarget);
    }
  }
  function switchOrganizationButtonClicked() {
    setOpenOrganizationDialog(true);
  }

  function switchOrganizationConfirm() {
    setOpenOrganizationDialog(false);
  }

  function changeLanguageButtonClicked() {
    setOpenChangeLanguageDialog(true);
  }

  function changeLanguageButtonConfirm() {
    setOpenChangeLanguageDialog(false);
  }

  return (
    <button
      className="pi-accountbutton-root"
      area-aria-describedby={id}
      onClick={(event) => handleClick(event)}
    >
      <AccountCircleOutlinedIcon />
      <div className="pi-accountbutton-text">
        <div className="pi-accountbutton-username">{username}</div>
        <div className="pi-accountbutton-organizationName">
          {currentOrganization?.name}
        </div>
      </div>
      <PiAccountDialog
        id={id}
        anchorEl={anchorEl}
        open={openAccountDialog}
        switchOrgCallback={switchOrganizationButtonClicked}
        changeLanguageCallback={changeLanguageButtonClicked}
      />
      <PiSwitchOrganizationDialog
        open={openSwitchOrganizationDialog}
        onConfirm={switchOrganizationConfirm}
      />
      <PiChangeLanguageDialog
        open={openChangeLanguageDialog}
        onConfirm={changeLanguageButtonConfirm}
      />
    </button>
  );
}
