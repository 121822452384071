import { FindInPageOutlined, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface AdvancedSearchColumnHeaderProps {
    isSelected: boolean;
    onClickCallback: () => void;
}

export default function AdvancedSearchColumnHeader(props: AdvancedSearchColumnHeaderProps) {
    const { isSelected, onClickCallback } = props;
    const { t } = useTranslation();


    const columnWidth = isSelected ? " w-[292px] min-w-[292px] " : "w-full ";

    return (
        <div className={`${columnWidth} h-[60px] flex justify-between items-center bg-creator-primary-background ${isSelected ? "pl-[24px] " : "pl-[10px] "} pr-[10px] border-solid border-b-2 border-creator-border text-creator-text-sub`}>
            <div className={`flex ${isSelected ? "gap-[8px] " : ""} `}>
                <FindInPageOutlined color={"inherit"} />
                <div className={"my-auto h-[24px] font-medium text-[15px] truncate"}>{isSelected && t("advanced_search")}</div>
            </div>
            <button onClick={onClickCallback}>
                {isSelected && < KeyboardDoubleArrowLeft color={"inherit"} />}
                {!isSelected && < KeyboardDoubleArrowRight color={"inherit"} />}
            </button>
        </div>
    );
}