import Take3D from "../../../models/Take3D";
import {InteractionType} from "../../../models/interactions/Interaction";
import {IGrab} from "../../../models/interactions/Grab";
import {IDetach} from "../../../models/interactions/Detach";
import {IAttach} from "../../../models/interactions/Attach";

export class TrainingSliceStrategy3DHelper {
    static updateActorProp(previousTake: Take3D, newTake: Take3D): Take3D {
        // should be in order
        const allModifiers = previousTake.interactions.filter(i =>
            i.type === InteractionType.Grab ||
            i.type === InteractionType.Detach ||
            i.type === InteractionType.Attach);

        let propsCpy = [...newTake.props];
        let actorCpy = [...newTake.actors];
        let takeCpy = {...newTake};

        allModifiers.forEach(interaction => {
            // grab OR attach
            if (interaction.type === InteractionType.Grab || interaction.type === InteractionType.Attach) {
                const casted = interaction as IGrab | IAttach;
                const actorId = casted.playerGuid;
                const propId = casted.propGuid;

                const actorIndex = actorCpy.findIndex(a => a.actorId === actorId);
                const propIndex = propsCpy.findIndex(p => p.instanceId === propId);

                actorCpy[actorIndex] = {...actorCpy[actorIndex], prop: propId};
                propsCpy[propIndex] = {...propsCpy[propIndex], positionId: actorId};
            }

            // detach
            else if (interaction.type === InteractionType.Detach) {
                const casted = interaction as IDetach;
                const actorId = casted.playerGuid;
                const propId = casted.propGuid;

                const actorIndex = actorCpy.findIndex(a => a.actorId === actorId);
                const propIndex = propsCpy.findIndex(p => p.instanceId === propId);

                actorCpy[actorIndex] = {...actorCpy[actorIndex], prop: "None"};
                propsCpy = propsCpy.filter(p => p.id !== propsCpy[propIndex]?.id);
            }
        });

        return {...newTake, actors: actorCpy, props: propsCpy};
    }
}