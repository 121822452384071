import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import PostAddIcon from "@mui/icons-material/PostAdd";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import VideoSettingsOutlinedIcon from "@mui/icons-material/VideoSettingsOutlined";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import "./PiInteractionAssetPreview.css";
import CloseIcon from '@mui/icons-material/Close';
interface PiInteractionAssetPreviewProps {
  assetType: AssetKey;
  assetId: string;
  onAssetSelect: (asset: AssetDetailsDTO) => void;
  onDelete?: () => void;
}

export default function PiInteractionAssetPreview(props: PiInteractionAssetPreviewProps) {
  const { assetType, assetId, onAssetSelect, onDelete} = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview, getAssetPreviewRaw } = useAssetPreview(assetId);

  const onSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    onAssetSelect(asset);
  };

  const getAddIcon = () => {
    if (assetType === AssetKey.Image) {
      return <AddPhotoAlternateOutlinedIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    } else if (assetType === AssetKey.Video) {
      return <VideoCallOutlinedIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    } else if (assetType === AssetKey.Pdf) {
      return <PostAddIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    }
  };

  const getEditIcon = () => {
    if (assetType === AssetKey.Image) {
      return <ImageSearchOutlinedIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    } else if (assetType === AssetKey.Video) {
      return <VideoSettingsOutlinedIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    } else if (assetType === AssetKey.Pdf) {
      return <PostAddIcon onClick={() => setIsOpen(true)} color="inherit" fontSize="inherit" />;
    }
  };


  const closeButton = onDelete !== undefined ? <div className={"absolute cursor-pointer top-[8px] right-[16px] text-[32px] text-creator-error hover:text-creator-error-hover"} onClick={onDelete}>
    <CloseIcon fontSize={"inherit"} color={"inherit"} />
  </div> : <></>


  const getBody = () => {
    if (assetId === "" || assetId === undefined) {
      return (
        <div aria-label="piinteractionassetpreview-empty-body" className="piinteractionassetpreview-select-middle">
          {getAddIcon()}
        </div>
      );
    } else {
      if (assetType === AssetKey.Image) {
        return (
          <>
            {closeButton}
            <img aria-label="piinteractionassetpreview-image" src={getAssetPreview()} alt="" />
            <div className="piinteractionassetpreview-select-bot-right">{getEditIcon()}</div>
          </>
        );
      } else if (assetType === AssetKey.Video) {
        return (
          <>
            <div aria-label="piinteractionassetpreview-video" className="piinteractionassetpreview-video-preview">
              <ReactPlayer url={getAssetPreviewRaw()} width="80%" height="100%" controls />
            </div>
            <div className="piinteractionassetpreview-select-bot-right">{getEditIcon()}</div>
          </>
        );
      } else if (assetType === AssetKey.Pdf) {
        return (
          <>
            <iframe aria-label="piinteractionassetpreview-pdf" src={getAssetPreviewRaw()} title="PDF Viewer" width="80%" height="90%" />
            <div className="piinteractionassetpreview-select-bot-right">{getEditIcon()}</div>
          </>
        );
      }
    }
  };

  return (
    <div className="piinteractionassetpreview-root">
      {getBody()}
      <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={assetType} onSelect={onSelect} />
    </div>
  );
}
