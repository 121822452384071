import {ColumnSizer, Grid} from "react-virtualized";
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {selectTranslatedEnvironments} from "../../app/slices/EnvironmentSlice";
import AssetCard from "../file_explorer/AssetCard";
import AssetCardSkeleton from "../file_explorer/AssetCardSkeleton";
import {RenderedSection} from "react-virtualized/dist/es/Grid";
import useTranslatedEnvironments from "./TranslatedEnvironments.hook";
import TranslatedEnvironmentCard from "./TranslatedEnvironmentCard";
import CloseIcon from "@mui/icons-material/Close";
import {GetNewGuid} from "../../helpers/GuidHelper";

export default function TranslatedEnvironmentView(props: {visible: boolean, onSelectEnvironment: (environmentId: string) => void, onClose: () => void}) {
    const {onSelectEnvironment, onClose} = {...props};
    const translatedEnvironments = useSelector(selectTranslatedEnvironments);
    const parentRef = useRef<HTMLDivElement>(null);
    const [calculatedWidth, setCalculatedWidth] = useState<number>(0);
    const [amountOfColumns, setAmountOfColumns] = useState<number>(4);
    const [gridHeight, setGridHeight] = useState<number>(0);

    const {onRerender} = useTranslatedEnvironments();

    useEffect(() => {
        onWindowResize();
        window.addEventListener("resize", onWindowResize);

        return () => window.removeEventListener("resize", onWindowResize);
        //onWindowResize();
        //console.log("resizing");
    });

    useEffect(() => {
        if(props.visible) onWindowResize();
    }, [props.visible]);
    
    const onWindowResize = () => {
        if (parentRef.current) {
            // manually calculate the width of the grid + calculate the amount of columns that fit on the screen
            var offset = 64;
            var newCalcWidth = parentRef.current.clientWidth - 128;
            var newcols = newCalcWidth / 289;

            setCalculatedWidth(newCalcWidth);
            setAmountOfColumns(Math.floor(newcols));
            setGridHeight(parentRef.current.clientHeight);
        }
    };

    const cellRenderer = ({columnIndex, key, rowIndex, style}: { columnIndex: number; key: string; rowIndex: number; style: CSSProperties }) => {
        if (!translatedEnvironments) return;
        const index = amountOfColumns * rowIndex + columnIndex;
        return getCell(index, key, style);
    };

    function onSectionRenderer({
                                   columnOverscanStartIndex,
                                   columnOverscanStopIndex,
                                   columnStartIndex,
                                   columnStopIndex,
                                   rowOverscanStartIndex,
                                   rowOverscanStopIndex,
                                   rowStartIndex,
                                   rowStopIndex,
                               }: RenderedSection) {
        onRerender(amountOfColumns, rowStartIndex, rowStopIndex);
    }

    const getCell = (index: number, key: string, style: CSSProperties) => {
        if(index >= translatedEnvironments.length) return <div key={key}></div>;
        return <TranslatedEnvironmentCard  environment={translatedEnvironments[index]} key={key} style={style} onSelectEnvironment={onSelectEnvironment}/>
    };

    const rowCount = Math.ceil(translatedEnvironments.length / amountOfColumns)

    const getGrid = () => {
        if (translatedEnvironments.length > 0) {
            return (
                <ColumnSizer columnMaxWidth={1000} columnMinWidth={0} columnCount={amountOfColumns} width={calculatedWidth}>
                    {({adjustedWidth, getColumnWidth, registerChild}) => (
                        <Grid
                            ref={registerChild}
                            cellRenderer={cellRenderer}
                            columnCount={amountOfColumns}
                            columnWidth={getColumnWidth}
                            height={gridHeight}
                            rowCount={rowCount}
                            rowHeight={428}
                            width={adjustedWidth}
                            autoWidth
                            onSectionRendered={onSectionRenderer}
                        />
                    )}
                </ColumnSizer>
            );
        } else {
            return <></>;
        }
    };


    return <div ref={parentRef} className={"w-full h-full relative pl-[72px] pt-[32px] overflow-hidden"}>
        <div className={"absolute top-[8px] right-[24px] text-creator-text text-[24px] cursor-pointer"} onClick={onClose}> <CloseIcon fontSize={"inherit"} color={"inherit"} /> </div>
        {getGrid()}
    </div>
}