import { ISetCamera } from "../../models/interactions/SetCamera";
import Take3D from "../../models/Take3D";
import { Actor } from "../../models/Actor";
import useInteractionMigration from "./InteractionMigrationHook";

export default function useProjectMigration3D() {
  const { migrateInteraction } = useInteractionMigration();

  const migrateProject = (from: number, to: number, takes: Take3D[], actors: Actor[]): Take3D[] => {
    console.log("[3D] Migrating project from version " + from + " to " + to);
    // TODO: migrate all actors (we will probably never need this?)

    // migrate all interactions
    for (let t = 0; t < takes.length; t++) {
      // migrate take properties
      takes[t].mainCamera = migrateInteraction(from, to, takes[t].mainCamera) as ISetCamera;

      // migrate interactions
      for (let i = 0; i < takes[t].interactions.length; i++) {
        takes[t].interactions[i] = migrateInteraction(from, to, takes[t].interactions[i]);
      }
    }

    return takes;
  };

  return { migrateProject } as const;
}
