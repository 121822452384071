import { useDispatch, useSelector } from "react-redux";
import { selectEditingAsset, selectEditingVariantId, updateAssetInCurrentFolder, updateEditingVariant } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const useSaveVariant = () => {
  const org = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const variantId = useSelector(selectEditingVariantId);
  const asset = useSelector(selectEditingAsset);
  const dispatch = useDispatch();

  const saveVariant = async (newVariant: string | undefined, file: File | undefined) => {
    if (!org || !variantId || !asset) return;
    const variant = asset.variants.find((v) => v.id === variantId);

    // 1. update variant id (if changed && filled in)
    if (variant && newVariant && newVariant != variant.id) {
      await AssetService.UpdateVariantId(org.id, token, asset.id, variantId, newVariant);
      dispatch(updateEditingVariant(newVariant));
    }

    // 2. update variant content (if file != null)
    if (variant && file) {
      await AssetService.UpdateVariantContent(org.id, token, asset.id, variantId, file);

      // get asset again + update on slice!
      var updatedAsset = await AssetService.GetAsset(org.id, token, asset.id);
      if (updatedAsset) {
        dispatch(updateAssetInCurrentFolder(updatedAsset));
      }
    }
  };

  return [saveVariant] as const;
};
