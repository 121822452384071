import { Viewer } from "@photo-sphere-viewer/core";
import React, { useEffect, useState } from "react";
import { MarkersPlugin, ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { useSelector } from "react-redux";
import { selectSelectedElement, selectSelectedTake } from "../app/slices/TrainingSlice";
import { useAssetPreview } from "../hooks/AssetPreviewHook";
import Interaction, { InteractionType, Marker } from "../models/interactions/Interaction";
import Take360 from "../models/Take360";
import "./Pi360Viewer.css";
interface Pi360ViewerProps {
  container: string; // name of the class that the parent element of the viewer has
}

export default function Pi360Viewer(props: Pi360ViewerProps) {
  const [lastSetPanorama, setLastSetPanorama] = useState<string>("");
  const { container } = { ...props };
  const ref360 = React.createRef<Viewer>();
  const selectedTake = useSelector(selectSelectedTake);
  const selectedElement = useSelector(selectSelectedElement);
  const { getAssetPreview } = useAssetPreview(selectedTake?.assetId);

  useEffect(() => {
    if (ref360.current) {
      // only set 360 image if its new
      var assetPreviewUrl = getAssetPreview();
      if (!assetPreviewUrl) return;

      if (lastSetPanorama !== assetPreviewUrl) {
        ref360.current.setPanorama(assetPreviewUrl);
        setLastSetPanorama(assetPreviewUrl);
      }
      // destroy the viewer when display 360 is disabled
      if (!selectedTake?.display360) {
        ref360.current?.hideError();
        ref360.current?.stopAnimation();
        ref360.current?.destroy();
      }

      // markers
      if (selectedElement !== undefined && (selectedElement as Interaction).type !== undefined && (selectedElement as Interaction).type === InteractionType.TeleportMarker) {
        addMarkerToView(selectedElement as Marker, ref360.current.getPlugin(MarkersPlugin), "/tp_marker.png");
      } else if (selectedElement !== undefined && (selectedElement as Interaction).type !== undefined && (selectedElement as Interaction).type === InteractionType.Marker) {
        addMarkerToView(selectedElement as Marker, ref360.current.getPlugin(MarkersPlugin), "/tp_marker.png");
      } else if (selectedElement !== undefined && (selectedElement as Take360).originalRotation !== undefined) {
        addMarkerToView((selectedElement as Take360).originalRotation as Marker, ref360.current.getPlugin(MarkersPlugin), "/tp_eye.png");
      } else {
        clearMarkers();
      }
    }
  }, [selectedTake, selectedElement]);

  const clearMarkers = () => {
    var instance: MarkersPlugin = ref360.current!.getPlugin(MarkersPlugin);
    instance.clearMarkers();
  };

  const addMarkerToView = (marker: Marker, instance: MarkersPlugin, img: string) => {
    var degYaw = (marker.xPos - 0.5) * 360 + "deg";
    var degPitch = (marker.yPos - 0.5) * -180 + "deg";

    instance.clearMarkers();
    instance.addMarker({
      id: "marker",
      imageLayer: img,
      size: { width: 128, height: 128 },
      position: { yaw: degYaw, pitch: degPitch },
    });
  };

  const getStartPitch = () => {
    if (!selectedTake) return "0deg";
    return (selectedTake.originalRotation.yPos - 0.5) * -180 + "deg";
  };

  const getStartYaw = () => {
    if (!selectedTake) return "0deg";
    return (selectedTake.originalRotation.xPos - 0.5) * 360 + "deg";
  };

  return (
    <ReactPhotoSphereViewer
      src={getAssetPreview() ? getAssetPreview()! : ""}
      height="100%"
      width="100%"
      container={container}
      ref={ref360}
      plugins={[MarkersPlugin]}
      navbar={false}
      defaultPitch={getStartPitch()}
      defaultYaw={getStartYaw()}
    />
  );
}
