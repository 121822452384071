import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import React from "react";
import "./PiCard.css";

interface PiCardProps {
  onClose: () => void;
  children: React.ReactNode;
}

export default function PiCard(props: PiCardProps) {
  const closebtn = (
    <div className="picard-close-button">
      <IconButton aria-label="PiCard Close Button" onClick={props.onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
  return (
    <div className="picard-root">
      {closebtn}
      {props.children}
    </div>
  );
}
