import {ActorState} from "../../models/ActorState";
import {useSelector} from "react-redux";
import {selectAllPropPresets} from "../../app/slices/TrainingSlice";
import {selectUsedProps} from "../../app/slices/PropLibrarySlice";
import DoNotTouchOutlinedIcon from '@mui/icons-material/DoNotTouchOutlined';

export default function ActorStatePropInfoField(props: { actorState: ActorState }) {
    const {actorState} = {...props};
    const propPresets = useSelector(selectAllPropPresets);
    const usedProps = useSelector(selectUsedProps);

    const instanceId = actorState.prop;
    const myPreset = propPresets.find(x => x.id === instanceId);

    const propView = <div className={"flex w-full h-full px-[12px] py-[10px]"}>
        {myPreset && <img className={"object-contain w-full h-full"} alt={"props"}
                          src={usedProps[myPreset.propId].propContent.downloadUri}/>}
        {actorState.prop === "None" &&
            <div className={"m-auto text-[52px] text-creator-text-sub"}><DoNotTouchOutlinedIcon color={"inherit"}
                                                                                                fontSize={"inherit"}/>
            </div>}
    </div>

    const label = <div className={"text-creator-text text-[14px] font-medium"}>Has Prop</div>;
    const body = <div className={"h-[96px] border-2 border-solid border-creator-primary rounded-[14px] shadow-md"}>
        {propView}
    </div>;

    return <div className={"flex flex-col gap-[8px]"}>
        {label}
        {body}
    </div>
}