import {useSelector} from "react-redux";
import {selectActors} from "../../../app/slices/TrainingSlice";
import {messageBubbleAnimations} from "../../../models/interactions/MessageBubble";
import {PiInteractionDropdown} from "../../../components/PiInteractionDropdown";
import React from "react";

export default function MessageBubbleAnimationDropdown(props: {selectedAnimation: string; onClick: (animation: string) => void;}) {
    const {selectedAnimation, onClick} = {...props};

    const animDropdownItems = messageBubbleAnimations.map((animation) => {
        return <PiInteractionDropdown.Item key={animation} onClick={() => onClick(animation)}>
            {animation}</PiInteractionDropdown.Item>;
    });

    const animDropdown = <PiInteractionDropdown label={"Animation"}>
        <PiInteractionDropdown.Trigger>
            <div className={"w-full text-left"}>{selectedAnimation}</div>
        </PiInteractionDropdown.Trigger>
        {animDropdownItems}
    </PiInteractionDropdown>;
    
    return <>{animDropdown}</>
}

