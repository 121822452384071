import {emotionBubbleStylesMapped, IEmotionBubble} from "../../../models/interactions/EmotionBubble";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import {useSelector} from "react-redux";
import {selectActors} from "../../../app/slices/TrainingSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useScreenScale} from "../../../hooks/ScreenScale.hook";
import {PiInteractionDropdown} from "../../../components/PiInteractionDropdown";
import EmotionDropdown from "./EmotionDropdown";

export default function EmotionBubblePreviewContent(props: { emotionBubble: IEmotionBubble, onChange: (emotion: string) => void }) {

    const {emotionBubble, onChange} = {...props};
    const {scale} = useScreenScale();


    const currentEmotion = emotionBubbleStylesMapped.find(emotion => emotion.name === props.emotionBubble.messageBubbleStyle);
    const currentActor = useSelector(selectActors).find(a => a.id === emotionBubble.npcId);


    const avatar = <div className={"m-auto"}><ActorAvatar actor={currentActor}/></div>;
    const arrow = <div className={"absolute bottom-7 text-white text-[48px] left-1/2 -translate-x-1/2"}>
        <ArrowDropDownIcon className={"text-2xl drop-shadow-md"} fontSize={"inherit"}/>
    </div>;


    const content = <div style={{transform: `scale(${scale})`}} className={"h-full flex flex-col relative gap-6"}>
        <div className={"m-auto rounded-full  p-1"}><img className={"max-w-[96px] max-h-[96px] min-w-[96px] min-h-[96px] drop-shadow-md "} src={currentEmotion?.path}
                                                         alt={currentEmotion?.name}/></div>
        {arrow}
        {avatar}
    </div>

    const dropdown = <PiInteractionDropdown>
        <PiInteractionDropdown.Trigger>
            <div className={"flex w-full gap-2"}>
                <img src={currentEmotion?.path} alt={currentEmotion?.name} className={"m-auto max-w-[32px] max-h-[32px] m-auto"}/>
                <div className={"my-auto overflow-hidden text-ellipsis"}>
                    {emotionBubble.messageBubbleStyle}
                </div>
            </div>
        </PiInteractionDropdown.Trigger>
        {emotionBubbleStylesMapped.map(emotion => {
            return <PiInteractionDropdown.Item key={emotion.name} onClick={() => onChange(emotion.name)}>
                <div className={"flex gap-2 w-full"}>
                    <img src={emotion.path} alt={emotion.name} className={"m-auto max-w-[32px] max-h-[32px] m-auto"}/>
                    <div className={"my-auto overflow-hidden text-ellipsis"}>
                        {emotion.name}
                    </div>
                </div>
            </PiInteractionDropdown.Item>
        })}
    </PiInteractionDropdown>;


    return <div className={"relative w-full h-full flex bg-interaction-softBackground rounded-t-[5px] border-b-2 border-solid border-interaction-primary box-content"}>
        <div className={"m-auto"}>
            {content}
        </div>
        <div className={"absolute bottom-2 w-full"}>
            <div className={"m-auto w-[32px]"}>
                <EmotionDropdown onChange={onChange}/>
            </div>
        </div>
    </div>
}