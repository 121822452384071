import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import PiInputfield from "../../components/PiInputField";
import PiTextArea from "../../components/PiTextArea";
import { TranslationWithDescriptionDTO } from "../../dto/TranslationWithDescriptionDTO";
import "./CourseTranslationCard.css";
import { useTranslation } from "react-i18next";
interface CourseTranslationCardProps {
  shortcode: string;
  lang: string;
  dto: TranslationWithDescriptionDTO;
  onChange: (dto: TranslationWithDescriptionDTO) => void;
}

export default function CourseTranslationCard(props: CourseTranslationCardProps) {
  const { shortcode, lang, dto, onChange } = { ...props };
  const [isExpanded, setisExpanded] = useState<boolean>(true);
  const animArrowClass = isExpanded ? "coursetranslationcard-up" : "coursetranslationcard-down";
  const contentClass = isExpanded ? "coursetranslationcard-expanded" : "coursetranslationcard-minimized";
  const { t } = useTranslation();

  const getFlagCode = () => {
    if (shortcode === "nl" || shortcode === "fr") return shortcode;
    else if (shortcode === "en") return "gb";
    else return "nl";
  };
  const flagUrl = "https://flagcdn.com/" + getFlagCode() + ".svg";

  const onToggleExpand = () => {
    setisExpanded(!isExpanded);
  };

  const onChangeName = (e: string) => {
    var cdto = { ...dto, name: e };
    onChange(cdto);
  };
  const onChangeDesc = (e: string) => {
    var cdto = structuredClone(dto);
    cdto.description = e;
    onChange(cdto);
  };

  return (
    <div className="coursetranslationcard-root">
      <div className="coursetranslationcard-header" onClick={onToggleExpand}>
        <img className="coursetranslationcard-flag" src={flagUrl} />
        {lang}
        <div className={"coursetranslationcard-expander " + animArrowClass}>
          <IconButton onClick={onToggleExpand}>
            <KeyboardArrowDownIcon />
          </IconButton>
        </div>
      </div>
      <div className={"coursetranslationcard-content " + contentClass}>
        <PiInputfield value={dto.name} label={t("course_name")} placeholder={t("course_name_dotted")} onChange={onChangeName} />
        <PiTextArea value={dto.description} label={t("course_description")} placeholder={t("course_description_dotted")} onChange={onChangeDesc} />
      </div>
    </div>
  );
}
