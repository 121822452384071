import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { IAnswer } from "../../../models/Quiz";
import "./PiBasicQuizAnswer.css";
import PiQuizCheckbox from "./PiQuizCheckbox";
import PiQuizTextfield from "./PiQuizTextfield";

export interface PiQuizAnswerProps {
  answer: IAnswer;
  index?: number;
  quizType?: "order_quiz" | "basic_quiz" | "";
  handleAnswerChange: (
    id: string,
    field: string,
    value: boolean | string
  ) => void;
  getValue: (text: string) => string;
  changeValue: (text: string, value: string) => void;
  handleDeleteAnswer: (id: string) => void;
  answersLength: number;
  canDelete: boolean;
  hideBottom?: boolean;
  disableInput?: boolean;
}

export default function PiBasicQuizAnswer(props: PiQuizAnswerProps) {
  const {
    answer,
    handleAnswerChange,
    getValue,
    changeValue,
    handleDeleteAnswer,
    answersLength,
    canDelete,
  } = { ...props };

  return (
    <div key={answer.id} className="pibasicquizanswer-root">
      <PiQuizCheckbox
        checked={answer.correct}
        onChange={(e) => handleAnswerChange(answer.id, "checked", e)}
      />
      <PiQuizTextfield
        value={getValue(answer.text)}
        onChange={(e) => changeValue(answer.text, e)}
      />
      {canDelete && (
        <div
          className="pibasicquizanswer-delete-btn"
          onClick={() => handleDeleteAnswer(answer.id)}
        >
          <ClearIcon fontSize="inherit" color="inherit" />
        </div>
      )}
    </div>
  );
}
