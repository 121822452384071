import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import PiInteractionIconButton from "../../../components/interaction/PiInteractionIconButton";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import { IMessageBubble, messageBubbleStyles } from "../../../models/interactions/MessageBubble";
import { useSelector } from "react-redux";
import { selectActors } from "../../../app/slices/TrainingSlice";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useRef } from "react";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import { useDebouncedCallback } from "use-debounce";
import { useScreenScale } from "../../../hooks/ScreenScale.hook";
import { selectCurrentLanguage } from '../../../app/slices/TrainingLocalizationSlice';

export default function MessageBubblePreviewText(props: { messageBubble: IMessageBubble; updateInteraction: (field: string, value: any) => void }) {
    const { messageBubble, updateInteraction } = { ...props };
    const currentActor = useSelector(selectActors).find(a => a.id === messageBubble.npcId);

    const currentLang = useSelector(selectCurrentLanguage);

    const { getValue, changeValue } = useTrainingLocalization();
    const { scale } = useScreenScale();

    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (!ref.current) return;
        ref.current.value = getValue(messageBubble.text);
    }, [messageBubble.id, currentLang]);

    const debounced = useDebouncedCallback((value: string) => {
        changeValue(messageBubble.text, value);
    }, 500);

    const cycleMessageBubbleStyle = (next: boolean) => {
        const index = messageBubbleStyles.indexOf(messageBubble.messageBubbleStyle);
        var newIndex = index + (next ? 1 : -1);

        if (newIndex < 0) newIndex += messageBubbleStyles.length - 1;
        if (newIndex >= messageBubbleStyles.length) newIndex = 0;

        updateInteraction("messageBubbleStyle", messageBubbleStyles[newIndex]);
    };

    const messageBubbleCycle = <div className={"flex w-full h-[32px] justify-center gap-2"}>
        <PiInteractionIconButton onClick={() => cycleMessageBubbleStyle(false)}>
            <KeyboardArrowLeftIcon className={"m-auto"} fontSize={"inherit"} />
        </PiInteractionIconButton>
        <div className={"flex mx-[38px] w-[64px] text-center"}>
            <span className={"m-auto align-middle text-interaction-primary font-bold"}>
                {messageBubble.messageBubbleStyle}
            </span>
        </div>
        <PiInteractionIconButton onClick={() => cycleMessageBubbleStyle(true)}>
            <KeyboardArrowRightIcon className={"m-auto"} fontSize={"inherit"} />
        </PiInteractionIconButton>
    </div>;

    const avatar = <div className={"w-full flex justify-center"}>
        <ActorAvatar actor={currentActor} />
    </div>;

    const textarea = <div style={{ transform: `scale(${scale})` }} className={"w-full h-full flex flex-col relative gap-6"}>
        <div className={"flex flex-col gap-2 bg-white mx-auto p-[20px] w-[440px] shadow-base rounded-[5px]"}>
            <div className={"text-interaction-secondary font-medium text-[18px]"}>{currentActor?.workingName}</div>
            <textarea ref={ref} className={"w-full h-[100px] text-interaction-primary text-[16px] resize-none focus:outline-none"} placeholder={"Message"} defaultValue={getValue(messageBubble.text)}
                onChange={(e) => debounced(e.target.value)} />
        </div>
        <div className={"absolute bottom-6 text-white text-[48px] left-1/2 -translate-x-1/2"}>
            <ArrowDropDownIcon className={"text-2xl"} fontSize={"inherit"} />
        </div>
        {avatar}
    </div>;

    return <div className={"w-full h-full flex relative rounded-t-[5px] bg-interaction-softBackground border-solid border-b-2 border-interaction-primary box-content"}>
        <div className={"m-auto"}>
            {textarea}
        </div>

        <div className={"absolute bottom-1 piLg:bottom-6 w-full flex flex-col gap-12 "}>
            {messageBubbleCycle}
        </div>
    </div>
}