import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import SetPropStatePreviewBody from "./SetPropStatePreviewBody";

export default function SetPropStatePreview() {
  const title = <PiPopupWindowStaticTitle title="Set Prop State" />;

  return (
    <PiPopupWindowPreview
      size="medium"
      body={<SetPropStatePreviewBody />}
      title={title}
    />
  );
}
