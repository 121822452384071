import { useSelector } from "react-redux";
import { selectProps, selectUsedProps } from "../app/slices/PropLibrarySlice";
import Take3D from "../models/Take3D";
import {
  InteractionCategory,
  InteractionDefintion,
  InteractionIcon,
} from "../features/interaction_picker/InteractionDefinitions";
import { InteractionType } from "../models/interactions/Interaction";
import {
  selectActors,
  selectAllPropPresets,
  selectSelectedTake3D,
  selectSelectedTakeActors,
  selectSelectedTakeProps,
} from "../app/slices/TrainingSlice";
import { PropTakeState } from "../models/PropTakeState";
import { Actor } from "../models/Actor";
import { Prop } from "../models/Prop";
import { IAttach } from "../models/interactions/Attach";

export default function usePropHelper() {
  const allPropDtos = useSelector(selectProps); // all props
  const allPropPresets = useSelector(selectAllPropPresets);
  const take = useSelector(selectSelectedTake3D);
  const usedProps = useSelector(selectUsedProps);
  const actorPresets = useSelector(selectActors);
  const takeProps = useSelector(selectSelectedTakeProps);
  const actors = useSelector(selectSelectedTakeActors);

  const getPropDTO = (propId: string) => {
    const prop = usedProps[propId];
    if (!prop) throw new Error("Prop not found");

    return prop;
  };

  const getPropPresetByInstanceId = (instanceId: string) => {
    return allPropPresets.find((p) => p.id === instanceId);
  };

  const getUsedPropByInstanceId = (instanceId: string) => {
    const inst = allPropPresets.find((p) => p.id === instanceId);
    const dto = getPropDTO(inst?.propId ?? "");

    if (!dto) throw new Error("Prop not found");

    return dto;
  };

  const getAllInvolvedProps = (take: Take3D) => {
    var props = take.props.map((p) => p.instanceId);
    return allPropPresets.filter((p) => props.indexOf(p.id) > -1);
  };

  const getInvolvedPropInteractions = (take: Take3D) => {
    // all attaches this take
    const newAttachedPropIds = take.interactions
      .filter((i) => i.type === InteractionType.Attach)
      .map((i) => (i as IAttach).propGuid);
    // all actors that have a prop in their hand
    const attachedPropIds = take.actors
      .filter((a) => a.prop !== "None")
      .map((f) => f.prop);
    // concat of all props currently in play
    const involvedPropIds = [
      ...take.props.map((p) => p.instanceId),
      ...attachedPropIds,
      ...newAttachedPropIds,
    ];
    const definitions: Record<string, InteractionDefintion[]> = {};

    involvedPropIds.forEach((involvedPropId) => {
      const preset = allPropPresets.find((p) => p.id === involvedPropId);
      if (!preset) return;
      const usedProp = usedProps[preset.propId];

      if (usedProp && usedProp.metadata.dynamicInteractions) {
        let localDefinitions: InteractionDefintion[] = [];
        usedProp.metadata.dynamicInteractions.forEach((interaction) => {
          localDefinitions.push({
            name: interaction.title,
            icon: InteractionIcon.Message,
            type: InteractionType.DynamicInteraction,
            category: InteractionCategory.Quizes,
            previewImage: "",
            description: interaction.description,
          });
        });

        definitions[involvedPropId] = localDefinitions;
      }
    });

    return definitions;
  };

  const getIsPropAttachedToActor = (propTakeState: PropTakeState) => {
    const found = actorPresets.find((a) => a.id === propTakeState.positionId);
    return found !== undefined;
  };

  const getActorsNotAttachedToProps = () => {
    const result: Actor[] = [];

    actors.forEach((actor) => {
      const found = takeProps.find((prop) => prop.positionId === actor.id);
      if (!found) result.push(actor);
    });

    return result;
  };

  const getPropsAndAttachedActors = () => {
    const result: { propInstanceId: string; actor: Actor }[] = [];
    if (!take) return result;

    //  check attaches in current take
    take.interactions
      .filter((i) => i.type === InteractionType.Attach)
      .forEach((f) => {
        const converted = f as IAttach;
        const actor = actors.find((a) => a.id === converted.playerGuid);

        if (!actor) return;
        result.push({
          propInstanceId: converted.propGuid,
          actor: actor,
        });
      });

    // check actors that have something attached instantly such as a cellphone
    take.actors
      .filter((a) => a.prop !== "None")
      .forEach((f) =>
        result.push({
          propInstanceId: f.prop,
          actor: actors.find((a) => a.id === f.actorId)!,
        })
      );

    // check all props in the take
    takeProps.forEach((tp) => {
      const found = actors.find((a) => a.id === tp.positionId);
      if (found)
        result.push({
          propInstanceId: tp.instanceId,
          actor: found,
        });
    });

    return result;
  };

  const getPropPresetsNotSpawnedInTake = () => {
    const notSpawned: Prop[] = [];

    allPropPresets.forEach((pp) => {
      const found = takeProps.find((p) => p.instanceId === pp.id);
      if (!found) notSpawned.push(pp);
    });

    return notSpawned;
  };

  return {
    getPropDTO,
    getUsedPropByInstanceId,
    getInvolvedPropInteractions,
    getIsPropAttachedToActor,
    getActorsNotAttachedToProps,
    getPropsAndAttachedActors,
    getPropPresetByInstanceId,
    getPropPresetsNotSpawnedInTake,
    getAllInvolvedProps,
  };
}
