import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import "./PiDropdown.css";
import PiPopper, { PiPopperMenuProps } from "./PiPopper";

export interface PiDropdownProps {
  options: PiPopperMenuProps[];
  selectedText: string;
  width?: string;
  label?: string;
  info?: string;
  onClick?: (index: number) => void;
  variant?: "key-blue" | "game-blue";
  disabled?: boolean;
}

export default function PiDropdown(props: PiDropdownProps) {
  const { options, selectedText, width, label, info } = { ...props };
  const [open, setOpen] = useState<boolean>(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const onClose = () => {
    setOpen(false);
  };

  const dropperClasses = open ? "pidropdown-root pidropdown-root-open" : "pidropdown-root";
  const arrowClasses = open ? "pidropdown-arrow pidropdown-arrow-up" : "pidropdown-arrow pidropdown-arrow-down";

  const lbl = label ? <div className="pidropdown-label">{label}</div> : <></>;

  const infoButton = info ? (
    <div className="pidropdown-info-button">
      <Tooltip title={props.info} arrow placement="right">
        <IconButton tabIndex={-1}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    <></>
  );

  const dropper = (
    <div ref={anchorRef} className={dropperClasses} onClick={() => setOpen(!open)}>
      <div className="pidropdown-text">{selectedText}</div>
      <div className={arrowClasses}>
        <KeyboardArrowDownIcon />
      </div>
      {infoButton}
    </div>
  );
  const popper = (
    <PiPopper
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      selectedIndex={options.indexOf(options.find((o) => o.text === selectedText)!)}
      width={width ?? "200px"}
      items={options}
      onClick={props.onClick}
    />
  );

  return (
    <>
      {lbl}
      {dropper}
      {popper}
    </>
  );
}
