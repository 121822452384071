import React from "react";
import "./PiProgressbar.css";

interface PiProgressbarProps {
  progressBlue: string;
  progressYellow: string;
}

export default function PiProgressbar(props: PiProgressbarProps) {
  const { progressBlue, progressYellow } = { ...props };
  return (
    <div className="piprogressbar-root">
      <div className="piprogressbar-blue" style={{ width: progressBlue }}></div>
      <div className="piprogressbar-yellow" style={{ width: progressYellow }}></div>
    </div>
  );
}
