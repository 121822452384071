import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import MessageBubblePreview from "../../features/previews/message_bubble/MessageBubblePreview";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import Take3D from "../Take3D";

export const messageBubbleAnimations: string[] = ["Talk_1", "Talk_2", "Talk_3", "Thinking", "Head_Shake", "Head_Nodding", "Happy_ThumbsUp", "HappyJump", "Hello_Wave", "Sigh"];

export const messageBubbleStyles: string[] = ["Normal", "Yelling", "Shout", "Thinking"];

export interface IMessageBubble extends Interaction {
    text: string;
    npcId: string;
    messageBubbleStyle: string;
    lookAt: string;
    animation: string;
}

const requiredMessageBubbleProperties: InteractionProperty[] = [];

export function CreateMessageBubble(keys: string[]): IMessageBubble {
    return {
        id: uuidv4(),
        name: "Message Bubble",
        type: InteractionType.MessageBubble,
        text: keys[0],
        npcId: "player",
        lookAt: "",
        animation: messageBubbleAnimations[0],
        messageBubbleStyle: messageBubbleStyles[0],
        properties: requiredMessageBubbleProperties,
    };
}

export function CreateMessageBubbleCopy(titleKey: string, interaction: IMessageBubble): IMessageBubble {
    return {
        id: uuidv4(),
        name: "Message Bubble",
        type: InteractionType.MessageBubble,
        text: titleKey,
        npcId: interaction.npcId,
        lookAt: interaction.lookAt,
        animation: interaction.animation,
        messageBubbleStyle: interaction.messageBubbleStyle,
        properties: interaction.properties,
    };
}

export const messageBubbleInteraction = (): InteractionFactoryEntity => {
    const component = <MessageBubblePreview />;

    const create = (getKeys: (amount: number) => string[]): IMessageBubble => {
        const keys = getKeys(1);
        return {
            id: uuidv4(),
            name: "Message Bubble",
            type: InteractionType.MessageBubble,
            text: keys[0],
            npcId: "player",
            lookAt: "",
            animation: messageBubbleAnimations[0],
            messageBubbleStyle: messageBubbleStyles[0],
            properties: requiredMessageBubbleProperties,
        };
    };
    const migrate = (from: number, to: number, interaction: Interaction): IMessageBubble => {
        return interaction as IMessageBubble;
    };

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IMessageBubble => {

        const messageBubble = interaction as IMessageBubble;
        const newKeys = getKeys(1);
        replaceKey(messageBubble.text, newKeys[0]);

        return {
            ...messageBubble,
            id: uuidv4(),
            text: newKeys[0],
        }
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const take3d = take as Take3D;
        const converted = interaction as IMessageBubble;
        const warnings: string[] = [];
        
        ValidationHelper.ValidateKey(converted.text, getValue, warnings, "Message");
        ValidationHelper.ValidateNpcInTake(converted.npcId, take3d, actorPresets, warnings);
        ValidationHelper.ValidateNpcInTake(converted.lookAt, take3d, actorPresets, warnings);

        return warnings;
    }
    
    
    const filter = (interaction: Interaction): string[] => {
        let results: string[] = [];

        const bubble = interaction as IMessageBubble

        results.push(interaction.name);
        results.push(bubble.text);

        return results;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    };
};

