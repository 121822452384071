import {InteractionFactoryEntity} from "../../features/interaction_factory/InteractionFactory";
import Interaction, {InteractionType} from "./Interaction";
import {GetNewGuid} from "../../helpers/GuidHelper";
import {IGrab} from "./Grab";
import DetachPreview from "../../features/previews/detach/DetachPreview";

export interface IDetach extends Interaction {
    propGuid: string;
    playerGuid: string;
}

export const detachInteraction = (): InteractionFactoryEntity => {
    const component = <DetachPreview />;

    const create = (getKeys: (amount: number) => string[]): IDetach => {
        return {
            id: GetNewGuid(),
            name: "Detach",
            type: InteractionType.Detach,
            propGuid: "",
            playerGuid: ""
        }
    }

    const migrate = (from: number, to: number, interaction: Interaction): IDetach => {
        return interaction as IDetach;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): IDetach => {
        return create(getKeys);
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    }
}