import {useDispatch, useSelector} from "react-redux";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import {PropTakeState} from "../../models/PropTakeState";
import {selectProps} from "../../app/slices/PropLibrarySlice";
import {selectAllPropPresets} from "../../app/slices/TrainingSlice";

export default function TakePropertiesProp(props: { prop: PropTakeState, isInvolved: boolean }) {
    const {prop, isInvolved} = {...props};
    const propPreset = useSelector(selectAllPropPresets).find(p => p.id === prop.instanceId);
    const propDto = useSelector(selectProps).find(p => p.id === propPreset?.propId);

    const dispatch = useDispatch();

    // if prop is in current take location, show different icon !
    const icon = isInvolved ? <DoorBackOutlinedIcon fontSize={"inherit"} color={"inherit"}/> : <MeetingRoomOutlinedIcon fontSize={"inherit"} color={"inherit"}/>;

    const image = <div className={"w-[24px] h-[24px] min-w-[24px] min-h-[24px] p-[2px] flex bg-white border-2 border-solid border-creator-border rounded-full"}>
        <img className={"m-auto object-contain w-[14px] h-[14px]"} src={propDto?.downloadUri} alt={""}/>
    </div>;

    return <div className={"flex px-[10px] gap-[10px] py-[4px] cursor-pointer select-none hover:bg-creator-primary-washed"}>
        <div>
            {icon}
        </div>
        <div>
            {image}
        </div>
        <div className={"my-auto text-creator-text-sub text-14 font-medium"}>
            {propPreset?.name}
        </div>
    </div>
}