import React, { useState } from "react";
import "./PiSelectAssetButton.css";

import { Button } from "@mui/material";
import { AssetDetailsDTO, AssetKey } from "../dto/AssetDTO";
import FileExplorerDialog from "../features/file_explorer/FileExplorerDialog";

interface PiSelectAssetButtonProps {
  text: string;
  label: string;
  assetType: AssetKey;
  onAssetSelectCallback: (asset: AssetDetailsDTO) => void;
}

export default function PiSelectAssetButton(props: PiSelectAssetButtonProps) {
  const { label, text, assetType, onAssetSelectCallback } = {
    ...props,
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onAssetSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    onAssetSelectCallback(asset);
  };

  return (
    <div className="piselectassetbutton-root">
      <span className="piselectassetbutton-label">{label}</span>
      <div className="piselectassetbutton-button">
        <Button aria-label="piselectassetbutton-button" variant="piBlue" onClick={() => setIsOpen(true)}>
          {text}
        </Button>
      </div>

      <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={assetType} onSelect={onAssetSelect} />
    </div>
  );
}
