import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ReactNode, useRef } from "react";

interface PiInteractionPopOverProps {
    children?: ReactNode
}

export function PiInteractionPopOver({ children }: PiInteractionPopOverProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    return (
        <div ref={ref} className={"w-6 h-6"}>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger className={"w-full h-full flex items-center justify-center hover:bg-interaction-primary-washed rounded-circle"}>
                    <div className={" text-interaction-primary-faded font medium"}>
                        <MoreHorizIcon color={"inherit"} />
                    </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content
                    className={"w-full h-full py-[6px] gap-[6px] z-40 bg-interaction-white shadow-base border-interaction-primary-washed rounded-[5px]"}
                    side="top"
                    align="start">
                    {children}
                </DropdownMenu.Content>
            </DropdownMenu.Root>
        </div>);
}
