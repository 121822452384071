import { InteractionProperty } from "../../models/InteractionProperty";

export function FindPropertyInArray(currentProperties: InteractionProperty[], property: InteractionProperty): InteractionProperty | undefined {
  return currentProperties.find((p) => p.name === property.name && p.type === property.type);
}

export function GetMigratedProperties(requiredProperties: InteractionProperty[], currentProperties: InteractionProperty[]): InteractionProperty[] {
  var newProperties: InteractionProperty[] = [];
  requiredProperties.forEach((prop) => {
    var result = FindPropertyInArray(currentProperties, prop);
    if (result === undefined) newProperties.push(prop);
    else newProperties.push(result);
  });

  return newProperties;
}
