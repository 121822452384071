import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../app/slices/AuthenticationSlice";
import { setIsLoading } from "../../app/slices/GlobalSlice";
import { LanguageData, addAutoTranslatedLanguage, selectTrainingLocalizationState } from "../../app/slices/TrainingLocalizationSlice";
import { selectReferenceLanguage, setIsDirty } from "../../app/slices/TrainingSlice";
import { TranslationService } from "../../services/TranslationService";

export const useAddLanguage = () => {
  const locState = useSelector(selectTrainingLocalizationState);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const referenceLanguage = useSelector(selectReferenceLanguage);

  async function addNewLanguage(lang: LanguageData) {
    dispatch(setIsLoading(true));
    var from = referenceLanguage;
    var to = lang.shortcode;
    var texts: string[] = [];

    for (var key in locState.localizedEntries) {
      texts.push(locState.localizedEntries[key][from].value);
    }

    var translatedTexts = await TranslationService.TranslateTexts(token, texts, from, to);

    dispatch(
      addAutoTranslatedLanguage({
        lang: lang,
        translatedValues: translatedTexts,
      })
    );

    dispatch(setIsDirty());
    dispatch(setIsLoading(false));
  }
  return [addNewLanguage] as const;
};
