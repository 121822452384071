import axios from "axios";
import { TranslatedEnvironmentContentDTO } from "../dto/TranslatedEnvironmentContentDTO";
import { TranslatedEnvironmentDTO } from "../dto/TranslatedEnvironmentDTO";
import { Environment } from "../models/Environment";

var CREATOR_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

export class EnvironmentService {
  static async GetTranslatedEnvironments(
    orgId: string,
    token: string,
    langCode: string,
    countryCode: string,
    pageNumber: number,
    pageSize: number,
    orderBy: string
  ) {
    const path = "/organizations/" + orgId + "/translated-environments";
    const url = CREATOR_URL + path;

    const params = {
      LanguageCode: langCode,
      CountryCode: countryCode,
      PageSize: pageSize,
      PageNumber: pageNumber,
      OrderBy: orderBy,
    };

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: TranslatedEnvironmentDTO[] = [];
    var totalPages: number = 0;
    var totalCount: number = 0;

    await axios
      .get<TranslatedEnvironmentDTO[]>(url, config)
      .then((response) => {
        result = response.data;
        totalPages = JSON.parse(response.headers["x-pagination"])["totalPages"];
        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => {
        console.log(error.response);
      });

    return {
      translatedEnvironments: result,
      totalPages: totalPages,
      totalCount: totalCount,
    };
  }

  static async GetTranslatedEnvironment(
    orgId: string,
    token: string,
    environmentId: string,
    langCode: string,
    countryCode: string
  ): Promise<TranslatedEnvironmentContentDTO | undefined> {
    const path = "/organizations/" + orgId + "/translated-environments/" + environmentId;
    const url = CREATOR_URL + path;

    const params = {
      LanguageCode: langCode,
      CountryCode: countryCode,
    };

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: TranslatedEnvironmentContentDTO | undefined = undefined;

    await axios
      .get<TranslatedEnvironmentContentDTO>(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  // this is the json w/ all the take-positions, actor-positions, ...
  static async GetEnvironmentContent(url: string): Promise<Environment | undefined> {
    var result: Environment | undefined = undefined;

    await axios.get<Environment>(url).then((response) => {
      result = response.data;
    });

    return result;
  }

  static async GetFloorplanImage(orgId: string, token: string, environmentId: string, x: number, y: number, z: number): Promise<string> {
    const path = "/organizations/" + orgId + "/environments/" + environmentId + "/floorplans";
    const url = CREATOR_URL + path;
    const params = {
      level: z,
      x: x,
      y: y,
    };
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: string = "";

    await axios
      .get<any>(url, config)
      .then((response) => {
        //console.log(url);
        result = response.data["downloadUri"];
      })
      .catch((error) => {
        console.log(error.response);
      });
    return result;
  }

  static async GetCameraPreview(orgId: string, token: string, environmentId: string, cameraId: string): Promise<string> {
    const path = "/organizations/" + orgId + "/environments/" + environmentId + "/renders/" + cameraId + "/camera";
    const url = CREATOR_URL + path;

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    var result: string = "";

    await axios
      .get<any>(url, config)
      .then((response) => {
        //console.log(url);
        result = response.data["downloadUri"];
      })
      .catch((error) => {
        console.log(error.response);
      });
    return result;
  }
}
