import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";
import { IStatementQuiz } from "../../../models/interactions/StatementQuiz";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { useRef, useState } from "react";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import StatementQuizPreviewTextArea from "./StatementQuizPreviewTextArea";
import { useDispatch } from "react-redux";

export default function StatementQuizPreviewText(props: {
  index: number;
  onIndexChange: (index: number) => void;
}) {
  const { index, onIndexChange } = { ...props };
  const interaction = useSelector(selectSelectedElement) as IStatementQuiz;
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onDeleteStatement = (index: number) => {
    const statements = interaction.statements.filter((_, i) => i !== index);
    const correctAnswers = interaction.correctAnswers.filter(
      (_, i) => i !== index
    );

    onIndexChange(index - 1);

    dispatch(
      updateSelectedInteraction({
        ...interaction,
        statements: statements,
        correctAnswers: correctAnswers,
      } as IStatementQuiz)
    );
  };

  const incrementIndex = (amount: 1 | -1) => {
    let newIndex = index + amount;
    if (newIndex >= interaction.statements.length) newIndex = 0;
    if (newIndex < 0) newIndex = interaction.statements.length - 1;
    onIndexChange(newIndex);
  };

  const arrowLeft = (
    <div className="flex h-full">
      <button
        className={
          "my-auto w-[48px] h-[48px] bg-interaction-primary text-[32px] text-white rounded-full flex cursor-pointer hover:bg-interaction-primary-hover disabled:cursor-not-allowed disabled:bg-interaction-disabled"
        }
        onClick={() => incrementIndex(-1)}
        disabled={interaction.statements.length <= 1}
      >
        <ChevronLeftIcon
          className={"m-auto"}
          fontSize={"inherit"}
          color={"inherit"}
        />
      </button>
    </div>
  );

  const arrowRight = (
    <div className="flex h-full">
      <button
        className={
          "my-auto w-[48px] h-[48px] bg-interaction-primary text-[32px] text-white rounded-full flex cursor-pointer hover:bg-interaction-primary-hover disabled:cursor-not-allowed disabled:bg-interaction-disabled"
        }
        onClick={() => incrementIndex(1)}
        disabled={interaction.statements.length <= 1}
      >
        <ChevronRightIcon
          className={"m-auto"}
          fontSize={"inherit"}
          color={"inherit"}
        />
      </button>
    </div>
  );

  const textarea = (
    <StatementQuizPreviewTextArea index={index} onDelete={onDeleteStatement} />
  );

  return (
    <div className="flex w-full h-full gap-[8px]">
      {arrowLeft}
      {textarea}
      {arrowRight}
    </div>
  );
}
