import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import ClickIndicatorPreviewBody from "./ClickIndicatorPreviewBody";

export default function ClickIndicatorPreview() {
  const title = <PiPopupWindowStaticTitle title="Click Indicator" />;

  return (
    <PiPopupWindowPreview
      size="medium"
      body={<ClickIndicatorPreviewBody />}
      title={title}
    />
  );
}
