import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOutfits } from "../../app/slices/MetadataSlice";
import { selectSelectedActor, updateActor } from "../../app/slices/TrainingSlice";
import PiButtonSelector from "../../components/PiButtonSelector";

export default function ActorOutfitSelector() {
  const dispatch = useDispatch();
  const selectedActor = useSelector(selectSelectedActor);
  const allOutfits = useSelector(selectOutfits);
  const selectedIndex = useMemo(() => {
    return allOutfits.findIndex((outfit) => outfit.outfit_name === selectedActor?.outfit);
  }, [selectedActor, allOutfits]);

  var outfitsImages = allOutfits.map((outfit) => "/outfits/" + outfit.outfit_preview_male);

  const onOutfitButtonClicked = (index: number) => {
    if (!selectedActor) return;
    const newOutfit = index > -1 ? allOutfits[index].outfit_name : "";
    const actorCopy = { ...selectedActor, outfit: newOutfit };
    dispatch(updateActor(actorCopy));
  };

  return <PiButtonSelector imagePaths={outfitsImages} selectedIndex={selectedIndex} onButtonClicked={onOutfitButtonClicked} square={true} allowShuffle />;
}
