import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectActors, selectSelectedElement, selectSelectedTake3D, updateSelectedActorState} from "../../app/slices/TrainingSlice";
import {ActorMovementType} from "../../models/ActorMovementType";
import {ActorState} from "../../models/ActorState";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import ActorStateLookatDropdown from "./ActorStateLookatDropdown";
import ActorStateMovementDropdown from "./ActorStateMovementDropdown";
import ActorStateOutfitDropdown from "./ActorStateOutfitDropdown";
import "./ActorStatePropertiesColumn.css";
import useActorStateProperties, {ActorStatus} from "./ActorStatePropertiesColumn.hooks";
import ActorStateSetStateDropdown from "./ActorStateSetStateDropdown";
import ActorStateSetActivityDropdown from "./ActorStateActivityDropdown";
import ActorStatePropDropdown from "./ActorStatePropDropdown";
import PiBreadcrumbs from "../../components/PiBreadcrumbs";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ActorStatePropertiesBreadcrumb from "./ActorStatePropertiesBreadcrumb";
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import ActorStatePropertiesHeader from "./ActorStatePropertiesHeader";
import ActorStateMovementToggle from "./ActorStateMovementToggle";
import PiCreatorDropdown from "../../components/PiCreatorDropdown";
import ActorStatePropInfoField from "./ActorStatePropInfoField";

export default function ActorStatePropertiesColumn() {

    const selectedActorState = useSelector(selectSelectedElement) as ActorState;
    const actorPreset = useSelector(selectActors).find((actor) => actor.id === selectedActorState?.actorId);
    const {getActorStatus, getActorStatusLabel} = useActorStateProperties();
    const [myActorStatus, setMyActorStatus] = useState<ActorStatus>(ActorStatus.Invalid);

    const dispatch = useDispatch();

    useEffect(() => {
        setMyActorStatus(getActorStatus());
    }, [selectedActorState, getActorStatus]);

    const onChangeField = (field: keyof ActorState, value: any) => {
        dispatch(updateSelectedActorState({...selectedActorState, [field]: value}));
    };

    const onChangeMovementType = (movementType: ActorMovementType) => onChangeField("movement", movementType);
    const onChangeLookat = (lookatId: string) => onChangeField("lookat", lookatId);

    const canSelectMovement =
        (selectedActorState.actorId === "player" && myActorStatus !== ActorStatus.Idle) || (selectedActorState != null && myActorStatus !== ActorStatus.Idle && myActorStatus !== ActorStatus.Spawned);

    const actorStatusLabel = (
        <div className="actorstatuspropertiescolumn-actor-status-label">
            <InfoOutlinedIcon fontSize="medium" color="inherit"/>
            {getActorStatusLabel(myActorStatus)}
        </div>
    );

    // movement dropdown
    const movementDropdown = <ActorStateMovementDropdown actorState={selectedActorState} onChange={onChangeMovementType} disabled={!canSelectMovement}/>;

    // state dropdown
    const stateDropdown = <ActorStateSetStateDropdown actorState={selectedActorState} onChange={(state) => onChangeField("state", state)}/>;

    // outfit (dropdown?)
    const outfitDropdwon = <ActorStateOutfitDropdown actorState={selectedActorState} onChange={(outfit) => onChangeField("outfit", outfit)} disabled={myActorStatus === ActorStatus.Spawned}/>;

    // activity dropdown
    const activityDropdown = <ActorStateSetActivityDropdown actorState={selectedActorState} onChange={(activity) => onChangeField("activity", activity)}/>

    // props dropdown
    const propDropdown = <ActorStatePropDropdown actorState={selectedActorState} onChange={(prop: string) => onChangeField("prop", prop)}/>

    // 1. bread crumbs
    // -> find out which is my parent take, should be the selected take (duh)

    return (
        <>
            <PropertiesColumnHeader text={actorPreset?.workingName ?? "No Name"}/>
            <PropertiesColumnBody>
                <div className={"h-full"}>
                    <ActorStatePropertiesBreadcrumb/>
                    <div className={"bg-creator-primary-background"}>
                        <div className={"flex flex-col gap-[20px] py-[5px] px-[10px]"}>
                            <ActorStatePropertiesHeader actor={selectedActorState}/>
                            <ActorStateMovementToggle actor={selectedActorState} onChange={(movement) => onChangeField("movement", movement)} disabled={!canSelectMovement}/>
                            <ActorStateLookatDropdown actorState={selectedActorState} onChange={onChangeLookat}/>
                            {/*<ActorStateSetStateDropdown actorState={selectedActorState} onChange={(state) => onChangeField("state", state)}/>*/}
                            <ActorStateSetActivityDropdown actorState={selectedActorState} onChange={(activity) => onChangeField("activity", activity)}/>
                            <ActorStateOutfitDropdown actorState={selectedActorState} onChange={(outfit) => onChangeField("outfit", outfit)} disabled={myActorStatus === ActorStatus.Spawned}/>
                            {/*<ActorStatePropDropdown actorState={selectedActorState} onChange={(prop: string) => onChangeField("prop", prop)}/>*/}
                            <ActorStatePropInfoField actorState={selectedActorState} />
                        </div>
                    </div>
                </div>

            </PropertiesColumnBody>
        </>
    );
}
