import { MapContainer } from "react-leaflet";
import L from "leaflet";
import "./ViewportMap3D.css";
import EnvironmentMetadataView from "../viewport3d/Viewport3DEnvironmentMetadata";
import React, { useEffect } from "react";
import PiTileLayerComponent from "./PiTileLayer";
import { useSelector } from "react-redux";
import { selectMaxZoomLevel } from "../../app/slices/EnvironmentSlice";
import { SelectedTab, selectSelectedTab } from "../../app/slices/GlobalSlice";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import useDelay from "../../hooks/Delay.hook";

export default function ViewportMap3D() {
  const maxZoomLevel = useSelector(selectMaxZoomLevel);
  const ref = React.useRef<L.Map | null>(null);
  const selectedTab = useSelector(selectSelectedTab);
  const isPlaying = useSelector(selectIsPlaying);
  const { delay } = useDelay();

  // bounds: makes sure you can't pan outside the world
  const b = L.latLngBounds([-90, -180], [90, 180]);

  useEffect(() => {
    if (maxZoomLevel !== undefined && selectedTab === SelectedTab.Timeline) {
      ref.current?.setMaxZoom(maxZoomLevel - 1);
    }
  }, [maxZoomLevel, ref.current, selectedTab]);

  useEffect(() => {
    updateZoomLevelAsync();
  }, [isPlaying]);

  const updateZoomLevelAsync = async () => {
    await delay(150);
    if (maxZoomLevel !== undefined && selectedTab === SelectedTab.Timeline) {
      ref.current?.setMaxZoom(maxZoomLevel - 1);
    }
  };

  return (
    <MapContainer
      ref={ref}
      center={[180, 180]}
      zoom={2}
      worldCopyJump={false}
      minZoom={0}
      maxBoundsViscosity={1}
      zoomControl={false}
      attributionControl={false}
      maxBounds={b}
    >
      <PiTileLayerComponent urlTemplate={"/{z}/{x}_{y}.jpg"} />
      <EnvironmentMetadataView />
    </MapContainer>
  );
}
