import { useSelector } from "react-redux";
import { IStatementQuiz } from "../../../models/interactions/StatementQuiz";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import StatementQuizPreviewBody from "./StatementQuizPreviewBody";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";

export default function StatementQuizPreview() {
  const statementQuiz = useSelector(selectSelectedElement) as IStatementQuiz;
  const { getValue, changeValue } = useTrainingLocalization();

  const title = (
    <PiInteractionTitlePreview
      value={getValue(statementQuiz.title)}
      onChange={(newValue) => changeValue(statementQuiz.title, newValue)}
      placeholder={"Enter your Title"}
    />
  );

  return (
    <PiPopupWindowPreview
      title={title}
      body={<StatementQuizPreviewBody />}
      size="large"
    />
  );
}
