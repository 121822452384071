import Take360 from "../../models/Take360";
import useInteractionMigration from "./InteractionMigrationHook";

export default function useProjectMigration360() {
  const { migrateInteraction } = useInteractionMigration();

  const migrateProject = (from: number, to: number, takes: Take360[]): Take360[] => {
    console.log("[360] Migrating project from version " + from + " to " + to);

    // TODO: do some migration on the takes
    // migrate all interactions
    for (let t = 0; t < takes.length; t++) {
      for (let i = 0; i < takes[t].interactions.length; i++) {
        takes[t].interactions[i] = migrateInteraction(from, to, takes[t].interactions[i]);
      }
    }

    return takes;
  };

  return { migrateProject } as const;
}
