import PiBoxIcon from "../../icons/PiBoxIcon";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import {useHelper3D} from "../../hooks/HelperHooks3D";
import {selectSelectedTake3D} from "../../app/slices/TrainingSlice";
import {useSelector} from "react-redux";
import {useMemo} from "react";
import TakePropertiesProp from "./TakePropertiesProp";
import {useTranslation} from "react-i18next";

export default function TakePropertiesPropList() {
    const take = useSelector(selectSelectedTake3D)!;
    const {getInvolvedProps} = useHelper3D();
    const involvedProps = useMemo(() => getInvolvedProps(take), [take]);
    const {t} = useTranslation();

    const propsMapped = take.props.map(prop => <TakePropertiesProp key={prop.id} prop={prop} isInvolved={involvedProps.findIndex(ia => ia.instanceId === prop.instanceId) !== -1}/>);

    const settings = <PiCollapsibleSettings text={t("placed_props")} icon={<PiBoxIcon width={24} height={24} className={"fill-creator-primary"}/>} paddingY>
        {propsMapped}
    </PiCollapsibleSettings>;

    return <>{settings}</>;
}