import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { useSelector } from "react-redux";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import React, { useMemo } from "react";
import {
  selectPropById,
  selectProps,
  selectUsedProps,
} from "../../../app/slices/PropLibrarySlice";
import { RootState } from "../../../app/Store";
import { PropTakeState } from "../../../models/PropTakeState";
import usePropHelper from "../../../hooks/PropHelper";
import { UsedProp } from "../../../models/UsedProp";
import { Prop } from "../../../models/Prop";

export default function PropFilteredDropdown(props: {
  label: string;
  selectedPropStateId: string;
  onClick: (prop: Prop) => void;
  forcedCategory: "ui" | "attachable";
}) {
  const { label, selectedPropStateId, onClick, forcedCategory } = { ...props };
  const usedProps = useSelector(selectUsedProps);
  const allPropPresets = useSelector(selectAllPropPresets);
  const selectedPropPreset = allPropPresets.find(
    (pp) => pp.id === selectedPropStateId
  );

  const presetsFiltered = useMemo(() => {
    const result: Prop[] = [];

    allPropPresets.forEach((preset) => {
      // todo: expand filter system
      const prop = usedProps[preset.propId];
      if (prop) {
        const cat = prop.propContent.categories.find(
          (c) => c.name === forcedCategory
        );
        if (cat) {
          console.log(prop);
          result.push(preset);
        }
      }
    });

    return result;
  }, [usedProps]);

  const propDropdownItems = presetsFiltered.map((prop) => {
    return (
      <PiInteractionDropdown.Item key={prop.id} onClick={() => onClick(prop)}>
        <div className={"flex justify-center gap-4 w-full"}>
          <div className={"my-auto truncate"}>
            {allPropPresets.find((p) => p.id === prop.id)?.name}
          </div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const propDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>
            {selectedPropPreset?.name ?? "No Prop"}
          </div>
        </div>
      </PiInteractionDropdown.Trigger>
      {propDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{propDropdown}</>;
}
