import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React from "react";
import { InteractionDefintion } from "./InteractionDefinitions";
import "./InteractionPickerAccordion.css";
import InteractionPickerButton from "./InteractionPickerButton";
interface InteractionPickerAccordionProps {
  title: string;
  interactionDefinitions: InteractionDefintion[];
  onClose: () => void;
  children?: React.ReactNode;
}

export default function InteractionPickerAccordion(props: InteractionPickerAccordionProps) {
  const { title, interactionDefinitions, onClose, children } = { ...props };

  const pickerbtns = interactionDefinitions.map((interactionDef: InteractionDefintion, index: number) => {
    return <InteractionPickerButton key={index} interactionDefinition={interactionDef} onClose={onClose} />;
  });

  return (
    <div className="interactionpickeraccordion-root">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="interactionpickeraccordion-title">{title}</span>
        </AccordionSummary>
        <AccordionDetails>
          <div className="interactionpickeraccordion-pickers">
            {pickerbtns}
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
