import { Switch } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PiTooltip from "../features/menu/PiTooltip";

interface PiSwitchProps {
  label: string;
  labelLocId?: string;
  tooltipLocId?: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function PiSwitch(props: PiSwitchProps) {
  const { label, labelLocId, tooltipLocId, onChange } = { ...props };
  const { t } = useTranslation();
  
  const onSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange(checked);
   
  };

  const onClick = () => {
    onChange(!props.checked);
  };

  const anchor =
    <button className="font-medium leading-5 text-14 " onClick={onClick}>
      {labelLocId ? t(labelLocId) : label}
    </button>

  return (
    <div aria-label="pi-switch-root" className="flex w-full">
      <PiTooltip anchor={anchor} tooltipLocId={tooltipLocId} />
      <div className="ml-auto">
        <Switch aria-label="piswitch-switch" checked={props.checked} onChange={onSwitchToggle} />
      </div>

    </div>
  );
}
