import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import React from "react";
import PiTabView, {PiTabSettings} from "../../components/PiTabView";
import {useTranslation} from "react-i18next";
import {SupervisorAccountOutlined} from "@mui/icons-material";
import PiBoxIcon from "../../icons/PiBoxIcon";

export default function Timeline3DTab(props: { onTabClick: (index: number) => void }) {
    const {onTabClick} = props;
    const {t} = useTranslation();

    const tabSettings: PiTabSettings[] = [
        {
            icon: <MovieCreationOutlinedIcon color="inherit" fontSize="inherit"/>,
            onClick: () => {
            },
            text: t("timeline").toString()
        },
        {
            icon: <SupervisorAccountOutlined color="inherit" fontSize="inherit"/>,
            onClick: () => {
            },
            text: t("actors").toString()
        },
        {
            icon: <PiBoxIcon className={""} width={24} height={24}/>,
            onClick: () => {
            },
            text: t("props").toString()
        },
    ];

    return <PiTabView onTabClick={onTabClick} tabSettings={tabSettings}/>;
}
