import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  addLoadingAsset,
  addUnsupportedAsset,
  finishLoadingAsset,
  selectSelectedFolder,
  selectSupportedAudioTypes,
  selectSupportedImageTypes,
  selectSupportedPdfTypes,
  selectSupportedVideoTypes,
} from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";
import { useFileValidation } from "./FileValidationHook";
export const useNewAssetUpload = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const organization = useSelector(selectCurrentOrganization);
  const selectedFolder = useSelector(selectSelectedFolder);
  const supportedImageTypes = useSelector(selectSupportedImageTypes);
  const supportedVideoTypes = useSelector(selectSupportedVideoTypes);
  const supportedPdfTypes = useSelector(selectSupportedPdfTypes);
  const supportedAudioTypes = useSelector(selectSupportedAudioTypes);
  const supportedFilesTypes = [...supportedImageTypes, ...supportedVideoTypes, ...supportedPdfTypes, ...supportedAudioTypes];

  const { IsVideoValid } = useFileValidation();
  const { t } = useTranslation();

  const handleFiles = async (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      let file: File = files[i];

      if (supportedFilesTypes.includes(file.type)) {
        console.log("file supported!");

        // TODO: check if file is video
        if (supportedVideoTypes.includes(file.type)) {
          if (await IsVideoValid(file)) {
            uploadFile(file);
          } else {
            dispatch(addUnsupportedAsset({ name: t("video_too_long"), id: uuidv4() }));
          }
        } else {
          uploadFile(file);
        }
      } else {
        console.log("file not supported");
        dispatch(addUnsupportedAsset({ name: file.name, id: uuidv4() }));
      }

      console.log(files[i].name + " " + files[i].type);
    }
  };

  async function uploadFile(file: File) {
    if (!organization || !selectedFolder) return;

    // add temp loading asset card
    dispatch(addLoadingAsset(file.name));
    var newAsset = await AssetService.AddNewAssetToFolder(organization.id, token, selectedFolder.id, file);
    if (newAsset) {
      console.log("fetch new asset");
      var updatedAsset = await AssetService.GetAsset(organization.id, token, newAsset?.id);
      console.log(updatedAsset);
      if (updatedAsset) {
        dispatch(finishLoadingAsset({ name: file.name, newAsset: updatedAsset }));
      }
    } else {
      // failed to add asset
    }
  }

  return [handleFiles] as const;
};
