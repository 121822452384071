import {useAssetPreview} from "../hooks/AssetPreviewHook";
import FileExplorerDialog from "../features/file_explorer/FileExplorerDialog";
import React, {useState} from "react";
import {AssetKey} from "../dto/AssetDTO";
import {useSmallAssetPreview} from "../hooks/SmallAssetPreviewHook";
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
import PiPopoverMenu from "./PiPopoverMenu";
import PiMenuButton from "../features/menu/PiMenuButton";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export default function PiAssetSelect(props: { label: string, assetId: string, onChange: (assetId: string) => void }) {
    const {label, assetId, onChange} = {...props};
    const {getAssetPreview} = useSmallAssetPreview(assetId);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {t} = useTranslation();
    const previewUrl = getAssetPreview();

    const menu = (
        <div className={"absolute bottom-2 right-2"}>
            <PiPopoverMenu>
                <PiMenuButton text={t("select_new")} onClick={() => setIsOpen(true)}/>
                <PiMenuButton text={t("delete")} onClick={() => onChange("")}/>
            </PiPopoverMenu>
        </div>
    );

    return <>
        <div className={"w-full flex flex-col gap-y-[6px]"}>
            <div className={"font-medium text-[14px] text-creator-text"}>{label}</div>
            <div className={"px-[4px] h-[96px]"}>
                <div
                    className={classNames({"h-full w-full relative border-2 border-creator-primary rounded-[5px]": true, "cursor-pointer hover:opacity-75 hover:bg-creator-primary-washed": !previewUrl})} onClick={() => {
                        if(previewUrl) return;
                        setIsOpen(true);
                }}>
                    {previewUrl && <div className={"flex w-full h-full"}>
                        <img className={"m-auto object-contain rounded-[5px]"} src={previewUrl} alt={"preview"}/>
                    </div>}
                    {!previewUrl && <div className={"h-full w-full flex"}>
                        <div className={"m-auto text-creator-text-sub text-[32px]"}>
                            <ImageNotSupportedOutlinedIcon color={"inherit"} fontSize={"inherit"}/>
                        </div>
                    </div>}
                    {previewUrl && menu}
                </div>
            </div>
        </div>
        <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={AssetKey.Image}
                            onSelect={(asset) => {
                                setIsOpen(false);
                                onChange(asset.assetId)
                            }}/>
    </>
}