import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import "./PiTextArea.css";
interface PiTextAreaProps {
  label: string;
  placeholder: string;
  info?: string;
  value?: string;
  showInfoButton?: boolean;
  onChange: (newValue: string) => void;
}

export default function PiTextArea(props: PiTextAreaProps) {
  const infoButton = props.showInfoButton ? (
    <div className="pi-textarea-info">
      <Tooltip
        aria-label="pitextarea-tooltip"
        title={props.info}
        arrow
        placement="right"
      >
        <IconButton tabIndex={-1}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    ""
  );

  return (
    <div className="pi-textarea-root">
      <div className="pi-textarea-label">{props.label}</div>
      <textarea
        className="pi-textarea-input"
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
      {infoButton}
    </div>
  );
}
