import { useSelector } from "react-redux";
import { selectSelectedElement, selectSelectedTake3D, selectTakes3D } from "../../app/slices/TrainingSlice";
import { ActorState } from "../../models/ActorState";

export enum ActorStatus {
  Spawned,
  Moving,
  Idle,
  Invalid,
}

export default function useActorStateProperties() {
  const selectedTake = useSelector(selectSelectedTake3D);
  const selectedActorState = useSelector(selectSelectedElement) as ActorState;
  const allTakes = useSelector(selectTakes3D);

  // if actor was not in previous take OR current take is the first
  // actors spawns
  // if actor was in previous take and positionId is different
  // actor was moved
  // if actor was in previous take and positionId is the same, actor is idle
  // i need the previous take
  const getActorStatus = (): ActorStatus => {
    if (!selectedTake) return ActorStatus.Invalid;

    const index = allTakes.indexOf(selectedTake);
    if (index === 0) return ActorStatus.Spawned;

    const previousTake = allTakes[index - 1];
    const actorInPreviousTake = previousTake.actors.find((actor) => actor.actorId === selectedActorState.actorId);
    if (!actorInPreviousTake) return ActorStatus.Spawned;

    if (actorInPreviousTake.positionId !== selectedActorState.positionId) return ActorStatus.Moving;

    return ActorStatus.Idle;
  };

  const getActorStatusLabel = (actorStatus: ActorStatus): string => {
    if (actorStatus === ActorStatus.Spawned) return "Actor gets spawned this take";
    else if (actorStatus === ActorStatus.Moving) return "Actor is moved this take";
    else if (actorStatus === ActorStatus.Idle) return "Actor is idle this take";

    return "";
  };

  return { getActorStatus, getActorStatusLabel };
}
