import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageDTO } from "../../dto/LanguageDTO";
import LocalizedLanguageDTO from "../../dto/LocalizedLanguageDTO";
import { RootState } from "../Store";

export interface LocalizedString {
  [key: string]: { value: string; isAutoTranslated: boolean };
}

export interface LocalizedStringRecord {
  [key: string]: LocalizedString;
}

export interface LanguageData {
  shortcode: string;
  full: string;
}

interface TrainingLocalizationState {
  localizedEntries: LocalizedStringRecord;
  localizedLanguages: LanguageData[];
  availableLanguages: LanguageData[];
  currentLanguage: LanguageData;
  initialized: boolean;
}

const mockupLanguages: LanguageData[] = [{ shortcode: "nl", full: "Nederlands" }];

const mockupAvailableLanguages: LanguageData[] = [
  { shortcode: "nl", full: "Nederlands" },
  { shortcode: "en", full: "English" },
  { shortcode: "fr", full: "Francais" },
];

const initialState: TrainingLocalizationState = {
  localizedEntries: {},
  localizedLanguages: mockupLanguages,
  currentLanguage: mockupLanguages[0],
  availableLanguages: mockupAvailableLanguages,
  initialized: false
};

export const trainingLocalizationSlice = createSlice({
  name: "trainingLocalization",
  initialState: initialState,
  reducers: {
    clearLocalization: (state) => {
      state.localizedEntries = {};
      state.localizedLanguages = [];
      state.initialized = false;
    },
    initializeNewLocalization: (state) => {
      state.localizedLanguages = [mockupLanguages[0]];
    },
    registerNewKey: (state, action: PayloadAction<string>) => {
      state.localizedEntries[action.payload] = {};

      // add empty record for every added language
      state.localizedLanguages.forEach((lang) => {
        state.localizedEntries[action.payload][lang.shortcode] = {
          isAutoTranslated: false,
          value: "",
        };
      });
    },
    setCurrentLanguage: (state, action: PayloadAction<LanguageData>) => {
      state.currentLanguage = action.payload;
    },
    addLocalizedLanguage: (state, action: PayloadAction<LanguageData>) => {
      // add language to all localizedEntries
      for (const key in state.localizedEntries) {
        state.localizedEntries[key][action.payload.shortcode] = {
          isAutoTranslated: false,
          value: "",
        };
      }

      state.localizedLanguages.push(action.payload);
    },
    addAutoTranslatedLanguage: (state, action: PayloadAction<{ lang: LanguageData; translatedValues: string[] }>) => {
      let index = 0;
      for (const key in state.localizedEntries) {
        state.localizedEntries[key][action.payload.lang.shortcode] = {
          isAutoTranslated: true,
          value: action.payload.translatedValues[index],
        };
        index += 1;
      }

      // only add language if not already added
      if (!state.localizedLanguages.find((lang) => lang.shortcode === action.payload.lang.shortcode)) {
        state.localizedLanguages.push(action.payload.lang);
      }
    },
    addMissingAutoTranslatedKeys: (state, action: PayloadAction<{ lang: LanguageData; translatedValues: string[] }>) => {
      const lang = action.payload.lang;
      const values = action.payload.translatedValues;
      var index = 0;

      for (const key in state.localizedEntries) {
        if (!state.localizedEntries[key][lang.shortcode].value || state.localizedEntries[key][lang.shortcode].value === " ") {
          state.localizedEntries[key][lang.shortcode] = { value: values[index], isAutoTranslated: true };
        }

        index += 1;
      }
    },
    deleteLocalizedLanguage: (state, action: PayloadAction<LanguageData>) => {
      const index = state.localizedLanguages.indexOf(state.localizedLanguages.find((lang) => lang.shortcode === action.payload.shortcode) as LanguageData);
      state.localizedLanguages.splice(index, 1);

      if (state.currentLanguage.shortcode === action.payload.shortcode) {
        state.currentLanguage = state.localizedLanguages[0];
      }
    },
    updateLocalizedString: (state, action: PayloadAction<{ key: string; newValue: string }>) => {
      state.localizedEntries[action.payload.key][state.currentLanguage.shortcode] = { value: action.payload.newValue, isAutoTranslated: false };
    },
    updateLocalizedStringFromExcel: (state, action: PayloadAction<{ key: string; shortcode: string; value: string }>) => {
      const { key, shortcode, value } = { ...action.payload };
      if (state.localizedLanguages.find((lang) => lang.shortcode === shortcode) === undefined) return;
      if (state.localizedEntries[key] === undefined) return;
      state.localizedEntries[key][shortcode] = {
        value: value,
        isAutoTranslated: false,
      };
    },
    addLocalizedKeys: (state, action: PayloadAction<string[]>) => {
      action.payload.forEach((key) => {
        state.localizedEntries[key] = {};
      });
    },
    addSerializedLanguage: (state, action: PayloadAction<LocalizedLanguageDTO>) => {
      const dto = action.payload;
      const language = state.availableLanguages.find((lang) => lang.shortcode === dto.lang);

      // 1. add language to array
      if (!state.localizedLanguages.find((lang) => lang.shortcode === language?.shortcode)) {
        state.localizedLanguages.push(language as LanguageData);
      }

      // 2. add all entries
      dto.entries.forEach((entry) => {
        state.localizedEntries[entry.key][dto.lang] = {
          value: entry.value,
          isAutoTranslated: entry.isAutoTranslated,
        };
      });
    },
    addSupportedLanguages: (state, action: PayloadAction<LanguageDTO[]>) => {
      var langData: LanguageData[] = [];
      action.payload.map((lang) => {
        langData.push({
          shortcode: lang.id,
          full: lang.name,
        });
      });
      state.availableLanguages = langData;
    },
    setInitialLanguage: (state, action: PayloadAction<string>) => {
      var initLang = state.localizedLanguages.find((lang) => lang.shortcode === action.payload);
      if (initLang) {
        state.currentLanguage = initLang;

        // swap array elems
        var index = state.localizedLanguages.indexOf(initLang);
        const locLang = [...state.localizedLanguages];
        const temp = locLang[index];
        locLang[index] = locLang[0];
        locLang[0] = temp;
        state.localizedLanguages = locLang;
      }

      state.initialized = true;
    },
    deleteKeys: (state, action: PayloadAction<string[]>) => {
      var keys = action.payload;
      action.payload.forEach((key) => {
        delete state.localizedEntries[key];
      });
    },
  },
});

export const selectTrainingLocalizationState = (state: RootState) => {
  return state.trainingLocalization;
};

export const selectLocalizedLanguages = (state: RootState) => state.trainingLocalization.localizedLanguages;

export const selectCurrentLanguage = (state: RootState) => state.trainingLocalization.currentLanguage;

export const selectAvailableLanguagesNotAdded = (state: RootState) => {
  return state.trainingLocalization.availableLanguages.filter((lang) => !state.trainingLocalization.localizedLanguages.some((locLang) => locLang.shortcode === lang.shortcode));
};

export const selectAvailableLanguages = (state: RootState) => state.trainingLocalization.availableLanguages;

export const {
  clearLocalization,
  registerNewKey,
  setCurrentLanguage,
  updateLocalizedString,
  updateLocalizedStringFromExcel,
  addLocalizedLanguage,
  addAutoTranslatedLanguage,
  addMissingAutoTranslatedKeys,
  deleteLocalizedLanguage,
  addLocalizedKeys,
  addSerializedLanguage,
  addSupportedLanguages,
  setInitialLanguage,
  initializeNewLocalization,
  deleteKeys,
} = trainingLocalizationSlice.actions;

export default trainingLocalizationSlice.reducer;

export const selectLocalizedEntries = (state: RootState) => state.trainingLocalization.localizedEntries;

// helper functions
export const requestNewKey = (state: TrainingLocalizationState) => {
  // todo: for every lang available, add an empty localized string
  let newKey = "";
  let currIndex = 1;
  do {
    newKey = formatIndexToKey(currIndex);
    currIndex += 1;
  } while (newKey in state.localizedEntries);

  return newKey;
};

export const requestArrayOfKeys = (state: TrainingLocalizationState, count: number) => {
  const keys: string[] = [];

  let newKey = "";
  let currIndex = 1;
  for (let i = 0; i < count; i++) {
    do {
      newKey = formatIndexToKey(currIndex);
      currIndex += 1;
    } while (newKey in state.localizedEntries || keys.indexOf(newKey) > -1);
    keys.push(newKey);
  }

  return keys;
};

export const selectIsLocalizationInitialized = (state: RootState) => state.trainingLocalization.initialized;

const formatIndexToKey = (index: number) => {
  if (index < 10) return `key_00${index}`;
  else if (index >= 10 && index < 100) return `key_0${index}`;
  else if (index >= 100 && index < 1000) return `key_${index}`;
  else {
    throw new Error("too many keys in localization!");
  }
};
