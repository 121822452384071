import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useRef, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteTake, setSelectedTake, updateSelectedTake } from "../../app/slices/TrainingSlice";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import { useSmallAssetPreview } from "../../hooks/SmallAssetPreviewHook";
import Take360 from "../../models/Take360";
import PiMenuButton from "../menu/PiMenuButton";
import "./TakeCard360Header.css";
interface TakeCard360HeaderProps {
  take: Take360;
  isExpanded: boolean;
  isSelected: boolean;
  onToggleExpand: () => void;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
}

export default function TakeCard360Header(props: TakeCard360HeaderProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { take, isExpanded, isSelected, onToggleExpand, dragHandleProps } = { ...props };
  const { getAssetPreview } = useSmallAssetPreview(take?.assetId);
  const animArrowClass = isExpanded ? "takecard360-up" : "takecard360-down";
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    setIsRenaming(false);
  }, [isSelected]);

  const onClickHeader = () => {
    dispatch(setSelectedTake(take.id));
  };

  const DeleteBubble = () => {
    dispatch(deleteTake(take.id));
  };

  const startRenaming = async () => {
    setIsRenaming(true);
    await delay(100);
    inputRef.current?.focus();
  };

  const onChangeName = (e: string) => {
    const takeCopy = { ...take };
    takeCopy.name = e;
    dispatch(updateSelectedTake(takeCopy));
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      setIsRenaming(false);
    }
  };

  const rootclass = isSelected ? "takecard360header-root takecard360header-selected" : "takecard360header-root";

  const name = <div className="takecard360-name">{take.name}</div>;
  const inputField = (
    <input className="takecard360-name takecard360-input" ref={inputRef} onBlur={() => setIsRenaming(false)} onKeyDown={onKeyDown} value={take.name} onChange={(e) => onChangeName(e.target.value)} />
  );

  const menu = (
    <PiPopoverMenu>
      <PiMenuButton text={t("rename")} onClick={startRenaming} />
      <PiMenuButton text={t("delete")} onClick={() => setIsDeleteDialogOpen(true)} />
    </PiPopoverMenu>
  );

  return (
    <div className={rootclass} onClick={onClickHeader} {...dragHandleProps} draggable>
      <div className={"takecard360-arrow " + animArrowClass}>
        <IconButton className="takecard360-icon" onClick={onToggleExpand}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      <div className="takecard360-thumbnail">
        {isLoading && (
          <div className="takecard360-spinner">
            <CircularProgress />
          </div>
        )}
        <img src={getAssetPreview()} className={isLoading ? "takecard360-loading" : ""} alt="" onLoad={() => setIsLoading(false)} />
      </div>
      {!isRenaming && name}
      {isRenaming && isSelected && inputField}
      {menu}
      <PiConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={DeleteBubble}
        text={t("delete_bubble.delete_description")}
        title={t("delete_bubble.delete_title")}
        confirmText={t("delete_bubble.confirm_delete")}
        cancelText={t("delete_bubble.cancel_delete")}
        height="215px"
        confirmButtonVariant="piRed"
      />
    </div>
  );
}
