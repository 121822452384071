import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import { Actor } from "../Actor";
import ClickIndicatorPreview from "../../features/previews/click_indicator/ClickIndicatorPreview";

export const clickIndicatorStyles: string[] = [
  "Object",
  "Door",
  "Person",
  "Event",
  "Evac",
  "Finger",
  "Feet",
];

export interface IClickIndicator extends Interaction {
  propGuid: string;
  tag: string;
  style: string;
}

export const clickIndicatorInteraction = (): InteractionFactoryEntity => {
  const view = <ClickIndicatorPreview />;

  const create = (): IClickIndicator => {
    return {
      id: GetNewGuid(),
      name: "Click Indicator",
      type: InteractionType.ClickIndicator,
      propGuid: "",
      tag: "",
      style: clickIndicatorStyles[0],
      properties: [],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IClickIndicator => {
    return interaction as IClickIndicator;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void
  ): IClickIndicator => {
    return create();
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IClickIndicator;
    const warnings: string[] = [];

    if (!converted.propGuid) {
      warnings.push("No prop selected.");
    }

    if (!converted.tag) {
      warnings.push("No tag selected.");
    }

    return warnings;
  };

  const filter = (interaction: Interaction): string[] => {
    const result: string[] = [];
    const indicator = interaction as IClickIndicator;

    result.push(indicator.name);
    result.push(indicator.tag);
    result.push(indicator.style);

    return result;
  };

  return {
    View: view,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
