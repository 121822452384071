import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import ScrabbleQuizBody from "./ScrabbleQuizBody";
export default function ScrabbleQuizPreview() {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;

  const { getValue, changeValue } = useTrainingLocalization();
  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Scrabble Quiz Title" showStarIcons />;

  const body = <ScrabbleQuizBody />;
  return <PiPopupWindowPreview title={title} body={body} />;
}
