import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { Button, Skeleton } from "@mui/material";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import { selectFolders, selectHasUnsubittedFolder, selectRenamingFolder, selectSelectedFolder } from "../../app/slices/AssetsSlice";
import "./FileExplorerDialog.css";
import FolderButton from "./FolderButton";

import { AutoSizer } from "react-virtualized";
import { useAddFolder } from "../../hooks/AddFolderHook";
import "./FolderView.css";
import useFolderViewPageLoader from "./FolderViewPageLoaderHooks";

export default function FolderView() {
  const folders = useSelector(selectFolders);
  const selectedFolderId = useSelector(selectSelectedFolder);
  const selectCurrentRenamingFolder = useSelector(selectRenamingFolder);
  const [addFolder, addTempFolder] = useAddFolder();
  const [onItemsRendered] = useFolderViewPageLoader();
  const hasUnssubmittedFolder = useSelector(selectHasUnsubittedFolder);

  const dispatch = useDispatch();
  const listRef = useRef<List>(null);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  function renderRow({ index, style }: ListChildComponentProps) {
    return folders[index].id !== "loading" ? (
      <FolderButton isSelected={folders[index].id === selectedFolderId?.id} isRenamingFolder={folders[index] === selectCurrentRenamingFolder} key={index} style={style} folder={folders[index]} />
    ) : (
      <div style={style}>
        <Skeleton variant="rounded" style={{ margin: "8px" }} />
      </div>
    );
  }

  async function onAddFolderClicked() {
    //dispatch(addNewFolder());
    //await delay(100);
    //listRef.current?.scrollToItem(folders.length);
    //await addFolder();
    addTempFolder();
  }

  return (
    <div className="pi-folderview-content-folders">
      <div className="pi-folderview-content-folders-full-height">
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <List className="pi-folderview-content-folders-list" ref={listRef} height={height} itemCount={folders.length} itemSize={64} width={width} onItemsRendered={onItemsRendered}>
              {renderRow}
            </List>
          )}
        </AutoSizer>
      </div>
      <div className="pi-folderview-content-folders-add">
        <Button variant="piBlue" onClick={onAddFolderClicked} disabled={hasUnssubmittedFolder}>
          <div className="pi-folderview-content-folders-add-button">
            <LibraryAddOutlinedIcon />
            <div>Add Folder</div>
          </div>
        </Button>
      </div>
    </div>
  );
}
