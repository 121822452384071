import axios from "axios";

const URL_AUTH: string =
  process.env.REACT_APP_PLAYIT_MAINAPI! + "/v2/Authenticate";
const URL_AUTH_REFRESH: string =
  process.env.REACT_APP_PLAYIT_MAINAPI! + "/v2/Authenticate/RefreshToken";
const ROLE: string = "10";

interface Jwt {
  token?: string;
  refreshToken?: string;
}
export interface AuthenticationResponse {
  jwt: Jwt;
  organizationIDs: string[];
  role: number;
  userID: string;
}

export class AuthenticationService {
  static async Authenticate(
    email: string,
    password: string
  ): Promise<AuthenticationResponse | null> {
    try {
      var { data, status } = await axios.post<AuthenticationResponse>(
        URL_AUTH,
        { email, password, requestedRole: ROLE }
      );
      if (status === 200) return data;
    } catch (error: any) {
      console.log(error.message);
    }
    return null;
  }

  static async AuthenticateWithRefreshToken(): Promise<AuthenticationResponse | null> {
    var email = localStorage.getItem("email");
    var userID = localStorage.getItem("userID");
    var refreshToken = localStorage.getItem("refreshToken");

    let data: AuthenticationResponse | null = null;

    if (email && userID && refreshToken) {
      var url =
        URL_AUTH_REFRESH +
        "?userID=" +
        userID +
        "&RefreshToken=" +
        refreshToken +
        "&RequestedRole=" +
        ROLE;

      await axios
        .get<AuthenticationResponse>(url)
        .then((response) => {
          data = response.data;
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
    return data;
  }
}
