import { useRef, useState } from "react";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiEditMenu from "../../../components/PiEditMenu";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import classNames from "classnames";
import PiBasicQuizAnswerTextfieldV2 from "../components/v2/PiBasicQuizAnswerTextfieldV2";

export default function StatementQuizPreviewAnswer(props: {
  answer: string;
  answerIndex: number;
  isCorrect: boolean;
  onToggleCorrect: (answerIndex: number) => void;
  onDelete: (answerIndex: number) => void;
}) {
  const { answerIndex, answer, isCorrect, onToggleCorrect, onDelete } = {
    ...props,
  };
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const canDelete = true;
  const parentRef = useRef<HTMLDivElement>(null);

  const onFocus = () => {
    if (canDelete) {
      setIsFocused(true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const focusedElement = event.relatedTarget || document.activeElement;

    if (!parentRef?.current?.contains(focusedElement)) {
      setIsFocused(false);
    }
  };

  const onToggleAnswer = () => {
    onToggleCorrect(answerIndex);
  };

  const editMenu = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <CheckIcon color="inherit" fontSize="inherit" />,
          onClick: onToggleAnswer,
          highlight: isCorrect,
        },
        {
          icon: (
            <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" />
          ),
          onClick: () => {
            setIsFocused(false);
            onDelete(answerIndex);
          },
          highlight: false,
        },
      ]}
    />
  );

  return (
    <div
      ref={parentRef}
      tabIndex={-1}
      className={classNames({
        "relative w-[20%] h-[64px] rounded-[5px] px-[8px]": true,
        "bg-interaction-primary": !isCorrect,
        "bg-interaction-correct": isCorrect,
      })}
      onFocus={onFocus}
      onBlur={handleBlur}
    >
      <PiBasicQuizAnswerTextfieldV2 color="white" localizationKey={answer} />
      {isFocused && (
        <div className="absolute top-[-48px] left-1/2 -translate-x-1/2">
          {editMenu}
        </div>
      )}
    </div>
  );
}
