import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import FileExplorerDialog from "../file_explorer/FileExplorerDialog";
import PiMenu from "../menu/PiMenu";
import PiMenuButton from "../menu/PiMenuButton";

export default function DashboardMenu() {
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const location = useLocation();

  function onCloseFileExplorer() {
    setIsFileExplorerOpen(false);
  }

  return (
    <div id={"dashboard-menu"}>
      <PiMenu>
        <PiMenuButton
          text={t("dashboard")}
          id="dashboard"
          icon={<DashboardOutlinedIcon fontSize="inherit" />}
          link="/"
          doHighlight={true}
        />
        <PiMenuButton
          text={t("newTraining")}
          id="newTraining"
          icon={<DashboardCustomizeOutlinedIcon fontSize="inherit" />}
          link="/newTraining"
        />
        <PiMenuButton
          text={t("fileExplorer")}
          id="fileExplorer"
          icon={<FolderOutlinedIcon fontSize="inherit" />}
          onClick={() => setIsFileExplorerOpen(true)}
        />
      </PiMenu>

      <FileExplorerDialog
        open={isFileExplorerOpen}
        onClose={onCloseFileExplorer}
      />
    </div>
  );
}
