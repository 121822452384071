import React from "react";
import "./WordQuizSentenceSegment.css";

interface WordQuizSentenceSegmentProps {
  id: string;
  text: string;
  highlight?: boolean;
  onClick?: () => void;
  isCorrect?: boolean;
}

export default function WordQuizSentenceSegment(
  props: WordQuizSentenceSegmentProps
) {
  const { id, text, highlight, onClick, isCorrect } = { ...props };

  const getClassnames = () => {
    var classname = "wordquizsentencesegment-root";

    if (highlight) {
      classname += " wordquizsentencesegment-highlight";
    }
    if (isCorrect) {
      classname += " wordquizsentencesegment-correct";
    }
    return classname;
  };

  const onClickWrapper = () => {
    if (isCorrect) return;
    onClick?.();
  };

  const createMarkup = (text: string) => {
    // Replace &amp; with &
    return { __html: text.replace("{", "").replace("}", "") };
  };

  return (
    <>
      <span
        id={id}
        className={getClassnames()}
        onClick={onClickWrapper}
        dangerouslySetInnerHTML={createMarkup(text)}
      ></span>
      <span> </span>
    </>
  );
}
