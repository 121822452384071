import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";

export default function ImageButtonQuizAddAnswerButton(props: {
  onClick: () => void;
}) {
  const { onClick } = { ...props };

  return (
    <div
      className="relative text-[48px] w-[128px] h-[200px] cursor-pointer rounded-[5px] bg-interaction-primary-washed hover:bg-interaction-text-hoverBackground flex drop-shadow-lg"
      onClick={onClick}
    >
      <div className="flex flex-col m-auto gap-y-[16px]">
        <div className="flex m-auto">
          <PostAddOutlinedIcon
            className="m-auto text-interaction-primary"
            fontSize="inherit"
          />
        </div>
        <div className="m-auto text-[14px] font-medium text-center text-interaction-primary">
          Add Answer
        </div>
      </div>
    </div>
  );
}
