import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton, Popover } from "@mui/material";
import React, { useState } from "react";

export enum PiPopoverMenuStyle {
  Default = "default",
  Light = "light"
}

interface PiPopoverMenuProps {
  children?: JSX.Element[];
  style?: PiPopoverMenuStyle;
}

export default function PiPopoverMenu({ children, style = PiPopoverMenuStyle.Default }: PiPopoverMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  }

  return (
    <div>
      <IconButton aria-label="pipopovermenu-icon-button" className="w-[30px] h-[30px]" onClick={(event) => handleClick(event)}>
        <MoreHorizIcon
          className={style === PiPopoverMenuStyle.Default ?
            "" : "text-interaction-primary-faded"}
          fontSize={style === PiPopoverMenuStyle.Default ?
            "small" : "medium"} />
      </IconButton>

      <Popover
        open={openPopover}
        onClose={() => setOpenPopover(false)}
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: { width: "auto" },
        }}
      >
        <div className="w-[200px] mt-2 mb-2 mr-4 ml-4" onClick={() => setOpenPopover(false)}>
          {children}
        </div>
      </Popover>
    </div>
  );
}
