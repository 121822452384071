import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IAnswer, IFormQuiz } from "../../../models/Quiz";
import ButtonsQuizPreviewAnswer from "./ButtonsQuizPreviewAnswer";
import PiBasicQuizAddAnswerButtonV2 from "../components/v2/PiBasicQuizAddAnswerButtonV2";
import { useDispatch } from "react-redux";
import { GetNewGuid } from "../../../helpers/GuidHelper";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";

export default function ButtonsQuizPreviewBody() {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getNewKey } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onAnswerChange = (answer: IAnswer) => {
    const myIndex = quiz.answers.findIndex((a) => a.id === answer.id);
    const cpy = [...quiz.answers];
    cpy[myIndex] = answer;
    dispatch(updateSelectedInteraction({ ...quiz, answers: cpy } as IFormQuiz));
  };

  const onDeleteAnswer = (answer: IAnswer) => {
    const rmIndex = quiz.answers.findIndex((a) => a.id === answer.id);
    const updatedAnswers = [...quiz.answers].filter(
      (_, index) => rmIndex !== index
    );

    dispatch(
      updateSelectedInteraction({
        ...quiz,
        answers: updatedAnswers,
      } as IFormQuiz)
    );
  };

  const answersMapped = quiz.answers.map((answer) => {
    return (
      <ButtonsQuizPreviewAnswer
        key={answer.id}
        answer={answer}
        onChange={onAnswerChange}
        onDelete={onDeleteAnswer}
      />
    );
  });

  const onAddNewAnswer = () => {
    dispatch(
      updateSelectedInteraction({
        ...quiz,
        answers: [
          ...quiz.answers,
          { id: GetNewGuid(), correct: false, text: getNewKey() },
        ],
      } as IFormQuiz)
    );
  };

  const answerBody = (
    <div className="flex w-full h-full bg-interaction-primary-washed rounded-[5px]">
      <div className="flex w-full h-auto m-auto gap-[12px] justify-center">
        {answersMapped}
      </div>
    </div>
  );

  const addAnswerButton = (
    <div className="">
      <PiBasicQuizAddAnswerButtonV2
        disabled={quiz.answers.length >= 5}
        onClick={onAddNewAnswer}
      />
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full gap-[12px]">
      {answerBody}
      {addAnswerButton}
    </div>
  );
}
