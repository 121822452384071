import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectIsDirty } from "../../app/slices/TrainingSlice";
import PiConfirmationDialog from "../../components/PiConfirmationDialog";
import { useSaveTraining } from "../../hooks/SaveTrainingHook";
import FileExplorerDialog from "../file_explorer/FileExplorerDialog";
import PiMenu from "../menu/PiMenu";
import PiMenuButton from "../menu/PiMenuButton";
import { OnboardingContext } from "../onboarding/OnboardingContext";
import PublishDialog from "../publish_course/PublishDialog";
import useOnExitEditor from "./../../hooks/OnExitEditorHook";
export default function TrainingEditorMenu() {
  const isDirty = useSelector(selectIsDirty);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { saveTraining } = useSaveTraining();
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const { state, setState } = useContext(OnboardingContext);
  const { onExitEditorCleanup } = useOnExitEditor();

  function onExitEditor() {
    onExitEditorCleanup();
    navigate("/");
  }
  function onCloseFileExplorer() {
    setIsFileExplorerOpen(false);
  }

  const onSaveProject = async () => {
    await saveTraining();
    setIsSnackbarOpen(true);
  };

  const onExitEditorClicked = () => {
    if (isDirty) {
      setConfirmLeaveDialogOpen(true);
    } else {
      onExitEditor();
    }
  };

  const onSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  // publish window vars
  const [publishWindowOpen, setPublishWindowOpen] = useState<boolean>(false);
  const [confirmLeaveDialogOpen, setConfirmLeaveDialogOpen] = useState<boolean>(false);

  const onClosePublishWindow = () => setPublishWindowOpen(false);

  const onClickHelp = () => {
    setState((prevState) => ({
      ...prevState,
      run: true,
      onboardingActive: true,
      context: "trainingEditor",
    }));
  };

  return (
    <>
      <PiMenu>
        <PiMenuButton text={t("training_editor")} icon={<AccountTreeOutlinedIcon />} doHighlight={true} id="trainingeditor-menu-button" />
        <PiMenuButton text={t("save")} icon={<SaveOutlinedIcon />} onClick={onSaveProject} disabled={!isDirty} id="trainingeditor-save-button" />
        <PiMenuButton text={t("publish")} icon={<SendOutlinedIcon />} onClick={() => setPublishWindowOpen(true)} id="trainingeditor-publish-button" />
        <PiMenuButton text={t("fileExplorer")} icon={<FolderOutlinedIcon />} onClick={() => setIsFileExplorerOpen(true)} id="trainingeditor-file-explorer-button" />
        <PiMenuButton text={t("help")} icon={<QuestionMarkIcon />} onClick={onClickHelp} />
        <PiMenuButton text={t("exitEditor")} icon={<LogoutOutlinedIcon />} onClick={onExitEditorClicked} id="trainingeditor-exit-button" />
      </PiMenu>
      <FileExplorerDialog open={isFileExplorerOpen} onClose={onCloseFileExplorer} />
      <PiConfirmationDialog
        open={confirmLeaveDialogOpen}
        onClose={() => setConfirmLeaveDialogOpen(false)}
        title={t("exit_editor.title")}
        text={t("exit_editor.description")}
        onConfirm={onExitEditor}
        confirmText={t("exit_editor.confirm")}
        cancelText={t("exit_editor.cancel")}
        confirmButtonVariant="piBlue"
      />
      <PublishDialog open={publishWindowOpen} onClose={onClosePublishWindow} />
      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={onSnackbarClose} anchorOrigin={{ horizontal: "right", vertical: "top" }}>
        <Alert onClose={onSnackbarClose} severity="info" sx={{ width: "100%" }}>
          {t("project_saved")}
        </Alert>
      </Snackbar>
    </>
  );
}
