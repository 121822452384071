import React from "react";
import "./PiEditMenu.css";

interface IEditButtonSettings {
  icon: JSX.Element;
  onClick: () => void;
  highlight?: boolean;
}

interface PiEditMenuProps {
  buttonSettings: IEditButtonSettings[];
}

export default function PiEditMenu(props: PiEditMenuProps) {
  const { buttonSettings } = { ...props };
  const buttons = buttonSettings.map((setting, index) => {
    return (
      <div className={"pieditmenu-button " + (setting.highlight ? "pieditmenu-button-highlighted" : "")} key={index} onClick={setting.onClick}>
        {setting.icon}
      </div>
    );
  });

  return <div className="pieditmenu-root">{buttons}</div>;
}
