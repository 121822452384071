import { Button, LinearProgress } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEditingAsset, selectEditingVariantId } from "../../app/slices/AssetsSlice";
import { LanguageData, selectAvailableLanguages } from "../../app/slices/TrainingLocalizationSlice";
import PiDropdown from "../../components/PiDropdown";
import { PiPopperMenuProps } from "../../components/PiPopper";
import { useSaveVariant } from "../../hooks/SaveVariantHook";
import "./VariantDetails.css";
import VariantDetailsEdit from "./VariantDetailsEdit";

export default function VariantDetails() {
  const { t } = useTranslation();
  const [saveVariant] = useSaveVariant();

  // TODO: filter on variants not used
  const availableLanguages = useSelector(selectAvailableLanguages);
  const [unusedLanguages, setUnusedLanguages] = useState<LanguageData[]>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<LanguageData | undefined>(undefined);
  const [isVariantDirty, setIsVariantDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);

  const variantId = useSelector(selectEditingVariantId);
  const asset = useSelector(selectEditingAsset);
  const variant = asset?.variants.find((v) => v.id === variantId);

  useEffect(() => {
    if (variant) {
      // select variant language
      var lang = availableLanguages.find((lang) => lang.shortcode === variant.variant);
      if (lang) {
        setSelectedLanguage(lang);
      } else {
        setSelectedLanguage(undefined);
      }

      // updated unused langs
      var langsToIgnore = asset!.variants.map((x) => availableLanguages.find((y) => y.shortcode === x.variant));
      if (langsToIgnore) {
        var temp = availableLanguages.filter((x) => langsToIgnore.indexOf(x) === -1);

        // add current variant lang as one of the options
        var currLang = availableLanguages.find((lang) => lang.shortcode === variant.variant);
        if (currLang) temp = [currLang, ...temp];

        setUnusedLanguages(temp);
      }
    }
    setIsVariantDirty(false);
  }, [variantId]);

  const options = unusedLanguages.map((lang) => {
    var option: PiPopperMenuProps = {
      text: lang.full,
      onClick: () => {
        setIsVariantDirty(true);
        setSelectedLanguage(lang);
      },
    };
    return option;
  });

  const onFileSelected = (file: File) => {
    setIsVariantDirty(true);
    setSelectedFile(file);
  };

  const onSaveVariant = async () => {
    setIsLoading(true);
    await saveVariant(selectedLanguage?.shortcode ?? undefined, selectedFile);

    setIsVariantDirty(false);
    setIsLoading(false);
  };

  return (
    <div className="variantdetails-root">
      <div className="variantdetails-title">{t("edit_localization")}</div>
      {isLoading && (
        <div className="variantdetails-loading">
          <div className="variantdetails-loading-content">
            <LinearProgress />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div>
            {variant?.variant !== "default" && <PiDropdown width="95%" options={options} selectedText={selectedLanguage?.full ?? t("no_language_set")} />}
            {variant?.variant === "default" && <div className="variantdetails-default-dropdown">{t("default")}</div>}
          </div>
          <div className="variantdetails-edit">
            <VariantDetailsEdit onFileSelected={onFileSelected} />
          </div>
          <div className="variantdetails-save-btn">
            <Button variant="piBlue" disabled={!isVariantDirty} onClick={onSaveVariant}>
              {t("save")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
