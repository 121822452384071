import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IConversation } from "../../../models/interactions/Conversation";
import ConversationDetailsView from "./ConversationDetailsView";
import ConversationPreviewInput from "./ConversationPreviewInput";
import ConversationPreviewMessageView from "./ConversationPreviewMessageView";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import { IEmotionBubble } from "../../../models/interactions/EmotionBubble";

export default function ConversationPreviewBody() {
  const conversation = useSelector(selectSelectedElement) as IConversation;

  const messageViewRef = useRef<HTMLDivElement>(null);
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const [detailedConversationMessage, setDetailedConversationMessage] = useState<(IMessageBubble | IEmotionBubble) | undefined>(undefined);

  useEffect(() => {
    setDetailedConversationMessage(undefined);
  }, [conversation.id]);

  const onSubmitClick = async () => {
    await delay(100);

    if (messageViewRef.current) {
      messageViewRef.current.scrollTop = messageViewRef.current.scrollHeight;
    }
  };

  const viewport = <ConversationPreviewMessageView ref={messageViewRef} onDetailsSelect={(msg) => setDetailedConversationMessage(msg)} />;

  const input = <ConversationPreviewInput onSubmitClick={onSubmitClick} />;

  //Use key property of IMessagebubble to verify type
  const detailsView = <ConversationDetailsView messageId={detailedConversationMessage?.id} onClose={() => setDetailedConversationMessage(undefined)} />;

  return (
    <div className="flex flex-col w-full h-full gap-[20px]">
      {detailedConversationMessage && detailsView}
      {!detailedConversationMessage && (
        <>
          {viewport}
          {input}
        </>
      )}
    </div>
  );
}
