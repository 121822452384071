import React from "react";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import "./MessageBubblePreview.css";
import MessageBubblePreviewBody from "./MessageBubblePreviewBody";
import { useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import { useDispatch } from "react-redux";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";

export default function MessageBubblePreview() {
  const title = <div className="messagebubblepreview-title">Message Bubble</div>;

  const dispatch = useDispatch();
  const messageBubble = useSelector(selectSelectedElement) as IMessageBubble;

  const updateMessageBubble = (field: string, value: any) => {
    dispatch(updateSelectedInteraction({ ...messageBubble, [field]: value } as IMessageBubble));
  };

  const body = <MessageBubblePreviewBody messageBubble={messageBubble} updateInteraction={updateMessageBubble} />;

  return <PiPopupWindowPreview title={<PiPopupWindowStaticTitle title={"Message Bubble"}/> } body={body} />;
}
