import { v4 as uuidv4 } from "uuid";
import Take from "./Take";
import { Marker } from "./interactions/Interaction";

export default interface Take360 extends Take {
  assetId: string;
  rotation: number;
  originalRotation: Marker;
  teleportMarker: Marker;
  automaticTransition: boolean;
  is360Image: boolean;

  // these are specifically for the view of the editor
  display360: boolean;
}

export function CreateTake360Copy(
  name: string,
  assetId: string,
  rotation: number,
  originalRotation: Marker,
  teleportMarker: Marker,
  automaticTransition: boolean,
  is360Image: boolean,
  display360: boolean
): Take360 {
  return {
    id: uuidv4(),
    name: name,
    interactions: [],
    isExpanded: false,
    assetId: assetId,
    rotation: rotation,
    originalRotation: { ...originalRotation, id: uuidv4() },
    teleportMarker: { ...teleportMarker, id: uuidv4() },
    automaticTransition: automaticTransition,
    is360Image: is360Image,
    display360: display360,
  };
}
