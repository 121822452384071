import {useSelector} from "react-redux";
import {selectTrainingLocalizationState} from "../app/slices/TrainingLocalizationSlice";
import LocalizedLanguageDTO, {LocalizedLanguageEntryDTO,} from "../dto/LocalizedLanguageDTO";

export const UseSerializeLocalization = () => {
    const locState = useSelector(selectTrainingLocalizationState);
    const serializeLanguage = (shortcode: string) => {
        var keys = Object.keys(locState.localizedEntries);
        var entries: LocalizedLanguageEntryDTO[] = [];

        //value: locState.localizedEntries[keys[k]][shortcode].value !== "" ? locState.localizedEntries[keys[k]][shortcode].value : " "

        for (var k = 0; k < keys.length; k++) {
            entries.push({
                key: keys[k],
                value: locState.localizedEntries[keys[k]][shortcode].value,
                isAutoTranslated:
                locState.localizedEntries[keys[k]][shortcode].isAutoTranslated,
            });
        }

        var dto: LocalizedLanguageDTO = {
            lang: shortcode,
            entries: entries,
        };

        return dto;
    };


    const serializeLanguageForTesting = (shortcode: string) => {
        var keys = Object.keys(locState.localizedEntries);
        var entries: LocalizedLanguageEntryDTO[] = [];

        for (var k = 0; k < keys.length; k++) {
            entries.push({
                key: keys[k],
                value: locState.localizedEntries[keys[k]][shortcode].value !== "" ? locState.localizedEntries[keys[k]][shortcode].value : " ",
                isAutoTranslated:
                locState.localizedEntries[keys[k]][shortcode].isAutoTranslated,
            });
        }

        var dto: LocalizedLanguageDTO = {
            lang: shortcode,
            entries: entries,
        };

        return dto;
    }


    return {serializeLanguage, serializeLanguageForTesting} as const;
};
