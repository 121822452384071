import { useDispatch, useSelector } from "react-redux";
import { addCachedAsset, selectCachedAsset, selectCachedAssets } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const useAssetPreview = (assetId: string | undefined) => {
  const cachedAsset = selectCachedAsset(useSelector(selectCachedAssets), assetId);
  const previewUrl = cachedAsset?.variants[0]?.downloadUri;
  const assetName = cachedAsset?.name;

  var orgId = useSelector(selectCurrentOrganization)?.id;
  var token = useSelector(selectToken);
  const dispatch = useDispatch();

  const fetchAsset = async (content: boolean) => {
    if (!orgId || !assetId) return;

    var asset = await AssetService.GetAssetByGuid(orgId, token, assetId, content, "large");

    if (asset) {
      dispatch(addCachedAsset(asset));
    }
  };

  const getAssetPreview = () => {
    if (!previewUrl) {
      fetchAsset(false);
    }

    return previewUrl;
  };

  const getAssetPreviewRaw = () => {
    if (!previewUrl) {
      fetchAsset(true);
    }

    return previewUrl;
  };

  const getAssetName = () => {
    if (!assetName) {
      fetchAsset(false);
    }
    return assetName;
  };

  return { getAssetPreview, getAssetPreviewRaw, getAssetName } as const;
};
