import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetKey } from "../dto/AssetDTO";
import { AssetService } from "../services/AssetService";
export const useAssetService = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const selectedFolder = useSelector(selectSelectedFolder);
  const [previousOrgId, setPreviousOrgId] = useState<string>("not-set"); // TODO: add this again

  const pageSize = 10;

  const fetchAssetsFromSelectedFolder = async (pageNumber: number, assetKey?: AssetKey) => {
    if (!selectedFolder) return undefined;
    if (!currentOrganization) return undefined;

    var assets = await AssetService.GetAssetsFromFolder(currentOrganization!.id, token, selectedFolder!.id, pageSize, pageNumber, assetKey);

    return assets;
  };

  const fetchFilteredAssets = async (pageNumber: number, filter: string, assetKey?: AssetKey) => {
    return await AssetService.GetFilteredAssets(currentOrganization!.id, token, pageSize, pageNumber, filter, assetKey);
  };

  const fetchFoldersFromPage = async (pageNumber: number) => {
    return await AssetService.GetFolders(currentOrganization!.id, token, pageSize, pageNumber);
  };

  return { fetchAssetsFromSelectedFolder, fetchFilteredAssets, fetchFoldersFromPage } as const;
};
