import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import "./PiDialog.css";

export interface DialogProps {
  open: boolean;
  onClose: () => void;
}

interface PiDialogProps {
  open: boolean;
  onClose: () => void;
  header: JSX.Element;
  body: JSX.Element;
  width: string;
  height: string;
  hideCloseButton?: boolean;
}

export default function PiDialog(props: PiDialogProps) {
  const closeButton = (
    <IconButton aria-label="PiDialog-Close-Button" className="pidialog-close-button" onClick={props.onClose}>
      <CloseIcon />
    </IconButton>
  );

  return (
    <Dialog
      open={props.open}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        style: {
          minHeight: props.height,
          maxHeight: props.height,
          minWidth: props.width,
          maxWidth: props.width,
        },
      }}
    >
      <DialogTitle>
        {props.header} {!props.hideCloseButton && closeButton}
      </DialogTitle>

      <div
        style={{
          height: props.height,
          overflowY: "hidden",
        }}
      >
        <div className="pidialog-body">{props.body}</div>
      </div>
    </Dialog>
  );
}
