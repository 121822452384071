import React from "react";
import { InteractionProperty } from "../../models/InteractionProperty";
import "./PiNumberProperty.css";

export default function PiNumberProperty(props: { interactionProperty: InteractionProperty; onChange: (interactionProperty: any) => void }) {
  const label = <div className="pinumberproperty-label">{props.interactionProperty.name}</div>;

  const onInputChange = (e: string) => {
    const number = Number(e);
    if (!isNaN(number)) {
      props.onChange({ ...props.interactionProperty, value: number });
    }
  };

  const input = (
    <div className="pinumberproperty-input">
      <input type="number" value={props.interactionProperty.value as number} onChange={(e) => onInputChange(e.currentTarget.value)} />
    </div>
  );

  return (
    <div className="pinumberproperty-root">
      {label} {input}
    </div>
  );
}
