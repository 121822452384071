import * as ScrollArea from '@radix-ui/react-scroll-area';
import classNames from "classnames";

export default function PiScrollArea(props: { children: React.ReactNode, variant: "piCreatorBlue" | "piInteractionBlue", maxHeight?: number }) {

    const {variant, maxHeight} = {...props};

    const maxHeightStyle = maxHeight ? maxHeight + "px" :  "100%";
    
    return (
        <ScrollArea.Root className={"h-full w-full overflow-y-auto"} type={"auto"}>
            <ScrollArea.Viewport className={"w-full h-full"} style={{maxHeight: maxHeightStyle}}>
                {props.children}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar className={"w-[12px] rounded-full  bg-interaction-softBackground h-full flex select-none p-[2px]"} orientation="vertical">
                <ScrollArea.Thumb
                    className={classNames({"rounded-full flex-1  relative": true, "bg-creator-primary": variant === "piCreatorBlue", "bg-interaction-primary": variant === "piInteractionBlue"})}/>
            </ScrollArea.Scrollbar>
        </ScrollArea.Root>
    );
} 