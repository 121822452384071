import AdsClickIcon from "@mui/icons-material/AdsClick";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import CableIcon from "@mui/icons-material/Cable";
import CachedIcon from "@mui/icons-material/Cached";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import GraphicEqOutlinedIcon from "@mui/icons-material/GraphicEqOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import React from "react";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import {
  InteractionDefintion,
  InteractionIcon,
} from "./InteractionDefinitions";
export const useInteractionPickerIcons = () => {
  const getIcon = (interactionDef: InteractionDefintion) => {
    switch (interactionDef.icon) {
      case InteractionIcon.Message:
        return <MessageOutlinedIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.Image:
        return (
          <BrokenImageOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.Video:
        return (
          <VideoLibraryOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.Pdf:
        return (
          <PictureAsPdfOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.MultipleChoiceQuiz:
        return <QuizOutlinedIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.MultiselectQuiz:
        return <DoneAllOutlinedIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.OrderQuiz:
        return (
          <FormatListNumberedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.ConnectQuiz:
        return <CableIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.Marker:
        return <PushPinOutlinedIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.WordSpinnerQuiz:
        return <CachedIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.ClickRegion:
        return <AdsClickIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.PhotoRangeQuiz:
        return (
          <PhotoCameraOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.AmbientAudio:
        return (
          <GraphicEqOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.AudioPopup:
        return <MusicNoteIcon className={"my-auto"} fontSize="inherit" />;
      case InteractionIcon.Camera:
        return (
          <CameraAltOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
      case InteractionIcon.Prop:
        return (
          <ViewInArOutlinedIcon className={"my-auto"} fontSize="inherit" />
        );
    }
  };

  return [getIcon] as const;
};
