import {Prop} from "../../models/Prop";
import usePropHelper from "../../hooks/PropHelper";
import {useMemo} from "react";

export default function PropPresetPopoverCard(props: { prop: Prop, onClick: () => void }) {
    const {prop, onClick} = {...props}
    const {getPropDTO} = usePropHelper();
    const dto = useMemo(() => getPropDTO(prop.propId), [prop.propId]);

    return <div className={"flex gap-[10px] py-[8px] px-[10px] cursor-pointer rounded-[5px] hover:bg-interaction-text-hoverBackground"} onClick={onClick}>
        <div className={"my-auto w-[36px] h-[36px] p-[4px] rounded-full border-solid border-[2px] border-interaction-primary"}>
            <img className={"w-full h-full object-contain"} src={dto.propContent.downloadUri} alt={prop.id}/>
        </div>
        <div className={"my-auto text-[14px] text-interaction-primary truncate"}>
            {prop.name}
        </div>
    </div>
}