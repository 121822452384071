import L, {Coords} from 'leaflet';
import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../../app/slices/AuthenticationSlice";
import {selectCurrentOrganization} from "../../app/slices/UserSlice";
import {clearCachedLeafletData, registerLeafletData, selectCachedLeafletData, selectCurrentEnvironmentId} from "../../app/slices/EnvironmentSlice";
import {EnvironmentService} from "../../services/EnvironmentService";
import {useCallback, useEffect, useState} from "react";

export default function UsePiTileLayer() {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const currentOrganization = useSelector(selectCurrentOrganization);
    const environmentId = useSelector(selectCurrentEnvironmentId);

    const cachedLeafletData = useSelector(selectCachedLeafletData);

    const fetchTileUrl = useCallback(async (coords: L.Coords, cache: Record<string, LeafletData>) => {
        if (environmentId === "not-set") return "";
        if (!currentOrganization || !token || !environmentId) return "";

        // check if one of the coords is negative -> out of bounds! 
        if (coords.x < 0 || coords.y < 0 || coords.z < 0) return "";
        // check if one of the coords is larger than 2^z -> out of bounds!
        if (coords.x >= Math.pow(2, coords.z) || coords.y >= Math.pow(2, coords.z)) return "";

        // check if the tile is already cached
        const key = `${coords.x},${coords.y},${coords.z}`;
        if (key in cache) return cache[key].url;

        // if url is not cached, fetch it from the server
        const url = await EnvironmentService.GetFloorplanImage(currentOrganization.id, token, environmentId, coords.x, coords.y, coords.z)

        // add it to the cache
        dispatch(registerLeafletData({x: coords.x, y: coords.y, z: coords.z, url: url}));

        // return the url
        return url;

    }, [cachedLeafletData, environmentId, token, currentOrganization]);

    return {fetchTileUrl};
}