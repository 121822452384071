import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import { IAudioPopup } from "../../../models/interactions/AudioPopup";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { ReactComponent as FileIcon } from "../../file_explorer/assets/file.svg";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionAssetPreview from "../components/PiInteractionAssetPreview";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import "./AudioPopupPreview.css";

export default function AudioPopupPreview() {
  const popup = useSelector(selectSelectedElement) as IAudioPopup;
  const { getValue, changeValue } = useTrainingLocalization();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview, getAssetName } = useAssetPreview(popup.audioAssetId);
  const dispatch = useDispatch();

  const onAssetSelect = (asset: AssetDetailsDTO) => {
    const updatedImagePopup = { ...popup };
    updatedImagePopup.imageAssetId = asset.assetId;
    dispatch(updateSelectedInteraction(updatedImagePopup));
  };

  const onAudioSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    const copy: IAudioPopup = { ...popup, audioAssetId: asset.assetId };
    dispatch(updateSelectedInteraction(copy));
  };

  const audioPreview = <div>{popup.audioAssetId ? getAssetName() : "No audio selected"}</div>;

  const addAudioButton = (
    <div className="audiopopuppreview-add-button" onClick={() => setIsOpen(true)}>
      <GraphicEqIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const fileIcon = <FileIcon className="audiopopuppreview-file-icon" />;

  const title = <PiInteractionTitlePreview value={getValue(popup.title)} onChange={(e) => changeValue(popup.title, e)} placeholder="Image Popup" />;
  const body = (
    <div className="audiopopuppreview-body">
      <div className="audiopopuppreview-image-wrapper">
        <PiInteractionAssetPreview assetType={AssetKey.Image} assetId={popup.imageAssetId} onAssetSelect={onAssetSelect} />
      </div>
      <div className="audiopopuppreview-audio-wrapper">
        {fileIcon}
        {audioPreview}
        {addAudioButton}
        <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={AssetKey.Audio} onSelect={onAudioSelect} />
      </div>
    </div>
  );

  return (
    <div>
      <PiPopupWindowPreview title={title} body={body} />
    </div>
  );
}
