import { FaceRetouchingOffOutlined } from "@mui/icons-material";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCharacterPresets } from "../../app/slices/MetadataSlice";
import { selectSelectedActor, updateActor } from "../../app/slices/TrainingSlice";
import PiButtonSelector from "../../components/PiButtonSelector";

export default function ActorCharacterPresetSelector() {
  const dispatch = useDispatch();
  const selectedActor = useSelector(selectSelectedActor);
  const allPresets = useSelector(selectCharacterPresets);
  const selectedIndex = useMemo(() => {
    return allPresets.findIndex((preset) => preset.preset_name === selectedActor?.preset);
  }, [selectedActor, allPresets]);

  const presetImages = allPresets.map((preset) => "/presets/" + preset.preset_preview);

  const onPresetButtonClicked = (index: number) => {
    if (!selectedActor) return;
    const newPreset = index > -1 ? allPresets[index].preset_name : "";
    const actorCopy = { ...selectedActor, preset: newPreset };
    dispatch(updateActor(actorCopy));
  };

  return <PiButtonSelector imagePaths={presetImages} selectedIndex={selectedIndex} onButtonClicked={onPresetButtonClicked} square={true} customIcon={FaceRetouchingOffOutlined} />;
}
