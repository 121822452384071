import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Grid, IconButton, LinearProgress } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { CSSProperties, useState } from "react";
import { useDispatch } from "react-redux";
import { removeUnsupportedFile, setEditingAsset } from "../../app/slices/AssetsSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import useDeleteAsset from "../../hooks/DeleteAssetHook";
import { useDialogToggle } from "../../hooks/PiDialogHook";
import PiMenuButton from "../menu/PiMenuButton";
import "./AssetCard.css";
import EditAssetDialog from "./EditAssetDialog";
import { ReactComponent as FileIcon } from "./assets/file.svg";
interface AssetCardProps {
  asset: AssetDetailsDTO;
  onSelect?: (asset: AssetDetailsDTO) => void;
  style?: CSSProperties;
  type?: AssetKey;
}

export default function AssetCard(props: AssetCardProps) {
  const { asset, type } = { ...props };
  const dispatch = useDispatch();
  const [isOpen, onClose, onOpen] = useDialogToggle();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleteAsset] = useDeleteAsset();

  const onEditOpen = () => {
    dispatch(setEditingAsset(props.asset.id));
    onOpen();
  };

  const onEditClose = () => {
    dispatch(setEditingAsset(undefined));
    onClose();
  };

  const onClick = () => {
    if (type && asset.key !== type) return;
    if (asset.key === AssetKey.Loading) return;
    if (asset.key === AssetKey.Unsupported) return;

    props.onSelect?.(asset);
  };

  const preview = () => {
    if (props.asset.key === AssetKey.Unsupported) {
      return (
        <div>
          <FileIcon className="assetcard-unsupported-icon" />
        </div>
      );
    } else if (props.asset.key === AssetKey.Loading) {
      return (
        <div>
          <FileIcon className="assetcard-loading-icon" />
        </div>
      );
    } else {
      return (
        <>
          {isLoading && <CircularProgress />}
          <img
            className={isLoading ? "assetcard-hide-image" : ""}
            alt="Thumbnail"
            src={props.asset.variants[0]?.downloadUri}
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        </>
      );
    }
  };

  const title = props.asset.name;
  const subtitle = props.asset.key !== AssetKey.Unsupported ? props.asset.updatedBy : "unsupported";

  const getSubtitle = () => {
    if (asset.key === AssetKey.Unsupported) {
      return "unsupported";
    } else if (asset.key === AssetKey.Loading) {
      return (
        <div className="assetcard-progress">
          <LinearProgress />
        </div>
      );
    } else {
      return asset.updatedBy;
    }
  };

  const getRootClassnames = () => {
    if (props.asset.key !== AssetKey.Unsupported) {
      return "assetcard-root assetcard-normal-variant";
    } else {
      return "assetcard-root assetcard-error-variant";
    }
  };

  const labelContent = props.asset.key !== AssetKey.Unsupported ? "v" + props.asset.version.major + "." + props.asset.version.minor : "failed";

  const onSelectClass = () => {
    if (props.onSelect) {
      if (asset.key === AssetKey.Loading) {
        return " assetcard-onselect-wait";
      } else if (asset.key === AssetKey.Unsupported || (type && asset.key !== type)) {
        return " assetcard-onselect-blocked";
      } else {
        return " assetcard-onselect";
      }
    }
    return " ";
  };

  const getButton = () => {
    if (asset.key === AssetKey.Unsupported) {
      return (
        <IconButton
          color="error"
          onClick={() => {
            //dispatch(removeLegacyUnsupportedFile(props.asset)
            dispatch(removeUnsupportedFile(asset.id));
          }}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      );
    } else if (asset.key === AssetKey.Loading) {
    } else {
      return (
        <PiPopoverMenu>
          <PiMenuButton text="Preview" disabled />
          <PiMenuButton text="Edit" onClick={onEditOpen} />
          <PiMenuButton text="Duplicate" disabled />
          <PiMenuButton text="Delete" onClick={() => deleteAsset(asset.id)} />
        </PiPopoverMenu>
      );
    }
  };

  return (
    <div className={getRootClassnames() + onSelectClass()} style={props.style}>
      <div className="assetcard-preview assetcard-image" onClick={onClick}>
        {preview()}
      </div>
      <div className="assetcard-info">
        <Grid container alignItems="center" className="assetcard-info-grid">
          <Grid item xs={11}>
            <div onClick={onClick}>
              <div className="assetcard-assetname">{title}</div>
              <div className="assetcard-uploadedby">{getSubtitle()}</div>
            </div>
          </Grid>
          <Grid item xs={1}>
            {getButton()}
          </Grid>
        </Grid>
        <div className="assetcard-version">
          <span>{labelContent}</span>
        </div>
      </div>

      <EditAssetDialog open={isOpen} onClose={onEditClose} />
    </div>
  );
}
