import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../app/slices/TrainingSlice";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { ImagePopup } from "../../models/interactions/ImagePopup";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import "./ImagePopupPreview.css";
import PiInteractionAssetPreview from "./components/PiInteractionAssetPreview";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";
import PiPlayButtonPreview from "./components/PiPlayButtonPreview";
import PiPopupWindowPreview from "./components/PiPopupWindowPreview";

export default function ImagePopupPreview() {
  const imagePopupData = useSelector(selectSelectedElement) as ImagePopup;
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onAssetSelect = (asset: AssetDetailsDTO) => {
    const updatedImagePopup = { ...imagePopupData };
    updatedImagePopup.assetId = asset.assetId;
    dispatch(updateSelectedInteraction(updatedImagePopup));
  };

  const title = <PiInteractionTitlePreview value={getValue(imagePopupData.title)} onChange={(e) => changeValue(imagePopupData.title, e)} placeholder="Image Popup" />;
  const body = <PiInteractionAssetPreview assetType={AssetKey.Image} assetId={imagePopupData.assetId} onAssetSelect={onAssetSelect} />;
  const footer = <PiPlayButtonPreview />;
  return (
    <div>
      <PiPopupWindowPreview title={title} body={body}  />
    </div>
  );
}
