import {activitiesMapped, actorActivities, ActorState} from "../../models/ActorState";
import PiDropdownV2 from "../../components/PiDropdownV2";
import {PiPopperMenuProps} from "../../components/PiPopper";
import ActorAvatar from "../../components/actors/ActorAvatar";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";
import React from "react";
import {useSelector} from "react-redux";
import {selectActors} from "../../app/slices/TrainingSlice";
import PiCreatorDropdown from "../../components/PiCreatorDropdown";

export default function ActorStateSetActivityDropdown(props: {
    actorState: ActorState;
    onChange: (state: string) => void
}) {
    const {actorState, onChange} = {...props};

    const items = Array.from(activitiesMapped).map(([key, value]: [string, string], index) => {
        const image = value ? <img src={value} alt={key} className={"w-[36px] h-[36px] object-contain"}/> :
            <div className={"flex w-[36px] h-[36px]"}><PersonOffOutlinedIcon className={"my-auto"}/></div>
        const label = <div className={"my-auto text-creator-text text-[14px] font-medium"}>{key}</div>
        return <div className={"flex gap-[10px] py-[8px]"} onClick={() => onChange(key)}>
            {image}
            {label}</div>
    });

    const trigger = <div className={"flex gap-[10px] h-full w-full"}>
        <img src={activitiesMapped.get(actorState.activity)} alt={"/"} className={"w-[36px] h-[36px] object-contain"}/>
        <div className={"my-auto text-creator-text text-[14px] font-medium"}>{actorState.activity}</div>
    </div>;

    const newDropdown = <PiCreatorDropdown label={"Activity"} trigger={trigger} items={items as JSX.Element[]}/>

    return <>{newDropdown}</>
}