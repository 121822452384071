import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ClickRegionPreview from "../../features/previews/click_region/ClickRegionPreview";
import { IAnswer } from "../Quiz";
import Interaction, { InteractionType, Marker } from "./Interaction";

export interface IClickRegion extends Interaction {
    xPos: number;
    yPos: number;
    xSize: number;
    ySize: number;
    correct?: boolean;
}

export function CreateClickRegion(): IClickRegion {
    return {
        id: uuidv4(),
        name: "Click Region",
        type: InteractionType.ClickRegion,
        xPos: 0.5,
        yPos: 0.5,
        xSize: 0.1,
        ySize: 0.1,
    };
}

export function CreateCustomClickRegion(xPos: number, yPos: number, xSize: number, ySize: number): IClickRegion {
    return {
        id: uuidv4(),
        name: "Click Region",
        type: InteractionType.ClickRegion,
        xPos: xPos,
        yPos: yPos,
        xSize: xSize,
        ySize: ySize,
    };
}

export function MigrateClickRegion(from: number, to: number, interaction: IClickRegion): IClickRegion {
    return interaction;
}

export const clickRegionInteraction = (): InteractionFactoryEntity => {
    const component = <ClickRegionPreview />;


    const create = (getKeys: (amount: number) => string[]): IClickRegion => {
        return {
            id: uuidv4(),
            name: "Click Region",
            type: InteractionType.ClickRegion,
            xPos: 0.5,
            yPos: 0.5,
            xSize: 0.1,
            ySize: 0.1,
        };
    }

    const migrate = (from: number, to: number, interaction: Interaction): IClickRegion => {
        return interaction as IClickRegion;
    }

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): Marker => {
        return create(getKeys);
    }

    const filter = (interaction: Interaction): string[] => {
        return [interaction.name];
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Filter: filter
    }
}

