import React from "react";
import { useTranslation } from "react-i18next";
import "./TranslationProgressbarTooltip.css";

interface TranslationprogressbarTooltipProps {
  manuallyTranslated: string;
  autoTranslated: string;
  notTranslated: string;
}
export default function TranslationprogressbarTooltip(props: TranslationprogressbarTooltipProps) {
  const { manuallyTranslated, autoTranslated, notTranslated } = { ...props };
  const { t } = useTranslation();

  return (
    <div>
      <div className="translationprogressbartooltip-row">
        <div className="translationprogressbartooltip-dot-lightblue"></div>
        <div className="translationprogressbartooltip-percentage">{notTranslated}</div>
        <div>{t("blank")}</div>
      </div>
      <div className="translationprogressbartooltip-row">
        <div className="translationprogressbartooltip-dot-blue"></div>
        <div className="translationprogressbartooltip-percentage">{manuallyTranslated}</div>
        <div>{t("translated")}</div>
      </div>
      <div className="translationprogressbartooltip-row">
        <div className="translationprogressbartooltip-dot-yellow"></div>
        <div className="translationprogressbartooltip-percentage">{autoTranslated}</div>
        <div>{t("ai_translated")}</div>
      </div>
    </div>
  );
}
