import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import React from "react";
import "./PiBasicGraphicsQuizAddButton.css";

interface PiBasicGraphicsQuizAddButtonProps {
  onClick: () => void;
}

export default function PiBasicGraphicsQuizAddButton(props: PiBasicGraphicsQuizAddButtonProps) {
  const { onClick } = { ...props };
  return (
    <div className="pibasicgraphicsquizaddbutton-root" onClick={onClick}>
      <div className="pibasicgraphicsquizaddbutton-body">
        <div className="pibasicgraphicsquizaddbutton-icon">
          <PostAddOutlinedIcon fontSize="inherit" color="inherit" />
        </div>
        <div className="pibasicgraphicsquizaddbutton-text">Add Answer</div>
      </div>
    </div>
  );
}
