import { Clear } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import "./PiInputField.css";
interface PiInputFieldProps {
  label: string;
  placeholder: string;
  info?: string;
  showInfoButton?: boolean;
  onChange: (newValue: string) => void;
  value?: string;
  disabled?: boolean;
  showSearchIcon?: boolean;
  showCrossIcon?: boolean;
  id?: string;
  autoFocus?: boolean;
}

export default function PiInputfield(props: PiInputFieldProps) {
  const { value, disabled, showSearchIcon, autoFocus, showCrossIcon } = {
    ...props,
  };
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current || !value) return;
    ref.current.value = value;
  }, [value]);

  useEffect(() => {
    console.log("input value changed");
    if (!ref.current || !inputValue) return;
    ref.current.value = inputValue;
  }, [inputValue]);

  const infoButton = props.showInfoButton ? (
    <div className="pi-inputfield-info">
      <Tooltip title={props.info} arrow placement="right">
        <IconButton tabIndex={-1}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    ""
  );

  const searchIcon = (
    <div className="pi-inputfield-searchicon">
      <SearchIcon />
    </div>
  );

  const crossIcon = (
    <button
      aria-label="cross-icon"
      className="text-[15px] mr-[12px] flex items-center text-creator-text-sub"
      onClick={() => clearInput()}
    >
      <Clear fontSize="inherit" />
    </button>
  );

  const debounced = useDebouncedCallback((value: string) => {
    props.onChange(value);
  }, 500);

  const clearInput = () => {
    setInputValue("");
    props.onChange("");
    if (ref && ref.current) ref.current.value = "";
  };

  return (
    <div
      id={props.id ?? ""}
      className={
        disabled
          ? "pi-inputfield-root pi-inputfield-disabled"
          : "pi-inputfield-root"
      }
    >
      <div className="pi-inputfield-label">{props.label}</div>
      <div className="pi-inputfield-wrapper">
        {showSearchIcon && searchIcon}
        <input
          ref={ref}
          className="pi-inputfield-input"
          defaultValue={inputValue ? inputValue : ""}
          placeholder={props.placeholder}
          onChange={(e) => {
            debounced(e.target.value);
            setInputValue(e.target.value);
          }}
          onBlur={(e) => {
            if (ref) {
              const currentInputValue = ref.current?.value!;
              props.onChange(currentInputValue);
              setInputValue(currentInputValue);
            }
          }}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {showCrossIcon && inputValue && inputValue !== "" && crossIcon}
        {infoButton}
      </div>
    </div>
  );
}
