import { useSelector } from "react-redux";
import {
  selectActors,
  selectSelectedTake3D,
} from "../../app/slices/TrainingSlice";
import { ActorState } from "../../models/ActorState";
import ActorAvatar from "../../components/actors/ActorAvatar";
import PiCreatorDropdown from "../../components/PiCreatorDropdown";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";

export default function ActorStateLookatDropdown(props: {
  actorState: ActorState;
  onChange: (lookatId: string) => void;
}) {
  const { actorState, onChange } = { ...props };
  const actorsInSelectedTake = useSelector(selectSelectedTake3D)?.actors.filter(
    (a) => a.actorId !== actorState.actorId
  );
  const allActors = useSelector(selectActors).filter(
    (a) => a.id !== actorState.actorId
  );
  const selectedActor = allActors.find((a) => a.id === actorState.lookat);

  const noneOption = (
    <div
      key={"none"}
      className={"flex gap-[10px] py-[8px]"}
      onClick={() => onChange("")}
    >
      <div className={"my-auto flex gap-[10px] h-[36px]"}>
        <PersonOffOutlinedIcon className={"my-auto"} />
        <span className={"my-auto"}>No Actor Selected</span>
      </div>
    </div>
  );

  const items = actorsInSelectedTake
    ? actorsInSelectedTake?.map((actor) => {
        const myActor = allActors.find((a) => a.id === actor.actorId);
        return (
          <div
            key={actor.id}
            className={"flex gap-[10px] py-[8px]"}
            onClick={() => onChange(actor.actorId)}
          >
            <ActorAvatar actor={myActor} theme={"creator-selected"} />
            <div
              className={"my-auto text-[14px] text-creator-text font-medium"}
            >
              {myActor!.workingName}
            </div>
          </div>
        );
      })
    : [];

  const itemsCombined = [noneOption, ...items];

  const trigger = selectedActor ? (
    <div className={"flex gap-[10px] h-full w-full"}>
      <ActorAvatar actor={selectedActor} theme={"creator-selected"} />
      <div className={"my-auto"}>{selectedActor!.workingName}</div>
    </div>
  ) : (
    <div
      className={"flex text-left text-[14px] text-creator-text h-full w-full"}
    >
      <div className={"my-auto flex gap-[10px]"}>
        <PersonOffOutlinedIcon className={"my-auto"} />
        <span className={"my-auto"}>No Actor Selected</span>
      </div>
    </div>
  );

  const newDropdown = (
    <PiCreatorDropdown
      label={"Lookat"}
      trigger={trigger}
      items={itemsCombined as JSX.Element[]}
    />
  );

  return <>{newDropdown}</>;
}
