import { useDispatch, useSelector } from "react-redux";
import {
  DynamicInteractionPropertyType,
  IDynamicInteraction,
  IDynamicInteractionProperty,
} from "../../models/interactions/DynamicInteraction";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../app/slices/TrainingSlice";
import MessageBubbleActorDropdown from "../previews/message_bubble/MessageBubbleActorDropdown";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import DynamicInteractionLocalizedTextfield from "./DynamicInteractionLocalizedTextfield";
import { CapitalizeFirstLetter } from "../../helpers/StringHelper";
import DynamicInteractionStringDropdown from "./DynamicInteractionStringDropdown";

export default function DynamicInteractionPreviewBody() {
  const interaction = useSelector(selectSelectedElement) as IDynamicInteraction;
  const dispatch = useDispatch();
  const { getValue, changeValue } = useTrainingLocalization();

  const onEditField = (
    id: string,
    field: keyof IDynamicInteractionProperty,
    value: any
  ) => {
    if (!interaction || !interaction.dynamicInteractionProperties) return;

    const target = interaction.dynamicInteractionProperties.find(
      (prop) => prop.id === id
    );
    if (!target) return;

    const index = interaction.dynamicInteractionProperties.indexOf(target);
    const copy = { ...target, [field]: value };

    const copiedArray = [...interaction.dynamicInteractionProperties];
    copiedArray[index] = copy;

    dispatch(
      updateSelectedInteraction({
        ...interaction,
        dynamicInteractionProperties: copiedArray,
      } as IDynamicInteraction)
    );
  };

  const props = interaction.dynamicInteractionProperties.map((prop) => {
    if (prop.type === DynamicInteractionPropertyType.actorDropdown) {
      return (
        <MessageBubbleActorDropdown
          key={prop.id}
          label={CapitalizeFirstLetter(prop.name)}
          selectedTargetActorId={prop.value}
          onClick={(actorId) => {
            onEditField(prop.id, "value", actorId);
          }}
        />
      );
    } else if (prop.type === DynamicInteractionPropertyType.localizedText) {
      return (
        <DynamicInteractionLocalizedTextfield
          key={prop.id}
          dynamicProperty={prop}
        />
      );
    } else if (prop.type === DynamicInteractionPropertyType.stringDropdown) {
      return (
        <DynamicInteractionStringDropdown
          key={prop.id}
          label={prop.name}
          value={prop.value}
          values={prop.values!}
          onChange={(newValue: string) =>
            onEditField(prop.id, "value", newValue)
          }
        />
      );
    }
  });

  return (
    <div className={"w-full h-full flex flex-col gap-[24px]"}>{props}</div>
  );
}
