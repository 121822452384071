import {useEffect, useState} from "react";

export const useScreenScale = () => {
    const [scale, setScale] = useState(1);
    const scaleFactor = 850;

    useEffect(() => {
        setScale(window.innerHeight / scaleFactor);
    }, [window.innerHeight])

    return {scale};
}