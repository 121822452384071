import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import PiInputfield from "../../components/PiInputField";
import "./New360Training.css";

import {useSelector} from "react-redux";
import {LanguageData, selectAvailableLanguages} from "../../app/slices/TrainingLocalizationSlice";
import PiCard from "../../components/PiCard";
import PiCardContent from "../../components/PiCardContent";
import PiDropdown from "../../components/PiDropdown";
import {useGetLanguages} from "../../hooks/GetLanguagesHook";

interface New360TrainingProps {
    onContinueClick: (name: string, description: string, refLanguage: string) => void;
    onCloseClick: () => void;
}

export default function New360Training(props: New360TrainingProps) {
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const {t} = useTranslation();
    const languages = useSelector(selectAvailableLanguages);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageData>();
    const [] = useGetLanguages();

    useEffect(() => {
        setSelectedLanguage(languages.find((lang) => lang.shortcode === "en"));
    }, []);

    function onProjectNameChange(newName: string) {
        setProjectName(newName);
    }

    function onProjectDescriptionChange(newDescription: string) {
        setProjectDescription(newDescription);
    }

    return (
        <PiCard onClose={props.onCloseClick}>
            <PiCardContent
                titleL="360"
                titleR="Training"
                titleVariant="purple"
                submitText={t("continue")}
                onSubmit={() => props.onContinueClick(projectName, projectDescription, selectedLanguage?.shortcode ?? "en")}
                submitDisabled={projectName.length === 0}
            >
                <PiInputfield label={t("trainingName")} placeholder={t("name")} onChange={onProjectNameChange} showInfoButton={true} info={t("trainingNameInfo") || ""}/>
                <PiDropdown
                    selectedText={selectedLanguage?.full ?? ""}
                    options={languages.map((lang) => {
                        return {
                            text: lang.full,
                            onClick: () => setSelectedLanguage(lang),
                        };
                    })}
                    width="calc(100% - 10px)"
                    label={t("training_reference_language") ?? ""}
                    info={t("training_reference_language_info") ?? ""}
                />
            </PiCardContent>
        </PiCard>
    );
}
