import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import {useMemo, useState} from "react";
import {AssetDetailsDTO, AssetKey} from "../../../dto/AssetDTO";
import {useAssetPreview} from "../../../hooks/AssetPreviewHook";
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import classNames from "classnames";

export default function PiBasicQuizImageSelectButton(props: {
    onChange: (assetId: string) => void,
    assetId: string | undefined
}) {
    const {onChange, assetId} = {...props}
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {getAssetPreview} = useAssetPreview(assetId);
    const previewUrl = getAssetPreview();
    const hasNoImage = useMemo(() => assetId === undefined || assetId === "", [assetId]);

    const noImageView = <div className={"w-full h-full flex"} onClick={() => setIsOpen(true)}>
        <AddPhotoAlternateOutlinedIcon className={"m-auto"} color={"inherit"} fontSize={"inherit"}/>
    </div>;

    // TODO: change image button
    const changeImgButton = <div
        className={"absolute flex right-[16px] bottom-[16px] bg-interaction-primary text-white text-[32px] w-[48px] h-[48px] rounded-full cursor-pointer hover:opacity-75"} onClick={() => setIsOpen(true)}>
        <ImageSearchOutlinedIcon className={"m-auto"} color={"inherit"} fontSize={"inherit"}/>
    </div>

    const imageView = <div className={"relative w-full h-full flex p-[24px]"}>
        <img className={"m-auto"} src={previewUrl} alt={"image preview"}/>
        {changeImgButton}
    </div>

    const onSelect = (asset: AssetDetailsDTO) => {
        onChange(asset.assetId);
        setIsOpen(false);
    }

    return <>
        <div
            className={classNames({
                "flex w-full h-full rounded-[5px] bg-interaction-primary-washed text-[64px] text-interaction-primary ": true,
                "cursor-pointer hover:opacity-75": hasNoImage
            })}>
            {hasNoImage && noImageView}
            {!hasNoImage && imageView}
        </div>

        <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={AssetKey.Image} onSelect={onSelect}/>
    </>
}