import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import LookatPreviewBody from "./LookatPreviewBody";

export default function LookatPreview() {
  return (
    <PiPopupWindowPreview
      size="medium"
      title={<PiPopupWindowStaticTitle title="Look At" />}
      body={<LookatPreviewBody />}
    />
  );
}
