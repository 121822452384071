import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { setIsLoading } from "../app/slices/GlobalSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { TrainingService } from "../services/TrainingService";
import { useGetTrainings } from "./GetTrainingsHook";

export const useDeleteTraining = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);
  const [getTrainings] = useGetTrainings(false);

  const deleteTraining = async (trainingId: string) => {
    dispatch(setIsLoading(true));
    if (!orgId) return;

    await TrainingService.DeleteTraining(orgId, token, trainingId);
    await getTrainings();
    dispatch(setIsLoading(false));
  };
  return [deleteTraining] as const;
};
