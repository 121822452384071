import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectOrganizations } from "../../app/slices/UserSlice";
import { useSwitchOrganization } from "../../hooks/SwitchOrganizationHook";
import { Organization } from "../../services/UserService";
import PiMenuButton from "./PiMenuButton";
import "./PiSwitchOrganizationDialog.css";
interface PiSwitchOrganizationDialogProps {
  open: boolean;
  onConfirm: () => void;
}

export default function PiSwitchOrganizationDialog(
  props: PiSwitchOrganizationDialogProps
) {
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();
  const organizations = useSelector(selectOrganizations);
  const { t } = useTranslation();
  const [switchOrganization] = useSwitchOrganization();

  function OnSelectOrganization(org: Organization) {
    props.onConfirm();
    //dispatch(setCurrentOrganization(org));
    switchOrganization(org);
  }

  function onClose() {
    props.onConfirm();
  }

  const filteredOrgs = organizations.filter((obj) => {
    return obj.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
  });
  const buttons = filteredOrgs.map((value: Organization, index) => {
    return (
      <PiMenuButton
        text={value.name}
        onClick={() => OnSelectOrganization(value)}
        key={index}
      />
    );
  });

  return (
    <Dialog
      open={props.open}
      maxWidth={"xs"}
      fullWidth={true}
      PaperProps={{
        style: {
          minHeight: "75vh",
          maxHeight: "75vh",
        },
      }}
    >
      <DialogTitle>
        <div>{t("switchOrganization")}</div>
        <IconButton className="pi-switchorganization-close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="pi-switchorganization-content">
          <TextField
            label={t("filterOrganization")}
            className="pi-switchorganization-textfield"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div className="pi-switchorganization-list">{buttons}</div>
      </DialogContent>
    </Dialog>
  );
}
