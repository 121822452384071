import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { IConversation } from "../../../models/interactions/Conversation";
import "./ConversationDetailsView.css";
import MessageBubblePreviewBody from "../message_bubble/MessageBubblePreviewBody";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import EmotionBubblePreviewBody from "../emotion_bubble/EmotionBubblePreviewBody";
import { IEmotionBubble } from "../../../models/interactions/EmotionBubble";
import { InteractionType } from "../../../models/interactions/Interaction";
import { useTranslation } from "react-i18next";

export default function ConversationDetailsView(props: { messageId: string | undefined; onClose: () => void }) {
  const { onClose, messageId } = { ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const conversation = useSelector(selectSelectedElement) as IConversation;
  const message = conversation.messages.find((m) => m.id === messageId);
  const index = conversation.messages.findIndex((m) => m.id === message?.id);

  const updateConversation = (field: string, value: string) => {
    if (index === -1) return;

    const msgCpy = [...conversation.messages];
    msgCpy[index] = { ...msgCpy[index], [field]: value };

    dispatch(updateSelectedInteraction({ ...conversation, messages: msgCpy } as IConversation));
  }


  const title =
    <div className="flex items-center">
      <IconButton onClick={onClose}>
        <ArrowBackIcon className=" text-interaction-primary" />
      </IconButton>
      <div className=" ml-2 not-italic font-semibold leading-normal text-[28px] text-interaction-primary">
        {t("settings")}
      </div>
    </div>;

  const body = conversation.messages[index].type === InteractionType.EmotionBubble ? <EmotionBubblePreviewBody emotionBubble={conversation.messages[index] as IEmotionBubble} updateInteraction={updateConversation} /> : <MessageBubblePreviewBody messageBubble={conversation.messages[index] as IMessageBubble} updateInteraction={updateConversation} />;

  return <PiPopupWindowPreview title={title} body={body} />;
}
