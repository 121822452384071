import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BasicInteractions360, BasicInteractions3D, InteractionDefintion} from "../../features/interaction_picker/InteractionDefinitions";
import {RootState} from "../Store";
import {TrainingType} from "./TrainingSlice";

interface InteractionPickerState {
    interactionDefinitions: InteractionDefintion[];
    mostRecent: InteractionDefintion[];
    selectedInteraction: InteractionDefintion;
    filter: string;
    selectedPropStateId: string;
}

const initialState: InteractionPickerState = {
    interactionDefinitions: BasicInteractions360,
    mostRecent: [],
    selectedInteraction: BasicInteractions360[0],
    filter: "",
    selectedPropStateId: "",
};

export const interactionPickerSlice = createSlice({
    name: "interactionPicker",
    initialState: initialState,
    reducers: {
        setInteractionPickerContext: (state, action: PayloadAction<TrainingType>) => {
            if (action.payload === TrainingType.Training3D) {
                state.interactionDefinitions = BasicInteractions3D;
                state.mostRecent = [];
                state.filter = "";
                state.selectedInteraction = BasicInteractions360[0];
            } else if (action.payload === TrainingType.Training360) {
                state.interactionDefinitions = BasicInteractions360;
                state.mostRecent = [];
                state.filter = "";
                state.selectedInteraction = BasicInteractions360[0];
            }
        },
        setSelectedInteractionDefinition: (state, action: PayloadAction<InteractionDefintion>) => {
            state.selectedInteraction = action.payload;
        },
        addMostRecentInteraction: (state, action: PayloadAction<InteractionDefintion>) => {
            if (state.mostRecent.find((def) => def.type === action.payload.type) !== undefined) {
                state.mostRecent.splice(
                    state.mostRecent.findIndex((mr) => mr.type === action.payload.type),
                    1
                );
            }
            const copy = {...action.payload};
            copy.name = copy.name + " "; // add space for small difference

            state.mostRecent.unshift(copy);

            if (state.mostRecent.length > 4) state.mostRecent.pop();

            state.selectedInteraction = copy;
        },
        setInteractionPickerFilter: (state, action: PayloadAction<string>) => {
            state.filter = action.payload;
        },
        setSelectedInteractionPropStateId: (state, action: PayloadAction<string>) => {
            state.selectedPropStateId = action.payload;
        }
    },
});

export const {setInteractionPickerContext, setSelectedInteractionDefinition, addMostRecentInteraction, setInteractionPickerFilter, setSelectedInteractionPropStateId} = interactionPickerSlice.actions;

export const selectInteractionDefinitions = (state: RootState) => state.interactionPicker.interactionDefinitions;

// export const selectFilteredInteractionDefinitions = (state: RootState) =>
//   state.interactionPicker.interactionDefinitions.filter((def) => {
//     return def.name.toLowerCase().includes(state.interactionPicker.filter.toLowerCase()) && state.interactionPicker.mostRecent.find((mr) => mr.name === def.name) === undefined;
//   });
export const selectFilteredInteractionDefinitions = (state: RootState) =>
    state.interactionPicker.interactionDefinitions.filter((def) => {
        return def.name.toLowerCase().includes(state.interactionPicker.filter.toLowerCase());
    });
export const selectSelectedInteractionDefinition = (state: RootState) => state.interactionPicker.selectedInteraction;

export const selectMostRecentInteractionDefinitions = (state: RootState) =>
    state.interactionPicker.mostRecent.filter((def) => def.name.toLowerCase().includes(state.interactionPicker.filter.toLowerCase()));

export const selectFilter = (state: RootState) => state.interactionPicker.filter;
export const selectSelectedInteractionPropStateId = (state: RootState) => state.interactionPicker.selectedPropStateId;

export default interactionPickerSlice.reducer;
