import { useSelector } from "react-redux";
import StatementQuizPreviewText from "./StatementQuizPreviewText";
import { IStatementQuiz } from "../../../models/interactions/StatementQuiz";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { useState } from "react";
import PiBasicQuizAddAnswerButtonV2 from "../components/v2/PiBasicQuizAddAnswerButtonV2";
import { useDispatch } from "react-redux";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import StatementQuizPreviewAnswers from "./StatementQuizPreviewAnswers";
import { GetNewGuid } from "../../../helpers/GuidHelper";

export default function StatementQuizPreviewBody() {
  const interaction = useSelector(selectSelectedElement) as IStatementQuiz;
  const [index, setIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const { getNewKey } = useTrainingLocalization();

  const onAddStatement = () => {
    const newStatements = [...interaction.statements, getNewKey()];

    const correctAnswers = [...interaction.correctAnswers, -1];

    dispatch(
      updateSelectedInteraction({
        ...interaction,
        statements: newStatements,
        correctAnswers: correctAnswers,
      } as IStatementQuiz)
    );
    setIndex(newStatements.length - 1);
  };

  const top = (
    <div className="w-full h-[50%]">
      <StatementQuizPreviewText
        index={index}
        onIndexChange={(newIndex) => setIndex(newIndex)}
      />
    </div>
  );

  const middle = (
    <div className="h-[25%]">
      <StatementQuizPreviewAnswers index={index} />
    </div>
  );

  const bottom = (
    <div className="h-[25%] flex relative">
      <div className="absolute bottom-[4px] w-full">
        <PiBasicQuizAddAnswerButtonV2
          onClick={onAddStatement}
          disabled={interaction.statements.length >= 5}
          text="Add Statement"
        />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full gap-[8px]">
      {top}
      {middle}
      {bottom}
    </div>
  );
}
