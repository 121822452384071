import Take3D from "../../models/Take3D";
import {Actor} from "../../models/Actor";


export class ValidationHelper {
    static ValidateKey(key: string, getValue: (key: string) => string, warnings: string[], subject: string) {
        const value = getValue(key);
        if (value === "") warnings.push(`${subject} has no text`);
    }

    static ValidateAssetId(assetId: string, warnings: string[], customWarning?: string) {
        if (assetId === "")
            warnings.push(customWarning ? `${customWarning} not selected` : "No asset selected");
    }

    static ValidateNpcInTake(actorId: string, take: Take3D, actors: Actor[], warnings: string[]) {

        const actor = actors.find(actor => actor.id === actorId);
        if (!actor) return;

        let found = take.actors.find(actor => actor.actorId === actorId);
        if (!found)
            warnings.push(`${actor.workingName} was not found in current take`);
    }
}