import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { updateSelectedTake } from "../../app/slices/TrainingSlice";
import Take360 from "../../models/Take360";
import "./Take360Card.css";
import Take360CardContent from "./Take360CardContent";
import TakeCard360Header from "./TakeCard360Header";

interface TakeCardProps {
  take: Take360;
  isSelected: boolean;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined | null;
}

export default function Take360Card(props: TakeCardProps) {
  const { take, isSelected, dragHandleProps } = { ...props };
  const dispatch = useDispatch();
  const isSelectedClasses = isSelected ? "take360card-selected" : "take360card-not-selected";

  const toggleExpanded = () => {
    var updatedTake: Take360 = { ...props.take };
    updatedTake.isExpanded = !updatedTake.isExpanded;
    dispatch(updateSelectedTake(updatedTake));
  };

  return (
    <div className={"take360card-root " + isSelectedClasses}>
      <TakeCard360Header take={take} isExpanded={take.isExpanded} isSelected={isSelected} onToggleExpand={toggleExpanded} dragHandleProps={dragHandleProps} />
      <Take360CardContent isExpanded={take.isExpanded} take={take} />
    </div>
  );
}
