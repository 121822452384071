import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { GetSupportedFiletypes, selectEditingAsset, updateAssetName } from "../../app/slices/AssetsSlice";
import PiDialog, { DialogProps } from "../../components/PiDialog";
import PiInputField from "../../components/PiInputField";
import { UpdateAssetName } from "../../hooks/UpdateAssetNameHook";
import VariantDetails from "../variants/VariantDetails";
import VariantsList from "../variants/VariantsList";
import "./EditAssetDialog.css";
type EditAssetDialogProps = DialogProps;

export default function EditAssetDialog(props: EditAssetDialogProps) {
  const dispatch = useDispatch();
  const editingAsset = useSelector(selectEditingAsset);
  const [updateAsset] = UpdateAssetName();
  const { t } = useTranslation();

  const [hasEditedText, setHasEditedText] = useState<boolean>(false);

  const acceptedFiletypes = editingAsset ? GetSupportedFiletypes(editingAsset.key) : [];

  const onEditAssetName = (newName: string) => {
    if (editingAsset) {
      console.log(newName);
      const copy = structuredClone(editingAsset);
      copy.name = newName;
      setHasEditedText(true);
      dispatch(updateAssetName(copy));
      //dispatch(updateLegacyAsset(copy));
    }
  };

  const onSaveAssetName = () => {
    if (editingAsset && hasEditedText) {
      setHasEditedText(false);
      updateAsset(editingAsset.id, editingAsset.name);
    }
  };

  const fileExplorerHeader = (
    <div className="pi-editassetdialog-header">
      <div>{t("localize_file")}</div>
    </div>
  );

  const body = (
    <div className="pi-editassetdialog-body">
      <div className="pi-editassetdialog-name">
        <div className="pi-editassetdialog-name-edit">
          <PiInputField label={t("file_name")} placeholder={t("file_name")} value={editingAsset?.name} onChange={onEditAssetName} />
        </div>
        <div className="pi-editassetdialog-name-save">
          <Button variant="piBlue" sx={{ height: "42px" }} disabled={!hasEditedText} onClick={onSaveAssetName}>
            {t("save")}
          </Button>
        </div>
      </div>
      <div className="pi-editassetdialog-variants">
        <div className="pi-editassetdialog-variantslist">
          <VariantsList />
        </div>
        <div className="pi-editassetdialog-variantdetails">
          <VariantDetails />
        </div>
      </div>
    </div>
  );

  return <PiDialog open={props.open} onClose={props.onClose} width="90vw" height="90vh" header={fileExplorerHeader} body={body} />;
}
