import { v4 as uuidv4 } from "uuid";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import Interaction, { InteractionType } from "./Interaction";
import { InteractionProperty } from "../InteractionProperty";
export const requiredCameraProperties: InteractionProperty[] = [];

export interface ISetCamera extends Interaction {
  cameraId: string;
}

export function CreateSetCamera(camera?: ISetCamera): ISetCamera {
  return {
    id: uuidv4(),
    name: "Main Camera",
    cameraId: camera ? camera.cameraId : "",
    type: InteractionType.SetCamera,
    properties: camera ? camera.properties : requiredCameraProperties,
  } as ISetCamera;
}

export function MigrateSetCamera(from: number, to: number, interaction: ISetCamera): ISetCamera {
  if (interaction.properties === undefined) return { ...interaction, properties: requiredCameraProperties };

  // drill the props and add any missing ones
  var newProperties: InteractionProperty[] = GetMigratedProperties(requiredCameraProperties, interaction.properties);
  console.log("[SetCamera] migrated " + (newProperties.length - interaction.properties.length) + " properties");

  return { ...interaction, properties: newProperties };
}
