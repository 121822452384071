import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDebouncedCallback} from "use-debounce";
import {selectImageBounds, selectSelectedElement, selectViewportBounds, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {IClickRegion} from "../../../models/interactions/ClickRegion";
import PiQuizCheckbox from "../components/PiQuizCheckbox";
import "./ClickRegionPreview.css";

interface ClickRegionPreviewProps {
    clickregionProp?: IClickRegion;
    onChange?: (region: IClickRegion) => void;
    index?: number;
    canDelete?: boolean;
    onDelete?: (clickregion: IClickRegion) => void;
    hasCheckbox?: boolean;
    checked?: boolean;
}

export default function ClickRegionPreview(props: ClickRegionPreviewProps) {
    const {clickregionProp, onChange, index, canDelete, onDelete, hasCheckbox, checked} = {...props};
    const selectedInteraction = useSelector(selectSelectedElement) as IClickRegion;

    const clickregion = useMemo(() => clickregionProp ?? selectedInteraction, [clickregionProp]);

    const parentBounds = useSelector(selectViewportBounds)!;
    const imageBounds = useSelector(selectImageBounds)!;
    const dispatch = useDispatch();
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isDraggingBlocked, setIsDragginBlocked] = useState<boolean>(false);
    const [xPos, setXPos] = useState<number>(0.5);
    const [yPos, setYPos] = useState<number>(0.5);
    const [xSize, setXSize] = useState<number>(0.1);
    const [ySize, setYSize] = useState<number>(0.1);

    useEffect(() => {
        setXPos(clickregion.xPos);
        setYPos(clickregion.yPos);
        setXSize(clickregion.xSize);
        setYSize(clickregion.ySize);
    }, [clickregion]);

    const debounceToSlice = useDebouncedCallback(() => {
        const updatedRegion = structuredClone(clickregion);
        updatedRegion.xPos = xPos;
        updatedRegion.yPos = yPos;
        updatedRegion.xSize = xSize;
        updatedRegion.ySize = ySize;

        if (onChange) onChange(updatedRegion);
        else dispatch(updateSelectedInteraction(updatedRegion));
    }, 500);

    const updateMarkerPosition = (nx: number, ny: number) => {
        setXPos(nx >= 0 && nx < 1 ? nx : xPos);
        setYPos(ny >= 0 && ny < 1 ? ny : yPos);

        debounceToSlice();
    };

    const updateMarkerSize = (nx: number, ny: number) => {
        var newXSize = nx + xSize > 0.07 ? nx + xSize : xSize;
        var newYSize = ny + ySize > 0.07 ? ny + ySize : ySize;

        setXSize(newXSize);
        setYSize(newYSize);

        debounceToSlice();
    };

    const getXProcentual = () => {
        return `calc(${xPos} * 100%)`;
    };

    const getYProcentual = () => {
        return `calc(${yPos} * 100%)`;
    };

    const getXSizeProcentual = () => {
        return `calc(${xSize} * 100%)`;
    };

    const getYSizeProcentual = () => {
        return `calc(${ySize} * 100%)`;
    };

    const getImgWidth = () => {
        if (imageBounds) {
            return `${(imageBounds.width / parentBounds.width) * 100}%`;
        }
    };

    const getImgHeight = () => {
        if (imageBounds) {
            return `${(imageBounds.height / parentBounds.height) * 100}%`;
        }
    };

    const getImageLeft = () => {
        if (imageBounds) {
            return `${imageBounds.x}px`;
        }
    };

    const getImageTop = () => {
        if (imageBounds) {
            return `${imageBounds.y}px`;
        }
    };

    const getStyle = () => {
        return {
            ["--marker-x"]: getXProcentual(),
            ["--marker-y"]: getYProcentual(),
            ["--marker-size-x"]: getXSizeProcentual(),
            ["--marker-size-y"]: getYSizeProcentual(),
        };
    };

    const getImgDimensions = () => {
        return {
            ["--image-width"]: getImgWidth(),
            ["--image-height"]: getImgHeight(),
            ["--image-x"]: getImageLeft(),
            ["--image-y"]: getImageTop(),
        };
    };

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (isDraggingBlocked || isResizing) return;
        setIsDragging(true);
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (isDragging && !isResizing) {
            const newX = e.clientX;
            const newY = e.clientY;

            const nx = (newX - parentBounds.x - imageBounds.x) / imageBounds.width;
            const ny = (newY - parentBounds.y - imageBounds.y) / imageBounds.height;

            updateMarkerPosition(nx, ny);
        }
        if (isResizing) {
            var xdiff = e.movementX / imageBounds.width;
            var ydiff = e.movementY / imageBounds.height;
            updateMarkerSize(xdiff * 2, ydiff * 2);
        }
    };

    const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsDragging(false);
        setIsResizing(false);
    };

    const regionClasses = isDragging ? "clickregion-marker clickregion-dragging" : "clickregion-marker ";

    const onResizeDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
        setIsResizing(true);
    };

    const onDragControlMouseEnter = () => {
        setIsDragginBlocked(true);
    };
    const onDragControlMouseLeave = () => {
        setIsDragginBlocked(false);
    };

    const onCheckboxClicked = (checked: boolean) => {
        const updatedRegion: IClickRegion = {...clickregion, correct: checked};
        onChange?.(updatedRegion);
    };

    return (
        <div className={"clickregion-root" + (isDragging || isResizing ? " clickregion-highlight" : "")} onPointerMove={onMouseMove} onPointerUp={onMouseUp} style={getImgDimensions() as {}}>
            <div className={regionClasses} style={getStyle() as {}} draggable onDragStart={onDragStart}>
                {index && <div className="clickregion-index-display">{index}</div>}
                <div
                    className={"clickregion-resize-dot" + (isDragging || isResizing ? " clickregion-highlight" : "")}
                    draggable
                    onDragStart={onResizeDragStart}
                    onMouseEnter={onDragControlMouseEnter}
                    onMouseLeave={onDragControlMouseLeave}
                ></div>
                {canDelete && (
                    <div
                        className="clickregion-delete-button"
                        onClick={() => {
                            if (onDelete) onDelete(clickregion);
                        }}
                    >
                        <CloseIcon color="inherit" fontSize="inherit"/>
                    </div>
                )}

                {hasCheckbox && (
                    <div className="clickregionpreview-checkbox">
                        <PiQuizCheckbox checked={checked!} onChange={onCheckboxClicked} variant="key-blue"/>
                    </div>
                )}
            </div>
        </div>
    );
}
