import { useState } from "react";

export const useDialogToggle = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return [isOpen, onClose, onOpen] as const;
};
