import PiPopupWindowPreview from "../previews/components/PiPopupWindowPreview";
import {useSelector} from "react-redux";
import {selectSelectedElement} from "../../app/slices/TrainingSlice";
import {IDynamicInteraction} from "../../models/interactions/DynamicInteraction";
import PiPopupWindowStaticTitle from "../previews/components/PiPopupWindowStaticTitle";
import DynamicInteractionPreviewBody from "./DynamicInteractionPreviewBody";

export default function DynamicInteractionPreview() {
    const interaction = useSelector(selectSelectedElement) as IDynamicInteraction;
    const title = <PiPopupWindowStaticTitle title={interaction.name}/>;

    return <PiPopupWindowPreview body={<DynamicInteractionPreviewBody/>} title={title}/>;
}