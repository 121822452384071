import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IPhotoRangeQuiz } from "../../../models/interactions/PhotoRangeQuiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PhotoRangeQuizBody from "./PhotoRangeQuizBody";

export default function PhotoRangeQuizPreview() {
  const quiz = useSelector(selectSelectedElement) as IPhotoRangeQuiz;
  const { getValue, changeValue } = useTrainingLocalization();

  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Quiz Title" showStarIcons />;

  const body = <PhotoRangeQuizBody />;

  return <PiPopupWindowPreview title={title} body={body} />;
}
