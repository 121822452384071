import PiModal, { PiModalProps } from "../../components/PiModal";
import PropLibraryCategories from "./PropLibraryCategories";
import PropLibraryItems from "./PropLibraryItems";
import PropLibraryDetails from "./PropLibraryDetails";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initializePropLibrary,
  selectIsPropSliceInitialized,
  setPropFilter,
} from "../../app/slices/PropLibrarySlice";
import usePropLibraryPagination from "./PropLibraryPagination.hooks";
import { useDebouncedCallback } from "use-debounce";

interface PropLibraryProps extends PiModalProps {
  onAddProp: () => void;
}

export default function PropLibrary(props: PropLibraryProps) {
  const { isOpen, onClose, title, width, height, onAddProp } = { ...props };
  const dispatch = useDispatch();
  const { LoadFirstPage } = usePropLibraryPagination();

  const isInitalized = useSelector(selectIsPropSliceInitialized);

  useEffect(() => {
    if (isOpen) {
      if (!isInitalized) {
        // if not initialized, fetch first page
        LoadFirstPage();
      } else {
        // ???
      }
    }
  }, [isOpen]);

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(setPropFilter(value));
  }, 500);

  return (
    <PiModal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      width={width}
      height={height}
    >
      <div className={"w-full h-full flex flex-col"}>
        <div className={"w-full h-full flex"}>
          <div className={"w-[250px] h-full"}>
            <PropLibraryCategories />
          </div>
          <div className={"flex-1 h-full"}>
            <PropLibraryItems />
          </div>
          <div className={"w-[500px]"}>
            <PropLibraryDetails onAddPropClick={onAddProp} />
          </div>
        </div>
      </div>
    </PiModal>
  );
}
