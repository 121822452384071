import axios from "axios";

const URL_ORGANIZATIONS: string = process.env.REACT_APP_PLAYIT_MAINAPI + '/v2/Organizations';
const CREATOR_API: string = process.env.REACT_APP_PLAYIT_CREATORAPI!;

export interface Organization {
  id: string;
  name: string;
}

export interface UserInfo {
  name: string;
  id: string;
}

export class UserService {
  static async GetOrganizations(token: string, userId: string): Promise<Organization[]> {
    var path = "/users/" + userId + "/organizations";
    var url = CREATOR_API + path;

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    var organizations: Organization[] = [];

    await axios
      .get<Organization[]>(url, config)
      .then((response) => {
        organizations = response.data;
      })
      .catch((error) => {
        //console.log(error.response.data);
      });

    return organizations;
  }

  static async GetUserInfo(token: string, organizationID: string, userID: string): Promise<UserInfo | null> {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    var url = URL_ORGANIZATIONS + "/" + organizationID + "/users/" + userID;

    var userInfo: UserInfo | null = null;

    await axios
      .get<UserInfo>(url, config)
      .then((response) => {
        userInfo = response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    return userInfo;
  }
}
