import { useDispatch, useSelector } from "react-redux";
import { addCachedAsset_Small, selectCachedAsset, selectCachedAssets_small } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const useSmallAssetPreview = (assetId: string | undefined) => {
  const cachedAsset = selectCachedAsset(useSelector(selectCachedAssets_small), assetId);
  const previewUrl = cachedAsset?.variants[0]?.downloadUri;
  const assetName = cachedAsset?.name;

  var orgId = useSelector(selectCurrentOrganization)?.id;
  var token = useSelector(selectToken);
  const dispatch = useDispatch();

  const fetchAsset = async () => {
    if (!orgId || !assetId) return;

    var asset = await AssetService.GetAssetByGuid(orgId, token, assetId, false, "small");

    if (asset) {
      dispatch(addCachedAsset_Small(asset));
    }
  };

  const getAssetPreview = () => {
    if (!previewUrl) {
      fetchAsset();
    }

    return previewUrl;
  };

  const getAssetName = () => {
    if (!assetName) {
      fetchAsset();
    }
    return assetName;
  };

  return { getAssetPreview, getAssetName } as const;
};
