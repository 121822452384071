import { Check, TuneOutlined } from "@mui/icons-material";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { FilterType } from "../models/interactions/FilterType";

export interface PiFilterDropDownListItem {
  type: FilterType;
  count: number;
  checked: boolean;
}

export interface PiFilterDropDownList {
  filterDropDownListItems: PiFilterDropDownListItem[];
  onChecked: (type: FilterType, checked: boolean) => void;
}

export default function PiFilterDropdown(props: PiFilterDropDownList) {
  const { filterDropDownListItems, onChecked } = props;

  const filterItems = filterDropDownListItems.map(
    (listItem: PiFilterDropDownListItem) => {
      return (
        <DropdownMenu.CheckboxItem
          className="flex items-center cursor-pointer font-medium text-14 font-poppins text-creator-text-sub py-[5px] gap-[10px] focus:outline-none hover:bg-creator-primary-washed"
          onCheckedChange={() => {
            onChecked(listItem.type, !listItem.checked);
          }}
          onSelect={(event: any) => {
            // Prevent dropdown from closing when clicking on a list item
            event.preventDefault();
          }}
          key={listItem.type}
        >
          <div className="flex w-[20px] h-[20px] ml-[8px] items-center text-creator-text-sub">
            {listItem.checked ? (
              <Check className="h-4 min-w-[20px] max-w-[20px] " />
            ) : (
              ""
            )}
          </div>
          <div className="w-[135px] h-[20px] leading-5 [overflow:hidden] [whiteSpace:nowrap] [textOverflow:ellipsis]">
            {listItem.type.toString()}
          </div>
          <div className="flex min-w-[18px] h-[18px] bg-creator-primary-disabled rounded-full justify-center px-[5px] items-center box">
            {listItem.count}
          </div>
        </DropdownMenu.CheckboxItem>
      );
    }
  );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="min-w-[40px] max-w-[40px] text-white bg-creator-primary rounded-circle aspect-square hover:bg-creator-primary-hover focus:outline-none"
          aria-label="filter-list-trigger"
        >
          <TuneOutlined />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="z-50 min-w-[226px] bg-white rounded-md py-[10px] px-[8px] shadow-base border-[1px] border-interaction-border mt-[-14px]"
          align="end"
          alignOffset={-4}
        >
          {filterItems}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
