import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import PhotoRangeQuizPreview from "../../features/previews/photo_range_quiz/PhotoRangeQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {IWordQuiz} from "./WordQuiz";

export interface IPhotoRangeQuiz extends IFormQuiz {
    baseImage: string;
}

export const requiredPhotoRangeProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreatePhotoRangeQuiz(key: string): IPhotoRangeQuiz {
    return {
        id: uuidv4(),
        name: "Photo Range Quiz",
        type: InteractionType.PhotoRangeQuiz,
        title: key,
        baseImage: "",
        answers: [
            {
                id: uuidv4(),
                correct: false,
                text: "",
                assetId: "",
            },
            {
                id: uuidv4(),
                correct: false,
                text: "",
                assetId: "",
            },
        ],
        properties: requiredPhotoRangeProperties,
    };
}

export function CreatePhotoRangeQuizCopy(
    titleKey: string,
    baseImage: string,
    answers: IAnswer[],
    properties: InteractionProperty[] | undefined
): IPhotoRangeQuiz {
    return {
        id: uuidv4(),
        name: "Photo Range Quiz",
        type: InteractionType.PhotoRangeQuiz,
        title: titleKey,
        baseImage: baseImage,
        answers: answers,
        properties: properties,
    };
}

export function MigratePhotoRangeQuiz(from: number, to: number, interaction: IPhotoRangeQuiz): IPhotoRangeQuiz {
    if (interaction.properties === undefined) return { ...interaction, properties: requiredPhotoRangeProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredPhotoRangeProperties, interaction.properties);
    console.log("[PhotoRangeQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return { ...interaction, properties: newProperties };
}

export const photoRangeQuizInteraction = (): InteractionFactoryEntity => {
    const component = <PhotoRangeQuizPreview />;


    const create = (getKeys: (amount: number) => string[]): IPhotoRangeQuiz => {
        const keys = getKeys(1);
        return {
            id: uuidv4(),
            name: "Photo Range Quiz",
            type: InteractionType.PhotoRangeQuiz,
            title: keys[0],
            baseImage: "",
            answers: [
                {
                    id: uuidv4(),
                    correct: false,
                    text: "",
                    assetId: "",
                },
                {
                    id: uuidv4(),
                    correct: false,
                    text: "",
                    assetId: "",
                },
            ],
            properties: requiredPhotoRangeProperties,
        };
    }
    const migrate = (from: number, to: number, interaction: Interaction): IPhotoRangeQuiz => {
        const quizData = interaction as IPhotoRangeQuiz;
        if (interaction.properties === undefined) return { ...quizData, properties: requiredPhotoRangeProperties };

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredPhotoRangeProperties, interaction.properties);
        console.log("[PhotoRangeQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return { ...quizData, properties: newProperties };
    }

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IPhotoRangeQuiz => {

        const formQuiz = interaction as IPhotoRangeQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 1);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);

        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.PhotoRangeQuiz,
            title: newKeys[newKeys.length - 1],
            baseImage: formQuiz.baseImage,
            answers: getAnswerArray(formQuiz.answers, newKeys),
            properties: formQuiz.properties,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IPhotoRangeQuiz;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");
        ValidationHelper.ValidateAssetId(converted.baseImage, warnings, "Base Image ");
        let hasCorrectAnswer = false;

        // validate all answers + assetIds + validate we have at least one answer
        for (let i = 0; i < converted.answers.length; i++) {
            const tempAnswer = converted.answers[i];
            ValidationHelper.ValidateAssetId(tempAnswer.assetId ?? "", warnings, `Answer ${i+1} asset`);
            if (tempAnswer.correct) hasCorrectAnswer = true;
        }

        if (!hasCorrectAnswer)
            warnings.push("No correct answer selected");

        return warnings;
    }
    
        const filter = (interaction: Interaction): string[] => {
        let results: string[] = [];

        const photoRangeQuiz = interaction as IPhotoRangeQuiz
        const answers = photoRangeQuiz.answers.map(item => item.text);

        results.push(interaction.name);
        results.push(photoRangeQuiz.title);
        answers.forEach(answer => { results.push(answer); });

        return results;
    }

    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    }
}
