import Interaction, {InteractionType} from "./Interaction";
import {GetNewGuid} from "../../helpers/GuidHelper";

export interface ITransition extends Interaction {
    text: string;
    assetId: string;
    color: {r: number, g: number, b: number, a: number}
}

export function CreateTransition(key: string): ITransition {
    return {
        id: GetNewGuid(),
        type: InteractionType.Transition,
        name: "Transition",
        color: {r: 0, g: 0, b: 0, a: 1},
        assetId: "",
        text: key,
    }
}