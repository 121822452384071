import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import PiBasicQuizPreview from "../../features/previews/PiBasicQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";

export const requiredMultiSelectGraphicsProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateMultiSelectGraphicsQuiz(keys: string[]): IFormQuiz {
  return {
    id: uuidv4(),
    name: "MultiSelect Graphics Quiz",
    type: InteractionType.MultiSelectGraphicsQuiz,
    title: keys[0],
    answers: [
      { id: uuidv4(), correct: true, text: keys[1] },
      { id: uuidv4(), correct: false, text: keys[2] },
    ],
    properties: requiredMultiSelectGraphicsProperties,
  };
}

export function CreateMultiSelectGraphicsQuizCopy(titleKey: string, answers: IAnswer[], properties: InteractionProperty[] | undefined): IFormQuiz {
  return {
    id: uuidv4(),
    name: "MultiSelect Graphics Quiz",
    type: InteractionType.MultiSelectGraphicsQuiz,
    title: titleKey,
    answers: answers,
    properties: properties,
  };
}

export function MigrateMultiselectGraphicsQuiz(from: number, to: number, interaction: IFormQuiz): IFormQuiz {
  if (interaction.properties === undefined) return { ...interaction, properties: requiredMultiSelectGraphicsProperties };

  var newProperties: InteractionProperty[] = GetMigratedProperties(requiredMultiSelectGraphicsProperties, interaction.properties);
  console.log("[MultiSelectGraphicsQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

  return { ...interaction, properties: newProperties };
}

export const multiSelectGraphicsQuizInteraction = (): InteractionFactoryEntity => {
  const component = <PiBasicQuizPreview multipleCorrectAnswers displayGraphics />;

  const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
    const keys = getKeys(3);

    return {
      id: uuidv4(),
      name: "MultiSelect Graphics Quiz",
      type: InteractionType.MultiSelectGraphicsQuiz,
      title: keys[0],
      answers: [
        { id: uuidv4(), correct: true, text: keys[1], assetId: "" },
        { id: uuidv4(), correct: false, text: keys[2], assetId: "" },
      ],
      properties: requiredMultiSelectGraphicsProperties,
    };
  };

  const migrate = (from: number, to: number, interaction: Interaction): IFormQuiz => {
    const quizData = interaction as IFormQuiz; // need to cast

    if (interaction.properties === undefined) return { ...quizData, properties: requiredMultiSelectGraphicsProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredMultiSelectGraphicsProperties, interaction.properties);
    console.log("[MultiSelectGraphicsQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return { ...quizData, properties: newProperties };
  };

  const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IFormQuiz => {
    const formQuiz = interaction as IFormQuiz;
    const newKeys = getKeys(formQuiz.answers.length + 1);
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.MultiSelectGraphicsQuiz,
      title: newKeys[newKeys.length - 1],
      answers: getAnswerArray(formQuiz.answers, newKeys),
      properties: formQuiz.properties,
    };
  }

  const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
    const converted = interaction as IFormQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    let hasCorrectAnswer = false;

    // validate all answers + assetIds + validate we have at least one answer
    for (let i = 0; i < converted.answers.length; i++) {
      const tempAnswer = converted.answers[i];
      ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer ${i + 1}`);
      ValidationHelper.ValidateAssetId(tempAnswer.assetId ?? "", warnings, `Answer ${i+1} asset`);
      if (tempAnswer.correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer)
      warnings.push("No correct answer selected");

    return warnings;
  }
  
    const filter = (interaction: Interaction): string[] => {
    let results: string[] = [];

    const formQuiz = interaction as IFormQuiz;
    const answers = formQuiz.answers.map(item => item.text);

    results.push(interaction.name);
    results.push(formQuiz.title);
    answers.forEach(answer => { results.push(answer); });

    return results;
  }

  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};


