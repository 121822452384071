import React, { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../app/slices/TrainingSlice";

import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { PdfPopup } from "../../models/interactions/PdfPopup";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import "./ImagePopupPreview.css";
import PiInteractionAssetPreview from "./components/PiInteractionAssetPreview";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";
import PiPlayButtonPreview from "./components/PiPlayButtonPreview";
import PiPopupWindowPreview from "./components/PiPopupWindowPreview";

export default function PdfPopupPreview() {
  const pdfPopupData = useSelector(selectSelectedElement) as PdfPopup;
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onAssetSelect = (asset: AssetDetailsDTO) => {
    const updatedPdfPopup = { ...pdfPopupData };
    updatedPdfPopup.assetId = asset.assetId;
    dispatch(updateSelectedInteraction(updatedPdfPopup));
  };

  const title = <PiInteractionTitlePreview value={getValue(pdfPopupData.title)} onChange={(e) => changeValue(pdfPopupData.title, e)} placeholder="Pdf Popup" />;
  const body = <PiInteractionAssetPreview assetType={AssetKey.Pdf} assetId={pdfPopupData.assetId} onAssetSelect={onAssetSelect} />;
  return (
    <div>
      <PiPopupWindowPreview title={title} body={body} />
    </div>
  );
}
