import {useDispatch, useSelector} from "react-redux";
import {
    clearCachedLeafletData,
    initializeEnvironment,
    registerTranslatedEnvironmentContent,
    selectCurrentEnvironmentId,
    selectTranslatedEnvironmentContent,
    setEnvironmentMetadata
} from "../app/slices/EnvironmentSlice";
import {setInteractionPickerContext} from "../app/slices/InteractionPickerSlice";
import {initializeCharacterPresets, initializeOutfits} from "../app/slices/MetadataSlice";
import {loadActors, loadPropPresets, setTakes, TrainingType} from "../app/slices/TrainingSlice";
import useProjectMigration3D from "../features/project_migration/ProjectMigration3DHook";
import {Actor} from "../models/Actor";
import {CharacterPreset} from "../models/CharacterPreset";
import {Outfit} from "../models/Outfit";
import Take3D from "../models/Take3D";
import {Training} from "../models/Training";
import {EnvironmentService} from "../services/EnvironmentService";
import {MetadataService} from "../services/MetadataService";
import {Prop} from "../models/Prop";
import {selectCurrentOrganization} from "../app/slices/UserSlice";
import {selectToken} from "../app/slices/AuthenticationSlice";
import {PropService} from "../services/PropService";
import {UsedProp} from "../models/UsedProp";
import {addUsedProps} from "../app/slices/PropLibrarySlice";
import UsePropLibrary from "../features/prop_library/PropLibrary.hooks";
import useValidation from "../features/validation/Validation.hooks";

export default function useLoadTraining3DContent() {
    const dispatch = useDispatch();
    const targetVersion = Number(process.env.REACT_APP_PLAYIT_TRAINING_VERSION);
    const {migrateProject} = useProjectMigration3D();

    const cachedEnvironmentContent = useSelector(selectTranslatedEnvironmentContent);
    const currentEnvironmentId = useSelector(selectCurrentEnvironmentId);


    const {loadCategories, loadInteractions} = UsePropLibrary();
    const {validateArrayOfTakes} = useValidation();

    const loadTraining3DContent = async (training: Training, token: string, orgId: string) => {
        var envId = training.metadata?.["environmentId"];
        if (!envId) return; // something must be wrong

        // clear leaflet cache
        if (envId !== currentEnvironmentId) {
            dispatch(clearCachedLeafletData());
        }

        // load prop categories
        await loadCategories();
        // load prop interactions
        await loadInteractions();

        const environmentContent = await getEnvironmentContent(orgId, token, envId);

        if (!environmentContent) return;

        var content = await EnvironmentService.GetEnvironmentContent(environmentContent.metadataUri);

        if (!content) return;

        // environment vars
        dispatch(initializeEnvironment({
            id: environmentContent.id,
            name: environmentContent.name,
            floorplanUri: environmentContent.floorplanUri,
            gameEnvironment: environmentContent.gameEnvironment
        }));
        dispatch(setEnvironmentMetadata(content.take_positions));

        // loading outfits
        var outfits: Outfit[] = await MetadataService.GetOutfits();
        dispatch(initializeOutfits(outfits));

        // loading character presets
        var presets: CharacterPreset[] = await MetadataService.GetCharacterPresets();
        dispatch(initializeCharacterPresets(presets));

        // actors
        var actors: Actor[] = training.metadata?.["actors"];
        dispatch(loadActors(actors ?? []));

        // prop presets
        var props: Prop[] = training.metadata?.["props"];
        dispatch(loadPropPresets(props ?? []));

        // load used prop content from the backend
        await loadUsedPropsContent(props, orgId, token);

        // takes
        var takes: Take3D[] = training.takes as Take3D[];

        // migration
        if (training.version < targetVersion) {
            takes = migrateProject(training.version, targetVersion, takes, actors);
        }

        // set takes
        dispatch(setTakes(takes ?? []));

        // interaction picker context
        dispatch(setInteractionPickerContext(TrainingType.Training3D));
    };

    const getEnvironmentContent = async (orgId: string, token: string, envId: string) => {
        if (cachedEnvironmentContent[envId] !== undefined) {
            return cachedEnvironmentContent[envId];
        } else {
            const environmentContent = await EnvironmentService.GetTranslatedEnvironment(orgId, token, envId, "en", "US");

            if (environmentContent)
                dispatch(registerTranslatedEnvironmentContent(environmentContent));

            return environmentContent;
        }
    }


    const loadUsedPropsContent = async (props: Prop[], orgId: string, token: string) => {
        if (!props) return;

        const ids = props.map(prop => prop.propId);
        const usedProps: UsedProp[] = [];

        for (let i = 0; i < ids.length; i++) {
            const content = await PropService.GetPropContent(orgId, token, ids[i]);
            if (!content) continue;
            const metadata = await PropService.GetPropMetadata(content.metadataUri);
            if (!metadata) continue;

            usedProps.push({propContent: content, metadata: metadata});
        }

        dispatch(addUsedProps(usedProps));
    }

    return {loadTraining3DContent} as const;
}
