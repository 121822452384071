import React from "react";
import { Outlet } from "react-router-dom";
import DashboardMenu from "./DashboardMenu";
import "./DashboardPage.css";

export default function Dashboard() {
  return (
    <div className="dashboardpage-root">
      <DashboardMenu />
      <Outlet />
    </div>
  );
}
