import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IConnectQuiz } from "../../../models/interactions/ConnectQuiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import ConnectQuizBody from "./ConnectQuizBody";
import "./ConnectQuizPreview.css";
interface ConnectQuizPreviewProps {
  displayGraphics?: boolean;
}
export default function ConnectQuizPreview(props: ConnectQuizPreviewProps) {
  const quiz = useSelector(selectSelectedElement) as IConnectQuiz;
  const { displayGraphics } = { ...props };

  const { getValue, changeValue } = useTrainingLocalization();
  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Connect Quiz Title" showStarIcons />;

  const body = <ConnectQuizBody displayGraphics={displayGraphics} />;
  return <PiPopupWindowPreview title={title} body={body} />;
}
