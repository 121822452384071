import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTrainingName} from "../../app/slices/TrainingSlice";
import { useDebouncedCallback } from "use-debounce";
import { UpdateTrainingName } from "../../hooks/UpdateTrainingNameHook";
import "./TrainingActionbarTitle.css";

export default function TrainingActionbarTitle() {
  const trainingName = useSelector(selectTrainingName);
  const [inputValue, setInputValue] = useState<string>(trainingName);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => { setInputValue(trainingName); }, [trainingName]);

  const [updateTrainingName] = UpdateTrainingName();

  //Renaming should end when out of focus if pressing enter
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === "NumpadEnter") {
      inputRef.current?.blur();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debouncedEditTrainingName(e.target.value);
  };

  //Debounce for renaming training
  const debouncedEditTrainingName = useDebouncedCallback((value: string) => {
    updateTrainingName(value)
  }, 500);

  return (
      <input className="trainingactionbartitle-input trainingactionbartitle-root"
        ref={inputRef}
        value={inputValue}
        onBlur={() => inputRef.current?.blur()}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
      />
  );
}
