import ShuffleIcon from "@mui/icons-material/Shuffle";
import { Actor, ProfilePictures } from "../models/Actor";

export interface PiButtonSelectorProps {
  imagePaths?: string[];
  icons?: JSX.Element[];
  selectedIndex: number;
  onButtonClicked: (index: number) => void;
  square?: boolean;
  allowShuffle?: boolean;
  customIcon?: React.ElementType | null;
}

export default function PiButtonSelector(props: PiButtonSelectorProps) {
  const { imagePaths, icons, selectedIndex, onButtonClicked, square, allowShuffle, customIcon: Icon } = { ...props };

  const getRoundImageView = (selected: Boolean) => {
    return "relative flex flex-col w-[46px] h-[46px] border-2 rounded-circle items-center justify-center bg-creator-primary-background cursor-pointer " + (selected ? " border-creator-primary" +
        " shadow-base " : " border-creator-border ");
  };

  const getSquareImageView = (selected: Boolean) => {
    return "relative flex flex-col w-[100px] h-[100px] border-2 rounded-5 items-center justify-center bg-white cursor-pointer " + (selected ? " border-creator-primary shadow-base " : " border-creator-border ");
  };


  const shuffleButton = (
    <div className={square
      ? getSquareImageView(selectedIndex === -1)
      : getRoundImageView(selectedIndex === -1)}
      onClick={() => onButtonClicked(-1)}>
      <div className={" flex cursor-pointer text-[24px] justify-center items-center " + (selectedIndex === -1 ? "text-creator-primary" : "text-creator-text-sub")}>
        <ShuffleIcon color="inherit" fontSize="inherit" />
      </div>
      {selectedIndex === -1 && <div className="absolute bottom-[-12px] w-[51px] max-h-[4px] min-h-[4px] bg-creator-primary" />}
    </div>
  );

  const customButton = (
    <div className={square
      ? getSquareImageView(selectedIndex === -1)
      : getRoundImageView(selectedIndex === -1)}
      onClick={() => onButtonClicked(-1)}>
      <div className={" flex cursor-pointer bg-creator-primary-background text-[24px] justify-center items-center " + (selectedIndex === -1 ? "text-creator-primary" : "text-creator-text-sub")}>
        {Icon && <Icon />}
      </div>
      {selectedIndex === -1 && <div className="absolute bottom-[-12px] w-[51px] max-h-[4px] min-h-[4px] bg-creator-primary" />}
    </div>
  );

  const imageViews = imagePaths?.map((imagePath, index) => {
    var isSelected = selectedIndex === index;
    return (
      <div key={index} className=
        {square
          ? getSquareImageView(isSelected)
          : getRoundImageView(isSelected)}
        onClick={() => onButtonClicked(index)}>
        <img className="cursor-pointer object-contain w-full h-full" src={imagePath} alt={imagePath} />
        {isSelected && <div className="absolute bottom-[-12px] w-[51px] max-h-[4px] min-h-[4px] bg-creator-primary" />}
      </div>
    );
  });

  const iconViews = icons?.map((icon, index) => {
    var isSelected = selectedIndex === index;
    return (
      <div key={index} className=
        {square
          ? getSquareImageView(isSelected)
          : getRoundImageView(isSelected)}
        onClick={() => onButtonClicked(index)}>
        <div className={isSelected ? "text-creator-primary" : "text-creator-text-sub"} > {icon}</div>
        {isSelected && <div className="absolute bottom-[-12px] w-[51px] max-h-[4px] min-h-[4px] bg-creator-primary" />}
      </div >
    );
  });

  const views = icons ? iconViews : imageViews;

  return (
    <div className={"relative flex flex-wrap gap-[22px] py-[6px]"}>
      {Icon && customButton}
      {allowShuffle && shuffleButton}
      {views}
    </div>
  );
}

export interface PiButtonActorSelectorProps {
  actors: Actor[];
  selectedActorId: string;
  onButtonClicked: (npcId: string) => void;
  square?: boolean;
}

export function PiButtonActorSelector(props: PiButtonActorSelectorProps) {
  const { actors, selectedActorId, onButtonClicked } = { ...props };

  const buttons = actors.map((actor, index) => {
    return (
      <div
        key={index}
        className={selectedActorId !== actor.id ? "pibuttonselector-button-npcs" : "pibuttonselector-button-npcs pibuttonselector-button-selected"}
        onClick={() => onButtonClicked(actor.id)}
      >
        {actor.id !== "player" && <img src={ProfilePictures[actor.profilePicture]} alt={"pfp"} />}
        {actor.id === "player" && <div className="pibuttonselector-button-player">P</div>}
        {selectedActorId === actor.id && <div className="pibuttonselector-button-selected-indicator" />}
        {selectedActorId !== actor.id && <div className="pibuttonselector-button-unselected-indicator" />}
      </div>
    );
  });

  return (
    <div className="pibuttonselector-root">
      <div className="pibuttonselector-content">{buttons}</div>
    </div>
  );
}
