import { LinearProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectAvailableLanguages } from "../../app/slices/TrainingLocalizationSlice";
import { AssetKey, AssetVariantDTO } from "../../dto/AssetDTO";
import { ReactComponent as FileIcon } from "../file_explorer/assets/file.svg";
import "./VariantCard.css";

interface VariantCardProps {
  variant: AssetVariantDTO;
  assetKey: AssetKey;
  onClick: () => void;
  isSelected: boolean;
}

export default function VariantCard(props: VariantCardProps) {
  const { t } = useTranslation();
  const { variant, assetKey, onClick, isSelected } = { ...props };
  const langFull = useSelector(selectAvailableLanguages).find((lang) => lang.shortcode === variant.variant)?.full;

  const getPreview = () => {
    if (variant.id === "loading") {
      return (
        <div className="variantcard-preview-icon">
          <FileIcon className="variantcard-loading-icon" />
        </div>
      );
    } else {
      return (
        <div className="variantcard-preview-image">
          <img src={variant.downloadUri} />
        </div>
      );
    }
  };

  const rootClasses = () => {
    var classes = "variantcard-root";

    if (isSelected) classes += " variantcard-selected";
    if (variant.id === "loading") classes += " variantcard-loading";

    return classes;
  };

  const loadingBar = (
    <div className="variantcard-progress">
      <LinearProgress />
    </div>
  );

  const variantName = langFull ?? (variant.variant === "default" ? t("default") : t("no_language_set"));

  return (
    <div className={rootClasses()} onClick={onClick}>
      <div className="variantcard-preview">{getPreview()}</div>
      <div className="variantcard-info">
        <div className="variantcard-info-text">{variantName}</div>
        {variant.id === "loading" && loadingBar}
      </div>
    </div>
  );
}
