import Interaction from "../../../models/interactions/Interaction";
import { InteractionProperty } from "../../../models/InteractionProperty";

export function FindInteractionPropertyIndex(interaction: Interaction, property: InteractionProperty): number {
  return interaction.properties!.findIndex((p) => p.id === property.id);
}

export function UpdatePropertyOfInteraction(interaction: Interaction, property: InteractionProperty): Interaction {
  const properties = interaction.properties!;
  const pi = FindInteractionPropertyIndex(interaction, property);
  if (pi !== -1) {
    properties[pi] = property;
  }
  return { ...interaction, properties: properties };
}
