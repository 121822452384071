import {v4 as uuidv4} from "uuid";
import Interaction, {InteractionType} from "../../models/interactions/Interaction";
import {IAnswer, IFormQuiz} from "../../models/Quiz";
import {useTrainingLocalization} from "../trainingLocalization/TrainingLocalizationHooks";
import {getInteractionFromFactory} from "../interaction_factory/InteractionFactory";

export const useCopyInteraction = () => {
    const {getNewKey, GetArrayOfKeys, changeValue, getValue} = useTrainingLocalization();

    //Need to get an array of keys where applicable, to avoid race conditions
    const getInteractionCopy = (originalInteraction: Interaction): Interaction => {
        const factoryResult = getInteractionFromFactory(originalInteraction.type);

        //return factoryResult ? factoryResult.Migrate(from, to, interaction) : interaction;
        
        return factoryResult && factoryResult.Copy ? factoryResult.Copy(originalInteraction, GetArrayOfKeys, replaceKey, getAnswerArray) : originalInteraction;
        
        // const type: InteractionType = originalInteraction.type;
        // switch (type) {
        //     case InteractionType.MessagePopup:
        //         return CreateMessagePopup(replaceKey((originalInteraction as MessagePopup).message, getNewKey()));
        //     case InteractionType.LisaMessage:
        //         return CreateLisaMessage(replaceKey((originalInteraction as LisaMessage).message, getNewKey()));
        //     case InteractionType.ImagePopup:
        //         const imagePopup: ImagePopup = originalInteraction as ImagePopup;
        //         return CreateImagePopup(replaceKey(imagePopup.title, getNewKey()), imagePopup.assetId);
        //     case InteractionType.VideoPopup:
        //         const videoPopup: VideoPopup = originalInteraction as VideoPopup;
        //         return CreateVideoPopup(replaceKey(videoPopup.title, getNewKey()), videoPopup.assetId);
        //     case InteractionType.PdfPopup:
        //         const pdfPopup: PdfPopup = originalInteraction as PdfPopup;
        //         return CreatePdfPopup(replaceKey(pdfPopup.title, getNewKey()), pdfPopup.assetId);
        //     case InteractionType.MultipleChoiceQuiz:
        //         const mcQuiz: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysMCQuiz = GetArrayOfKeys(mcQuiz.answers.length + 1);
        //         return CreateMultipleChoiceQuizCopy(replaceKey(mcQuiz.title, newKeysMCQuiz[0]), getAnswerArray(mcQuiz.answers, newKeysMCQuiz.slice(1)), mcQuiz.properties);
        //     case InteractionType.MultiselectQuiz:
        //         const msQuiz: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysMSQuiz = GetArrayOfKeys(msQuiz.answers.length + 1);
        //         return CreateMultiSelectQuizCopy(replaceKey(msQuiz.title, newKeysMSQuiz[0]), getAnswerArray(msQuiz.answers, newKeysMSQuiz.slice(1)), msQuiz.properties);
        //     case InteractionType.MultipleChoiceGraphicsQuiz:
        //         const mcQuizGraphics: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysMCQuizGraphics = GetArrayOfKeys(mcQuizGraphics.answers.length + 1);
        //
        //         return CreateMultipleChoiceGraphicsQuizCopy(replaceKey(mcQuizGraphics.title, newKeysMCQuizGraphics[0]), getAnswerArray(mcQuizGraphics.answers, newKeysMCQuizGraphics.slice(1)), mcQuizGraphics.properties);
        //     case InteractionType.MultiSelectGraphicsQuiz:
        //         const msQuizGraphics: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysMSQuizGraphics = GetArrayOfKeys(msQuizGraphics.answers.length + 1);
        //
        //         return CreateMultiSelectGraphicsQuizCopy(replaceKey(msQuizGraphics.title, newKeysMSQuizGraphics[0]), getAnswerArray(msQuizGraphics.answers, newKeysMSQuizGraphics.slice(1)), msQuizGraphics.properties);
        //     case InteractionType.OrderQuiz:
        //         const orderQuiz: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysOrderQuiz = GetArrayOfKeys(orderQuiz.answers.length + 1);
        //         return CreateOrderQuizCopy(replaceKey(orderQuiz.title, newKeysOrderQuiz[0]), getAnswerArray(orderQuiz.answers, newKeysOrderQuiz.slice(1)), orderQuiz.properties);
        //     case InteractionType.OrderGraphicsQuiz:
        //         const orderQuizGraphics: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysOrderQuizGraphics = GetArrayOfKeys(orderQuizGraphics.answers.length + 1);
        //         return CreateMultipleChoiceGraphicsQuizCopy(replaceKey(orderQuizGraphics.title, newKeysOrderQuizGraphics[0]), getAnswerArray(orderQuizGraphics.answers, newKeysOrderQuizGraphics.slice(1)), orderQuizGraphics.properties);
        //     case InteractionType.ConnectQuiz:
        //         const connectQuiz = originalInteraction as IConnectQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysConnectQuiz = GetArrayOfKeys(connectQuiz.answersA.length + connectQuiz.answersB.length + 1);
        //
        //         return CreateConnectQuizCopy(
        //             replaceKey(connectQuiz.title, newKeysConnectQuiz[0]),
        //             getAnswerArray(connectQuiz.answersA, newKeysConnectQuiz.slice(1, connectQuiz.answersA.length + 1)),
        //             getAnswerArray(connectQuiz.answersB, newKeysConnectQuiz.slice(connectQuiz.answersA.length + 1)),
        //             connectQuiz.connections,
        //             connectQuiz.properties);
        //     case InteractionType.ConnectGraphicsQuiz:
        //         const connectGraphicsQuiz = originalInteraction as IConnectQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysConnectGraphicsQuiz = GetArrayOfKeys(connectGraphicsQuiz.answersA.length + connectGraphicsQuiz.answersB.length + 1);
        //
        //         return CreateConnectGraphicsQuizCopy(
        //             replaceKey(connectGraphicsQuiz.title, newKeysConnectGraphicsQuiz[0]),
        //             getAnswerArray(connectGraphicsQuiz.answersA, newKeysConnectGraphicsQuiz.slice(1, connectGraphicsQuiz.answersA.length + 1)),
        //             getAnswerArray(connectGraphicsQuiz.answersB, newKeysConnectGraphicsQuiz.slice(connectGraphicsQuiz.answersA.length + 1)),
        //             connectGraphicsQuiz.connections,
        //             connectGraphicsQuiz.properties);
        //     case InteractionType.Marker:
        //         return CreateMarker();
        //     case InteractionType.ScrabbleQuiz:
        //         const scrabbleQuiz: IFormQuiz = originalInteraction as IFormQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysScrabbleQuiz = GetArrayOfKeys(scrabbleQuiz.answers.length + 1);
        //         return CreateScrabbleQuizCopy(replaceKey(scrabbleQuiz.title, newKeysScrabbleQuiz[0]), getAnswerArray(scrabbleQuiz.answers, newKeysScrabbleQuiz.slice(1)), scrabbleQuiz.properties);
        //     case InteractionType.WordSpinnerQuiz:
        //         const wordSpinner = originalInteraction as IWordSpinnerQuiz;
        //         //extra keys for variables => race conditions so I need to get all new keys at once.
        //         const newKeysWordSpinner = GetArrayOfKeys(wordSpinner.answers.length + 5);
        //         const titleWordSpinner = replaceKey(wordSpinner.title, newKeysWordSpinner[0]);
        //         const part1WordSpinner = replaceKey(wordSpinner.sentencePart1, newKeysWordSpinner[1]);
        //         const part2WordSpinner = replaceKey(wordSpinner.sentencePart2, newKeysWordSpinner[2]);
        //         const formattedWordSpinner = replaceKey(wordSpinner.formattedSentence, newKeysWordSpinner[3]);
        //         const rawWordSpinner = replaceKey(wordSpinner.rawSentence, newKeysWordSpinner[4]);
        //         const answersWordSpinner = getAnswerArray(wordSpinner.answers, newKeysWordSpinner.slice(5));
        //
        //         return CreateWordspinnerQuizCopy(
        //             titleWordSpinner,
        //             part1WordSpinner,
        //             part2WordSpinner,
        //             formattedWordSpinner,
        //             rawWordSpinner,
        //             wordSpinner.correctIndex,
        //             answersWordSpinner,
        //             wordSpinner.properties);
        //     case InteractionType.ClickRegion:
        //         return CreateClickRegion();
        //     case InteractionType.ClickRegionOrderQuiz:
        //         return CreateClickRegionOrderQuiz(replaceKey((originalInteraction as IClickRegionOrderQuiz).title, getNewKey()));
        //     case InteractionType.ClickRegionMultiSelectQuiz:
        //         return CreateClickRegionMultiSelectQuiz(replaceKey((originalInteraction as IClickRegionMultiSelectQuiz).title, getNewKey()));
        //     case InteractionType.WordQuiz:
        //         const wordQuiz = originalInteraction as IWordQuiz;
        //         //Two extra for the title and formattedsentence => race conditions so I need to get all new keys at once.
        //         const newKeysWordQuiz: string[] = GetArrayOfKeys(wordQuiz.answers.length + 2);
        //
        //         const title = replaceKey(wordQuiz.title, newKeysWordQuiz[0]);
        //         const formattedSentence = replaceKey(wordQuiz.formattedSentence, newKeysWordQuiz[1]);
        //         const answers = getAnswerArray(wordQuiz.answers, newKeysWordQuiz.slice(2))
        //
        //         return CreateWordQuizCopy(title, formattedSentence, answers, wordQuiz.properties);
        //     case InteractionType.PhotoRangeQuiz:
        //         const photoQuiz = originalInteraction as IPhotoRangeQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysPhotoQuiz = GetArrayOfKeys(photoQuiz.answers.length + 1);
        //         return CreatePhotoRangeQuizCopy(
        //             replaceKey(photoQuiz.title, newKeysPhotoQuiz[0]),
        //             photoQuiz.baseImage,
        //             getAnswerArray(photoQuiz.answers, newKeysPhotoQuiz.slice(1)),
        //             photoQuiz.properties);
        //     case InteractionType.AmbientAudio:
        //         return CreateAmbientAudio((originalInteraction as IAmbientAudio).assetId);
        //     case InteractionType.AudioPopup:
        //         const audioPopup: IAudioPopup = originalInteraction as IAudioPopup;
        //         return CreateAudioPopup(replaceKey(audioPopup.title, getNewKey()), audioPopup.imageAssetId, audioPopup.audioAssetId);
        //     case InteractionType.Conversation:
        //         const convo = originalInteraction as IConversation;
        //         const numberOfKeys = convo.messages.filter((i) => i.type === InteractionType.MessageBubble).length;
        //         const newKeysConvo = GetArrayOfKeys(numberOfKeys);
        //         var localCounter = 0;
        //
        //         const newMessages: (IMessageBubble | IEmotionBubble)[] = convo.messages.map(message => {
        //             if (message.type === InteractionType.MessageBubble) {
        //                 const localBubble = message as IMessageBubble;
        //                 const newBubble = CreateMessageBubbleCopy(replaceKey(localBubble.text, newKeysConvo[localCounter]), localBubble);
        //                 ++localCounter;
        //                 return newBubble;
        //             } else if (message.type === InteractionType.EmotionBubble) {
        //                 return CreateEmotionBubbleCopy(message as IEmotionBubble);
        //             }
        //
        //             return message;
        //         });
        //         return CreateConversationCopy(newMessages, convo.properties);
        //     case InteractionType.MessageBubble:
        //         const messageBubble: IMessageBubble = originalInteraction as IMessageBubble;
        //         return CreateMessageBubbleCopy(replaceKey(messageBubble.text, getNewKey()), messageBubble);
        //     case InteractionType.EmotionBubble:
        //         return CreateEmotionBubbleCopy(originalInteraction as IEmotionBubble);
        //     case InteractionType.DialogQuiz:
        //         const dialogQuiz = originalInteraction as IDialogQuiz;
        //         //One extra for the title => race conditions so I need to get all new keys at once.
        //         const newKeysDialogQuiz = GetArrayOfKeys(dialogQuiz.answers.length + 1);
        //         return CreateDialogQuizCopy(replaceKey(dialogQuiz.title, newKeysDialogQuiz[0]), getAnswerArray(dialogQuiz.answers, newKeysDialogQuiz.splice(1)), dialogQuiz.playerGuid);
        //     case InteractionType.Grab:
        //         const grabInteraction = originalInteraction as IGrab;
        //         return CreateGrab(grabInteraction.playerGuid, grabInteraction.propGuid);
        // }
        // return {id: uuidv4(), name: "No Type", type: InteractionType.Default};
    };

    const replaceKey = (originalKey: string, newKey: string): string => {
        changeValue(newKey, getValue(originalKey));
        return newKey;
    }

    const getAnswerArray = (originalAnswers: IAnswer[], newKeys: string[]): IAnswer[] => {
        const newAnswers: IAnswer[] = originalAnswers.map((answer, index) => {
            var newAnswer = {...answer, id: uuidv4(), text: newKeys[index]};
            changeValue(newAnswer.text, getValue(answer.text));
            return newAnswer;
        });

        return newAnswers;
    }

    return {getInteractionCopy} as const;
};
