import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { addNewTake } from "../../app/slices/TrainingSlice";
import { AssetDetailsDTO } from "../../dto/AssetDTO";
import { OnboardingContext } from "../onboarding/OnboardingContext";

export const useAddNewBubble = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { state, setState } = useContext(OnboardingContext);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const selectImageAsset = async (asset: AssetDetailsDTO) => {
    setIsDialogOpen(false);
    // add new bubble to training, assetid is id
    dispatch(addNewTake({ name: asset.name, assetId: asset.assetId }));

    // await delay(200);
    // // onboarding on first bubble
    // if (!OnboardingService.HasVisited("addFirstBubble")) {
    //   console.log("starting add bubble onboarding");
    //   setState((prevState) => ({
    //     ...prevState,
    //     steps: OnboardingService.GetSteps("addFirstBubble"),
    //     run: true,
    //     onboardingActive: true,
    //     context: "addFirstBubble",
    //   }));
    //   OnboardingService.SetVisited("addFirstBubble");
    // } else console.log("not starting add bubble onboarding");
  };

  return [selectImageAsset, isDialogOpen, setIsDialogOpen] as const;
};
