import AddIcon from "@mui/icons-material/Add";
import React, { ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSupportedFileTypes } from "../../app/slices/AssetsSlice";
import { useNewAssetUpload } from "../../hooks/UploadNewAssetHook";
import "./FileDropzone.css";

export default function FileDropzone() {
  const [dragActive, setDragActive] = React.useState(false);
  const { t } = useTranslation();
  const [handleFiles] = useNewAssetUpload();
  const inputRef = useRef<HTMLInputElement>(null);
  const supportedFileTypes = useSelector(selectSupportedFileTypes);

  // handle drag events
  const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      handleFiles(e.dataTransfer.files);
    }
  };

  const handleFileInput = function (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files?.length > 0) {
      handleFiles(e.target.files);
    }
  };

  const openFileExplorer = () => {
    inputRef.current?.click();
  };

  const classname = dragActive ? "filedropzone-root filedropzone-dragactive" : "filedropzone-root";

  return (
    <div className={classname} onDragEnter={handleDrag} onClick={openFileExplorer} id="filedropzone">
      <div className="filedropzone-content">
        <div>
          <div className="filedropzone-icon">
            <AddIcon />
          </div>
          <div className="filedropzone-title">{t("dropYourFilesHere")}</div>
          <div className="filedropzone-subtitle">
            <span>{t("browseFiles")}</span> {t("fromYourComputer")}
          </div>
        </div>
      </div>
      {dragActive && <div className="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}

      <input className="filedropzone-hide-input" ref={inputRef} type="file" onChange={handleFileInput} accept={supportedFileTypes.toString()} multiple />
    </div>
  );
}
