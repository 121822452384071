import { useDispatch, useSelector } from "react-redux";
import { setTrainingName, selectTrainingDescription } from "../app/slices/TrainingSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentTrainingId } from "../app/slices/ProjectsSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { TrainingService } from "../services/TrainingService";

export const UpdateTrainingName = () => {
  const organization = useSelector(selectCurrentOrganization);
  const currentTrainingId = useSelector(selectCurrentTrainingId);
  const trainingDescription = useSelector(selectTrainingDescription);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const updateCourse = (newName: string) => {
    if (!organization || !currentTrainingId) return;

    TrainingService.UpdateTrainingName(organization.id, currentTrainingId, token, newName, trainingDescription);

    dispatch(setTrainingName(newName));
  };

  return [updateCourse] as const;
};
