import {useDispatch, useSelector} from "react-redux";
import {selectToken} from "../../app/slices/AuthenticationSlice";
import {selectCurrentOrganization} from "../../app/slices/UserSlice";
import {useEffect, useState} from "react";
import {EnvironmentService} from "../../services/EnvironmentService";
import {
    initializeTranslatedEnvironments,
    selectIsTranslatedEnvironmentsInitialized,
    selectTranslatedEnvironments,
    selectTranslatedEnvironmentsTotalPages,
    updateTranslatedEnvironments
} from "../../app/slices/EnvironmentSlice";
import {AssetKey} from "../../dto/AssetDTO";
import {addPagedAssetsToFolder} from "../../app/slices/AssetsSlice";

export default function useTranslatedEnvironments() {

    const dispatch = useDispatch();
    const translatedEnvironments = useSelector(selectTranslatedEnvironments);

    const token = useSelector(selectToken);
    const currentOrganization = useSelector(selectCurrentOrganization);

    const isInitialized = useSelector(selectIsTranslatedEnvironmentsInitialized);
    const totalPages = useSelector(selectTranslatedEnvironmentsTotalPages);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const pageSize = 3;

    useEffect(() => {
        if (!isInitialized)
            initialize();
    }, []);

    const initialize = async () => {
        var result = await EnvironmentService.GetTranslatedEnvironments(currentOrganization!.id, token!, "en", "US", currentPage, pageSize, "name");

        //setTotalPages(result.totalPages);
        setTotalCount(result.totalCount);

        dispatch(initializeTranslatedEnvironments({totalPages: result.totalPages, totalCount: result.totalCount, firstPage: result.translatedEnvironments}));
    }

    const fetchPage = async (page: number) => {

        if (!currentOrganization || !token) return;

        // TODO: get lang + country code from the editor !
        var envs = await EnvironmentService.GetTranslatedEnvironments(currentOrganization.id, token, "en", "US", page, pageSize, "name");

        dispatch(updateTranslatedEnvironments({pageNumber: page, pageSize: pageSize, page: envs.translatedEnvironments}));
    }

    const loadNextPage = () => {
        // if at the end, don't do anything
        console.log("test " + currentPage + " " + totalPages);
        if (currentPage >= totalPages) return;
        console.log("test 2")

        if (translatedEnvironments[(currentPage) * pageSize].id === "loading") {
            fetchPage(currentPage + 1);
            setCurrentPage(currentPage + 1);
        }
    }

    const onRerender = async (amountOfColumns: number, rowStartIndex: number, rowStopIndex: number) => {
        var startIndex = amountOfColumns * rowStartIndex;
        var endIndex = amountOfColumns * rowStopIndex + (amountOfColumns - 1);
        var startPage = Math.floor(startIndex / pageSize);
        var endPage = Math.ceil(endIndex / pageSize);

        for (var s = startPage; s < endPage; s++) {
            if (translatedEnvironments[pageSize * s]?.id === "loading") {
                var result = await fetchPage(s + 1);
            }
        }
    };
    
    return {loadNextPage, onRerender};
}