import ThreeDRotationIcon from "@mui/icons-material/ThreeDRotation";

import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import {useSelector} from "react-redux";
import {LanguageData, selectAvailableLanguages} from "../../app/slices/TrainingLocalizationSlice";
import PiDropdown from "../../components/PiDropdown";
import PiInputfield from "../../components/PiInputField";
import EnvironmentCarousel from "./EnvironmentCarousel";
import "./New3DTraining.css";
import {AnimatePresence, motion} from "framer-motion";
import TranslatedEnvironmentView from "../translated_environments/TranslatedEnvironmentView";
import TranslatedEnvironmentSelector from "../translated_environments/TranslatedEnvironmentSelector";
import useTranslatedEnvironments from "../translated_environments/TranslatedEnvironments.hook";
import {selectIsTranslatedEnvironmentsInitialized, selectTranslatedEnvironments} from "../../app/slices/EnvironmentSlice";
import CloseIcon from "@mui/icons-material/Close";
import PiScrollArea from "../../components/PiScrollArea";


interface New3DTrainingProps {
    onContinueClick: (name: string, description: string, refLanguage: string, environmentId: string) => void;
    onCloseClick: () => void;
}

export default function New3DTraining(props: New3DTrainingProps) {
    const {onContinueClick, onCloseClick} = {...props};
    const {t} = useTranslation();
    const languages = useSelector(selectAvailableLanguages);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageData>();
    const [trainingName, setTrainingName] = useState<string>("");
    const [environmentId, setEnvironmentId] = useState<string>("");
    const {} = useTranslatedEnvironments();
    const initialized = useSelector(selectIsTranslatedEnvironmentsInitialized);
    const environments = useSelector(selectTranslatedEnvironments);

    useEffect(() => {
        setSelectedLanguage(languages.find((lang) => lang.shortcode === "en"));
    }, []);

    useEffect(() => {
        if (initialized && environments.length > 0)
            setEnvironmentId(environments[0].id);
    }, [initialized]);

    const logo = (
        <div className="new3dtraining-logo">
            <ThreeDRotationIcon fontSize="inherit" color="inherit"/>
        </div>
    );

    const title = (
        <div className={"mx-auto font-semibold text-[28px] text-center h-[64px] flex gap-x-2"}>
            <span className="new3dtraining-title-yellow my-auto">3D </span>
            <span className={"my-auto"}>Training</span>
        </div>
    );

    const name = (
        <div className="">
            <PiInputfield
                label={t("trainingName")}
                onChange={(e) => {
                    setTrainingName(e);
                }}
                placeholder={t("trainingName")}
            />
        </div>
    );

    const category = (
        <div className="">
            <PiDropdown selectedText={"General Safety"} options={[]} width="350px" label={t("category") ?? ""} info={t("category") ?? ""}/>
        </div>
    );

    const refLang = (
        <div className="">
            <PiDropdown
                selectedText={selectedLanguage?.full ?? ""}
                options={languages.map((lang) => {
                    return {
                        text: lang.full,
                        onClick: () => setSelectedLanguage(lang),
                    };
                })}
                width="350px"
                label={t("training_reference_language") ?? ""}
                info={t("training_reference_language_info") ?? ""}
            />
        </div>
    );

    const onSubmitClick = () => {
        onContinueClick(trainingName, "", selectedLanguage?.shortcode ?? "en", environmentId);
    };

    const submit = (
        <div className={"mt-auto flex h-[68px] border-t-[2px] px-[12px] box-border border-solid border-creator-primary-disabled"}>
            <div className="my-auto w-full">
                <Button variant="piBlue" onClick={onSubmitClick} disabled={environmentId === "" || trainingName === ""}>
                    {t("continue")}
                </Button>
            </div>
        </div>
    );

    const close = <div className={"absolute top-[8px] right-[16px] text-creator-text text-[24px] cursor-pointer"} onClick={onCloseClick}><CloseIcon fontSize={"inherit"} color={"inherit"}/></div>;

    const envSelector = <TranslatedEnvironmentSelector selectedEnvironmentId={environmentId} onClick={() => setIsEditingEnvironment(!editingEnvironment)}/>

    const [editingEnvironment, setIsEditingEnvironment] = useState<boolean>(false);
    const [finishedAnimating, setFinishedAnimating] = useState<boolean>(false);

    return (
        <div className={"w-full h-full relative flex justify-center gap-[16px]"}>
            <div className={"h-[90%] w-[450px] relative bg-white rounded-[5px] flex flex-col"}>
                <div className={"flex flex-col px-[20px] py-[16px] gap-y-[15px] piLg:gap-y-[30px] h-[90%]"}>
                    {title}
                    <PiScrollArea variant={"piCreatorBlue"}>
                        <div className={"px-[20px] flex flex-col gap-y-[15px] piLg:gap-y-[30px] overflow-hidden"}>
                            {name}
                            {category}
                            {refLang}
                            {envSelector}
                        </div>
                    </PiScrollArea>
                    {close}
                </div>
                {submit}

            </div>
            <AnimatePresence>
                {editingEnvironment &&
                    <motion.div className={"h-[90%] w-[70%] piXl:w-[50%] flex bg-white rounded-[5px] "}
                                initial={{opacity: 0, width: "0%"}}
                                animate={{opacity: 1, width: "60%"}}
                                exit={{opacity: 0, width: "0%"}}
                                onAnimationComplete={() => setFinishedAnimating(true)}
                                onAnimationStart={() => setFinishedAnimating(false)}
                                transition={{
                                    duration: 0.15,
                                    ease: "easeInOut"
                                }}>

                        <TranslatedEnvironmentView visible={editingEnvironment && finishedAnimating} onSelectEnvironment={setEnvironmentId} onClose={() => setIsEditingEnvironment(false)}/>
                    </motion.div>}
            </AnimatePresence>
        </div>
    );
}
