import { createTheme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    piLogin: true;
    piBlue: true;
    piRed: true;
    piGrey: true;
    piBlueOutlined: true;
    piBlueRound: true;
    piRedOutlined: true;
    piQuizAddButton: true;
  }
}

export const PiTheme = createTheme({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "piLogin" },
          style: {
            width: "100%",
            backgroundColor: "var(--pi-yellow)",
            borderRadius: 32,
            color: "white",
            height: "46px",
            fontSize: "11px",
            "&:hover": {
              background: "var(--pi-yellow-hover)",
            },
          },
        },
        {
          props: { variant: "piBlue" },
          style: {
            width: "100%",
            backgroundColor: "var(--pi-key-blue)",
            borderRadius: 26,
            color: "white",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textTransform: "inherit",
            border: "2px solid var(--pi-key-blue)",
            "&:hover": {
              background: "var(--pi-key-blue-hover)",
              border: "2px solid var(--pi-key-blue-hover)",
            },
            ":disabled": {
              backgroundColor: "var(--pi-disabled)",
              border: "2px solid var(--pi-disabled)",
              color: "white",
            },
          },
        },
        {
          props: { variant: "piRed" },
          style: {
            width: "100%",
            backgroundColor: "var(--pi-error)",
            borderRadius: 26,
            color: "white",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textTransform: "inherit",
            border: "2px solid var(--pi-error)",
            "&:hover": {
              background: "var(--pi-error-hover)",
              border: "2px solid var(--pi-error-hover)",
            },
            ":disabled": {
              backgroundColor: "var(--pi-disabled)",
              border: "none",
              color: "white",
            },
          },
        },
        {
          props: { variant: "piBlueRound" },
          style: {
            width: "42px",
            height: "42px",
            backgroundColor: "var(--pi-key-blue)",
            borderRadius: "100%",
            color: "white",
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "20px",
            fontFamily: "Poppins",
            padding: "none",
            margin: "none",
            textTransform: "inherit",
            "&:hover": {
              background: "var(--pi-key-blue-hover)",
            },
          },
        },
        {
          props: { variant: "piGrey" },
          style: {
            width: "100%",
            backgroundColor: "transparent",
            borderRadius: 26,
            color: "var(--pi-menu-text-color-1)",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textTransform: "inherit",
            "&:hover": {
              background: "transparent",
            },
          },
        },
        {
          props: { variant: "piBlueOutlined" },
          style: {
            backgroundColor: "white",
            borderRadius: 26,
            color: "var(--pi-key-blue)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textTransform: "inherit",
            border: "2px solid var(--pi-key-blue)",
            "&:hover": {
              background: "var(--pi-key-blue)",
              color: "white",
            },
            "&:disabled": {
              border: "2px solid var(--pi-disabled)",
            },
          },
        },
        {
          props: { variant: "piRedOutlined" },
          style: {
            backgroundColor: "white",
            borderRadius: 26,
            color: "var(--pi-error)",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textTransform: "inherit",
            border: "2px solid var(--pi-error)",
            "&:hover": {
              background: "var(--pi-error)",
              color: "white",
            },
          },
        },
        {
          props: { variant: "piQuizAddButton" },
          style: {
            width: "100%",
            height: "64px",
            backgroundColor: "var(--pi-game-blue-faded)",
            borderRadius: "10px",
            color: "var(--pi-game-blue)",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "20px",
            fontFamily: "Poppins",
            textAlign: "left",
            justifyContent: "flex-start",
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: "var(--pi-menu-text-color-1)",
          border: " solid 2px",
          borderColor: "var(--pi-tooltip-border)",
          borderRadius: "5px",
          fontSize: "13px",
          fontFamily: "Poppins",
          fontWeight: "500",
          lineHeight: "18px",
          textAlign: "center",
          boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.3)",
        },
        arrow: {
          "&:before": {
            border: "2px solid",
            borderColor: "var(--pi-tooltip-border)",
          },
          color: "white",
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          padding: 0,
          margin: 14,
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "white",
            transform: "translateX(14px)",
          },
        },
        thumb: {
          width: 16,
          height: 16,
        },
        track: {
          borderRadius: 20,
          boxSizing: "border-box",
          height: 20,
          // Controls default (unchecked) color for the track
          backgroundColor: "var(--pi-disabled)",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 1,
            backgroundColor: "var(--pi-key-blue)",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderBottom: 0,
          borderRadius: 0,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "var(--pi-progress-bar)",
          borderRadius: "10px",
        },
        colorPrimary: {
          backgroundColor: "var(--pi-game-blue-faded)",
          borderRadius: "10px",
          height: "8px",
        },
      },
    },
  },
});
