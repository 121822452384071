import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { registerCameraPreview } from "../app/slices/EnvironmentSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { EnvironmentService } from "../services/EnvironmentService";

export const useCameraPreview = (cameraId: string) => {
    const org = useSelector(selectCurrentOrganization);
    const token = useSelector(selectToken);

    const dispatch = useDispatch();

    const getCameraPreview = async (environmentId: string) => {
        if (!org) return;

        const uri = await EnvironmentService.GetCameraPreview(org.id, token, environmentId, cameraId);

        if (uri) {
            dispatch(registerCameraPreview({ cameraId, uri }));
        }
    };

    return [getCameraPreview] as const;
};
