import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedTake, updateSelectedTake } from "../../app/slices/TrainingSlice";
import PiCardContent from "../../components/PiCardContent";
import PiDialog, { DialogProps } from "../../components/PiDialog";
import PiInputfield from "../../components/PiInputField";
import PiSelectAssetButton from "../../components/PiSelectAssetButton";
import PiSwitch from "../../components/PiSwitch";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import Take360 from "../../models/Take360";
import "./EditBubbleDialog.css";
export default function EditBubbleDialog(props: DialogProps) {
  const { open, onClose } = { ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTake = useSelector(selectSelectedTake);

  const onChangeName = (newName: string) => {
    var updatedTake: Take360 = { ...selectedTake! };
    updatedTake.name = newName;
    dispatch(updateSelectedTake(updatedTake));
  };

  const onChangeAsset = (asset: AssetDetailsDTO) => {
    var updatedTake: Take360 = { ...selectedTake! };
    updatedTake.assetId = asset.assetId;
    dispatch(updateSelectedTake(updatedTake));
  };

  const onToggleAutomaticTransition = (checked: boolean) => {
    var updatedTake: Take360 = { ...selectedTake! };
    updatedTake.automaticTransition = checked;
    dispatch(updateSelectedTake(updatedTake));
  };
  const onToggleIs360Image = (checked: boolean) => {
    var updatedTake: Take360 = { ...selectedTake! };
    updatedTake.is360Image = checked;

    // disabled 360 view if switching to 2d
    updatedTake.display360 = false;

    dispatch(updateSelectedTake(updatedTake));
  };
  const body = (
    <PiCardContent titleL={t("edit_bubble_dialog.edit")} titleR={t("edit_bubble_dialog.bubble")} titleVariant="blue" submitText={t("edit_bubble_dialog.done")} onSubmit={onClose}>
      <div className="editbubble-dialog-body">
        <PiSelectAssetButton label={t("edit_bubble_dialog.bubble")} text="Change Bubble" assetType={AssetKey.Image} onAssetSelectCallback={onChangeAsset} />
        <PiInputfield label={t("edit_bubble_dialog.name")} placeholder={t("edit_bubble_dialog.name")} onChange={onChangeName} value={selectedTake?.name} />
        <div className="editbubble-dialog-switches">
          <PiSwitch label={t("edit_bubble_dialog.automatic_transition")} checked={selectedTake!.automaticTransition} onChange={onToggleAutomaticTransition} />
          <PiSwitch label={t("edit_bubble_dialog.360_image")} checked={selectedTake!.is360Image} onChange={onToggleIs360Image} />
        </div>
      </div>
    </PiCardContent>
  );

  return <PiDialog open={open} onClose={onClose} width="450px" height="544px" header={<div />} body={body} />;
}
