import React, { useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectIsPlaying,
  selectSelectedTake,
  setImageBounds,
  setViewportBounds,
} from "../../app/slices/TrainingSlice";
import "./TrainingBubbleViewport.css";

import { Viewer } from "@photo-sphere-viewer/core";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Pi360Viewer from "../../components/Pi360Viewer";
import { useAssetPreview } from "../../hooks/AssetPreviewHook";
import TrainingPreviewOverlay from "./TrainingPreviewOverlay";

export interface ImageBounds {
  x: number;
  y: number;
  width: number;
  height: number;
}

export default function TrainingBubbleViewport() {
  const dispatch = useDispatch();
  const imgRef = useRef<HTMLImageElement>(null);
  const ref360 = React.createRef<Viewer>();
  const isPlaying = useSelector(selectIsPlaying);
  const selectedTake = useSelector(selectSelectedTake);
  // const thumbnailUrl = selectAssetThumbnailUrlFromFolders(
  //   useSelector(selectAllFolders),
  //   selectedTake?.assetId
  // );
  const { getAssetPreview } = useAssetPreview(selectedTake?.assetId);
  const onWindowResize = () => {
    onLoad();
  };

  useEffect(() => {
    // 360 stuff
    if (ref360.current) {
      ref360.current.setPanorama(getAssetPreview());

      if (!selectedTake?.display360) {
        ref360.current?.hideError();
        ref360.current?.stopAnimation();
        ref360.current?.destroy();
      }
    }
  }, [selectedTake, ref360]);

  useEffect(() => {
    // window resize
    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  });

  // resize after stop playing -> the transition messes up the calculations
  useEffect(() => {
    if (!isPlaying) {
      const waitResize = async () => {
        await delay(150);
        onWindowResize();
      };

      waitResize();
    }
  }, [isPlaying]);

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  const onLoad = async () => {
    // get actual image size
    if (imgRef.current) {
      var previewUrl = getAssetPreview();
      let img = new Image();
      img.src = previewUrl === undefined ? "" : previewUrl!;
      img.onload = async () => {
        // black bars top/bottom
        var imgBounds: ImageBounds = { x: 0, y: 0, width: 0, height: 0 };
        var b = imgRef.current?.getBoundingClientRect();
        var arImage = 0;
        if (b) {
          if (
            (img.width > img.height &&
              img.width / img.height > b.width / b.height) ||
            (img.width <= img.height && b.height > b.width)
          ) {
            imgBounds.width = b.width;
            imgBounds.x = 0;

            //calculate height accordingly
            arImage = img.width / img.height;
            imgBounds.height = b.width / arImage;
            imgBounds.y = (b.height - imgBounds.height) * 0.5;
          } else if (
            img.width > img.height &&
            img.width / img.height <= b.width / b.height
          ) {
            imgBounds.height = b.height;
            imgBounds.y = 0;

            //calculate width accordingly
            arImage = img.width / img.height;
            imgBounds.width = b.height * arImage;
            imgBounds.x = (b.width - imgBounds.width) * 0.5;
          } else {
            if (b.height < b.width) {
              imgBounds.height = b.height;
              imgBounds.y = 0;

              //calculate width accordingly
              arImage = img.width / img.height;
              imgBounds.width = b.height / arImage;
              imgBounds.x = (b.width - imgBounds.width) * 0.5;
            }
          }

          dispatch(
            setViewportBounds({
              x: b.x,
              y: b.y,
              width: b.width,
              height: b.height,
            })
          );
          dispatch(setImageBounds(imgBounds));
        }
      };
    }
  };

  const viewer360 = <Pi360Viewer container="trainingbubbleviewport-root" />;

  const overlay = selectedTake?.display360 ? (
    <div></div>
  ) : (
    <TrainingPreviewOverlay />
  );

  const normalImage = (
    <img
      className="trainingbubbleviewport-img"
      src={getAssetPreview()}
      ref={imgRef}
      onLoad={onLoad}
      alt=""
    />
  );

  const content = selectedTake?.display360 ? viewer360 : normalImage;

  return (
    <div className="trainingbubbleviewport-root">
      {content}
      {overlay}
    </div>
  );
}
