import PiBasicQuizCheckboxV2 from "./PiQuizCheckboxV2";
import {IAnswer} from "../../../../models/Quiz";
import PiBasicQuizAnswerTextfieldV2 from "./PiBasicQuizAnswerTextfieldV2";
import PiDeleteIcon from "../../../../icons/PiDeleteIcon";

export type PiBasicQuizAnswerV2Props = {
    answer: IAnswer
    onAnswerChange: (newAnswer: IAnswer) => void;
    canDelete: boolean;
    onDelete: () => void;
    checkboxSquare?: boolean;
}

export default function PiBasicQuizAnswerV2(props: PiBasicQuizAnswerV2Props) {

    const {answer, onAnswerChange, canDelete, onDelete, checkboxSquare} = {...props};

    const changeAnswer = (field: keyof IAnswer, value: any) => {
        const newAnswer = {...answer, [field]: value};
        onAnswerChange(newAnswer);
    }

    return <div className={"w-full h-[42px] piXl:h-[68px] piLg:h-[58px] flex gap-[10px]"}>
        <div className={"my-auto"}>
            <PiBasicQuizCheckboxV2 checked={answer.correct} onChange={(checked) => changeAnswer("correct", checked)} square={checkboxSquare}/>
        </div>
        <div className={"w-full flex bg-interaction-softBackground border-b-2 border-solid border-interaction-primary px-[18px] hover:bg-interaction-primary-washed"}>
            <PiBasicQuizAnswerTextfieldV2 localizationKey={answer.text}/>
            {canDelete && <div className={"m-auto cursor-pointer"} onClick={onDelete}>
                <PiDeleteIcon width={24} height={24} className={"fill-interaction-false hover:fill-interaction-false-hover"}/>
            </div>}
        </div>
    </div>
}