import React, {useMemo} from "react";
import {InteractionProperty} from "../../models/InteractionProperty";
import PiSwitch from "./../PiSwitch";
import "./PiBooleanProperty.css";

export default function PiBooleanProperty(props: {
    interactionProperty: InteractionProperty;
    onChange: (interactionProperty: InteractionProperty) => void
}) {
    const onSwitchChange = (checked: boolean) => {
        props.onChange({...props.interactionProperty, value: checked});
    };

    const piSwitch = useMemo(() => {      
      return <PiSwitch checked={props.interactionProperty.value as boolean}
                label={props.interactionProperty.name}
                labelLocId={props.interactionProperty.locId}
                tooltipLocId={props.interactionProperty.tooltipLocId}
                onChange={onSwitchChange}/>
    }   , [props.interactionProperty]);

    return <div className="pi-boolean-property-root">{piSwitch}</div>;
}
