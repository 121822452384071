import {useDispatch} from "react-redux";
import {SelectedTab, setIsPropertiesColumnOpen, setSelectedTab} from "../app/slices/GlobalSlice";
import {resetIsDirty, setIsPlaying} from "../app/slices/TrainingSlice";
import {cleanupCurrentEnvironment} from "../app/slices/EnvironmentSlice";
import {clearLocalization} from "../app/slices/TrainingLocalizationSlice";

export default function useOnExitEditor() {
  const dispatch = useDispatch();

  const onExitEditorCleanup = () => {
    dispatch(setIsPropertiesColumnOpen(true));
    dispatch(setSelectedTab(SelectedTab.Timeline));
    dispatch(setIsPlaying({ isPlaying: false, data: "" }));
    dispatch(resetIsDirty());
    dispatch(cleanupCurrentEnvironment());
    dispatch(clearLocalization());
  };

  return { onExitEditorCleanup } as const;
}
