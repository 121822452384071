import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsTeleportAllowed,
  selectSelectedElementId,
  selectTakes360,
  setSelectedInteraction
} from "../../app/slices/TrainingSlice";
import Interaction from "../../models/interactions/Interaction";
import "./TeleportMarkerCard.css";

interface TeleportMarkerCardProps {
  interaction: Interaction;
}
export default function TeleportMarkerCard(props: TeleportMarkerCardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { interaction } = { ...props };
  const selectedElement = useSelector(selectSelectedElementId);
  const isTpAllowed = selectIsTeleportAllowed(
    useSelector(selectTakes360),
    interaction.id
  );

  const selectInteractionClick = () => {
    if (isTpAllowed) dispatch(setSelectedInteraction(interaction.id));
  };

  const getRootclasses = () => {
    if (!isTpAllowed) return "teleportmarkcard-root teleportmarkcard-disabled";

    if (selectedElement === interaction.id)
      return "teleportmarkcard-root teleportmarkcard-selected";

    return "teleportmarkcard-root";
  };

  const icon = <PinDropOutlinedIcon className="teleportmarkcard-icon" />;
  const name = (
    <span className="teleportmarkercard-name">{t("teleport_marker")}</span>
  );
  return (
    <div className={getRootclasses()} onClick={selectInteractionClick}>
      {icon}
      {name}
    </div>
  );
}
