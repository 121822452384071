import { useDispatch } from "react-redux";
import { setInteractionPickerContext } from "../app/slices/InteractionPickerSlice";
import { TrainingType, setTakes } from "../app/slices/TrainingSlice";
import useProjectMigration360 from "../features/project_migration/ProjectMigration360Hook";
import Take360 from "../models/Take360";
import { Training } from "../models/Training";

export default function useLoadTraining360Content() {
  const dispatch = useDispatch();
  const targetVersion = Number(process.env.REACT_APP_PLAYIT_TRAINING_VERSION);
  const { migrateProject } = useProjectMigration360();

  const loadTraining360Content = async (training: Training) => {
    var takes: Take360[] = training.takes as Take360[];

    // Migration if needed
    if (targetVersion > training.version) {
      takes = migrateProject(training.version, targetVersion, takes);
    }

    // takes
    if (takes.length > 0) {
      dispatch(setTakes(takes));
    } else {
      dispatch(setTakes([]));
    }

    // interaction picker context
    dispatch(setInteractionPickerContext(TrainingType.Training360));
  };

  return { loadTraining360Content } as const;
}
