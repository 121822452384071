import usePropHelper from "../../../hooks/PropHelper";
import {Prop} from "../../../models/Prop";
import {PiInteractionDropdown} from "../../../components/PiInteractionDropdown";
import React, {useMemo} from "react";
import {DropdownMenuPortal} from "@radix-ui/react-dropdown-menu";

export default function AttachPropDropdown(props: { onChange: (prop: Prop) => void, selectedPropId: string }) {
    const {onChange, selectedPropId} = {...props};
    const {getPropPresetsNotSpawnedInTake, getPropPresetByInstanceId} = usePropHelper();

    const options = getPropPresetsNotSpawnedInTake();

    const selectedPropName = useMemo(() => {
        const p = getPropPresetByInstanceId(selectedPropId);
        return p ? p.name : "No prop selected";
    }, [selectedPropId]);
    
    const dropdownItems = options.map(prop => {
        return <PiInteractionDropdown.Item key={prop.id} onClick={() => onChange(prop)}>
            <div className={"flex justify-center gap-4 w-full"}>
                <div className={"my-auto truncate"}>{prop.name}</div>
            </div>
        </PiInteractionDropdown.Item>;
    })

    const dropdown = <PiInteractionDropdown label={"Prop"}>
        <PiInteractionDropdown.Trigger>
            <div className={"flex gap-x-4 w-full"}>
                <div className={"my-auto truncate"}>
                    {selectedPropName}
                </div>
            </div>
        </PiInteractionDropdown.Trigger>
        {dropdownItems}
    </PiInteractionDropdown>

    return <>{dropdown}</>
}