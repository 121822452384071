import {HsvaColor, hsvaToRgba, ColorResult} from "@uiw/color-convert";
import {useEffect, useMemo, useRef, useState} from "react";
import Colorful from "@uiw/react-color-colorful";
import {RgbaColor, rgbaToHsva} from "@uiw/react-color";

// docs: https://github.com/uiwjs/react-color

export default function PiColorPicker(props: {
    onChange: (rgba: RgbaColor) => void,
    color: RgbaColor
}) {
    const {onChange, color} = {...props};
    const convertedColor = useMemo(() => rgbaToHsva(color), [color]);

    return <Colorful color={convertedColor}
                     style={{width: "100%"}}
                     onChange={(r) => onChange(r.rgba)}
                     disableAlpha
    />
}