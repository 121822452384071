import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthenticationSuccess, setIsAuthenticated, setReauthenticationSuccess } from "../../app/slices/AuthenticationSlice";
import { setOrganizations, setUserInfo } from "../../app/slices/UserSlice";
import { AuthenticationResponse, AuthenticationService } from "../../services/AuthenticationService";
import { Organization, UserInfo, UserService } from "../../services/UserService";

export const useLogin = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [autoLoggingIn, setAutoLoggingIn] = useState<boolean>(false);
  const [isIncorrectCreds, setIsIncorrectCreds] = useState<boolean>(false);
  const dispatch = useDispatch();

  const tryLogin = async (email: string, password: string) => {
    setLoading(true);
    var authResp: AuthenticationResponse | null = await AuthenticationService.Authenticate(email, password);

    if (authResp) {
      dispatch(
        setAuthenticationSuccess({
          email: email,
          jwt: authResp.jwt.token,
          userID: authResp.userID,
          organizationIDs: authResp.organizationIDs,
          refreshToken: authResp.jwt.refreshToken,
        })
      );

      // get organizations
      var hasOrgs: boolean = await getOrganizations(authResp.jwt.token!, authResp.userID);

      if (!hasOrgs) {
        dispatch(setIsAuthenticated(false));
        setLoading(false);
        return;
      }

      // get user details
      await getUserDetails(authResp.jwt.token!, authResp.userID, authResp.organizationIDs[0]);
      dispatch(setIsAuthenticated(true));
    } else {
      setIsIncorrectCreds(true);
    }

    setLoading(false);
  };

  const tryAutoLogin = async () => {
    console.log("Logging in with token");
    setAutoLoggingIn(true);
    var authResp: AuthenticationResponse | null = await AuthenticationService.AuthenticateWithRefreshToken();
    if (authResp) {
      dispatch(
        setReauthenticationSuccess({
          jwt: authResp.jwt.token,
          userID: authResp.userID,
          organizationIDs: authResp.organizationIDs,
          refreshToken: authResp.jwt.refreshToken,
        })
      );

      // get organizations
      var hasOrgs: boolean = await getOrganizations(authResp.jwt.token!, authResp.userID);

      if (!hasOrgs) {
        dispatch(setIsAuthenticated(false));
        setAutoLoggingIn(false);
        return;
      }

      // get user details
      await getUserDetails(authResp.jwt.token!, authResp.userID, authResp.organizationIDs[0]);
      dispatch(setIsAuthenticated(true));
    }

    setAutoLoggingIn(false);
  };

  const getOrganizations = async (token: string, userId: string) => {
    var organizations: Organization[] = await UserService.GetOrganizations(token, userId);
    if (organizations.length === 0) {
      console.log("User is not part of an organization with a creator license");
      return false;
    }

    dispatch(setOrganizations(organizations));
    return true;
  };

  const getUserDetails = async (token: string, userID: string, organizationID: string) => {
    var userInfo: UserInfo | null = await UserService.GetUserInfo(token, organizationID, userID);

    dispatch(setUserInfo(userInfo!));
  };

  useEffect(() => {
    tryAutoLogin();
  }, []);

  return [loading, autoLoggingIn, isIncorrectCreds, tryLogin] as const;
};
