import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import { PiBasicQuizPreviewProps } from "../PiBasicQuizPreview";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPlayButtonPreview from "../components/PiPlayButtonPreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import OrderQuizBody from "./OrderQuizBody";

export default function OrderQuizPreview(props: PiBasicQuizPreviewProps) {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue } = useTrainingLocalization();
  const { multipleCorrectAnswers, displayGraphics, hasFooter, showStars } = { ...props };

  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Quiz Title" showStarIcons={showStars} />;

  const body = <OrderQuizBody displayGraphics={displayGraphics} />;

  const footer = hasFooter ? <PiPlayButtonPreview /> : <></>;

  return <PiPopupWindowPreview title={title} body={body} footer={footer} />;
}
