import { useDispatch, useSelector } from "react-redux";
import { Folder, addPagedAssetsToFolder, selectAssetsFromCurrentFolder, selectFolder, selectFolders, selectSelectedFolder, setFolders, setNewFolderDetails } from "../app/slices/AssetsSlice";
import { AssetDetailsDTO, AssetKey } from "../dto/AssetDTO";
import { useAssetService } from "./AssetServiceHooks";

export const useFileExplorerLoading = () => {
  const selectedFolder = useSelector(selectSelectedFolder);
  const currentFolders = useSelector(selectFolders);
  const { fetchAssetsFromSelectedFolder, fetchFoldersFromPage, fetchFilteredAssets } = useAssetService();
  const dispatch = useDispatch();
  const assetsInCurrentFolder = useSelector(selectAssetsFromCurrentFolder);

  const refreshFolder = async (assetKey?: AssetKey, filter?: string) => {
    if (!selectedFolder) return;

    // temp remove all old assets
    dispatch(setNewFolderDetails({ assets: [], totalCount: 0 }));

    if (selectedFolder.id === "newfolder") return;

    var totalCount = 0;
    var assets: AssetDetailsDTO[] = [];

    // 2. fetch first page
    if (filter) {
      var f = await fetchFilteredAssets(1, filter, assetKey);
      console.log(f);
      totalCount = f.totalCount;
      assets = f.assets;
    } else {
      var data = await fetchAssetsFromSelectedFolder(1, assetKey);
      totalCount = data?.totalCount ?? 0;
      assets = data?.assets ?? [];
    }

    dispatch(setNewFolderDetails({ assets: assets, totalCount: totalCount }));
  };

  const onRerender = async (amountOfColumns: number, rowStartIndex: number, rowStopIndex: number, assetKey?: AssetKey, filter?: string) => {
    var startIndex = amountOfColumns * rowStartIndex;
    var endIndex = amountOfColumns * rowStopIndex + (amountOfColumns - 1);
    var startPage = Math.floor(startIndex / 10);
    var endPage = Math.ceil(endIndex / 10);

    for (var s = startPage; s < endPage; s++) {
      if (assetsInCurrentFolder[10 * s] === undefined) {
        // load in the page
        if (!filter) {
          var result = await fetchAssetsFromSelectedFolder(s + 1, assetKey);
          if (result !== undefined && result.folderId === selectedFolder?.id) {
            dispatch(addPagedAssetsToFolder({ pageNumber: s, assets: result.assets }));
          }
        } else {
          var filtered = await fetchFilteredAssets(s + 1, filter, assetKey);
          if (filtered !== undefined) {
            dispatch(addPagedAssetsToFolder({ pageNumber: s, assets: filtered.assets }));
          }
        }
      }
    }
  };

  const fetchFolders = async () => {
    // dont fetch if already fetched before
    if (currentFolders.length > 0) return;
    const data = await fetchFoldersFromPage(1);
    const dtos = data.folders;

    // create a new array of folders with size dtos.length
    const folders = new Array<Folder>(data.totalCount);
    dtos.map((dto, index) => {
      folders[index] = { id: dto.id, name: dto.name } as Folder;
    });

    // fill all the null entries in the folders array with a default folder
    for (var i = 0; i < folders.length; i++) {
      if (folders[i] === undefined) {
        folders[i] = { id: "loading", name: "" } as Folder;
      }
    }

    dispatch(setFolders(folders));

    if (folders.length > 0) {
      dispatch(selectFolder(folders[0].id));
    }
  };

  return { refreshFolder, onRerender, fetchFolders } as const;
};
