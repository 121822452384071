export interface InteractionProperty {
  id: string;
  locId?: string;
  tooltipLocId?: string;
  name: string;
  value: any;
  type: InteractionPropertyType;
}

export enum InteractionPropertyType {
  boolean = "boolean",
  number = "number",
  text = "text",
  actor = "actor",
}
