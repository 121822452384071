import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TrainingDetails } from "../../models/TrainingDetails";
import { RootState } from "../Store";

interface ProjectsState {
  projects: TrainingDetails[];
  filter: string;
  sortBy: string;
  currentPage: number;
  pageSize: number;
  currentTrainingId: string;
  totalPages: number;
}
const initialState: ProjectsState = {
  projects: [],
  filter: "",
  sortBy: "updatedAt desc",
  currentPage: 1,
  pageSize: 5,
  currentTrainingId: "",
  totalPages: 1,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setCurrentTrainingPage: (state, action: PayloadAction<TrainingDetails[]>) => {
      state.projects = action.payload;
    },
    setTrainingFilter: (state, action: PayloadAction<string>) => {
      state.filter = action.payload;
      state.currentPage = 1;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setCurrentTrainingId: (state, action: PayloadAction<string>) => {
      state.currentTrainingId = action.payload;
    },
    setTotalPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setCurrentTrainingPageNumber: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const { setCurrentTrainingPage, setTrainingFilter, setSortBy, setCurrentTrainingId, setTotalPages, setCurrentTrainingPageNumber } = projectsSlice.actions;
export const selectProjects = (state: RootState) => state.projects.projects;
export const selectTrainingFilter = (state: RootState) => state.projects.filter;
export const selectTrainingSortBy = (state: RootState) => state.projects.sortBy;
export const selectCurrentTrainingPage = (state: RootState) => state.projects.currentPage;
export const selectTrainingPageSize = (state: RootState) => state.projects.pageSize;
export const selectCurrentTrainingId = (state: RootState) => state.projects.currentTrainingId;
export const selectTotalPages = (state: RootState) => state.projects.totalPages;
export const selectCurrentPageNumber = (state: RootState) => state.projects.currentPage;

export default projectsSlice.reducer;
