import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearLocalization, initializeNewLocalization } from "../../app/slices/TrainingLocalizationSlice";
import { TrainingType, createNewTraining } from "../../app/slices/TrainingSlice";
import { selectIsGreygin } from "../../app/slices/UserSlice";
import { BreadcrumbItem } from "../../components/PiBreadcrumbs";
import { useCreateTraining } from "../../hooks/CreateTrainingHook";
import DashboardHeader from "../dashboard/DashboardHeader";
import AutoOnboarding from "../onboarding/AutoOnboarding";
import OnboardingButton from "../onboarding/OnboardingButton";
import { OnboardingContext } from "../onboarding/OnboardingContext";
import "../pages/page.css";
import New360Training from "./New360Training";
import New3DTraining from "./New3DTraining";
import NewTrainingCard from "./NewTrainingCard";
import "./NewTrainingPage.css";

export default function NewTrainingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, setState } = useContext(OnboardingContext);
  const { create360Training, create3DTraining } = useCreateTraining();
  const isGreygin = useSelector(selectIsGreygin);
  const breadcrumbItems: BreadcrumbItem[] = [{ name: "Play It - Creator" }, { name: t("dashboard"), link: "/" }, { name: t("newTraining") }];

  const projectCreationCards = (
    <>
      <NewTrainingCard image="360_thumbnail.png" type="360" variant="purple" onSelectClick={onSelect360Project} />
      <NewTrainingCard image="3d_thumbnail.png" type="3D" variant="yellow" onSelectClick={onSelect3DProject} disabled={!isGreygin} />
    </>
  );

  const [currentElement, setCurrentElement] = useState(projectCreationCards);

  function onSelect360Project() {
    setCurrentElement(<New360Training onContinueClick={onCreateNew360Project} onCloseClick={onCloseNewProjectDialog} />);
  }

  function onSelect3DProject() {
    setCurrentElement(<New3DTraining onContinueClick={onCreateNew3DProject} onCloseClick={onCloseNewProjectDialog} />);
  }

  async function onCreateNew360Project(name: string, description: string, refLanguage: string) {
    // clear loc from previous course

    // create new training w/ api, save first version, can be changed later
    // set current training on slice
    var newTrainingId = await create360Training(name, description, 1, refLanguage);

    dispatch(clearLocalization());
    dispatch(initializeNewLocalization());

    dispatch(
      createNewTraining({
        trainingname: name,
        trainingDescription: description,
        trainingType: TrainingType.Training360,
      })
    );

    navigate("/trainingEditor/" + newTrainingId);
  }

  async function onCreateNew3DProject(name: string, description: string, refLanguage: string, environmentId: string) {
    console.log("environment id: " + environmentId);

    var newTrainingId = await create3DTraining(name, description, 0, refLanguage, environmentId);

    dispatch(clearLocalization());
    dispatch(initializeNewLocalization());

    dispatch(createNewTraining({ trainingname: name, trainingDescription: description, trainingType: TrainingType.Training3D }));

    navigate("/trainingEditor/" + newTrainingId);
  }

  function onCloseNewProjectDialog() {
    setCurrentElement(projectCreationCards);
  }

  return (
    <>
      <div className="page-root">
        <div className="page-header">
          <DashboardHeader subtitle={t("wantToCreatoSomethingNew")} breadcrumbs={breadcrumbItems} />
        </div>
        <div className="page-content">
          <div className="page-content-flex">{currentElement}</div>
        </div>
      </div>
      <OnboardingButton context="newTraining" position="fixed" />
      <AutoOnboarding context="newTraining" />
    </>
  );
}
