import React from "react";
import { NpcPointStrategy } from "./InteractionPointPreview";
import NpcPointPreview from "./NpcPointPreview";
import { Viewport3DLayerProps } from "./Viewport3DEnvironmentMetadata";

export default function Viewport3DNpcLayer(props: Viewport3DLayerProps) {
  const { currentTakeLocation, takePositions, isCameraSelected } = { ...props };

  const npcs = takePositions.map((point, index) => {
    return point.actor_positions.map((actorPos, index) => {
      return <NpcPointPreview strategy={NpcPointStrategy} x={actorPos.position.x} y={actorPos.position.y} positionId={actorPos.id} key={index} disabled={isCameraSelected} />;
    });
  });

  return <>{currentTakeLocation && npcs}</>;
}
