import React from "react";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import "./EmotionBubblePreview.css";
import EmotionBubblePreviewBody from "./EmotionBubblePreviewBody";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {IEmotionBubble} from "../../../models/interactions/EmotionBubble";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";

export default function EmotionBubblePreview() {
    const dispatch = useDispatch();
    const emotionBubble = useSelector(selectSelectedElement) as IEmotionBubble;

    const updateEmotionBubble = (field: string, value: any) => {
        dispatch(updateSelectedInteraction({...emotionBubble, [field]: value} as IEmotionBubble));
    };

    const body = <EmotionBubblePreviewBody emotionBubble={emotionBubble} updateInteraction={updateEmotionBubble}/>;

    return <PiPopupWindowPreview title={<PiPopupWindowStaticTitle title={"Emotion Bubble"}/>} body={body}/>;
}
