import { PiInteractionDropdown } from "./PiInteractionDropdown";

export default function PiInteractionStringDropdown(props: {
  selectedItem: string;
  items: string[] | undefined;
  label: string;
  onClick: (item: string) => void;
}) {
  const { selectedItem, items, label, onClick } = { ...props };

  const propDropdownItems =
    items?.map((item) => {
      return (
        <PiInteractionDropdown.Item key={item} onClick={() => onClick(item)}>
          <div className={"flex justify-center gap-4 w-full"}>
            <div className={"my-auto truncate"}>{item}</div>
          </div>
        </PiInteractionDropdown.Item>
      );
    }) || [];

  const propDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>{selectedItem}</div>
        </div>
      </PiInteractionDropdown.Trigger>
      {propDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{propDropdown}</>;
}
