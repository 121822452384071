import usePropHelper from "../../../hooks/PropHelper";
import {PiInteractionDropdown} from "../../../components/PiInteractionDropdown";
import LinkIcon from '@mui/icons-material/Link';
import {PiAvatarProfileGroup} from "../conversation/PiAvatarProfileGroup";
import ActorAvatar from "../../../components/actors/ActorAvatar";

export default function DetachDropdown(props: { onChange: (propGuid: string, actorId: string) => void, selectedPropGuid: string }) {
    const {onChange, selectedPropGuid} = {...props};

    // TODO: get grabbed props
    const {getPropsAndAttachedActors, getUsedPropByInstanceId, getPropPresetByInstanceId} = usePropHelper();
    const attachements = getPropsAndAttachedActors();

    const selectedElement =() => {
        if(selectedPropGuid === "") return <div></div>
        
        const prop = getUsedPropByInstanceId(selectedPropGuid);
        const preset = getPropPresetByInstanceId(selectedPropGuid);
        const attachment = attachements.find(a => a.propInstanceId === selectedPropGuid);

        return <div className={"flex gap-[8px]"}>
            <ActorAvatar actor={attachment!.actor}/>
            <div className={"my-auto"}>{attachment!.actor.workingName}</div>
            <div className={"px-[10px] my-auto"}><LinkIcon/></div>
            <img className={"rounded-full w-[36px] h-[36px] my-auto"} alt={"thumbnail"}
                 src={prop.propContent.downloadUri}/>
            <div className={"my-auto"}>{preset!.name}</div>
        </div>
    }

    const dropdownItems = attachements.map(attachment => {
        const prop = getUsedPropByInstanceId(attachment.propInstanceId);
        const preset = getPropPresetByInstanceId(attachment.propInstanceId);

        return <PiInteractionDropdown.Item key={attachment.propInstanceId}
                                           onClick={() => onChange(attachment.propInstanceId, attachment.actor.id)}>
            <div className={"flex gap-[8px]"}>
                <ActorAvatar actor={attachment.actor}/>
                <div className={"my-auto"}>{attachment.actor.workingName}</div>
                <div className={"px-[10px] my-auto"}><LinkIcon/></div>
                <img className={"rounded-full w-[36px] h-[36px] my-auto"} alt={"thumbnail"}
                     src={prop.propContent.downloadUri}/>
                <div className={"my-auto"}>{preset!.name}</div>
            </div>
        </PiInteractionDropdown.Item>
    })

    const dropdown = <PiInteractionDropdown label={"Attachment"}>
        <PiInteractionDropdown.Trigger>
            <div>
                {selectedElement()}
            </div>
        </PiInteractionDropdown.Trigger>
        {dropdownItems}
    </PiInteractionDropdown>

    return <>{dropdown}</>
}