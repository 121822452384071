import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSelectedFolder } from "../../app/slices/AssetsSlice";
import PiDialog from "../../components/PiDialog";
import PiInputField from "../../components/PiInputField";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { useFileExplorerLoading } from "../../hooks/FileExplorerHooks";
import AutoOnboarding from "../onboarding/AutoOnboarding";
import AssetView from "./AssetView";
import OnboardingButton from "../onboarding/OnboardingButton";
import "./FileExplorerDialog.css";
import FolderView from "./FolderView";

interface FileExplorerDialogProps {
  open: boolean;
  onClose: () => void;
  type?: AssetKey;
  onSelect?: (id: AssetDetailsDTO) => void;
}

export default function FileExplorerDialog(props: FileExplorerDialogProps) {
  const { type } = { ...props };
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>("");
  const selectedFolder = useSelector(selectSelectedFolder);
  const { refreshFolder, fetchFolders } = useFileExplorerLoading();

  useEffect(() => {
    if (props.open) {
      fetchFolders(); // only fetches folders if not already fetched
    }

    // clear filter when closing the dialog
    if (!props.open) {
      if (filter) {
        setFilter("");
        refreshFolder(type, "");
      }
    }
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      refreshFolder(type, filter);
    }
  }, [props.open, selectedFolder, type, filter]);

  function onClose() {
    props.onClose();
  }

  const filterText = type !== undefined ? t(AssetKey[type].toLowerCase()) : t("all_types");

  const fileExplorerHeader = (
    <div className="pi-fileexplorer-header">
      <div>{t("fileExplorer")}</div>
      <div className="pi-fileexplorer-header-filter"> | {filterText}</div>
    </div>
  );

  const fileExplorerBody = (
    <div className="pi-fileexplorer-body">
      <div id="onboarding-fileexplorer-searchFiles">
        <PiInputField
          label=""
          placeholder={t("searchFiles")}
          onChange={(newFilter) => {
            setFilter(newFilter);
          }}
          showSearchIcon
          autoFocus
        />
      </div>

      <div className="pi-fileexplorer-content">
        {!filter && <FolderView />}
        <AssetView type={props.type} filter={filter} onSelect={props.onSelect} fullWidth={!!filter} showDropzone={!filter} />
      </div>

      {/*staat in de weg van de pagination component, temp disable*/}
      <div className="pi-fileexplorer-onboarding">
        <OnboardingButton context="fileExplorer" position="absolute" />
      </div>
    </div>
  );

  return (
    <>
      <PiDialog open={props.open} onClose={onClose} width="90vw" height="90vh" header={fileExplorerHeader} body={fileExplorerBody} />
      {props.open && <AutoOnboarding context="fileExplorer" />}
    </>
  );
}
