import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { ILookat } from "../../../models/interactions/Lookat";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import { useDispatch } from "react-redux";
import ActorPropDropdown from "./ActorPropDropdown";

export default function LookatPreviewBody() {
  const lookat = useSelector(selectSelectedElement) as ILookat;
  const dispatch = useDispatch();

  const updateField = (field: keyof ILookat, value: any) => {
    dispatch(updateSelectedInteraction({ ...lookat, [field]: value }));
  };

  const npcDropdown = (
    <MessageBubbleActorDropdown
      label="Actor"
      onClick={(actorId) => {
        updateField("sourceNpcId", actorId);
      }}
      selectedTargetActorId={lookat.sourceNpcId}
    />
  );

  const targetDropdown = (
    <ActorPropDropdown
      label="Target Object"
      onClick={(id) => {
        updateField("targetId", id);
      }}
      selectedObjectId={lookat.targetId}
    />
  );

  // TODO: actor + prop dropdown
  // from the prop we need the instance id
  // from the actor we need the actor id

  return (
    <div className="h-full w-full flex flex-col gap-[24px]">
      {npcDropdown}
      {targetDropdown}
    </div>
  );
}
