import React from "react";
import "./PiPopupWindowPreview.css";
import classNames from "classnames";

interface PiPopupWindowPreviewProps {
  title?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  size?: "large" | "medium" | "small";
  hideBottom?: Boolean;
}

export default function PiPopupWindowPreview(props: PiPopupWindowPreviewProps) {
  const { title, body, footer, size, hideBottom } = { ...props };

  //const sizeClasses = size ? "pipopupwindowpreview-size-" + size : "pipopupwindowpreview-size-large";

  const sizeClasses = classNames({
    "pipopupwindowpreview-size-large": size === undefined || size === "large",
    "pipopupwindowpreview-size-small": size === "small",
    "w-[90%] h-[55%]": size === "medium",
  });

  return (
    <>
      <div
        className={
          (hideBottom
            ? "pipopupwindowpreview-root-no-bottom "
            : "pipopupwindowpreview-root ") + sizeClasses
        }
      >
        <div
          aria-label="pipopupwindowpreview-title"
          className="pipopupwindowpreview-title"
        >
          {title}
        </div>
        <div
          aria-label="pipopupwindowpreview-body"
          className={
            footer
              ? "pipopupwindowpreview-body-normal"
              : "pipopupwindowpreview-body-extended"
          }
        >
          {body}
        </div>
        {footer && (
          <div
            aria-label="pipopupwindowpreview-footer"
            className="pipopupwindowpreview-footer"
          >
            {footer}
          </div>
        )}
      </div>
    </>
  );
}
