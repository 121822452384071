import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { PiQuizAnswerProps } from "../components/PiBasicQuizAnswer";
import "./ScrabbleQuizTextfield.css";

interface ScrabbleQuizTextfieldProps extends PiQuizAnswerProps {
  index: number;
  onTextfieldClick: (index: number) => void;
  textDecoration?: "Bold" | "Error";
}

export default function ScrabbleQuizTextfield(props: ScrabbleQuizTextfieldProps) {
  const { answer, handleAnswerChange, getValue, changeValue, handleDeleteAnswer, canDelete, index, onTextfieldClick, textDecoration } = { ...props };

  const getTextDecoClasses = () => {
    if (textDecoration === "Bold") return "scrabblequiztextfield-bold";
    else if (textDecoration === "Error") return "scrabblequiztextfield-error";
    return "";
  };

  const deleteButton = (
    <div className="scrabblequiztextfield-delete-button" onClick={() => handleDeleteAnswer(answer.id)}>
      <CloseIcon fontSize="inherit" color="inherit" />
    </div>
  );

  return (
    <div className="scrabblequiztextfield-root">
      <input
        className={"scrabblequiztextfield-input " + getTextDecoClasses()}
        onClick={() => onTextfieldClick(index)}
        placeholder="Quiz Answer"
        value={getValue(answer.text)}
        onChange={(e) => changeValue(answer.text, e.target.value)}
      />
      {canDelete && deleteButton}
    </div>
  );
}
