import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Folder, selectFolder, setRenameFolder } from "../../app/slices/AssetsSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import { useAddFolder } from "../../hooks/AddFolderHook";
import useDeleteFolder from "../../hooks/DeleteFolderHook";
import useUpdateFolder from "../../hooks/UpdateFolderHook";
import PiMenuButton from "../menu/PiMenuButton";
import "./FolderButton.css";
interface FolderButtonProps {
  isSelected?: boolean;
  isRenamingFolder?: boolean;
  style?: React.CSSProperties;
  folder: Folder;
}

export default function FolderButton(props: FolderButtonProps) {
  const [folderName, setFolderName] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const [updateFolder] = useUpdateFolder();
  const [addFolder] = useAddFolder();
  const [deleteFolder] = useDeleteFolder();

  useEffect(() => {
    setFolderName(props.folder.name);
  }, [props]);

  function onClick() {
    if (!props.isRenamingFolder) {
      dispatch(selectFolder(props.folder.id));
    }
  }

  async function onRename() {
    dispatch(setRenameFolder(props.folder));
    setFolderName(props.folder.name);
    await delay(100);
    if (inputRef.current) inputRef.current.focus();
  }

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submitRename();
    }
  };

  async function submitRename() {
    if (!folderName) return;

    // we dont want empty folder names!
    if (props.folder.id === "newfolder") {
      // create folder in backend
      await addFolder(folderName);
    } else {
      await updateFolder(props.folder.id, folderName);
    }
  }

  const icon = props.isSelected ? <FolderOpenOutlinedIcon /> : <FolderOutlinedIcon />;

  const foldername = props.isRenamingFolder ? (
    <input
      className="pi-folderbutton-rename-input"
      autoFocus
      ref={inputRef}
      onKeyDown={(e) => handleKeyboardEvent(e)}
      onBlur={submitRename}
      value={folderName}
      onChange={(e) => setFolderName(e.target.value)}
    />
  ) : (
    <span className="pi-folderbutton-text">{props.folder.name}</span>
  );

  const classnames = props.isSelected ? "pi-folderbutton-spacing pi-folderbutton-selected" : "pi-folderbutton-spacing";
  return (
    <div className="pi-folderbutton-root" style={props.style}>
      <div className={classnames}>
        <div className="pi-folderbutton-clickable" onClick={onClick}>
          <div className="pi-folderbutton-icon">{icon}</div>
          {foldername}
        </div>
        <div className="pi-folderbutton-menu">
          <PiPopoverMenu>
            <PiMenuButton text="Rename" onClick={() => onRename()} />
            <PiMenuButton text="Delete" onClick={() => deleteFolder(props.folder)} disabled={props.isRenamingFolder} />
          </PiPopoverMenu>
        </div>
      </div>
    </div>
  );
}
