import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {resetAssetsSlice} from "../app/slices/AssetsSlice";
import {setCurrentTrainingPageNumber, setTrainingFilter} from "../app/slices/ProjectsSlice";
import {setCurrentOrganization} from "../app/slices/UserSlice";
import {Organization} from "../services/UserService";
import {cleanupTranslatedEnvironments} from "../app/slices/EnvironmentSlice";

export const useSwitchOrganization = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const switchOrganization = (org: Organization) => {
        // set org in slice
        dispatch(setCurrentOrganization(org));
        // reset current asset slice to dump the assets from previous org
        dispatch(resetAssetsSlice());

        // reset training table
        dispatch(setTrainingFilter(""));
        dispatch(setCurrentTrainingPageNumber(1));

        // reset translated environments
        dispatch(cleanupTranslatedEnvironments());

        // navigate to home
        if (location.pathname !== "/") {
            navigate("/");
        }
    };
    return [switchOrganization] as const;
};
