import { useEffect, useRef } from 'react';

export enum ShortCuts {
    Copy,
    Paste,
    Duplicate
}

const useShortcut = (shortcut: ShortCuts, callback: () => void): void => {
    const keyPressing = useRef(false);

    useEffect(() => {

        var keyDown = 'c';
        const handleShortcut = (event: KeyboardEvent): void => {
            // If the event target is an input field or a textarea, return early
            if ((event.target as HTMLElement).tagName === 'INPUT' || (event.target as HTMLElement).tagName === 'TEXTAREA') {
                return;
            }

            switch (shortcut) {
                case ShortCuts.Copy:
                    keyDown = 'c';
                    break;
                case ShortCuts.Paste:
                    keyDown = 'v';
                    break;
                case ShortCuts.Duplicate:
                    keyDown = 'v';
                    break;
            }

            if (event.ctrlKey && event.key === keyDown && !keyPressing.current) {
                keyPressing.current = true;
                callback();// Trigger the provided callback function
            };
        }

        const handleKeyUp = () => {
            keyPressing.current = false;
        }

        // Add event listener when component mounts
        document.addEventListener('keydown', handleShortcut);
        document.addEventListener('keyup', handleKeyUp);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('keydown', handleShortcut);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [callback]);
};

export default useShortcut;
