import { useDispatch } from "react-redux";
import { resetAssetsSlice } from "../app/slices/AssetsSlice";
import { setLogout } from "../app/slices/AuthenticationSlice";

export const UseLogout = () => {
  const dispatch = useDispatch();

  const onLogout = () => {
    // logout
    dispatch(setLogout());

    // clear assets
    dispatch(resetAssetsSlice());
  };

  return { onLogout } as const;
};
