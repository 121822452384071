import { useState } from "react";
import PiFilterDropdown, {
  PiFilterDropDownListItem,
} from "../../components/PiFilterDropDown";
import PiFilterLabel, {
  PiFilterLabelItem,
} from "../../components/PiFilterLabel";
import PiInputfield from "../../components/PiInputField";
import { FilterType } from "../../models/interactions/FilterType";
import AdvancedSearchColumnHeader from "./AdvancedSearchColumnHeader";
import AdvancedSearchList from "./AdvancedSearchList";
import { useSelector } from "react-redux";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import { SelectedTab, selectSelectedTab } from "../../app/slices/GlobalSlice";

export default function AdvancedSearchColumn() {
  const timelineSelected =
    useSelector(selectSelectedTab) === SelectedTab.Timeline;
  const isPlaying = useSelector(selectIsPlaying);
  const [query, setQuery] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [filterListItems, setFilterListItems] = useState<
    PiFilterDropDownListItem[]
  >([{ type: FilterType.All, count: 0, checked: true }]);
  const [filterLabelItems, setFilterLabelItems] = useState<PiFilterLabelItem[]>(
    []
  );

  const handleFilterListItems = (searchResults: PiFilterDropDownListItem[]) => {
    const filters: PiFilterDropDownListItem[] = [
      { type: FilterType.All, count: 0, checked: true },
    ];
    let totalCounter = 0;
    searchResults.forEach((item) => {
      totalCounter += item.count; //increase count of "All"
      filters.push({ type: item.type, count: item.count, checked: true });
      filters[0].count = totalCounter;
    });
    setFilterListItems(filters);
    updateFilterLabels(filters);
  };

  const updateFilterLabels = (filterListItems: PiFilterDropDownListItem[]) => {
    const labels: PiFilterLabelItem[] = [];
    filterListItems.forEach((item) => {
      if (item.checked) {
        labels.push({
          type: item.type,
          onClick: () => onUpdateFilters(item.type, false),
        });
      }
    });
    setFilterLabelItems(labels);
  };

  const filterLabels = () => {
    return filterLabelItems.map((element) => (
      <PiFilterLabel
        key={element.type}
        type={element.type}
        onClick={() => onUpdateFilters(element.type, false)}
      />
    ));
  };

  const onClick = () => {
    setQuery("");
    setFilterListItems([{ type: FilterType.All, count: 0, checked: true }]);
    updateFilterLabels(
      !isSelected ? [{ type: FilterType.All, count: 0, checked: true }] : []
    );
    setIsSelected(!isSelected);
  };

  const onChange = (value: any) => {
    if (value === "") {
      setFilterListItems([{ type: FilterType.All, count: 0, checked: true }]);
      updateFilterLabels([{ type: FilterType.All, count: 0, checked: true }]);
    }

    setQuery(value);
  };

  const onUpdateFilters = (type: FilterType, checked: boolean) => {
    var filteredItems: PiFilterDropDownListItem[] = [];

    //If all is selected, all other checks should have the same state
    if (type === FilterType.All) {
      filteredItems = filterListItems.map((item) => ({
        ...item,
        checked: checked,
      }));
    } else {
      // If any other item is being checked to false, "All" should be unchecked as well.

      filteredItems = filterListItems.map((item) => {
        if (item.type === type) {
          return { ...item, checked: !item.checked };
        } else if (item.type === FilterType.All && item.checked) {
          return { ...item, checked: false };
        } else {
          return item;
        }
      });

      // Check if all items are checked, then "All" should be checked as well.
      const allChecked = filteredItems.every(
        (item) => item.type === FilterType.All || item.checked
      );

      if (allChecked) {
        filteredItems = filteredItems.map((item) =>
          item.type === FilterType.All ? { ...item, checked: true } : item
        );
      }
    }

    setFilterListItems(filteredItems);
    updateFilterLabels(filteredItems);
  };

  const column = (
    <div aria-label="advanced-search-column" className={`h-full bg-white`}>
      <AdvancedSearchColumnHeader
        isSelected={isSelected}
        onClickCallback={onClick}
      />
      {isSelected ? (
        <div className="flex items-end max-w-full justify-evenly gap-[10px] pt-[12px] pl-[15px] pr-[15px]">
          <PiInputfield
            label=""
            placeholder="Search"
            showSearchIcon={true}
            onChange={onChange}
            showCrossIcon={true}
          />
          <PiFilterDropdown
            filterDropDownListItems={filterListItems}
            onChecked={onUpdateFilters}
          />
        </div>
      ) : (
        ""
      )}
      <div className="w-full h-full overflow-y-auto pit-creator-scrollbar">
        <div className="flex flex-row max-w-[292px] flex-wrap gap-[10px] px-[14px] py-[12px]">
          {filterLabels()}
        </div>
        <div className="flex flex-col bg-transparent ">
          {isSelected && query ? (
            <AdvancedSearchList
              inputQuery={query}
              handleFilterItems={handleFilterListItems}
              filterItems={filterListItems}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );

  const isOpen = isSelected;
  const shouldRenderColumn = !isPlaying && timelineSelected && isOpen;
  const shouldRenderHeader = !isPlaying && timelineSelected && !isOpen;

  return (
    <>
      {shouldRenderColumn && column}
      {shouldRenderHeader && (
        <div className="absolute z-50 top-0 left-[292px]  bg-creator-error">
          <AdvancedSearchColumnHeader
            isSelected={isSelected}
            onClickCallback={onClick}
          />
        </div>
      )}
    </>
  );
}
