import React from "react";
import { useTranslation } from "react-i18next";
import "./PiOrderGraphicsQuizAnswerFooter.css";
import { PiQuizAnswerProps } from "../components/PiBasicQuizAnswer";
export default function PiOrderGraphicsQuizAnswerFooter(
  props: PiQuizAnswerProps
) {
  const { t } = useTranslation();

  const index =
    "index" in props && props.index !== undefined ? props.index : -1;

  const indexBox = (
    <div className="piordergraphicsquizanswerfooter-index">
      <div>{index + 1}</div>
    </div>
  );

  const textfield = (
    <div className="piordergraphicsquizanswerfooter-textfield">
      <input
        placeholder={t("answer_placeholder") ?? "Q A"}
        disabled={props.disableInput}
        value={props.getValue(props.answer.text)}
        onChange={(e) => props.changeValue(props.answer.text, e.target.value)}
      />
    </div>
  );

  return (
    <div className="piordergraphicsquizanswerfooter-footer">
      {indexBox}
      {textfield}
    </div>
  );
}
