import React from "react";
import {useOverlay} from "./InteractionPreviewHook";
import "./TrainingPreviewOverlay.css";
import {useSelector} from "react-redux";
import {SelectedTab, selectSelectedTab} from "../../app/slices/GlobalSlice";

export default function TrainingPreviewOverlay() {
    const {getOverlayElement} = useOverlay();
    const selectedTab = useSelector(selectSelectedTab);
  

    return <div className="trainingbubblepreviewoverlay-root">{selectedTab === SelectedTab.Timeline && getOverlayElement()}</div>;
}
