import CheckIcon from "@mui/icons-material/Check";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";
import { useTranslation } from "react-i18next";
import "./PublishCompleteCard.css";

interface PublishCompletedCardProps {
  courseName: string;
}

export default function PublishCompleteCard(props: PublishCompletedCardProps) {
  const { courseName } = { ...props };
  const { t } = useTranslation();
  const onNavClick = () => {
    window.open("https://portal.playitsafe.eu", "_blank");
  };

  return (
    <div className="publishcomplete-root">
      <div className="publishcomplete-content">
        <div className="publishcomplete-title">{t("publish_window.publish_complete")}</div>
        <div className="publishcomplete-subtitle">
          <div>{t("publish_window.publish_succesful")}</div>
          <div className="publishcomplete-subtitle-highlight">{courseName}</div>{" "}
        </div>
      </div>
      <div className="publishcomplete-check-icon">
        <CheckIcon color="inherit" fontSize="inherit" />
      </div>
      <div className="publishcomplete-bottom" onClick={onNavClick}>
        <div className="publishcomplete-bottom-text">{t("publish_window.publish_portal")}</div>
        <div className="publishcomplete-bottom-icon">
          <PlayArrowIcon color="inherit" fontSize="inherit" />
        </div>
      </div>
    </div>
  );
}
