import {useSelector} from "react-redux";
import {selectAllPropPresets} from "../../app/slices/TrainingSlice";
import PropCard from "./PropCard";

export default function PropPresetList() {    
    const allProps = useSelector(selectAllPropPresets);

    const allPropsMapped = allProps.map((prop, index) => {
        return <PropCard key={prop.id} prop={prop} />
    });
    
    return <div className={"w-full px-[18px] pt-[18px] max-h-[calc(100%-0px)] flex flex-col gap-[10px] overflow-y-auto pit-creator-scrollbar"}>
        {allPropsMapped}
    </div>
}