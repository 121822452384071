import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectProjects, setTrainingFilter } from "../../app/slices/ProjectsSlice";
import { clearTakes } from "../../app/slices/TrainingSlice";
import PiInputField from "../../components/PiInputField";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import PiTag from "../../components/PiTag";
import { useDeleteTraining } from "../../hooks/DeleteTrainingHook";
import { TrainingDetails } from "../../models/TrainingDetails";
import PiMenuButton from "../menu/PiMenuButton";
import "./ProjectsTable.css";
import ProjectsTableHead from "./ProjectsTableHead";
import ProjectsTablePagination from "./ProjectsTablePagination";
export default function ProjectsTable() {
  const [filter, setFilter] = useState<string>("");
  const projects: TrainingDetails[] = useSelector(selectProjects);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [deleteTraining] = useDeleteTraining();
  const navigate = useNavigate();

  const goToTrainingPage = (trainingId: string) => {
    dispatch(clearTakes());
    navigate("trainingEditor/" + trainingId);
  };

  const filteredProjects = () => {
    if (!filter) return projects;

    return projects.filter((project) => {
      return project.name.toLowerCase().includes(filter) || project.updatedBy.toLowerCase().includes(filter);
    });
  };

  const colGroup = (
    <colgroup>
      <col style={{ width: "5%" }} />
      <col style={{ width: "5%" }} />
      <col style={{ width: "40%" }} />
      <col style={{ width: "15%" }} />
      <col style={{ width: "15%" }} />
      <col style={{ width: "5%" }} />
      <col style={{ width: "15%" }} />
    </colgroup>
  );

  const tableHead = <ProjectsTableHead />;

  const tableRows = filteredProjects().map((project: TrainingDetails, index: number) => {
    return (
      <TableRow hover key={index} className="projectstable-row">
        <TableCell
          onClick={() => {
            goToTrainingPage(project.id);
          }}
        >
          <PiTag text={project.typeFormatted} variant={project.typeFormatted} />
        </TableCell>
        <TableCell
          onClick={() => {
            goToTrainingPage(project.id);
          }}
        >
          <span className="projectstable-tablecell">{project.version}</span>
        </TableCell>
        <TableCell
          onClick={() => {
            goToTrainingPage(project.id);
          }}
        >
          <span className="projectstable-tablecell">{project.name}</span>
        </TableCell>
        <TableCell
          onClick={() => {
            goToTrainingPage(project.id);
          }}
        >
          <span className="projectstable-tablecell">{project.updatedBy}</span>
        </TableCell>
        <TableCell
          onClick={() => {
            goToTrainingPage(project.id);
          }}
        >
          <span className="projectstable-tablecell">{project.updatedAt}</span>
        </TableCell>
        <TableCell align="center"></TableCell>
        <TableCell align="right">
          <PiPopoverMenu>
            <PiMenuButton text={t("edit")} onClick={() => goToTrainingPage(project.id)} />
            <PiMenuButton text={t("archive")} onClick={() => deleteTraining(project.id)} />
          </PiPopoverMenu>
        </TableCell>
      </TableRow>
    );
  });

  const searchBar = (
    <div className="projectstable-searchbar">
      <span className="projectstable-searchbar-label">{t("trainingList")}</span>
      <div className="projectstable-searchbar-inputfield">
        <PiInputField
          label=""
          placeholder={t("searchTrainings")}
          onChange={(e) => {
            dispatch(setTrainingFilter(e.toLowerCase()));
          }}
        />
      </div>
    </div>
  );

  const pagination = <ProjectsTablePagination />;

  return (
    <div className="projectstable-root" id="projectstable">
      {searchBar}
      <TableContainer>
        <Table>
          {colGroup}
          {tableHead}
          <TableBody>{tableRows}</TableBody>
        </Table>
      </TableContainer>
      {pagination}
    </div>
  );
}
