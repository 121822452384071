import { useSelector } from "react-redux";
import { LanguageData, deleteLocalizedLanguage, selectTrainingLocalizationState, updateLocalizedStringFromExcel } from "../../app/slices/TrainingLocalizationSlice";

import * as FileSaver from "file-saver";
import { ChangeEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import XLSX from "sheetjs-style";
import { selectReferenceLanguage, selectTrainingName, setIsDirty } from "../../app/slices/TrainingSlice";

export const useTrainingLocalizationTable = () => {
  const dispatch = useDispatch();
  const locState = useSelector(selectTrainingLocalizationState);
  const projName = useSelector(selectTrainingName);
  const referenceLanguage = useSelector(selectReferenceLanguage);
  const inputRef = useRef<HTMLInputElement>(null);
  const supportedFiletypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const [expectedLanguageFromExcel, setExpectedLanguageFromExcel] = useState<string>("");

  const getPercentageTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (entries[key][shortcode].value !== "" && !entries[key][shortcode].isAutoTranslated) {
        translated += 1;
      }
    }

    if (total === 0) return "0%";
    return Math.round((translated / total) * 100) + "%";
  };

  const getPercentageAutoTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (entries[key][shortcode].value !== "" && entries[key][shortcode].isAutoTranslated) {
        translated += 1;
      }
    }
    if (total === 0) return "0%";
    return Math.round((translated / total) * 100) + "%";
  };

  const getPercentageNotTranslated = (shortcode: string) => {
    var total = 0;
    var translated = 0;
    var entries = locState.localizedEntries;
    for (var key in entries) {
      total += 1;
      if (entries[key][shortcode].value === "") {
        translated += 1;
      }
    }
    if (total === 0) return "0%";
    return Math.trunc((translated / total) * 100) + "%";
  };

  const downloadExcel = (shortcode: string) => {
    const filetype = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const entries = locState.localizedEntries;
    var primaryLanguage = referenceLanguage;

    const json: Object[] = [];
    for (var key in entries) {
      json.push({
        key: key,
        [primaryLanguage]: entries[key][primaryLanguage].value,
        [shortcode]: entries[key][shortcode].value,
      });
    }

    const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(json);

      const cellStyle = {
        font: {
          bold: true,
          color: { rgb: "FF000000" },
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: "1A3AC4FF" },
          fgColor: { rgb: "FFFFFFFF" },
        },
      };

      const cellStyleUneven = {
        font: {
          bold: true,
          color: { rgb: "FFFFFFFF" },
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: "FF3AC4FF" },
          fgColor: { rgb: "FF3AC4FF" },
        },
      };

      for (var i = 1; i < json.length + 2; i++) {
        ws["A" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
        ws["B" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
        if (ws["C" + i] !== undefined) ws["C" + i].s = i % 2 === 0 ? cellStyle : cellStyleUneven;
      }

      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: filetype });
      FileSaver.saveAs(data, projName + "_" + shortcode + fileExtension);
    };

    exportToExcel();
  };

  const handleExcelInput = function (e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files?.length === 1) {
      handleFiles(e.target.files);
    }
  };

  const handleFiles = function (files: FileList) {
    var file = files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      if (e.target) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetname = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetname];

        // Convert the sheet to an array rows
        const sheetData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Get the first row (the headers)
        const headers: any[] = sheetData[0];

        // Find the column index that matches expectedLanguageFromExcel
        const columnIndex: number = headers.findIndex(header => header === expectedLanguageFromExcel);

        // check if the language in this excel matches the language we clicked on
        if (columnIndex === -1) {
          console.log("No matching language found in Excel file");
          setExpectedLanguageFromExcel("");
          return;
        }

        // Create a new array that only includes the first column and the column that matches the language code
        const filteredData: any[][] = sheetData.map(row => [row[0], row[columnIndex]]);

        parseExcel(expectedLanguageFromExcel, filteredData);
      }
    };
  };

  const parseExcel = (shortcode: string, parsedData: any[][]) => {
    for (var i = 0; i < parsedData.length; ++i) {
      var record: any = parsedData[i];
      // update record in slice
      // key, language, value
      dispatch(
        updateLocalizedStringFromExcel({
          key: record[0],
          shortcode: shortcode,
          value: record[1],
        })
      );
    }

    dispatch(setIsDirty());
    // todo: we can add a call to "upload" the new language asset to backend here
  };

  const removeLocalizedLanguage = (lang: LanguageData) => {
    dispatch(deleteLocalizedLanguage(lang));
  };

  return [
    getPercentageTranslated,
    getPercentageAutoTranslated,
    downloadExcel,
    handleExcelInput,
    inputRef,
    supportedFiletypes,
    setExpectedLanguageFromExcel,
    removeLocalizedLanguage,
    getPercentageNotTranslated,
  ] as const;
};
