import { useSelector } from "react-redux";
import PropFilteredDropdown from "./PropFilteredDropdown";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IShowUIProp } from "../../../models/interactions/ShowUIProp";
import { useDispatch } from "react-redux";
import { Prop } from "../../../models/Prop";

export default function ShowUIPropPreviewBody() {
  const interaction = useSelector(selectSelectedElement) as IShowUIProp;
  const dispatch = useDispatch();

  const onChangePropGuid = (prop: Prop) => {
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        propGuid: prop.id,
        prefabName: prop.prefabName,
      } as IShowUIProp)
    );
  };

  const dropdown = (
    <PropFilteredDropdown
      label={"UI Prop"}
      onClick={onChangePropGuid}
      selectedPropStateId={interaction.propGuid}
      forcedCategory="ui"
    />
  );

  return <>{dropdown}</>;
}
