import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import ShowUIPropPreviewBody from "./ShowUIPropPreviewBody";

export default function ShowUIPropPreview() {
  const title = <PiPopupWindowStaticTitle title="Show UI Prop" />;

  return (
    <PiPopupWindowPreview
      size="medium"
      body={<ShowUIPropPreviewBody />}
      title={title}
    />
  );
}
