import {TranslatedEnvironmentDTO} from "../../dto/TranslatedEnvironmentDTO";
import {useSelector} from "react-redux";
import {selectTranslatedEnvironments} from "../../app/slices/EnvironmentSlice";
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';

export default function TranslatedEnvironmentSelector(props: { selectedEnvironmentId?: string, onClick: () => void }) {
    const {selectedEnvironmentId, onClick} = {...props};
    const environments = useSelector(selectTranslatedEnvironments);
    const selectedEnvironment = environments.find((env) => env.id === selectedEnvironmentId);

    return <div className={"w-full"} onClick={onClick}>
        <div className={"font-medium text-[14px] text-creator-text"}>Environment</div>
        <div className={"w-full h-full relative rounded-[10px] object-cover mt-[10px] flex cursor-pointer"}>
            <div className={"w-[256px] h-[112px] relative mx-auto hover:opacity-75 bg-interaction-primary-washed rounded-[10px]"}>
                {selectedEnvironment && <img draggable={false} className={"w-full h-full object-cover rounded-[10px] mx-auto"} src={selectedEnvironment.thumbnailUri}/> }
                <div className={"absolute top-[12px] left-[12px] bg-creator-secondary text-white rounded-[5px] text-[13px] px-[8px] py-[2px]"}>{selectedEnvironment ? selectedEnvironment?.name : "Select Environment"}</div>
                <div className={"absolute top-[12px] right-[12px] bg-creator-primary rounded-[5px] text-white w-[26px] h-[22px] flex "}>
                    <EditLocationAltOutlinedIcon className={"m-auto"} fontSize={"inherit"}/>
                </div>
            </div>
        </div>
    </div>
}