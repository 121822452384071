import { useSelector } from "react-redux";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import { useSortable } from "@dnd-kit/sortable";
import { useEffect, useRef } from "react";
import { PiInteractionPopOver } from "./PiInteractionPopOver";
import PiInteractionButton from "../../menu/PiInteractionButton";
import classNames from "classnames";
import DragIcon from "@mui/icons-material/DragIndicator";
import { CSS } from "@dnd-kit/utilities";
import { selectActors, selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { ProfilePictures } from "../../../models/Actor";
import { useDispatch } from "react-redux";
import { IConversation } from "../../../models/interactions/Conversation";
import { IEmotionBubble, emotionBubbleStylesMapped } from "../../../models/interactions/EmotionBubble";


export function ConversationEmotionBubble(props: { message: (IMessageBubble | IEmotionBubble); onSettingsClicked: () => void }) {

    const { onSettingsClicked } = { ...props };
    const message = props.message as IEmotionBubble;
    const myActor = useSelector(selectActors).find((actor) => actor.id === message.npcId);

    const dispatch = useDispatch();
    const conversation = useSelector(selectSelectedElement) as IConversation;

    const onDeleteMessage = (message: IEmotionBubble) => {
        const cpy = { ...conversation };
        cpy.messages = cpy.messages.filter((msg) => msg.id !== message.id);
        dispatch(updateSelectedInteraction(cpy));
    };

    const isPlayer: Boolean = message.npcId === "player";

    const { isDragging, attributes, listeners, transform, transition, setNodeRef } = useSortable({ id: props.message.id });

    const dragStyle = {
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
    };

    const parentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setNodeRef(parentRef.current);
    }, [parentRef, setNodeRef]);


    const menu = (
        <PiInteractionPopOver>
            <PiInteractionButton text="Settings" onClick={onSettingsClicked} />
            <PiInteractionButton text="Delete" onClick={() => onDeleteMessage(message)} />
        </PiInteractionPopOver>
    );

    // 1. avatar icon
    const playerIcon = <div className="flex items-center justify-center font-medium bg-interaction-primary w-9 h-9 text-interaction-text-inverted text-16 rounded-circle">P</div>;

    // 2. avatar name
    const name = (
        <div className={classNames({
            "flex items-center h-5 gap-2 font-semibold leading-6 text-interaction-primary text-16 ": true,
            "justify-end": isPlayer
        })}>
            <div>{myActor?.workingName}</div>
        </div>);

    // 3. avatar message box
    const messageBox = (
        <div className={classNames({
            " min-w-[76px] max-w-[76px] min-h-[76px] max-h-[76px] shadow-base flex justify-center items-center": true,
            " rounded-tl-[20px] rounded-br-[20px] rounded-bl-[20px] bg-interaction-primary ml-[10%]": isPlayer,
            " rounded-bl-[20px] rounded-br-[20px] rounded-tr-[20px] bg-interaction-white border-interaction-border border-t-[1px]": !isPlayer
        })}>
            <img className="max-w-[40px] max-h-[40px]" src={(emotionBubbleStylesMapped.find(e => e.name === message.messageBubbleStyle)!).path} />
        </div>
    );

    const playerEmotionBubble = <div className="relative flex justify-end w-full group">
        <div className="absolute top-0 left-0 transition-opacity opacity-0 text-interaction-primary-faded group-hover:opacity-100 ">
            <DragIcon color="inherit" fontSize="medium" />
        </div>
        <div className="flex w-1/2 gap-2">
            <div className="flex flex-col justify-start flex-grow gap-2 mt-3 mr-1">
                {name}
                <div className="flex justify-end">
                    {messageBox}
                </div>
            </div>
            <div className="flex flex-col items-center">
                {playerIcon}
                <div className="mt-1">
                    {menu}
                </div>
            </div>
        </div>
    </div>;

    const npcEmotionBubble = <div className="relative flex group ">
        <div className="flex w-1/2 gap-2">
            <div className="flex flex-col items-center ">
                <img className="flex flex-col items-center object-contain border-2 min-w-9 min-h-9 max-w-9 max-h-9 rounded-circle border-interaction-primary" src={ProfilePictures[myActor?.profilePicture ?? 0]} alt="icon" />
                <div className="mt-1">
                    {menu}
                </div>
            </div>
            <div className="flex flex-col flex-grow gap-2 mt-3 ml-1">
                {name}
                {messageBox}
            </div>
        </div>
        <div className="absolute top-0 right-0 transition-opacity opacity-0 text-interaction-primary-faded group-hover:opacity-100 ">
            <DragIcon color="inherit" fontSize="medium" />
        </div>
    </div>;

    return (
        <div ref={parentRef} {...listeners} {...attributes} tabIndex={-1} className={(isDragging ? "bg-interaction-primary-washed" : "") + " pt-[10px] pb-4"} style={dragStyle}>
            <div></div>
            {isPlayer ? playerEmotionBubble : npcEmotionBubble}
        </div>
    )
};