import { useDispatch } from "react-redux";
import { deleteKeys } from "../app/slices/TrainingLocalizationSlice";
import { deleteInteraction } from "../app/slices/TrainingSlice";
import Interaction from "../models/interactions/Interaction";

export const useDeleteInteraction = () => {
  const dispatch = useDispatch();
  const deleteInteractionHook = (interaction: Interaction) => {
    // do a switch here
    dispatch(deleteInteraction(interaction));

    var keysToDelete: string[] = getKeys(interaction);
    console.log(keysToDelete);
    dispatch(deleteKeys(keysToDelete));
  };

  const getKeys = (obj: any): string[] => {
    var result: string[] = [];

    // if its an array -> go over every elem
    if (obj instanceof Array) {
      obj.map((elem) => {
        getKeys(elem).map((s) => result.push(s));
      });
    } else {
      for (var prop in obj) {
        if (typeof obj[prop] === "string") {
          var str: string = obj[prop];
          if (str.startsWith("key_")) result.push(obj[prop]);
        }
        if (obj[prop] instanceof Object || obj[prop] instanceof Array) {
          getKeys(obj[prop]).map((s) => result.push(s));
        }
      }
    }
    return result;
  };

  return [deleteInteractionHook] as const;
};
