import {TranslatedPropCategoryDTO} from "../../dto/TranslatedPropCategoryDTO";
import {useDispatch} from "react-redux";
import {setPropCategoryFilter} from "../../app/slices/PropLibrarySlice";

import classNames from "classnames";
export default function PropLibraryCategoryButton(props: { category: TranslatedPropCategoryDTO, isSelected: boolean }) {
    const {category, isSelected} = {...props};
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setPropCategoryFilter(category.name));
    }

    function hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    const rgb = hexToRgb(category.hexColor);


    return <div style={{backgroundColor: `rgba(${rgb?.r}, ${rgb?.g}, ${rgb?.b}, 0.1)`, borderColor: category.hexColor}} className={classNames({
        "h-[56px] rounded-[10px] flex gap-[10px] px-[20px] py-[16px] cursor-pointer box-border": true,
        "border-2 border-solid": isSelected,
    })} onClick={onClick}>
        <img className={"my-auto w-[24px] h-[24px]"} src={category.downloadUri} alt={category.name}/>
        <div style={{color: category.hexColor}} className={"my-auto text-[14px] font-medium"}>
            {category.name.charAt(0).toUpperCase() + category.name.slice(1)}
        </div>
    </div>;

}