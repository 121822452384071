import { TrainingDetailsDTO } from "../dto/TrainingDetailsDTO";

enum TrainingType {
  Project3d = 0,
  Project360 = 1,
}

export type TrainingDetails = {
  id: string;
  categoryId: string;
  type: TrainingType;
  typeFormatted: "360" | "3D";
  name: string;
  updatedBy: string;
  updatedAt: string;
  version: string;
};

export const getTrainingDetailsFromDTO = (dto: TrainingDetailsDTO): TrainingDetails => {
  return {
    id: dto.id,
    categoryId: dto.categoryId,
    type: dto.type,
    typeFormatted: dto.type === 0 ? "3D" : "360",
    name: dto.name,
    updatedAt: new Date(dto.updatedAt).toLocaleString(),
    updatedBy: dto.updatedBy,
    version: dto.version.major + "." + dto.version.minor,
  };
};
