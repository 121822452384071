import React from "react";
import "./PiIconButton.css";

interface PiIconButtonProps {
  icon: React.ReactNode;
  onClick: () => void;
}

export default function PiIconButton(props: PiIconButtonProps) {
  return (
    <button className="pi-iconbutton" onClick={() => props.onClick()}>
      {props.icon}
    </button>
  );
}
