import {useMemo} from "react";
import {useSelector} from "react-redux";
import {SelectedTab, selectSelectedTab} from "../../app/slices/GlobalSlice";
import {selectSelectedElementType, selectTrainingType, TrainingType} from "../../app/slices/TrainingSlice";
import "./PropertiesColumn.css";
import {
    ActorPresetPropertiesColumnContent,
    ActorStatePropertiesColumnContent,
    InteractionPropertiesColumnContent, PropPresetPropertiesColumnContent,
    Take360PropertiesColumnContent,
    Take3DPropertiesColumnContent
} from "./PropertiesColumnContent";

export default function PropertiesColumn() {
    const selectedTab = useSelector(selectSelectedTab);
    const selectedElementType = useSelector(selectSelectedElementType);
    const trainingType = useSelector(selectTrainingType);

    const strategy = useMemo(() => {
        if (selectedTab === SelectedTab.ActorPresets) return ActorPresetPropertiesColumnContent;
        else if(selectedTab === SelectedTab.PropPresets) return PropPresetPropertiesColumnContent;
        else{
            if (trainingType === TrainingType.Training360 && selectedElementType === "take") {
                return Take360PropertiesColumnContent;
            } else if (selectedElementType === "take" || selectedElementType === "camera") {
                return Take3DPropertiesColumnContent;
            } else if (selectedElementType === "interaction") {
                return InteractionPropertiesColumnContent;
            } else if (selectedElementType === "actor") {
                return ActorStatePropertiesColumnContent;
            }
        }
       
    }, [selectedTab, selectedElementType]);

    const columnWidth = selectedTab !== SelectedTab.Timeline ? "w-full" : " w-[292px] min-w-[292px] ";

    return <div aria-label="property-column" className={`relative ${columnWidth} h-full bg-white pit-scrollbar`}>{strategy?.GetContent() ?? ""}</div>;

}
