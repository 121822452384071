import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEN = require("./translations/en.json");
const translationNL = require("./translations/nl.json");
const translationDE = require("./translations/de.json");
const translationFR = require("./translations/fr.json");
const translationES = require("./translations/es.json");

const resources = {
  en: {
    translation: translationEN,
  },
  nl: {
    translation: translationNL,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
