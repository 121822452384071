import { BadgeOutlined } from '@mui/icons-material';
import "./PropertiesColumnHeader.css";

export default function PropertiesColumnHeader(props: { text: string }) {
    return (
        <div className="w-full h-[60px] flex gap-[8px] bg-creator-primary-background pl-[24px] pr-[10] border-solid border-b-2 border-creator-border text-creator-text-sub items-center">
            <BadgeOutlined color={"inherit"} />
            <div className={"my-auto h-[24px] font-medium text-[15px] truncate pr-[10px]"}>{props.text}</div>
        </div>
    );
}
