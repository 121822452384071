import { useRef, useState } from "react";
import { IAnswer } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicQuizAnswerTextfieldV2 from "../components/v2/PiBasicQuizAnswerTextfieldV2";
import PiEditMenu from "../../../components/PiEditMenu";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import classNames from "classnames";
export default function ButtonsQuizPreviewAnswer(props: {
  answer: IAnswer;
  onChange: (answer: IAnswer) => void;
  onDelete: (answer: IAnswer) => void;
}) {
  const { answer, onChange, onDelete } = { ...props };
  const { getValue, changeValue } = useTrainingLocalization();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const canDelete = true;
  const parentRef = useRef<HTMLDivElement>(null);

  const onFocus = () => {
    if (canDelete) {
      setIsFocused(true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const focusedElement = event.relatedTarget || document.activeElement;

    if (!parentRef?.current?.contains(focusedElement)) {
      setIsFocused(false);
    }
  };

  const onToggleAnswer = () => {
    onChange({ ...answer, correct: !answer.correct });
  };

  const editMenu = (
    <PiEditMenu
      buttonSettings={[
        {
          icon: <CheckIcon color="inherit" fontSize="inherit" />,
          onClick: onToggleAnswer,
          highlight: answer.correct,
        },
        {
          icon: (
            <DeleteOutlineOutlinedIcon color="inherit" fontSize="inherit" />
          ),
          onClick: () => {
            setIsFocused(false);
            onDelete(answer);
          },
          highlight: false,
        },
      ]}
    />
  );

  return (
    <div
      ref={parentRef}
      tabIndex={-1}
      className={classNames({
        "relative w-[20%] h-[64px] rounded-[5px] px-[8px]": true,
        "bg-interaction-primary": !answer.correct,
        "bg-interaction-correct": answer.correct,
      })}
      onFocus={onFocus}
      onBlur={handleBlur}
    >
      <PiBasicQuizAnswerTextfieldV2
        color="white"
        localizationKey={answer.text}
      />
      {isFocused && (
        <div className="absolute top-[-48px] left-1/2 -translate-x-1/2">
          {editMenu}
        </div>
      )}
    </div>
  );
}
