import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import {
  selectImageBounds,
  selectSelectedElement,
  selectSelectedElementIsTake,
  selectViewportBounds,
  updateSelectedInteraction,
} from "../../app/slices/TrainingSlice";
import { Marker } from "../../models/interactions/Interaction";
import "./MarkerPreview.css";
import Take360 from "../../models/Take360";

interface MarkerPreviewProps {
  variant: "teleport" | "eye" | "pin";
}

export default function MarkerPreview(props: MarkerPreviewProps) {
  const { variant } = { ...props };
  const selectedElement = useSelector(selectSelectedElement);
  const isTakeSelected = useSelector(selectSelectedElementIsTake);

  const marker = useMemo(() => {
    if (isTakeSelected) return (selectedElement as Take360).originalRotation;
    else return selectedElement as Marker;
  }, [selectedElement]);

  //const marker = useSelector(selectSelectedElement) as Marker;
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [xPos, setXPos] = useState<number>(0.5);
  const [yPos, setYPos] = useState<number>(0.5);

  const imageBounds = useSelector(selectImageBounds)!;
  const parentBounds = useSelector(selectViewportBounds)!;

  useEffect(() => {
    setXPos(marker.xPos);
    setYPos(marker.yPos);
  }, [marker]);

  const debounceToSlice = useDebouncedCallback(() => {
    const updatedMarker = structuredClone(marker);
    updatedMarker.xPos = xPos;
    updatedMarker.yPos = yPos;
    dispatch(updateSelectedInteraction(updatedMarker));
  }, 500);

  const updateMarkerPosition = (nx: number, ny: number) => {
    setXPos(nx >= 0 && nx < 1 ? nx : xPos);
    setYPos(ny >= 0 && ny < 1 ? ny : yPos);

    debounceToSlice();
  };

  const getXProcentual = () => {
    return `calc(${xPos} * 100%)`;
  };

  const getYProcentual = () => {
    return `calc(${yPos} * 100%)`;
  };

  const getImgWidth = () => {
    if (imageBounds) {
      return `${(imageBounds.width / parentBounds.width) * 100}%`;
    }
  };

  const getImgHeight = () => {
    if (imageBounds) {
      return `${(imageBounds.height / parentBounds.height) * 100}%`;
    }
  };

  const getImageLeft = () => {
    if (imageBounds) {
      return `${imageBounds.x}px`;
    }
  };

  const getImageTop = () => {
    if (imageBounds) {
      return `${imageBounds.y}px`;
    }
  };

  const getPos = () => {
    return {
      ["--marker-x"]: getXProcentual(),
      ["--marker-y"]: getYProcentual(),
    };
  };

  const getImgDimensions = () => {
    return {
      ["--image-width"]: getImgWidth(),
      ["--image-height"]: getImgHeight(),
      ["--image-x"]: getImageLeft(),
      ["--image-y"]: getImageTop(),
    };
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setIsDragging(true);
    e.preventDefault();
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      const newX = e.clientX;
      const newY = e.clientY;

      const nx = (newX - parentBounds.x - imageBounds.x) / imageBounds.width;
      const ny = (newY - parentBounds.y - imageBounds.y) / imageBounds.height;

      // if (nx > 0 && nx < 1) setXPos(nx);
      // if (ny > 0 && ny < 1) setYPos(ny);
      updateMarkerPosition(nx, ny);
    }
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(false);
  };

  const getIcon = () => {
    if (variant === "teleport")
      return <PinDropOutlinedIcon fontSize="inherit" />;
    else if (variant === "eye")
      return <RemoveRedEyeOutlinedIcon fontSize="inherit" />;
    else if (variant === "pin")
      return <PushPinOutlinedIcon fontSize="inherit" />;
  };

  const markerclass = isDragging
    ? "markerpreview-marker markerpreview-dragging"
    : "markerpreview-marker";

  return (
    <div
      className="markerpreview-root"
      onPointerMove={onMouseMove}
      onPointerUp={onMouseUp}
      style={getImgDimensions() as {}}
    >
      <div
        className={markerclass}
        style={getPos() as {}}
        draggable
        onDragStart={onDragStart}
      >
        {getIcon()}
      </div>
    </div>
  );
}
