import React from "react";
import {useSelector} from "react-redux";
import {
    TrainingType,
    selectIsPlaying,
    selectSelectedTake,
    selectTrainingType,
} from "../../app/slices/TrainingSlice";
import GameWindow from "../embed_game_window/GameWindow";
import {TrainingEditorStrategy} from "./TrainingEditorStrategy";
import "./TrainingPreview.css";
import classNames from "classnames";

export default function Preview(props: { strategy: TrainingEditorStrategy, isVisible: boolean }) {
    const {strategy, isVisible} = props;
    const isPlaying = useSelector(selectIsPlaying);
    const selectedTake = useSelector(selectSelectedTake);
    const trainingType = useSelector(selectTrainingType);

    const getViewport = () => {
        return isPlaying ? <GameWindow/> : strategy.getPreview(!!selectedTake);
    };

    const getRootClasses = () => {
        if (!isPlaying) return "trainingpreview-root";
        else return "trainingpreview-root trainingpreview-root-expanded";
    };

    const content = (
        <div
            className={classNames({
                "h-full w-full": true,
                "p-[16px]": trainingType === TrainingType.Training360,
            })}
        >
            {getViewport()}
        </div>
    );

    return (
        <div
            className={classNames({
                "trainingpreview-root": isVisible,
                "w-0": !isVisible,
                "trainingpreview-root-expanded": isPlaying,
                "bg-viewport": trainingType === TrainingType.Training360,
                "bg-black": trainingType === TrainingType.Training3D,
            })}
        >
            {content}
        </div>
    );
}
