import React, { useEffect } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import "./PiContentEditable.css";

interface PiContentEditableProps {
  innerHtml: string;
  onChange: (newValue: string) => void;
  onBlur: (innerText: string) => void;
}

export default function PiContentEditable(props: PiContentEditableProps) {
  const { innerHtml, onChange, onBlur } = { ...props };
  var ref = React.useRef<HTMLElement>(null);

  useEffect(() => {
    autoFocus();
  }, []);

  const onBlurWrapper = () => {
    onBlur(ref.current?.innerText || "");
  };

  const onChangeWrapper = (e: ContentEditableEvent) => {
    onChange(e.target.value);
    //onChange(ref.current?.innerText || "");
  };

  const autoFocus = () => {
    if (ref.current) {
      ref.current?.focus();
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(ref.current);
      range.collapse(false); // Move the cursor to the end of the text
      if (selection) {
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  };

  const onKeyDownWrapper = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // when pressing enter we want to blur instead of adding a new line
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      e.preventDefault();
      ref.current?.blur();
    }
  };

  return (
    <ContentEditable
      autoFocus
      className="picontenteditable-root"
      innerRef={ref}
      html={innerHtml}
      onChange={onChangeWrapper}
      onBlur={onBlurWrapper}
      onKeyDown={onKeyDownWrapper}
    />
  );
}
