import React from "react";
import "./PiTag.css";

interface PiTagProps {
  text: string;
  variant: "360" | "3D" | "blue" | "green";
}

export default function PiTag(props: PiTagProps) {
  function getVariant(): string {
    if (props.variant === "360") {
      return "pitag-purple";
    } else if (props.variant === "3D") {
      return "pitag-yellow";
    } else if (props.variant === "blue") {
      return "pitag-blue";
    } else if (props.variant === "green") {
      return "pitag-green";
    }

    return "";
  }

  return <div className={"pitag-root " + getVariant()}>{props.text}</div>;
}
