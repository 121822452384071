import { v4 as uuidv4 } from "uuid";
import { ActorState } from "./ActorState";
import {
  InteractionProperty,
  InteractionPropertyType,
} from "./InteractionProperty";
import { CreateSetCamera, ISetCamera } from "./interactions/SetCamera";
import Take from "./Take";
import { PropTakeState } from "./PropTakeState";
import {ITransition} from "./interactions/Transition";

const required3dProperties: InteractionProperty[] = [
  {
    id: "isStory",
    locId: "cinematic_mode",
    tooltipLocId: "tooltip_cinematic_mode",
    name: "Cinematic Mode",
    type: InteractionPropertyType.boolean,
    value: false,
  },
  {
    id: "useDefaultCamera",
    locId: "use_default_camera",
    name: "Use Default Camera",
    type: InteractionPropertyType.boolean,
    value: false,
  },
];

export default interface Take3D extends Take {
  interactionPointId?: string;
  actors: ActorState[];
  props: PropTakeState[];
  mainCamera: ISetCamera;
  transition: ITransition;
}

export function CreateTake3D(
  name: string,
  actors: ActorState[],
  props: PropTakeState[],
  transition: ITransition,
  interactionPointId?: string,
  camera?: ISetCamera
): Take3D {
  return {
    id: uuidv4(),
    name: name,
    interactions: [],
    isExpanded: false,
    actors: actors,
    props: props,
    mainCamera: CreateSetCamera(camera),
    properties: required3dProperties,
    interactionPointId: interactionPointId,
    transition: transition
  };
}
