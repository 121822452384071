import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ConversationPreview from "../../features/previews/conversation/ConversationPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Take3D from "../Take3D";
import { emotionBubbleInteraction, IEmotionBubble } from "./EmotionBubble";
import Interaction, { InteractionType } from "./Interaction";
import { IMessageBubble } from "./MessageBubble";

export interface IConversation extends Interaction {
    messages: (IMessageBubble | IEmotionBubble)[];
}

export function CreateConversation(): IConversation {
    return {
        id: uuidv4(),
        name: "Conversation",
        type: InteractionType.Conversation,
        messages: [],
        properties: [],
    };
}

export function CreateConversationCopy(messages: (IMessageBubble | IEmotionBubble)[], properties: InteractionProperty[] | undefined): IConversation {
    return {
        id: uuidv4(),
        name: "Conversation",
        type: InteractionType.Conversation,
        messages: messages,
        properties: properties,
    };
}

export function MigrateConversation(from: number, to: number, interaction: IConversation): IConversation {
    return interaction;
}

export const conversationInteraction = (): InteractionFactoryEntity => {
    const component = <ConversationPreview />;

    const create = (getKeys: (amount: number) => string[]): IConversation => {
        return {
            id: uuidv4(),
            name: "Conversation",
            type: InteractionType.Conversation,
            messages: [],
            properties: [],
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): IConversation => {
        return interaction as IConversation;
    };

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IConversation => {

        const convo = interaction as IConversation;
        const numberOfKeys = convo.messages.filter((i) => i.type === InteractionType.MessageBubble).length;
        const newKeys = getKeys(numberOfKeys);
        var localCounter = -1;

        const newMessages: (IMessageBubble | IEmotionBubble)[] = convo.messages.map(message => {
            if (message.type === InteractionType.MessageBubble) {
                localCounter++;

                replaceKey((message as IMessageBubble).text, newKeys[localCounter]);

                return {
                    id: uuidv4(),
                    name: "Message Bubble",
                    type: InteractionType.MessageBubble,
                    text: newKeys[localCounter],
                    npcId: message.npcId,
                    animation: message.animation,
                    lookAt: message.lookAt,
                    messageBubbleStyle: message.messageBubbleStyle
                } as IMessageBubble
            } else if (message.type === InteractionType.EmotionBubble) {
                return emotionBubbleInteraction().Copy!(message, getKeys, replaceKey, getAnswerArray) as IEmotionBubble
                //return CreateEmotionBubbleCopy(message as IEmotionBubble);
            }

            return message;
        });

        return {
            id: uuidv4(),
            name: "Conversation",
            type: InteractionType.Conversation,
            messages: newMessages,
            properties: convo.properties,
        }
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const take3D = take as Take3D;
        const convo = interaction as IConversation;
        const warnings: string[] = [];
        let counter = 0;

        if (convo.messages.length === 0) warnings.push("Conversation has no messages");

        convo.messages.forEach(msg => {
            counter += 1;
            if (msg.type === InteractionType.MessageBubble) {
                const msgConverted = msg as IMessageBubble;
                ValidationHelper.ValidateKey(msgConverted.text, getValue, warnings, `Message ${counter}`);
                ValidationHelper.ValidateNpcInTake(msgConverted.npcId, take3D, actorPresets, warnings);
                ValidationHelper.ValidateNpcInTake(msgConverted.lookAt, take3D, actorPresets, warnings);
            } else {
                const emotionConverted = msg as IMessageBubble;
                ValidationHelper.ValidateNpcInTake(emotionConverted.npcId, take3D, actorPresets, warnings);
                ValidationHelper.ValidateNpcInTake(emotionConverted.lookAt, take3D, actorPresets, warnings);
            }
        });

        return warnings;
    }


    const filter = (interaction: Interaction): string[] => {
        let results: string[] = [];

        const conversation = interaction as IConversation

        results.push(interaction.name);

        const answers = conversation.messages.filter(item => item.type === InteractionType.MessageBubble).map(item => (item as IMessageBubble).text);


        answers.forEach(answer => { results.push(answer); });

        return results;
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    };
};


