import { useDispatch, useSelector } from "react-redux";
import { selectCachedAssets, updateCachedAsset } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const UpdateAssetName = () => {
  const organization = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const cachedAssets = useSelector(selectCachedAssets);

  const updateAsset = (assetId: string, newName: string) => {
    if (!organization) return;

    AssetService.UpdateAssetName(organization.id, token, assetId, newName);

    // update cached assets
    var asset = cachedAssets.find((a) => a.id === assetId);
    if (asset) {
      var cpy = { ...asset, name: newName };
      dispatch(updateCachedAsset(cpy));
    }
  };

  return [updateAsset] as const;
};
