import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization, UserInfo } from "../../services/UserService";
import { RootState } from "../Store";

interface UserState {
  organizations: Organization[];
  userInfo: UserInfo;
  currentOrganization?: Organization;
}

const initialState: UserState = {
  organizations: [],
  userInfo: { name: "", id: "" },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      if (action.payload.length === 0) return;
      state.organizations = action.payload;
      var cachedOrgId: string | null = localStorage.getItem("organizationId");

      if (!cachedOrgId) {
        state.currentOrganization = state.organizations[0];
      } else {
        var org = state.organizations.find((org) => org.id === cachedOrgId);
        if (org === undefined) console.log("org was undefined");
        state.currentOrganization = org ? org : state.organizations[0];
      }

      localStorage.setItem("organizationId", state.currentOrganization.id);
    },
    setUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    setCurrentOrganization: (state, action: PayloadAction<Organization>) => {
      state.currentOrganization = action.payload;
      localStorage.setItem("organizationId", state.currentOrganization.id);
    },
  },
});

// reducers
export const { setOrganizations, setUserInfo, setCurrentOrganization } = userSlice.actions;

// selectors
export const selectOrganizations = (state: RootState) => state.user.organizations;
export const selectUsername = (state: RootState) => state.user.userInfo.name;
export const selectUserId = (state: RootState) => state.user.userInfo.id;
export const selectCurrentOrganization = (state: RootState) => state.user.currentOrganization;
export const selectIsGreygin = (state: RootState) => state.user.currentOrganization?.id === "ba31195f-b096-475d-81c1-86e9c4cfd184" || state.user.currentOrganization?.id === "d1a04fe7-7db4-4594-aa1c-a0fced0e0e0f";
export default userSlice.reducer;
