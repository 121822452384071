import {InteractionFactoryEntity} from "../../features/interaction_factory/InteractionFactory";
import Interaction, {InteractionType} from "./Interaction";
import {GetNewGuid} from "../../helpers/GuidHelper";
import {IGrab} from "./Grab";
import AttachPreview from "../../features/previews/attach/AttachPreview";

export interface IAttach extends Interaction {
    playerGuid: string;
    propGuid: string;
    prefabName: string;
}

export const attachInteraction = () : InteractionFactoryEntity => {
    const component = <AttachPreview />
    
    const create = (getKeys: (amount: number) => string[]): IAttach => {
        return {
            id: GetNewGuid(),
            name: "Attach",
            type: InteractionType.Attach,
            playerGuid: "",
            propGuid: "",
            prefabName: ""
        }
    }
    
    const migrate = (from: number, to: number, interaction: Interaction): IAttach => {
        return interaction as IAttach;
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): IGrab => {
        return create(getKeys);
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy
    }
}