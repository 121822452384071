import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectEditingAsset, selectEditingVariantId, setEditingVariantId } from "../../app/slices/AssetsSlice";
import { selectAvailableLanguages } from "../../app/slices/TrainingLocalizationSlice";
import PiInputfield from "../../components/PiInputField";
import { AssetKey } from "../../dto/AssetDTO";
import { useAssetDetails } from "../../hooks/GetAssetDetailsHook";
import { useGetLanguages } from "../../hooks/GetLanguagesHook";
import VariantCard from "./VariantCard";
import VariantFileDropzone from "./VariantFileDropzone";
import "./VariantsList.css";

export default function VariantsList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string>("");
  const editingAsset = useSelector(selectEditingAsset);
  const [getAssetDetails] = useAssetDetails();
  const editingVariantIndex = useSelector(selectEditingVariantId);
  const [getLanguages] = useGetLanguages();
  const languages = useSelector(selectAvailableLanguages);

  useEffect(() => {
    if (editingAsset) {
      getAssetDetails(editingAsset.id);
      getLanguages(); // we need all languages

      if (editingAsset.variants.length > 0) {
        dispatch(setEditingVariantId(editingAsset.variants[0].id));
      }
    }
  }, []);

  const onVariantCardClick = (id: string) => {
    if (id !== "loading") dispatch(setEditingVariantId(id));
  };

  const variantGridItems = editingAsset?.variants
    .filter((variant) => {
      if (filter) {
        // EDGE CASE: includes "default" into the search filter
        if (variant.variant === "default") {
          return variant.variant.toLowerCase().includes(filter.toLowerCase());
        }

        // query to check if variant matches a language
        var lang = languages.find((lang) => lang.shortcode === variant.variant);
        // if we find a language match, check if language name includes the filter
        if (lang) return lang.full.toLowerCase().includes(filter.toLowerCase()) || lang.shortcode.toLowerCase().includes(filter.toLowerCase());
        // if we dont have a language match, check if the filter matches "no language set"
        else return t("no_language_set").toLowerCase().includes(filter.toLowerCase());
      } else {
        // if not filter, then show all
        return true;
      }
    })
    .map((variant, index) => {
      return <VariantCard key={index} variant={variant} assetKey={editingAsset.key} onClick={() => onVariantCardClick(variant.id)} isSelected={editingVariantIndex === variant.id} />;
    });

  const dropzone = <VariantFileDropzone id={editingAsset?.id ?? ""} assetKey={editingAsset?.key ?? AssetKey.Unsupported} />;

  return (
    <div className="variantslist-root">
      <div className="variantslist-title">{t("localizations")}</div>
      <div className="variantslist-search">
        <PiInputfield label="" placeholder={t("search_localization")} onChange={(e) => setFilter(e)} />
      </div>
      <div className="variantslist-grid-wrapper">
        <div className="variantslist-grid playit-scrollbar">
          {dropzone}
          {variantGridItems}
        </div>
      </div>
    </div>
  );
}
