import React, { useSelector } from "react-redux";
import {
  selectImageBounds,
  selectSelectedElement,
  selectViewportBounds,
} from "../../app/slices/TrainingSlice";
import Interaction, {
  InteractionType,
  Marker,
} from "../../models/interactions/Interaction";
import Take360 from "../../models/Take360";
import MarkerPreview from "../previews/MarkerPreview";
import { getInteractionFromFactory } from "../interaction_factory/InteractionFactory";

export const useOverlay = () => {
  const selectedElement = useSelector(selectSelectedElement);
  const parentBounds = useSelector(selectViewportBounds);
  const imageBounds = useSelector(selectImageBounds);

  const getOverlayElement = () => {
    if (selectedElement) {
      if ((selectedElement as Take360).teleportMarker !== undefined) {
        return getTake360Overlay(selectedElement as Take360);
      } else if ((selectedElement as Interaction).type !== undefined) {
        return getInteractionOverlayElement(selectedElement as Interaction);
      }
    } else {
      return <></>;
    }
  };

  function getTake360Overlay(take: Take360) {
    if (parentBounds && imageBounds)
      return take.is360Image ? <MarkerPreview variant="eye" /> : <></>;
  }

  function getInteractionOverlayElement(interaction: Interaction) {
    var factoryResult = getInteractionFromFactory(interaction.type);

    return factoryResult ? factoryResult.View : <></>;
  }

  return { getOverlayElement, getInteractionOverlayElement } as const;
};
