import Skeleton from "@mui/material/Skeleton";
import React from "react";
import "./AssetCardSkeleton.css";

export default function AssetCardSkeleton() {
  return (
    <div className="assetcardskeleton-root">
      <div className="assetcardskeleton-top">
        <Skeleton variant="rounded" style={{ borderRadius: "7px 7px 0px 0px" }} height={"100%"} animation={"wave"} />
      </div>
      <div className="assetcardskeleton-bottom">
        <Skeleton variant="text" sx={{ fontSize: "24px", marginLeft: "25px" }} width={"80%"} />
        <Skeleton variant="text" sx={{ fontSize: "16px", marginLeft: "25px" }} width={"80%"} />
      </div>
    </div>
  );
}
