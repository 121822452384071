import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import usePagination from "@mui/material/usePagination";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentPageNumber, selectTotalPages, setCurrentTrainingPageNumber } from "../../app/slices/ProjectsSlice";
import "./ProjectsTablePagination.css";
export default function ProjectsTablePagination() {
  const { t } = useTranslation();
  const totalPages = useSelector(selectTotalPages);
  const currentPage = useSelector(selectCurrentPageNumber);
  const dispatch = useDispatch();
  const [next, setNext] = useState<JSX.Element>();
  const [previous, setPrevious] = useState<JSX.Element>();
  const [pages, setPages] = useState<JSX.Element[]>([]);

  const onChange = (e: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setCurrentTrainingPageNumber(page));
  };
  const { items } = usePagination({
    count: totalPages,
    onChange: onChange,
  });

  useEffect(() => {
    generatePageElements();
  }, [totalPages, currentPage]);

  const generatePageElements = () => {
    var copy: JSX.Element[] = [];
    items.forEach(({ page, type, selected, ...item }, index) => {
      if (type === "start-ellipsis" || type === "end-ellipsis") {
        copy.push(<div key={index}>...</div>);
      }

      if (type === "page") {
        copy.push(
          <button key={index} {...item} className={!selected ? "projectstablepagination-page" : "projectstablepagination-page projectstablepagination-selected"}>
            {page}
          </button>
        );
      }

      if (type === "previous") {
        setPrevious(
          <div className="projectstablepagination-previous">
            <Button variant="piGrey" startIcon={<ArrowBackIcon />} {...item}>
              <span>{t("prev")}</span>
            </Button>
          </div>
        );
      }

      if (type === "next") {
        setNext(
          <div className="projectstablepagination-next">
            <Button variant="piGrey" endIcon={<ArrowForwardIcon />} {...item}>
              <span>{t("next")}</span>
            </Button>
          </div>
        );
      }
      setPages(copy);
    });
  };

  return (
    <div className="projectstablepagination-root">
      <div className="projectstablepagination-grid">
        {previous}
        <div className="projectstablepagination-pages">{pages}</div>
        {next}
      </div>
    </div>
  );
}
