import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
export default function PiCreatorDropdown(props: { trigger: React.ReactNode, items: JSX.Element[], label?: string }) {
    const {trigger, items, label} = {...props};
    const [width, setWidth] = useState<number>(1000);
    const ref = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    useEffect(() => {
        if (ref.current) {
            var w = ref.current?.offsetWidth.toString() + "px";
            setWidth(ref.current?.offsetWidth);
        }
    }, [ref.current?.offsetWidth]);
    
    return <div ref={ref} className={"flex flex-col gap-[8px]"}>
        {label && <div className={"text-creator-text text-[14px] font-medium"}>{label}</div>}
        <DropdownMenu.Root onOpenChange={setIsOpen}>
            <DropdownMenu.Trigger className={"w-full h-[68px] bg-white px-[12px] py-[10px] flex border-[2px] border-solid border-creator-primary rounded-[14px] focus:outline-none"}>
                <div className={"my-auto text-creator-text text-[14px] text-interaction font-medium w-full h-full"}>
                    {trigger}
                </div>
                <div className={classNames(
                    {
                        "my-auto ml-auto text-creator-text": true,
                        "transition duration-100 ease-in-out": true,
                        "transform rotate-180": isOpen,
                        "transform rotate-0": !isOpen,
                    })}>
                    <ExpandMoreIcon color={"inherit"}/>
                </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content style={{width: width + "px"}} className={"flex flex-col max-h-[200px] pit-creator-scrollbar overflow-y-auto gap-[10px] py-[10px]  rounded-[5px] bg-white" +
                " border-solid" +
                " border-[1px]" +
                " border-creator-border drop-shadow-md z-40"}>
                {items.map((item, index) => {
                    return <DropdownMenu.Item key={index} className={"cursor-pointer focus:outline-none px-[8px] hover:bg-creator-primary-washed"}>
                        {item}
                    </DropdownMenu.Item>
                })
                }
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    </div>


}