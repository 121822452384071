import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    addMostRecentInteraction,
    selectSelectedInteractionDefinition,
    selectSelectedInteractionPropStateId,
    setSelectedInteractionDefinition,
    setSelectedInteractionPropStateId
} from "../../app/slices/InteractionPickerSlice";
import {InteractionDefintion} from "./InteractionDefinitions";
import "./InteractionPickerButton.css";
import {useInteractionPickerIcons} from "./InteractionPickerIconHooks";
import useInteractionPicker from "../../hooks/InteractionPicker.hook";

interface InteractionPickerButtonProps {
    interactionDefinition: InteractionDefintion;
    onClose: () => void;
}

interface DynamicInteractionPickerButtonProps extends InteractionPickerButtonProps {
    propStateId: string;
}


export function DynamicInteractionPickerButton(props: DynamicInteractionPickerButtonProps) {
    const {interactionDefinition, onClose, propStateId} = {...props};
    const [getIcon] = useInteractionPickerIcons();
    const selectedInteractionDef = useSelector(selectSelectedInteractionDefinition);
    const selectedPropStateId = useSelector(selectSelectedInteractionPropStateId);
    
    const dispatch = useDispatch();
    const {addNewInteraction} = useInteractionPicker();

    const onClick = () => {
        dispatch(setSelectedInteractionDefinition(interactionDefinition));
        dispatch(setSelectedInteractionPropStateId(propStateId));
    };

    const onDoubleClick = () => {
        // 1. create interaction
        // const interaction: Interaction = createInteraction(interactionDefinition.type);
        // // 2. add interaction to bubble (training slice)
        // dispatch(addInteractionToSelectedTake(interaction));
        // // 3. add interaction def to "Most recent" on interaction def slice
        // dispatch(addMostRecentInteraction(interactionDefinition));

        addNewInteraction();

        // 4. close window
        onClose();
    };

    const rootclasses =
        selectedInteractionDef.name === interactionDefinition.name && propStateId === selectedPropStateId ? "interactionpickerbutton-root interactionpickerbutton-selected" : "interactionpickerbutton-root" +
            " interactionpickerbutton-default";

    return (
        <div className={rootclasses} onDoubleClick={onDoubleClick} onClick={onClick}>
            <div className="interactionpickerbutton-content">
                <div className="interactionpickerbutton-icon">{getIcon(interactionDefinition)}</div>
                <div className="interactionpickerbutton-name">{interactionDefinition.name}</div>
            </div>
        </div>
    );
}



export default function InteractionPickerButton(props: InteractionPickerButtonProps) {
    const {interactionDefinition, onClose} = {...props};
    const [getIcon] = useInteractionPickerIcons();
    const selectedInteractionDef = useSelector(selectSelectedInteractionDefinition);
    const dispatch = useDispatch();
    const {addNewInteraction} = useInteractionPicker();

    const onClick = () => {
        dispatch(setSelectedInteractionDefinition(interactionDefinition));
    };

    const onDoubleClick = () => {
        // 1. create interaction
        // const interaction: Interaction = createInteraction(interactionDefinition.type);
        // // 2. add interaction to bubble (training slice)
        // dispatch(addInteractionToSelectedTake(interaction));
        // // 3. add interaction def to "Most recent" on interaction def slice
        // dispatch(addMostRecentInteraction(interactionDefinition));

        addNewInteraction();

        // 4. close window
        onClose();
    };

    const rootclasses =
        selectedInteractionDef.name === interactionDefinition.name ? "interactionpickerbutton-root interactionpickerbutton-selected" : "interactionpickerbutton-root interactionpickerbutton-default";

    return (
        <div className={rootclasses} onDoubleClick={onDoubleClick} onClick={onClick}>
            <div className="interactionpickerbutton-content">
                <div className="interactionpickerbutton-icon">{getIcon(interactionDefinition)}</div>
                <div className="interactionpickerbutton-name">{interactionDefinition.name}</div>
            </div>
        </div>
    );
}
