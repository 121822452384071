import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {TakePosition, selectEnvironmentInteractionPoints} from "../../app/slices/EnvironmentSlice";
import {selectImageBounds, selectSelectedElement, selectSelectedTake3D, selectViewportBounds} from "../../app/slices/TrainingSlice";
import Interaction, {InteractionType} from "../../models/interactions/Interaction";
import Viewport3DCameraLayer from "./Viewport3DCameraLayer";
import "./Viewport3DEnvironmentMetadata.css";
import Viewport3DNpcLayer from "./Viewport3DNpcLayer";
import Viewport3DTakeLocationLayer from "./ViewportTakeLocationLayer";
import Viewport3DPropLayer from "./Viewport3DPropLayer";

export interface Viewport3DLayerProps {
    currentTakeLocation?: TakePosition;
    takePositions: TakePosition[];
    isCameraSelected?: boolean;
}

export default function EnvironmentMetadataView() {
    const take = useSelector(selectSelectedTake3D);
    const imageBounds = useSelector(selectImageBounds);
    const parentBounds = useSelector(selectViewportBounds);
    const takePositions = useSelector(selectEnvironmentInteractionPoints);
    const currentTakeLocation = useMemo(() => {
        return take?.interactionPointId ? takePositions.find((point) => point.id === take.interactionPointId) : undefined;
    }, [take, takePositions]);

    const selectedElement = useSelector(selectSelectedElement);
    const isCameraSelected = useMemo(() => {
        if (!selectedElement) return false;

        if ((selectedElement as Interaction).type !== undefined && (selectedElement as Interaction).type === InteractionType.SetCamera) return true;
        return false;
    }, [selectedElement]);

    return (
        <>
            <Viewport3DNpcLayer currentTakeLocation={currentTakeLocation} takePositions={takePositions} isCameraSelected={isCameraSelected}/>
            <Viewport3DTakeLocationLayer currentTakeLocation={currentTakeLocation} takePositions={takePositions} isCameraSelected={isCameraSelected}/>
            <Viewport3DCameraLayer currentTakeLocation={currentTakeLocation} takePositions={takePositions} isCameraSelected={isCameraSelected}/>
            <Viewport3DPropLayer currentTakeLocation={currentTakeLocation} takePositions={takePositions} isCameraSelected={isCameraSelected}/>
        </>
    );
}
