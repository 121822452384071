export interface Actor {
  id: string;
  workingName: string;
  profilePicture: number;
  randomProfilePicture?: Boolean;
  positionId?: string;
  outfit?: string;
  preset?: string;
  gender: Gender;
}

export enum Gender {
  random = 0,
  male = 1,
  female = 2,
}

export const ProfilePictures: string[] = [
  "/profile_pictures/pfp_1.png",
  "/profile_pictures/pfp_2.png",
  "/profile_pictures/pfp_3.png",
  "/profile_pictures/pfp_4.png",
];

export const GenderNeutralNames = ["Sam", "Remi", "Drew", "Rami", "Kai", "Andi", "Noa", "Kris", "Robin", "Lee"];
