import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../app/slices/TrainingSlice";
import { LisaMessage } from "../../models/interactions/LisaMessage";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import "./LisaMessagePreview.css";

export default function LisaMessagePreview() {
  const lisaMessageData = useSelector(selectSelectedElement) as LisaMessage;
  const { getValue, changeValue } = useTrainingLocalization();

  return (
    <div className="lisamessagepreview-root">
      <div className="lisamessagepreview-position">
        <div className="lisamessagepreview-text-wrapper">
          <div className="lisamessagepreview-text">
            <textarea aria-label="lisamessage-text-area" value={getValue(lisaMessageData.message)} onChange={(e) => changeValue(lisaMessageData.message, e.target.value)} />
          </div>
          <div className="lisamessagepreview-arrow">
            <img src="arrow.png" alt="" />
          </div>
        </div>

        <div className="lisamessagepreview-img">
          <img aria-label="lisamessage-image" src="/lisa.png" alt="" />
        </div>
      </div>
    </div>
  );
}
