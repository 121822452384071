import {useDispatch, useSelector} from "react-redux";
import {selectCurrentOrganization} from "../../app/slices/UserSlice";
import {selectToken} from "../../app/slices/AuthenticationSlice";
import {PropService} from "../../services/PropService";
import {setPropCategories, setPropInteractions, setSelectedPropContent} from "../../app/slices/PropLibrarySlice";
import {PropMetadata} from "../../dto/PropMetadata";
import {TranslatedPropContentDTO} from "../../dto/TranslatedPropContentDTO";

export default function UsePropLibrary() {
    const dispatch = useDispatch();
    const orgId = useSelector(selectCurrentOrganization)?.id;
    const token = useSelector(selectToken);

    async function loadCategories() {
        if (!orgId || !token) return;

        const result = await PropService.GetTranslatedCategories(orgId, token);
        dispatch(setPropCategories(result));
    }

    async function loadInteractions() {
        if (!orgId || !token) return;

        const result = await PropService.GetTranslatedInteractions(orgId, token);
        dispatch(setPropInteractions(result));
    }

    async function loadPropContent(propId: string) {
        if (!orgId || !token) return;

        // load content
        var content: TranslatedPropContentDTO | undefined = await PropService.GetPropContent(orgId, token, propId);
        if (!content) return;

        // get metadata 
        var metadata: PropMetadata | undefined = await PropService.GetPropMetadata(content.metadataUri);
        if (!metadata) return;

        
        if (content) dispatch(setSelectedPropContent({content, metadata}));
    }

    return {loadCategories, loadInteractions, loadPropContent}
}