import { AccessibilityNewOutlined, AccountCircleOutlined, CheckroomOutlined, FaceOutlined, FormatColorText } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedActor, updateActor } from "../../app/slices/TrainingSlice";
import PiInputfield from "../../components/PiInputField";
import { Actor } from "../../models/Actor";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import ActorCharacterPresetSelector from "./ActorCharacterPresetSelector";
import ActorGenderSelector from "./ActorGenderSelector";
import ActorOutfitSelector from "./ActorOutfitSelector";
import "./ActorPresetEditor.css";
import ActorPresetEditorField from "./ActorPresetEditorField";
import ActorProfilePictureSelector from "./ActorProfilePictureSelector";
export default function ActorPresetEditor() {
  const selectedActor = useSelector(selectSelectedActor);
  const dispatch = useDispatch();
  const [workingName, setWorkingName] = useState<string>(selectedActor?.workingName ?? "");

  useEffect(() => {
    setWorkingName(selectedActor?.workingName ?? "");
  }, [selectedActor]);

  const onChangeWorkingName = (newName: string) => {
    if (!selectedActor) return;

    const actorCopy: Actor = { ...selectedActor, workingName: newName };

    setWorkingName(newName);
    dispatch(updateActor(actorCopy));
  };

  return (
    <>
      <div className="flex-col">
        <PropertiesColumnHeader text="Editing Actor Preset" />
      </div>

      {selectedActor?.id !== "player" && (
        <div aria-label="actor-preset-window-root" className="flex flex-col h-[calc(100%-60px)] px-[14px] py-[20px] gap-[20px] overflow-y-auto pb-18 pit-creator-scrollbar">
          <div className="flex flex-col gap-[20px]">
            <div className={"flex px-[14px] w-1/2"}>
              <ActorPresetEditorField name="Actor Name" icon={FormatColorText}>
                <PiInputfield label="" onChange={onChangeWorkingName} placeholder="Working name..." value={workingName} />
              </ActorPresetEditorField>
            </div>
            <Divider />
            <div className="flex px-[14px] gap-[20px]">
              <ActorPresetEditorField name="Actor Profile Picture" icon={AccountCircleOutlined}>
                <ActorProfilePictureSelector />
              </ActorPresetEditorField>
              <ActorPresetEditorField name="Actor Body Type" icon={AccessibilityNewOutlined}>
                <ActorGenderSelector />
              </ActorPresetEditorField>
            </div>
            <Divider />
            <div className="flex px-[14px] gap-[20px]">
              <ActorPresetEditorField name="Actor Outfit" icon={CheckroomOutlined}>
                <ActorOutfitSelector />
              </ActorPresetEditorField>
              <ActorPresetEditorField name="Actor Presets" icon={FaceOutlined}>
                <ActorCharacterPresetSelector />
              </ActorPresetEditorField>
            </div>
            <Divider />
          </div>
        </div>
      )}
      {selectedActor?.id === "player" && (
        <div className="actorpreseteditor-locked">
          <LockOutlinedIcon fontSize="inherit" color="inherit" />
        </div>
      )}
    </>

  );
}

function Divider() {
  return <div className="w-full border-b-2 text-creator-border"></div>
}


