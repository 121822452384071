import {ActorState} from "../../models/ActorState";
import ActorAvatar from "../../components/actors/ActorAvatar";
import {selectActors} from "../../app/slices/TrainingSlice";
import {useSelector} from "react-redux";

export default function ActorStatePropertiesHeader(props: {actor: ActorState}) {
    
    const {actor} = {...props};
    const actorPreset = useSelector(selectActors).find((a) => a.id === actor.actorId);
    
    return <div className={"w-full flex flex-col gap-[10px] py-[10px]"}>
        <div className={"mx-auto drop-shadow-md"}>
            <ActorAvatar actor={actorPreset} theme={"creator-selected"  } size={"36"} />
        </div>
        <div className={"mx-auto text-[14px] text-creator-text-sub font-medium"}>
            {actorPreset?.workingName}
        </div>
    </div>
}