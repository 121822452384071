import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { setIsLoading } from "../app/slices/GlobalSlice";
import { setCurrentTrainingId } from "../app/slices/ProjectsSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { Actor, Gender } from "../models/Actor";
import { Training } from "../models/Training";
import { TrainingService } from "../services/TrainingService";

export const useCreateTraining = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);

  const create360Training = async (name: string, description: string, type: number, refLanguage: string) => {
    if (!orgId) return;

    dispatch(setIsLoading(true));
    // make the new training
    var training = await TrainingService.CreateTraining(orgId, token, name, description, type);

    if (training) {
      // make an empty json structure
      var emptyProject: Training = {
        takes: [],
        trainingType: 1,
        refLanguage: refLanguage,
        version: 1,
      };

      // make a new version
      await TrainingService.CreateNewTrainingVersion(orgId, token, training?.id, JSON.stringify(emptyProject));
      // set current training id
      dispatch(setCurrentTrainingId(training.id));

      dispatch(setIsLoading(false));

      return training.id;
    }
  };

  const create3DTraining = async (name: string, description: string, type: number, refLanguage: string, environmentId: string) => {
    if (!orgId) return;

    dispatch(setIsLoading(true));
    // make the new training
    var training = await TrainingService.CreateTraining(orgId, token, name, description, type);

    var actors: Actor[] = [{ id: "player", profilePicture: 0, workingName: "Player", gender: Gender.random }];

    if (training) {
      // make an empty json structure
      var emptyProject: Training = {
        takes: [],
        refLanguage: refLanguage,
        trainingType: 0,
        version: 1,
        metadata: {
          environmentId: environmentId,
          actors: actors,
        },
      };

      // make a new version
      await TrainingService.CreateNewTrainingVersion(orgId, token, training?.id, JSON.stringify(emptyProject));
      // set current training id
      dispatch(setCurrentTrainingId(training.id));

      dispatch(setIsLoading(false));

      return training.id;
    }
  };

  return { create360Training, create3DTraining } as const;
};
