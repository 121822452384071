import {useDispatch, useSelector} from "react-redux";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {ISlideshow, slideshowInteraction} from "../../../models/interactions/Slideshow";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PiInteractionAssetPreview from "../components/PiInteractionAssetPreview";
import {AssetDetailsDTO, AssetKey} from "../../../dto/AssetDTO";
import {useMemo, useState} from "react";
import AssetView from "../../file_explorer/AssetView";
import PiInteractionCarouselDots from "../../../components/PiInteractionCarouselDots";
import {Slide} from "@mui/material";

export default function SlideshowBodyPreview() {
    const slideshow = useSelector(selectSelectedElement) as ISlideshow;
    const dispatch = useDispatch();

    const [index, setIndex] = useState<number>(0);

    const assetIdList = useMemo(() => {
        console.log("update asset id list");
        return [...slideshow.assetIds, ""];
    }, [slideshow])

    const onAssetSelect = (asset: AssetDetailsDTO) => {
        const cpy = [...slideshow.assetIds];
        console.log("index" + index);
        console.log("length " + assetIdList.length);

        if (index === assetIdList.length - 1) {
            console.log("case 1")
            // add new asset id
            cpy.push(asset.assetId);
            dispatch(updateSelectedInteraction({...slideshow, assetIds: cpy} as ISlideshow))
        } else {
            // update existing asset id
            cpy[index] = asset.assetId;
            dispatch(updateSelectedInteraction({...slideshow, assetIds: cpy} as ISlideshow))
        }
    }

    const onAssetDelete = () => {
        const cpy =[...slideshow.assetIds].filter((_, myIndex) => myIndex !== index);
        
        console.log("before " + slideshow.assetIds.length);
        console.log("after " + cpy.length);
        
        dispatch(updateSelectedInteraction({...slideshow, assetIds: cpy} as ISlideshow))
    }

    const assetViewsMapped = useMemo(() => assetIdList.map((assetId, myIndex) => <PiInteractionAssetPreview
            key={myIndex} assetType={AssetKey.Image}
            assetId={assetId}
            onAssetSelect={(asset) => onAssetSelect(asset)}
            onDelete={(index !== assetIdList.length - 1 && slideshow.assetIds.length > 1) ? onAssetDelete : undefined}/>)
        , [slideshow, index, assetIdList]);


    const cycle = (amount: number) => {
        let newIndex = index + amount;
        if (newIndex >= assetIdList.length) newIndex = 0;
        if (newIndex < 0) newIndex = assetIdList.length - 1;

        setIndex(newIndex);
    }

    const arrowLeft = <div
        className={"my-auto w-[48px] h-[48px] bg-interaction-primary text-[32px] text-white rounded-full flex cursor-pointer hover:bg-interaction-primary-hover"}
        onClick={() => cycle(-1)}>
        <ChevronLeftIcon className={"m-auto"} fontSize={"inherit"} color={"inherit"}/>
    </div>

    const arrowRight = <div
        className={"my-auto w-[48px] h-[48px] bg-interaction-primary text-[32px] text-white rounded-full flex cursor-pointer hover:bg-interaction-primary-hover"}
        onClick={() => cycle(1)}>
        <ChevronRightIcon className={"m-auto"} fontSize={"inherit"} color={"inherit"}/>
    </div>


    // TODO: delete slide, carousel dots
    // 

    return <div className={"w-full h-full flex flex-col gap-[8px]"}>
        <div className={"flex h-[90%] gap-[12px]"}>
            <div className={"my-auto"}>{arrowLeft}</div>
            {assetViewsMapped[index]}
            <div className={"my-auto"}>{arrowRight}</div>
        </div>
        <div className={"m-auto"}>
            <PiInteractionCarouselDots selectedIndex={index} totalDots={assetIdList.length}/>
        </div>
    </div>
}