import ActorAvatar from '../../../components/actors/ActorAvatar';
import { Actor } from '../../../models/Actor';

interface PiAvatarProfileGroupProps {
    actors: Actor[];
    maxActorsShown: number;
}

export function PiAvatarProfileGroup(props: PiAvatarProfileGroupProps) {
    const { maxActorsShown, actors } = { ...props }

    if (actors.length <= maxActorsShown) {
        return (
            <div className="flex justify-end flex-0">
                {
                    actors.map(actor =>
                        <div className="ml-[-20px]">
                            <ActorAvatar actor={actor} />
                        </div>)
                }
            </div>);
    }
    else {
        return (
            <div className="flex justify-end flex-0">
                {
                    actors.map((actor, index) => index < maxActorsShown ?
                        <div className="ml-[-20px]">
                            <ActorAvatar actor={actor} />
                        </div> : null)
                }
                <div className="flex ml-[-20px] rounded-circle w-[36px] h-[36px] justify-center items-center font-semibold leading-6 text-interaction-text-inverted bg-interaction-primary text-[16px]">
                    {actors.length - maxActorsShown}
                </div>
            </div>);
    }

}
