import React, { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../app/slices/TrainingSlice";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { VideoPopup } from "../../models/interactions/VideoPopup";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionAssetPreview from "./components/PiInteractionAssetPreview";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";
import PiPlayButtonPreview from "./components/PiPlayButtonPreview";
import PiPopupWindowPreview from "./components/PiPopupWindowPreview";

export default function VideoPopupPreview() {
  const videoPopupData = useSelector(selectSelectedElement) as VideoPopup;
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onAssetSelect = (asset: AssetDetailsDTO) => {
    const updatedVideo = { ...videoPopupData };
    updatedVideo.assetId = asset.assetId;
    dispatch(updateSelectedInteraction(updatedVideo));
  };

  const title = <PiInteractionTitlePreview value={getValue(videoPopupData.title)} onChange={(e) => changeValue(videoPopupData.title, e)} placeholder="Video Popup" />;
  const body = <PiInteractionAssetPreview assetType={AssetKey.Video} assetId={videoPopupData.assetId} onAssetSelect={onAssetSelect} />;
  
  return <PiPopupWindowPreview title={title} body={body} />;
}
