import { useDispatch, useSelector } from "react-redux";
import {
  registerNewKey,
  requestArrayOfKeys,
  requestNewKey,
  selectCurrentLanguage,
  selectTrainingLocalizationState,
  updateLocalizedString,
} from "../../app/slices/TrainingLocalizationSlice";
import { setIsDirty } from "../../app/slices/TrainingSlice";
import useValidation from "../validation/Validation.hooks";

export const useTrainingLocalization = () => {
  const dispatch = useDispatch();
  const locState = useSelector(selectTrainingLocalizationState);
  const currLang = useSelector(selectCurrentLanguage);

  function getNewKey() {
    const newKey = requestNewKey(locState);
    dispatch(registerNewKey(newKey));

    return newKey;
  }

  function getValue(key: string) {
    if (locState.localizedEntries[key]) {
      return locState.localizedEntries[key][currLang.shortcode].value;
    }
    return "";
  }

  function changeValue(key: string, newValue: string) {
    dispatch(updateLocalizedString({ key: key, newValue: newValue }));
    dispatch(setIsDirty());
}

  // this is not great but i will refactor it later
  function GetArrayOfKeys(amount: number) {
    const newKeys = requestArrayOfKeys(locState, amount);
    newKeys.forEach((key) => dispatch(registerNewKey(key)));
    return newKeys;
  }

  return { getValue, changeValue, getNewKey, GetArrayOfKeys } as const;
};
