import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../models/Quiz";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import PiQuizesFormPreview from "./components/PiBasicQuizBody";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";
import PiPlayButtonPreview from "./components/PiPlayButtonPreview";
import PiPopupWindowPreview from "./components/PiPopupWindowPreview";

export interface PiBasicQuizPreviewProps {
  multipleCorrectAnswers?: boolean;
  displayGraphics?: boolean;
  hasFooter?: boolean;
  showStars?: boolean;
}

export default function PiBasicQuizPreview(props: PiBasicQuizPreviewProps) {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue } = useTrainingLocalization();
  const { multipleCorrectAnswers, displayGraphics, hasFooter, showStars } = { ...props };

  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Quiz Title" showStarIcons={showStars} />;

  const body = <PiQuizesFormPreview multipleCorrectAnswers={multipleCorrectAnswers} displayGraphics={displayGraphics} />;
  return <PiPopupWindowPreview title={title} body={body} />;
}
