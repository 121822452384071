import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { CreateClickRegion, IClickRegion } from "../../../models/interactions/ClickRegion";
import { IClickRegionOrderQuiz } from "../../../models/interactions/ClickRegionOrderQuiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import QuizPromptPreview from "../QuizPromptPreview";
import ClickRegionPreview from "../click_region/ClickRegionPreview";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import "./ClickRegionOrderQuizPreview.css";

export default function ClickRegionOrderQuizPreview() {
  const quiz = useSelector(selectSelectedElement) as IClickRegionOrderQuiz;
  const dispatch = useDispatch();
  const { getValue, changeValue } = useTrainingLocalization();

  const canDelete = quiz.clickregions.length > 2;
  const canAdd = quiz.clickregions.length < 15;

  const onRegionChange = (region: IClickRegion) => {
    var copiedList = [...quiz.clickregions];
    var index = copiedList.indexOf(copiedList.find((cr) => cr.id === region.id)!);
    copiedList[index] = region;

    var copy: IClickRegionOrderQuiz = { ...quiz, clickregions: copiedList };
    dispatch(updateSelectedInteraction(copy));
  };

  const onAddRegion = () => {
    const newRegions = [...quiz.clickregions, CreateClickRegion()];
    const updatedQuiz: IClickRegionOrderQuiz = { ...quiz, clickregions: newRegions };
    dispatch(updateSelectedInteraction(updatedQuiz));
  };

  const onDeleteRegion = (clickregion: IClickRegion) => {
    const updatedRegions = quiz.clickregions.filter((region) => region.id !== clickregion.id);
    const updatedQuiz: IClickRegionOrderQuiz = { ...quiz, clickregions: updatedRegions };
    dispatch(updateSelectedInteraction(updatedQuiz));
  };

  const regions = quiz.clickregions.map((region, index) => {
    return <ClickRegionPreview key={region.id} clickregionProp={region} onChange={onRegionChange} index={index + 1} canDelete={canDelete} onDelete={onDeleteRegion} />;
  });

  const title = <QuizPromptPreview onChange={(e) => changeValue(quiz.title, e)} value={getValue(quiz.title)} />;

  const addButton = (
    <div className="clickregionorderquiz-add-button">
      <PiBasicQuizAddButton onClick={onAddRegion} />
    </div>
  );

  return (
    <div className="clickregionorderquiz-root">
      {title}
      {canAdd && addButton}
      {regions}
    </div>
  );
}
