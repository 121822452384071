import { useSelector } from "react-redux";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import ButtonsQuizPreviewBody from "./ButtonsQuizPreviewBody";

export default function ButtonsQuizPreview() {
  const buttonsQuiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue } = useTrainingLocalization();

  const title = (
    <PiInteractionTitlePreview
      value={getValue(buttonsQuiz.title)}
      onChange={(newValue) => changeValue(buttonsQuiz.title, newValue)}
      placeholder={"Enter your Title"}
    />
  );

  return (
    <PiPopupWindowPreview
      size="medium"
      body={<ButtonsQuizPreviewBody />}
      title={title}
    />
  );
}
