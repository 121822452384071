import {ActorState} from "../../models/ActorState";
import ActorAvatar from "../../components/actors/ActorAvatar";
import {Actor} from "../../models/Actor";
import {useDispatch, useSelector} from "react-redux";
import {selectActors, selectSelectedTake3D, setSelectedInteraction} from "../../app/slices/TrainingSlice";
import DoorBackOutlinedIcon from '@mui/icons-material/DoorBackOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';

export default function TakePropertiesActor(props: { actor: ActorState, isInvolved: boolean }) {
    const {actor, isInvolved} = {...props};
    const actorPreset = useSelector(selectActors).find(a => a.id === actor.actorId);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setSelectedInteraction(actor.id));
    }

    // if actor is in current take location, show different icon !
    const icon = isInvolved ? <DoorBackOutlinedIcon fontSize={"inherit"} color={"inherit"}/> : <MeetingRoomOutlinedIcon fontSize={"inherit"} color={"inherit"}/>;

    const pfp = actorPreset ? <ActorAvatar actor={actorPreset!} size={"24"} theme={"creator"}/> : "";

    return <div className={"flex px-[10px] gap-[10px] py-[4px] cursor-pointer select-none hover:bg-creator-primary-washed"} onClick={onClick}>
        <div>
            {icon}
        </div>
        <div>
            {pfp}
        </div>
        <div className={"my-auto text-creator-text-sub text-[14px] font-medium"}>
            {actorPreset?.workingName}
        </div>
    </div>
}