import React, {useEffect} from "react";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectAuthenticated} from "./app/slices/AuthenticationSlice";
import PiLoadingBackdrop from "./components/PiLoadingBackdrop";
import Login from "./features/authentication/Login";
import Editor from "./features/routing/Editor";
import "./style.css";
import useInteractionFactoryImports from "./hooks/InteractionFactoryImports.hook";

function Creator() {
    const isAuthenticated = useSelector(selectAuthenticated);
    const body = isAuthenticated ? <Editor/> : <Login/>;
    const {t, i18n} = useTranslation();
    const { } = useInteractionFactoryImports();

    useEffect(() => {
        const editorLang = localStorage.getItem("editorLanguage");
        if (editorLang) {
            i18n.changeLanguage(editorLang);
        }

    }, []);

    //const body = <Editor />;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Play It Creator</title>
                <meta name="description" content="somebody should write a description for the Play It Creator"/>
            </Helmet>
            {body}
            <PiLoadingBackdrop/>
        </>
    );
}

export default Creator;
