import { useDispatch, useSelector } from "react-redux";
import { addNewCreatedFolder, addNewFolder, removeTemporaryFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const useAddFolder = () => {
  const token = useSelector(selectToken);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();

  const addTempFolder = async () => {
    dispatch(addNewFolder());
  };

  const addFolder = async (folderName: string) => {
    var folder = await AssetService.AddFolder(currentOrganization!.id, token, folderName);
    if (folder) {
      // add to slice
      dispatch(addNewCreatedFolder({ id: folder.id, name: folder.name }));
    } else {
      console.log("no folder could be created");
      // remove the temp folder from slice?
      dispatch(removeTemporaryFolder());
    }
  };
  return [addFolder, addTempFolder] as const;
};
