import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import { IAmbientAudio } from "../../../models/interactions/AmbientAudio";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { ReactComponent as FileIcon } from "../../file_explorer/assets/file.svg";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import "./AmbientAudioPreview.css";

export default function AmbientAudioPreview() {
  const dispatch = useDispatch();
  const interaction = useSelector(selectSelectedElement) as IAmbientAudio;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview, getAssetName } = useAssetPreview(interaction.assetId);

  const title = <div className="ambientaudiopreview-title">Ambient Audio</div>;

  const onSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    const copy: IAmbientAudio = { ...interaction, assetId: asset.assetId };
    dispatch(updateSelectedInteraction(copy));
  };

  const addButton = (
    <div className="ambientaudiopreview-add-button" onClick={() => setIsOpen(true)}>
      <GraphicEqIcon fontSize="inherit" color="inherit" />
    </div>
  );
  const icon = <FileIcon className="ambientaudiopreview-audio" />;
  const placeholder = <div className="ambientaudiopreview-content">No Audio Selected</div>;
  const assetDisplay = (
    <div className="ambientaudiopreview-content">
      {icon}
      <div className="ambientaudiopreview-asset-name">{getAssetName()}</div>
    </div>
  );
  const content = interaction.assetId ? assetDisplay : placeholder;

  const body = (
    <div className="ambientaudiopreview-root">
      {content}
      {addButton}
      <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={AssetKey.Audio} onSelect={onSelect} />
    </div>
  );

  return <PiPopupWindowPreview title={title} body={body} size="small" />;
}
