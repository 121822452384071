import axios from "axios";
import { LanguageDTO } from "../dto/LanguageDTO";
import LocalizedLanguageDTO from "../dto/LocalizedLanguageDTO";
import { TrainingContentDTO } from "../dto/TrainingContentDTO";
import { TrainingDetailsDTO } from "../dto/TrainingDetailsDTO";
import { TrainingPublicCourseDTO } from "../dto/TrainingPublishCourseDTO";
import { TrainingNameDTO } from "../dto/TrainingNameDTO";
import TrainingTranslationDTO from "../dto/TrainingTranslationDTO";
import { TranslationWithDescriptionDTO } from "../dto/TranslationWithDescriptionDTO";
import Take360 from "../models/Take360";
import { Training } from "../models/Training";
import { TrainingDetails } from "../models/TrainingDetails";
var BASE_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

export class TrainingService {
  static async GetTrainings(orgId: string, token: string, searchByName: string, pageSize: number, pageNumber: number, orderBy: string) {
    var path = "/organizations/" + orgId + "/trainings";
    var url = BASE_URL + path;
    var params = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      OrderBy: orderBy,
      searchByName: searchByName,
    };
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var trainings: TrainingDetailsDTO[] = [];
    var totalPages = 0;
    await axios
      .get<TrainingDetailsDTO[]>(url, config)
      .then((response) => {
        if (response.data) {
          trainings = response.data;
          totalPages = JSON.parse(response.headers["x-pagination"])["totalPages"];
        }
      })
      .catch((error) => {
        console.log(error.response);
      });

    return { totalPages: totalPages, trainings: trainings };
  }

  static async CreateTraining(orgId: string, token: string, name: string, description: string, type: number): Promise<TrainingDetailsDTO | undefined> {
    var path = "/organizations/" + orgId + "/trainings";
    var url = BASE_URL + path;
    var config = getConfig(token);
    var body = {
      categoryId: "009223d8-f587-4a9c-8466-03e2bceb5b3b",
      name: name,
      description: description,
      type: type,
    };

    var result: TrainingDetailsDTO | undefined = undefined;
    await axios
      .post<TrainingDetailsDTO>(url, body, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response));

    return result;
  }

  static async UpdateTrainingName(orgId: string, trainingId: string, token: string, name:string, description:string): Promise<TrainingNameDTO | undefined> {
    var path = "/organizations/" + orgId + "/trainings/"+ trainingId;
    var url = BASE_URL + path;
    var config = getConfig(token);
    var body = {
      categoryId: "009223d8-f587-4a9c-8466-03e2bceb5b3b",
      name: name,
      description: description
    };

    var result: TrainingNameDTO | undefined = undefined;
    await axios
      .put<TrainingNameDTO>(url, body, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response));

    return result;
  }

  static async CreateNewTrainingVersion(orgId: string, token: string, trainingId: string, content: string) {
    var path = "/organizations/" + orgId + "/trainings/" + trainingId + "/content";
    var url = BASE_URL + path;
    var config = getConfig(token);

    // file
    var blob = new Blob([content], { type: "application/json" });
    const formData = new FormData();
    formData.append("file", blob, "file.json");

    await axios
      .post<TrainingDetails>(url, formData, config)
      .then((response) => console.log("success!"))
      .catch((error) => console.log(error.response));
  }

  static async GetTrainingContent(orgId: string, token: string, trainingId: string): Promise<TrainingContentDTO | undefined> {
    var path = "/organizations/" + orgId + "/trainings/" + trainingId + "/content";
    var url = BASE_URL + path;
    var config = getConfig(token);

    var result: TrainingContentDTO | undefined = undefined;
    await axios
      .get<TrainingContentDTO>(url, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response));
    return result;
  }

  static async GetTrainingJson(url: string): Promise<Training | undefined> {
    //var result: Take360[] = [];
    var result: Training | undefined = undefined;
    var temp: Training | Take360[];
    await axios
      .get<Training | Take360[]>(url)
      .then((response) => {
        temp = response.data;
        if ("takes" in temp) {
          result = temp;
        } else {
          result = {
            refLanguage: "",
            takes: temp as Take360[],
            trainingType: -1,
            version: 1,
          };
        }
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  static async DeleteTraining(orgId: string, token: string, trainingId: string) {
    var path = "/organizations/" + orgId + "/trainings/" + trainingId;
    var url = BASE_URL + path;
    var config = getConfig(token);

    await axios
      .delete(url, config)
      .then((response) => console.log("training deleted!"))
      .catch((error) => console.log(error.response));
  }

  // ===== Translations =====
  static async GetTrainingTranslations(orgId: string, token: string, trainingId: string) {
    var path = "/organizations/" + orgId + "/trainings/" + trainingId + "/translations";
    var url = BASE_URL + path;
    var config = getConfig(token);

    var dtos: TrainingTranslationDTO[] = [];
    await axios
      .get<TrainingTranslationDTO[]>(url, config)
      .then((response) => (dtos = response.data))
      .catch((error) => console.log(error.response));

    // download them all
    var result: LocalizedLanguageDTO[] = [];
    for (var i = 0; i < dtos.length; i++) {
      await axios
        .get<LocalizedLanguageDTO>(dtos[i].downloadUri)
        .then((response) => result.push(response.data))
        .catch((error) => console.log(error.response));
    }

    return result;
  }

  static async UpdateTrainingTranslation(orgId: string, token: string, trainingId: string, langId: string, content: string) {
    var path = "/organizations/" + orgId + "/trainings/" + trainingId + "/translations/" + langId;
    var url = BASE_URL + path;
    var config = getConfig(token);

    // file
    var blob = new Blob([content], { type: "application/json" });
    const formData = new FormData();
    formData.append("file", blob, "file.json");

    await axios
      .put(url, formData, config)
      .then((response) => console.log("training translation successfully created !: " + langId))
      .catch((error) => console.log(error.response));
  }

  static async GetTrainingTestData(orgId: string, token: string, content: object) {
    var path = "/trainings/testing";
    var url = BASE_URL + path;
    var config = getConfig(token);

    var result = "";

    await axios
      .post<object>(url, content, config)
      .then((response) => (result = JSON.stringify(response.data)))
      .catch((error) => console.log(error.response));

    return result;
  }

  static async GetCourseDetails(orgId: string, token: string, trainingId: string): Promise<TrainingPublicCourseDTO | undefined> {
    const path = "/organizations/" + orgId + "/publish/" + trainingId;
    const url = BASE_URL + path;
    const config = getConfig(token);

    var result: TrainingPublicCourseDTO | undefined = undefined;

    await axios
      .get<TrainingPublicCourseDTO>(url, config)
      .then((response) => console.log((result = response.data)))
      .catch((error) => console.log(error.data));

    return result;
  }

  static async PublishCourse(orgId: string, token: string, trainingId: string, translations: TranslationWithDescriptionDTO[]): Promise<number> {
    const path = "/organizations/" + orgId + "/publish/" + trainingId;
    const url = BASE_URL + path;
    const config = getConfig(token);
    const body = {
      translations: translations,
    };

    var result: TrainingPublicCourseDTO | undefined = undefined;
    var code = -1;

    await axios
      .post<TrainingPublicCourseDTO>(url, body, config)
      .then((response) => {
        result = response.data;
        code = response.status;
      })
      .catch((error) => {
        console.log(error.data);
        code = error.response.status;
      });

    return code;
  }

  static async GetLanguages(token: string): Promise<LanguageDTO[]> {
    const path = "/languages";
    const url = BASE_URL + path;
    const config = getConfig(token);
    var languages: LanguageDTO[] = [];
    await axios
      .get<LanguageDTO[]>(url, config)
      .then((response) => (languages = response.data))
      .catch((error) => {
        console.log(error.data);
      });

    return languages;
  }
}

const getConfig = (token: string) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};
