import KeyboardDoubleArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftOutlined";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsMenuExpanded } from "../../app/slices/GlobalSlice";
import PiUserButton from "./PiAccountButton";
import PiIconButton from "./PiIconButton";
import "./PiMenu.css";

const playItCreatorLogo = require("../../assets/PlayItCreator.png");
const playItLogoCompact = require("../../assets/PlayItLogo_Compact.png");

interface PiMenuProps {
  children?: React.ReactNode;
}

export default function PiMenu(props: PiMenuProps) {
  const [isMenuExpanded, setMenuExpandend] = useState(true);
  const dispatch = useDispatch();

  const toggleMenuExpanded = () => {
    const newState = !isMenuExpanded;
    setMenuExpandend(newState);
    dispatch(setIsMenuExpanded(newState));
  };

  const rootClassnames = isMenuExpanded ? "pimenu-root expanded" : "pimenu-root collapsed";
  const collapseButtonClassnames = isMenuExpanded ? "pimenu-bottom-collapse-button pimenu-bottom-collapse-button-expanded" : "pimenu-bottom-collapse-button";
  const bottomContentClassnames = isMenuExpanded ? "pimenu-bottom-content pimenu-bottom-content-expanded" : "pimenu-bottom-content";
  const menuLogo = isMenuExpanded ? playItCreatorLogo : playItLogoCompact;
  const collapseIcon = isMenuExpanded ? <KeyboardDoubleArrowLeftOutlinedIcon /> : <KeyboardDoubleArrowRightOutlinedIcon />;

  return (
    <div className={rootClassnames}>
      <div className="pimenu-logo">
        <img src={menuLogo} alt="Play It Creator Logo" />
      </div>
      <div className="pimenu-logo-small">
        <img src={playItLogoCompact} alt="Play It Creator Logo" />
      </div>
      <div className="pimenu-button-container">{props.children}</div>
      <div className="pimenu-bottom">
        <div className={bottomContentClassnames}>
          <PiUserButton isMenuExpanded={isMenuExpanded} />
          <div className={collapseButtonClassnames}>
            <PiIconButton icon={collapseIcon} onClick={toggleMenuExpanded} />
          </div>
        </div>
      </div>
    </div>
  );
}
