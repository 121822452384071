import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import DetachDropdown from "./DetachDropdown";
import {useDispatch, useSelector} from "react-redux";
import {IDetach} from "../../../models/interactions/Detach";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";


export default function DetachPreview() {
    return <PiPopupWindowPreview body={<DetachPreviewBody/>} title={<PiPopupWindowStaticTitle title={"Detach"}/>}/>
}

function DetachPreviewBody() {
    const detach = useSelector(selectSelectedElement) as IDetach;
    const dispatch = useDispatch();

    const changeAttachment = (propGuid: string, actorGuid: string) => {
        dispatch(updateSelectedInteraction({...detach, propGuid: propGuid, playerGuid: actorGuid} as IDetach))
    }

    const dropdown = <DetachDropdown onChange={changeAttachment} selectedPropGuid={detach.propGuid}/>

    return <div className={"w-full h-full"}>
        {dropdown}
    </div>
}