import React from "react";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import "./MessageBubblePreviewBody.css";
import MessageBubbleAnimationDropdown from "./MessageBubbleAnimationDropdown";
import MessageBubbleActorDropdown from "./MessageBubbleActorDropdown";
import MessageBubblePreviewText from "./MessageBubblePreviewText";

export default function MessageBubblePreviewBody(props: { messageBubble: IMessageBubble; updateInteraction: (field: string, value: any) => void }) {

    const { messageBubble, updateInteraction } = { ...props };

    return (
        <div className="messagebubblepreviewbody-root">
            <div className={"w-full flex gap-2"}>
                <div className={"w-1/3"}>
                    <MessageBubbleActorDropdown label={"Actor"} selectedTargetActorId={messageBubble.npcId} onClick={(actorId) => updateInteraction("npcId", actorId)} />
                </div>
                <div className={"w-1/3"}>
                    <MessageBubbleActorDropdown label={"Look at"} selectedTargetActorId={messageBubble.lookAt} onClick={(actorId) => updateInteraction("lookAt", actorId)} />
                </div>
                <div className={"w-1/3"}>
                    <MessageBubbleAnimationDropdown selectedAnimation={messageBubble.animation} onClick={(anim) => updateInteraction("animation", anim)} />
                </div>
            </div>
            <div className={"w-full h-full"}>
                <MessageBubblePreviewText messageBubble={messageBubble} updateInteraction={updateInteraction} />
            </div>
        </div>
    );
}
