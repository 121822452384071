import React from "react";
import { ConversationMessageBubble } from "./ConversationMessageBubble";
import { IMessageBubble } from "../../../models/interactions/MessageBubble";
import { IEmotionBubble } from "../../../models/interactions/EmotionBubble";
import { InteractionType } from "../../../models/interactions/Interaction";
import { ConversationEmotionBubble } from "./ConversationEmotionBubble";

export interface MessageBubbleStrategy {
  getPreview: (messageBubble: (IMessageBubble | IEmotionBubble), onSettingsClicked: () => void) => React.JSX.Element;
}

export const MessageBubble: MessageBubbleStrategy = {
  getPreview: (messageBubble: (IMessageBubble | IEmotionBubble), onSettingsClicked: () => void) => {
    return <><ConversationMessageBubble message={messageBubble} onSettingsClicked={onSettingsClicked} key={messageBubble.id} /></>;
  },
};

export const EmotionBubble: MessageBubbleStrategy = {
  getPreview: (messageBubble: (IMessageBubble | IEmotionBubble), onSettingsClicked: () => void) => {
    return <><ConversationEmotionBubble message={messageBubble} onSettingsClicked={onSettingsClicked} key={messageBubble.id} /></>;
  },
};

export function GetMessageStrategy(message: (IMessageBubble | IEmotionBubble)): MessageBubbleStrategy {

  switch (message.type) {
    case InteractionType.MessageBubble:
      return MessageBubble;
    case InteractionType.EmotionBubble:
      return EmotionBubble;
    default:
      return MessageBubble;
  }
};
