import { useSelector } from "react-redux";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { IStatementQuiz } from "../../../models/interactions/StatementQuiz";
import StatementQuizPreviewAnswer from "./StatementQuizPreviewAnswer";
import PiBasicQuizAddAnswerButtonV2 from "./../components/v2/PiBasicQuizAddAnswerButtonV2";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import { GetNewGuid } from "../../../helpers/GuidHelper";

export default function StatementQuizPreviewAnswers(props: { index: number }) {
  const interaction = useSelector(selectSelectedElement) as IStatementQuiz;
  const { index } = { ...props };
  const correctIndex = interaction.correctAnswers[index];
  const { getNewKey } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onToggleCorrect = (answerIndex: number) => {
    const answersCpy = [...interaction.correctAnswers];
    answersCpy[props.index] = answerIndex;
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        correctAnswers: answersCpy,
      } as IStatementQuiz)
    );
  };

  const onAddAnswer = () => {
    const newAnswers = [...interaction.answers, getNewKey()];

    dispatch(
      updateSelectedInteraction({
        ...interaction,
        answers: newAnswers,
      } as IStatementQuiz)
    );
  };

  const onDeleteAnswer = (answerIndex: number) => {
    console.log("onDeleteAnswer", answerIndex);

    // reset all correct answers that are equal to the deleted answer
    const newCorrectAnswers = interaction.correctAnswers.map(
      (correctAnswer) => {
        if (correctAnswer === answerIndex) {
          return -1;
        }

        return correctAnswer;
      }
    );

    // rm the answer
    const newAnswers = interaction.answers.filter(
      (_, index) => index !== answerIndex
    );

    // update slice
    dispatch(
      updateSelectedInteraction({
        ...interaction,
        answers: newAnswers,
        correctAnswers: newCorrectAnswers,
      } as IStatementQuiz)
    );
  };

  const answers = useMemo(() => {
    return interaction.answers.map((answer, answerIndex) => {
      return (
        <StatementQuizPreviewAnswer
          key={GetNewGuid()}
          answer={answer}
          isCorrect={correctIndex === answerIndex}
          answerIndex={answerIndex}
          onToggleCorrect={onToggleCorrect}
          onDelete={onDeleteAnswer}
        />
      );
    });
  }, [interaction, index]);

  // TODO: add add answer button
  const addAnswerButton = (
    <div className="w-[20%]">
      <PiBasicQuizAddAnswerButtonV2
        onClick={onAddAnswer}
        disabled={interaction.answers.length >= 5}
      />
    </div>
  );
  return (
    <div className="flex items-center justify-center w-full h-full gap-[12px]">
      {answers}
      {interaction.answers.length < 5 && addAnswerButton}
    </div>
  );
}
