import { useDispatch, useSelector } from "react-redux";
import { Folder, archiveFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export default function useDeleteFolder() {
  const org = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const deleteFolder = (folder: Folder) => {
    dispatch(archiveFolder(folder));
    AssetService.DeleteFolder(org!.id, token, folder.id);
  };

  return [deleteFolder] as const;
}
