import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTake,
  updateSelectedTake,
} from "../../app/slices/TrainingSlice";
import PiInputfield from "../../components/PiInputField";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import MovieCreationOutlinedIcon from "@mui/icons-material/MovieCreationOutlined";
import Take from "../../models/Take";
import { useTranslation } from "react-i18next";
import PiSwitch from "../../components/PiSwitch";
import Take360 from "../../models/Take360";
import PiSelectAssetButton from "../../components/PiSelectAssetButton";
import { AssetKey } from "../../dto/AssetDTO";
import { useEffect, useState } from "react";

export default function Take360PropertiesColumn() {
  const take = useSelector(selectSelectedTake);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("updating take name");
  }, [take, take?.name]);

  const [takeNameValue, setTakeNameValue] = useState(take?.name ?? "");

  useEffect(() => {
    setTakeNameValue(take?.name ?? "");
  }, [take, take?.name]);

  const onChangeTakeName = (newName: string) => {
    dispatch(updateSelectedTake({ ...take, name: newName } as Take));
  };

  const onChangeField = (field: keyof Take360, value: any) => {
    dispatch(updateSelectedTake({ ...take, [field]: value } as Take360));
  };

  const takeName = (
    <PiInputfield
      label={t("take_name")}
      value={takeNameValue}
      placeholder={"Take Name"}
      onChange={onChangeTakeName}
    />
  );

  const automaticTransition = (
    <PiSwitch
      checked={take?.automaticTransition ?? false}
      label={t("edit_bubble_dialog.automatic_transition")}
      onChange={(checked) => onChangeField("automaticTransition", checked)}
    />
  );

  const is360Image = (
    <PiSwitch
      checked={take?.is360Image ?? false}
      label={t("edit_bubble_dialog.360_image")}
      onChange={(checked) => onChangeField("is360Image", checked)}
    />
  );

  const changeBubble = (
    <PiSelectAssetButton
      label={t("edit_bubble_dialog.bubble")}
      text={t("change_bubble")}
      assetType={AssetKey.Image}
      onAssetSelectCallback={(asset) => onChangeField("assetId", asset.assetId)}
    />
  );

  const takeTab = (
    <PiCollapsibleSettings
      text={"Take"}
      icon={
        <MovieCreationOutlinedIcon color={"inherit"} fontSize={"inherit"} />
      }
      paddingX
      paddingY
    >
      {takeName}
      {changeBubble}
      {automaticTransition}
      {is360Image}
    </PiCollapsibleSettings>
  );

  // TODO: change bubble
  // TODO: automatic transition
  // TODO: 360 image

  return (
    <>
      <PropertiesColumnHeader text={take?.name ?? "Take"} />
      <PropertiesColumnBody>{takeTab}</PropertiesColumnBody>
    </>
  );
}
