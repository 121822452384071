import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import "./NewTrainingCard.css";

interface NewTrainingCardProps {
  onSelectClick: () => void;
  image: string;
  type: string;
  variant: string;
  disabled?: boolean;
}

export default function NewTrainingCard(props: NewTrainingCardProps) {
  const { t } = useTranslation();
  const { image, type, variant, onSelectClick, disabled } = { ...props };

  return (
    <div className="newtrainingcard-root">
      <div className={"newtrainingcard-tag newtrainingcard-tag-" + variant}>{type}</div>
      <div className="newtrainingcard-image">
        <img src={image} alt="" />
        {disabled && (
          <div className="newtrainingcard-coming-soon">
            <span className="newtrainingcard-coming-soon-text">Coming Soon</span>
            <div className="newtrainingcard-coming-soon-overlay" />
          </div>
        )}
      </div>

      <div className="newtrainingcard-title">
        <div className="newtrainingcard-title">
          <span className={"newtrainingcard-title-" + variant}>{type}</span>
          <span>{t("training")}</span>
        </div>
      </div>
      <div className="newtrainingcard-button">
        <Button variant="piBlue" onClick={onSelectClick} disabled={disabled}>
          {t("select")}
        </Button>
      </div>
    </div>
  );
}
