export enum AssetType {
  image = "Image",
  video = "Video",
  pdf = "PDF",
  audio = "audio",
  unsupported = "Unsupported",
  loading = "loading",
}

export interface Asset {
  id: string;
  name: string;
  thumbnailUrl: string;
  version: string;
  uploadedBy: string;
  assetType: AssetType;
  orgId: string;
  previewVersionId: string;
  previewVariantId: string;
  parentId: string;
  countVersions: number;
}
