import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CharacterPreset } from "../../models/CharacterPreset";
import { Outfit } from "../../models/Outfit";
import { RootState } from "../Store";

interface MetadataState {
  outfits: Outfit[];
  characterPresets: CharacterPreset[];
}

const initialMetadataState: MetadataState = {
  outfits: [],
  characterPresets: [],
};

export const metadataSlice = createSlice({
  name: "metadata",
  initialState: initialMetadataState,
  reducers: {
    initializeOutfits: (state, action: PayloadAction<Outfit[]>) => {
      state.outfits = action.payload;
    },
    initializeCharacterPresets: (state, action: PayloadAction<CharacterPreset[]>) => {
      state.characterPresets = action.payload;
    },
  },
});

export const { initializeOutfits, initializeCharacterPresets } = metadataSlice.actions;

export const selectOutfits = (state: RootState) => state.metadata.outfits;
export const selectCharacterPresets = (state: RootState) => state.metadata.characterPresets;

export default metadataSlice.reducer;
