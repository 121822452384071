import classNames from "classnames";

export default function PiButton(props: {
    children: JSX.Element[] | JSX.Element | string;
    variant: "piBlue" | "piRed";
    onClick: () => void;
}) {
    const {variant, children, onClick} = {...props};

    return (
        <div
            className={classNames({
                "w-full flex rounded-full h-[36px] cursor-pointer": true,
                "bg-creator-error hover:bg-creator-error-hover": variant === "piRed",
                "bg-creator-primary hover:bg-creator-primary-hover":
                    variant === "piBlue",
            })}
            onClick={onClick}
        >
            <div className="mx-auto w-full text-center my-auto text-white font font-medium">
                {children}
            </div>
        </div>
    );
}
