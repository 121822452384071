import {useSelector} from "react-redux";
import {selectSelectedTake3D} from "../../app/slices/TrainingSlice";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import TakePropertiesActor from "./TakePropertiesActor";
import {useMemo} from "react";
import {useHelper3D} from "../../hooks/HelperHooks3D";
import {useTranslation} from "react-i18next";

export default function TakePropertiesActorList() {
    const take = useSelector(selectSelectedTake3D)!;
    const {getInvolvedActors} = useHelper3D();
    const involvedActors = useMemo(() => getInvolvedActors(take), [take]);
    const {t} = useTranslation();

    const actorsMapped = take.actors.map(actor => <TakePropertiesActor key={actor.id} actor={actor} isInvolved={involvedActors.findIndex(ia => ia.id === actor.actorId) !== -1}/>);

    const settings = <PiCollapsibleSettings text={t("acting_actors")} icon={<RecentActorsOutlinedIcon color={"inherit"} fontSize={"inherit"}/>} paddingY>
        {actorsMapped}
    </PiCollapsibleSettings>

    return <>{settings}</>;
}