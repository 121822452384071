import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateIcon from "@mui/icons-material/Create";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  selectSelectedElement,
  updateSelectedInteraction,
} from "../../../app/slices/TrainingSlice";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import { IPhotoRangeQuiz } from "../../../models/interactions/PhotoRangeQuiz";
import { IAnswer } from "../../../models/Quiz";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicGraphicsQuizAddButton from "../components/PiBasicGraphicsQuizAddButton";
import PiBasicGraphicsQuizAnswer from "../components/PiBasicGraphicsQuizAnswer";
import "./PhotoRangeQuizBody.css";

export default function PhotoRangeQuizBody() {
  const quiz = useSelector(selectSelectedElement) as IPhotoRangeQuiz;
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const { getAssetPreview } = useAssetPreview(quiz.baseImage);
  const pencilButton = (
    <div
      className="photorangequizbody-pencil"
      onClick={() => setIsInEditMode(true)}
    >
      <CreateIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const overlayBaseImage = <img src={getAssetPreview()} />;
  const overlayAnswerImages = quiz.answers.map((answer) => {
    return <PhotoRangeQuizOverlayAnswer key={answer.id} answer={answer} />;
  });

  const overview = (
    <div className="photorangequizbody-overlay-image">
      {overlayBaseImage}
      {overlayAnswerImages}
      {pencilButton}
    </div>
  );

  const backButton = (
    <div
      className="photorangequizbody-pencil"
      onClick={() => setIsInEditMode(false)}
    >
      <ArrowBackIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const basicView = quiz.baseImage ? (
    overview
  ) : (
    <div className="photorangequizbody-basic-view">{pencilButton}</div>
  );
  const currentView = isInEditMode ? (
    <>
      <PhotoRangeQuizEditView />
      {backButton}
    </>
  ) : (
    basicView
  );

  return <div className="photorangequizbody-root">{currentView}</div>;
}

function PhotoRangeQuizOverlayAnswer(props: { answer: IAnswer }) {
  const { getAssetPreview } = useAssetPreview(props.answer.assetId);

  return (
    <img
      className="photorangequizbody-overlay-image-answer"
      src={getAssetPreview()}
    />
  );
}

function PhotoRangeQuizEditView() {
  const quiz = useSelector(selectSelectedElement) as IPhotoRangeQuiz;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview } = useAssetPreview(quiz.baseImage);
  const dispatch = useDispatch();

  const addAnswer = () => {
    const newAnswer: IAnswer = {
      id: uuidv4(),
      correct: false,
      text: "",
      assetId: "",
    };
    const copy = { ...quiz, answers: [...quiz.answers, newAnswer] };
    dispatch(updateSelectedInteraction(copy));
  };

  const onBaseImageSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    var copy: IPhotoRangeQuiz = { ...quiz, baseImage: asset.assetId };
    dispatch(updateSelectedInteraction(copy));
  };

  const baseImage = quiz.baseImage ? (
    <div className="photorangequizeditview-select-base-image">
      <div className="photorangequizeditview-image-display">
        <img src={getAssetPreview()} />
        <div
          className="photorangequizeditview-change-image"
          onClick={() => setIsOpen(true)}
        >
          <ImageSearchOutlinedIcon fontSize="inherit" color="inherit" />
        </div>
      </div>
      <FileExplorerDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type={AssetKey.Image}
        onSelect={onBaseImageSelect}
      />
    </div>
  ) : (
    <div className="photorangequizeditview-select-base-image">
      <div
        className="photorangequizeditview-image-preview"
        onClick={() => setIsOpen(true)}
      >
        <AddPhotoAlternateOutlinedIcon fontSize="inherit" color="inherit" />
      </div>
      <FileExplorerDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type={AssetKey.Image}
        onSelect={onBaseImageSelect}
      />
    </div>
  );

  const answers = quiz.answers.map((answer, index) => {
    return (
      <PhotoRangeQuizAnswerPreview
        key={answer.id}
        index={index}
        quiz={quiz}
        answer={answer}
      />
    );
  });
  const addAnswerButton =
    quiz.answers.length < 4 ? (
      <PiBasicGraphicsQuizAddButton onClick={addAnswer} />
    ) : (
      <></>
    );
  const answerPreviews = (
    <div className="photorangequizeditview-answers-preview">
      {answers} {addAnswerButton}
    </div>
  );

  return (
    <div className="photorangequizeditview-root">
      {baseImage} {answerPreviews}
    </div>
  );
}

function PhotoRangeQuizAnswerPreview(props: {
  quiz: IPhotoRangeQuiz;
  answer: IAnswer;
  index: number;
}) {
  const { quiz, answer } = { ...props };
  const { getValue, changeValue } = useTrainingLocalization();
  const dispatch = useDispatch();

  const onDeleteAnswer = () => {
    const copy: IPhotoRangeQuiz = {
      ...quiz,
      answers: quiz.answers.filter((a) => a.id !== answer.id),
    };
    dispatch(updateSelectedInteraction(copy));
  };

  const handleAnswerChange = (
    id: string,
    field: string,
    value: boolean | string
  ) => {
    const copy = { ...quiz };
    const updatedAnswers = copy.answers.map((answer) => ({ ...answer }));
    const index = updatedAnswers.findIndex((x) => x.id === id);

    if (field === "checked") {
      const checkedAnswer = updatedAnswers[index];

      checkedAnswer.correct = value as boolean;
    }

    if (field === "assetId") {
      const answer = updatedAnswers[index];
      answer.assetId = value as string;
    }

    copy.answers = updatedAnswers;
    dispatch(updateSelectedInteraction(copy));
  };

  return (
    <PiBasicGraphicsQuizAnswer
      answer={answer}
      answersLength={1}
      canDelete={quiz.answers.length > 2}
      quizType={"basic_quiz"}
      changeValue={() => {}}
      getValue={() => "Answer " + (props.index + 1)}
      handleAnswerChange={handleAnswerChange}
      handleDeleteAnswer={() => {
        onDeleteAnswer();
      }}
      disableInput
    />
  );
}
