import React from "react";
import { useSelector } from "react-redux";
import {
  selectFilteredInteractionDefinitions,
  selectMostRecentInteractionDefinitions,
} from "../../app/slices/InteractionPickerSlice";
import { InteractionCategory } from "./InteractionDefinitions";
import InteractionPickerAccordion from "./InteractionPickerAccordion";
import "./InteractionPickerContent.css";
import InteractionPickerPreview from "./InteractionPickerPreview";
import PiTabView from "../../components/PiTabView";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PiBoxIcon from "../../icons/PiBoxIcon";
import PropaneTankOutlinedIcon from "@mui/icons-material/PropaneTankOutlined";
import InteractionPickerPropsAccordions from "./interaction_picker_props/InteractionPickerPropsAccordions";
import {
  selectTrainingType,
  TrainingType,
} from "../../app/slices/TrainingSlice";

interface InteractionPickerContentProps {
  onClose: () => void;
}

export default function InteractionPickerContent(
  props: InteractionPickerContentProps
) {
  const { onClose } = { ...props };
  const interactionDefinitions = useSelector(
    selectFilteredInteractionDefinitions
  );
  const mostRecentInteractionDefinitions = useSelector(
    selectMostRecentInteractionDefinitions
  );
  const trainingType = useSelector(selectTrainingType);
  const [selectedTabId, setSelectedTabId] = React.useState<number>(0);

  const getInteractionDefsByCategory = (category: InteractionCategory) => {
    return interactionDefinitions.filter((def) => def.category === category);
  };

  const getAccordionForCategory = (category: InteractionCategory) => {
    var defs = getInteractionDefsByCategory(category);
    if (defs.length === 0) return "";

    return (
      <InteractionPickerAccordion
        title={category.toString()}
        interactionDefinitions={defs}
        onClose={onClose}
      />
    );
  };

  const getMostRecentAccordion = () => {
    if (mostRecentInteractionDefinitions.length === 0) return "";
    return (
      <InteractionPickerAccordion
        title="Most Recent"
        interactionDefinitions={mostRecentInteractionDefinitions}
        onClose={onClose}
      />
    );
  };

  const tabs360 = (
    <div className={"w-full h-[60px] mb-[4px]"}>
      <PiTabView
        tabSettings={[
          {
            icon: <LayersOutlinedIcon />,
            text: "Add Interaction",
            onClick: () => {},
          },
        ]}
        onTabClick={setSelectedTabId}
      />
    </div>
  );

  const tabs3D = (
    <div className={"w-full h-[60px] mb-[4px]"}>
      <PiTabView
        tabSettings={[
          {
            icon: <LayersOutlinedIcon />,
            text: "Add Interaction",
            onClick: () => {},
          },
          {
            icon: <PropaneTankOutlinedIcon />,
            text: "Add Prop Interaction",
            onClick: () => {},
          },
        ]}
        onTabClick={setSelectedTabId}
      />
    </div>
  );

  const tab1 = (
    <>
      {" "}
      {getMostRecentAccordion()}
      {getAccordionForCategory(InteractionCategory.ThreeD)}
      {getAccordionForCategory(InteractionCategory.Popups)}
      {getAccordionForCategory(InteractionCategory.Quizes)}
      {getAccordionForCategory(InteractionCategory.Other)}
    </>
  );

  const accordions = (
    <div className="interactionpickercontent-accordions">
      {trainingType === TrainingType.Training3D && tabs3D}
      {trainingType === TrainingType.Training360 && tabs360}

      {selectedTabId === 0 && tab1}
      {selectedTabId === 1 && <InteractionPickerPropsAccordions />}
    </div>
  );

  const selector = (
    <div className="interactionpickercontent-selector">
      <InteractionPickerPreview onClose={onClose} />
    </div>
  );

  return (
    <div className="interactionpickercontent-root">
      {accordions} {selector}
    </div>
  );
}
