import React from "react";
import { IEmotionBubble } from "../../../models/interactions/EmotionBubble";
import "./EmotionBubblePreviewBody.css";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import MessageBubbleAnimationDropdown from "../message_bubble/MessageBubbleAnimationDropdown";
import EmotionBubblePreviewContent from "./EmotionBubblePreviewContent";

export default function EmotionBubblePreviewBody(props: { emotionBubble: IEmotionBubble; updateInteraction: (field: string, value: any) => void }) {

    //const actors = useSelector(selectActors);
    const { emotionBubble, updateInteraction } = { ...props };

    // update interaction


    // target npc
    // const selectedTargetActor = actors.find((actor) => actor.id === emotionBubble.npcId)?.workingName;
    // const availableActors = actors.map((actor) => actor.workingName);
    // const targetDropdown = (
    //     <PiInteractionPreviewDropdown title={"Target"} options={availableActors} selectedText={selectedTargetActor ?? "no target"}
    //         onChange={(index) => updateInteraction("npcId", actors[index].id)} />
    // );

    // style
    // const styleDropdown = (
    //     <PiInteractionPreviewDropdown
    //         title="Emotion"
    //         options={emotionBubbleStyles}
    //         selectedText={emotionBubble.messageBubbleStyle}
    //         onChange={(index) => updateInteraction("messageBubbleStyle", emotionBubbleStyles[index])}
    //     />
    // );

    // lookat
    // const selectedLookat = actors.find((actor) => actor.id === emotionBubble.lookAt)?.workingName;
    // const lookatDropdown = (
    //     <PiInteractionPreviewDropdown title={"Lookat"} options={availableActors} selectedText={selectedLookat ?? "no target"} onChange={(index) => updateInteraction("lookAt", actors[index].id)} />
    // );

    // animation
    // const animationDropdown = (
    //     <PiInteractionPreviewDropdown
    //         title={"Animation"}
    //         options={messageBubbleAnimations}
    //         selectedText={emotionBubble.animation}
    //         onChange={(index) => updateInteraction("animation", messageBubbleAnimations[index])}
    //     />
    // );

    return (
        <div className="emotionbubblepreviewbody-root">
            <div className={"w-full flex gap-2"}>
                <div className={"w-1/3"}>
                    <MessageBubbleActorDropdown label={"Actor"} selectedTargetActorId={emotionBubble.npcId} onClick={(actorId) => updateInteraction("npcId", actorId)} />
                </div>
                <div className={"w-1/3"}>
                    <MessageBubbleActorDropdown label={"Look At"} selectedTargetActorId={emotionBubble.lookAt} onClick={(actorId) => updateInteraction("lookAt", actorId)} />
                </div>
                <div className={"w-1/3"}>
                    <MessageBubbleAnimationDropdown selectedAnimation={emotionBubble.animation} onClick={(animation) => updateInteraction("animation", animation)} />
                </div>
            </div>

            <div className={"w-full h-full"}>
                <EmotionBubblePreviewContent emotionBubble={emotionBubble} onChange={(emotion) => updateInteraction("messageBubbleStyle", emotion)} />
            </div>
        </div>
    );
}
