import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOutfits } from "../../app/slices/MetadataSlice";
import { selectSelectedActor, updateActor } from "../../app/slices/TrainingSlice";
import PiButtonSelector from "../../components/PiButtonSelector";
import { Actor, Gender } from "../../models/Actor";
export default function ActorGenderSelector() {
  const dispatch = useDispatch();
  const selectedActor = useSelector(selectSelectedActor);
  const allOutfits = useSelector(selectOutfits);
  const selectedIndex = useMemo(() => {
    if (!selectedActor) return -1;
    if (selectedActor.gender === Gender.random) return -1;
    if (selectedActor.gender === Gender.male) return 0;
    if (selectedActor.gender === Gender.female) return 1;

    return -1;
  }, [selectedActor, allOutfits]);

  const icons = [<ManIcon fontSize="inherit" color="inherit" />, <WomanIcon fontSize="inherit" color="inherit" />];

  const onGenderClick = (index: number) => {
    if (!selectedActor) return;

    var newGender = Gender.random;
    if (index === 0) newGender = Gender.male;
    else if (index === 1) newGender = Gender.female;

    const actorCopy: Actor = { ...selectedActor, gender: newGender };
    dispatch(updateActor(actorCopy));
  };

  return <PiButtonSelector icons={icons} selectedIndex={selectedIndex} onButtonClicked={onGenderClick} allowShuffle />;
}
