import { useDispatch } from "react-redux";
import { deleteActor, setSelectedActor } from "../../app/slices/TrainingSlice";
import PiPopoverMenu from "../../components/PiPopoverMenu";
import { Actor, ProfilePictures } from "../../models/Actor";
import PiMenuButton from "../menu/PiMenuButton";
import "./ActorCard.css";
import {useTranslation} from "react-i18next";

export default function ActorCard(props: { actor: Actor; isSelected?: boolean }) {
  const { actor, isSelected } = props;
  const dispatch = useDispatch();
const {t} = useTranslation();

  const onClick = () => {
    dispatch(setSelectedActor(actor.id));
  };

  const onDeleteClick = () => {
    dispatch(deleteActor(actor.id));
  };

  const getRootclass = () => {
    const actorRootTailwind: string = "w-full min-h-[60px] h-[60px] border-2 rounded-5 flex items-center gap-2 px-[10px] cursor-pointer border-creator-border text-14";

    const selectedTailwind = isSelected ? "text-creator-primary font-semibold border-creator-primary" : "text-creator-text-sub";

    return `${actorRootTailwind} ${selectedTailwind}`;
  };

  const getProfilePictureclass = () => {
    const actorRootTailwind: string = "flex items-center justify-center object-contain w-[36px] h-[36px] text-white border-2 bg-creator-border rounded-circle ";

    const selectedTailwind = isSelected ? "border-creator-primary shadow-base bg-creator-primary" : "border-creator-border";

    return `${actorRootTailwind} ${selectedTailwind}`;
  };

  const pfp = actor.profilePicture === -1 || actor.id === "player" ? <div className={getProfilePictureclass()}>P</div> : <img className={getProfilePictureclass()} src={ProfilePictures[actor.profilePicture]} alt="pfp" />;

  const name = <span aria-label="actorcard-name" className="overflow-ellipsis overflow-hidden whitespace-nowrap w-[70%] select-none">{actor.workingName}</span>;

  const menu = (
    <div aria-label="actorcard-menu" className="">
      <PiPopoverMenu>
        <PiMenuButton text={t("duplicate")} onClick={() => { }} disabled />
        <PiMenuButton text={t("delete")} disabled={actor.id === "player"} onClick={onDeleteClick} />
      </PiPopoverMenu>
    </div>
  );

  return (
    <div className={getRootclass()} onClick={onClick}>
      {pfp}
      {name}
      {menu}
    </div>
  );
}
