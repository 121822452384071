import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedActor, updateActor } from "../../app/slices/TrainingSlice";
import PiButtonSelector from "../../components/PiButtonSelector";
import { ProfilePictures } from "../../models/Actor";

export default function ActorProfilePictureSelector() {

  const dispatch = useDispatch();
  const selectedActor = useSelector(selectSelectedActor);
  const [selectedIndex, setSelectedIndex] = useState<number>(selectedActor ? selectedActor.profilePicture : -1);

  useEffect(() => {
    if(!selectedActor) return;
    setSelectedIndex(selectedActor.profilePicture);
  }, [selectedActor])
  
  const onProfilePictureClick = (index: number) => {
    if (!selectedActor) return;

    setSelectedIndex(index);
    const actorCopy = { ...selectedActor, profilePicture: index, randomProfilePicture: index === -1 };
    dispatch(updateActor(actorCopy));
  };

  return <PiButtonSelector imagePaths={ProfilePictures} selectedIndex={selectedIndex} onButtonClicked={onProfilePictureClick} />;
}


function getRandomNumber(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
}
