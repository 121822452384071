import { Button } from "@mui/material";
import React from "react";
import "./PiCardContent.css";

interface PiCardContentProps {
  titleL: string;
  titleR: string;
  titleVariant: "blue" | "purple";
  submitText: string;
  onSubmit: () => void;
  children?: React.ReactNode;
  submitDisabled?: boolean;
}

export default function PiCardContent(props: PiCardContentProps) {
  const title = (
    <div className="picardcontent-title">
      <span className={`picardcontent-title-${props.titleVariant}`}>{props.titleL} </span>
      <span>{props.titleR}</span>
    </div>
  );

  const body = <div className="picardcontent-body">{props.children}</div>;

  const footer = (
    <div className="picardcontent-footer">
      <div className="picardcontent-footer-button">
        <Button variant="piBlue" onClick={props.onSubmit} disabled={props.submitDisabled}>
          {props.submitText}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {title}
      {body}
      {footer}
    </>
  );
}
