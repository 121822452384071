import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import "./PiQuizCheckbox.css";

export interface PiQuizCheckboxProps {
    checked: boolean;
    onChange: (e: boolean) => void;
    variant?: "game-blue" | "key-blue";
}

export default function PiQuizCheckbox(props: PiQuizCheckboxProps) {
    const {checked, onChange, variant} = {...props};
    const check = <CheckIcon fontSize="inherit" color="inherit"/>;

    const variantClasses = !variant ? "piquizcheckbox-variant-game" : variant === "game-blue" ? "piquizcheckbox-variant-game" : "piquizcheckbox-variant-key";

    return (
        <div aria-label="piquizcheckbox-root" className={"piquizcheckbox-root " + variantClasses} onClick={() => onChange(!checked)}>
            {checked && check}
        </div>
    );
}
