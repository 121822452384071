import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedElement, selectSelectedTakeActors, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { Actor, ProfilePictures } from "../../../models/Actor";
import { CreateEmotionBubble, IEmotionBubble, emotionBubbleStylesMapped } from "../../../models/interactions/EmotionBubble";
import { CreateMessageBubble, IMessageBubble } from "../../../models/interactions/MessageBubble";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import NpcPopover from "../../viewport3d/NpcPopover";
import EmotionDropdown from "../emotion_bubble/EmotionDropdown";
import { IConversation } from "./../../../models/interactions/Conversation";

function ConversationPreviewActorSelect(props: { onActorClick: (actor: Actor) => void }) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);

  const actors: Actor[] = useSelector(selectSelectedTakeActors);
  const [selectedActor, setSelectedActor] = useState<Actor | undefined>(actors[0]);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
  };

  const onActorClick = (actor: Actor) => {
    setOpenPopover(false);
    setSelectedActor(actor);
    props.onActorClick?.(actor);
  };

  const popover = <NpcPopover element={anchorEl} isOpen={openPopover} onClose={() => setOpenPopover(false)} onActorClick={onActorClick} actors={actors} selectedActor={selectedActor} hideDelete />;

  const getActorPreview = () => {
    if (!selectedActor) return <></>;

    if (selectedActor.id === "player") return <div className="flex items-center justify-center font-medium text-interaction-text-inverted bg-interaction-primary w-9 h-9 rounded-circle text-16">P</div>;
    return <img className="flex items-center justify-center object-contain border-2 rounded-circle min-w-9 min-h-9 max-w-9 max-h-9 border-interaction-primary" src={ProfilePictures[selectedActor.profilePicture]} alt="pfp" />;
  };
  return (
    <>
      <div className="w-auto flex justify-center items-center cursor-pointer rounded-10 h-[72px] ml-[10px]" onClick={onClick}>
        {getActorPreview()}
        <div className={openPopover ? "flex justify-center items-center text-interaction-primary rotate-0 transition-transform" : "flex justify-center items-center text-interaction-primary" +
          " rotate-180 transition-transform"}>
          <ArrowDropUpIcon />
        </div>
      </div>
      {popover}
    </>
  );
}

function ConversationPreviewTextfield(props: { onSubmit: (text: string) => void; onEmotionSelect: (text: string) => void }) {
  const [text, setText] = useState<string>("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    autoGrow();
  }, [text]);

  const autoGrow = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "5px";
      textareaRef.current.parentElement!.parentElement!.style.height = `72px`;
      textareaRef.current.parentElement!.style.height = `72px`;

      var maxheight = 200; // (px)
      var height = textareaRef.current.scrollHeight > maxheight ? maxheight : textareaRef.current.scrollHeight;

      textareaRef.current.style.height = `${height}px`;

      var pHeight = height < 72 ? 72 : height;
      textareaRef.current.parentElement!.parentElement!.style.minHeight = `${pHeight}px`;
      textareaRef.current.parentElement!.style.minHeight = `${pHeight}px`;

      // console.log("text-area: " + height);
      // console.log("parent: " + textareaRef.current.parentElement?.parentElement?.scrollHeight);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = () => {
    props.onSubmit(text);
    setText("");
  };

  const onChangeEmotion = (emotion: string) => {
    const newEmotion = emotionBubbleStylesMapped.find((e) => e.name === emotion)!.name;

    props.onEmotionSelect(newEmotion);
  };

  //Expand logic when emoji's are implemented => directly send an emoji button to the convo
  const addReactionButton = (
    <div className="flex items-center justify-center w-12 h-12 ml-[10px]  border-0 cursor-pointer ">
      <EmotionDropdown onChange={onChangeEmotion} />
    </div>
  );

  const sendButton = (
    <button disabled={!text} className="flex items-center justify-center w-12 h-12 ml-[10px] mr-3 border-0 cursor-pointer rounded-10 text-interaction-primary text-32 focus:outline-none disabled:text-interaction-disabled" onClick={onSubmit}>
      <SendOutlinedIcon fontSize="inherit" color="inherit" />
    </button>
  );

  return (
    <div className="flex items-center w-full overflow-hidden min-h-24 max-h-48 rounded-10">
      {
        <textarea
          ref={textareaRef}
          className="bg-[transparent] align-middle resize-none border-none ml-[8px] text-interaction-text-primary text-18 font-medium leading-none w-full outline-none flex items-center max-h-48 hover:outline-none placeholder:text-16 placeholder:font-semibold placeholder:text-interaction-text-placeholder"
          placeholder="Type a message"
          onKeyDown={handleKeyDown}
          onChange={(e) => setText(e.currentTarget.value)}
          value={text}
        />
      }
      {addReactionButton}
      {sendButton}
    </div>
  );
}

export default function ConversationPreviewInput(props: { onSubmitClick: () => void }) {
  const conversation = useSelector(selectSelectedElement) as IConversation;
  const { getNewKey, changeValue } = useTrainingLocalization();

  const dispatch = useDispatch();

  const [selectedActor, setSelectedActor] = useState<Actor | undefined>(undefined);

  const onSubmit = (text: string) => {
    // request a new key
    var key = getNewKey();

    // fill in the key w/ entered text
    changeValue(key, text);

    const msg: IMessageBubble = CreateMessageBubble([key]);
    msg.npcId = selectedActor?.id ?? "player";

    const cpy = { ...conversation };
    cpy.messages = [...cpy.messages, msg];
    props.onSubmitClick(); // callback to parent

    dispatch(updateSelectedInteraction(cpy));
  };

  const onEmotionSelect = (emotion: string) => {
    const msg: IEmotionBubble = CreateEmotionBubble();
    msg.npcId = selectedActor?.id ?? "player";
    msg.messageBubbleStyle = emotion;
    const cpy = { ...conversation };
    cpy.messages = [...cpy.messages, msg];
    props.onSubmitClick();

    dispatch(updateSelectedInteraction(cpy));
  };

  return (
    <div className="flex items-center overflow-hidden border-b-2 rounded-tl-[2px] rounded-tr-[2px] border-b-interaction-primary bg-interaction-softBackground min-h-[96px] ">
      <ConversationPreviewActorSelect onActorClick={setSelectedActor} />
      <ConversationPreviewTextfield onSubmit={onSubmit} onEmotionSelect={onEmotionSelect} />
    </div>
  );
}
