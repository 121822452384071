import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { selectCurrentLanguage } from "../../../app/slices/TrainingLocalizationSlice";
import "./PiQuizTextfield.css";

interface PiQuizTextfieldProps {
  value: string;
  onChange: (e: string) => void;
}

export default function PiQuizTextfield(props: PiQuizTextfieldProps) {
  const { value, onChange } = { ...props };
  const [currentValue, setCurrentValue] = useState<string>(value);
  const currLang = useSelector(selectCurrentLanguage);

  useEffect(() => {
    setCurrentValue(value);
  }, [currLang]);

  const debounced = useDebouncedCallback((value: string) => {
    onChange(value);
  }, 500);

  const onInputChange = (newStr: string) => {
    debounced(newStr);
    setCurrentValue(newStr);
  };

  return <input aria-label="piquiztextfield-input" className="piquiztextfield-root" value={currentValue} placeholder={"Answer"} onChange={(e) => onInputChange(e.target.value)} />;
}
