import React from "react";
import { closestCenter, DndContext, UniqueIdentifier, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { rectSortingStrategy, SortableContext, } from "@dnd-kit/sortable";
import { useCallback } from "react";
import SmartPointerSensor from "../features/previews/conversation/SmartPointerSensor";

export enum ContainerStyle {
  Grid = "grid",
  Column = "column",
  Default = "default",
}

interface PiDragContainerProps {
  onHandleDragEnd: (prevIndex: number, newIndex: number) => void;
  containerElements: React.JSX.Element[];
  uniqueIds: UniqueIdentifier[];
  containerStyle: ContainerStyle;
}

export default function PiDragContainer(props: PiDragContainerProps) {
  const { onHandleDragEnd, containerElements, uniqueIds } = { ...props };

  const handleDragEnd = useCallback((event: any) => {
    const { active, over } = event;

    if (active !== null && over !== null && active.id !== over.id) {
      const oldIndex = uniqueIds.indexOf(active.id);
      const newIndex = uniqueIds.indexOf(over.id);

      onHandleDragEnd(oldIndex, newIndex);
    }
  }, [onHandleDragEnd, uniqueIds]);

  const style: string = (() => {
    switch (props.containerStyle) {
      case ContainerStyle.Grid:
        return "w-[calc(100%-50px)] h-[30%] justify-center grid grid-cols-3 gap-3";
      case ContainerStyle.Column:
        return "";
      default:
        return "";
    }
  })();

  const sensors = useSensors(useSensor(SmartPointerSensor));

  //#endregion
  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCenter} modifiers={[restrictToParentElement]}>
      <SortableContext items={uniqueIds} strategy={rectSortingStrategy}>
        <div className={style}>
          {containerElements}
        </div>
      </SortableContext>
    </DndContext >
  );
}
