import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import SlideshowBodyPreview from "./SlideshowBodyPreview";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { ISlideshow } from "../../../models/interactions/Slideshow";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";

export default function SlideshowPreview() {
  const interaction = useSelector(selectSelectedElement) as ISlideshow;

  const { getValue, changeValue } = useTrainingLocalization();

  const title = (
    <PiInteractionTitlePreview
      value={getValue(interaction.title)}
      onChange={(newValue) => changeValue(interaction.title, newValue)}
      placeholder={"Enter your Title"}
    />
  );

  return <PiPopupWindowPreview body={<SlideshowBodyPreview />} title={title} />;
}
