import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import {useDispatch, useSelector} from "react-redux";
import {IGrab} from "../../../models/interactions/Grab";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import React from "react";
import PropDropdown from "./PropDropdown";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";

export default function GrabPreview() {

    return (
        <PiPopupWindowPreview body={<GrabPreviewBody/>} title={<PiPopupWindowStaticTitle title={"Grab"}/>}/>
    );
}

function GrabPreviewBody() {
    const grabInteraction = useSelector(selectSelectedElement) as IGrab;
    const dispatch = useDispatch();

    const onChangeGrabInteraction = (field: keyof IGrab, value: any) => {
        const grabUpdate = {...grabInteraction, [field]: value} as IGrab;
        dispatch(updateSelectedInteraction(grabUpdate));
    }

    return <div className={"w-full h-full flex gap-x-[14px]"}>
        <MessageBubbleActorDropdown label={"Actor"} selectedTargetActorId={grabInteraction.playerGuid} onClick={(actorId) => onChangeGrabInteraction("playerGuid", actorId)} hideActorsWithProp={true}/>
        <PropDropdown label={"Prop"} selectedPropStateId={grabInteraction.propGuid} onClick={(propId) => onChangeGrabInteraction("propGuid", propId)}/>
    </div>
}