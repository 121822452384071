import { Popover } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { UseLogout } from "../../hooks/LogoutHooks";
import "./PiAccountPopover.css";
import PiMenuButton from "./PiMenuButton";

interface PiAccountDialogProps {
  id: string;
  open: boolean;
  anchorEl: any;
  switchOrgCallback: () => void;
  changeLanguageCallback: () => void;
}

export default function PiAccountDialog(props: PiAccountDialogProps) {
  const { t } = useTranslation();
  const { onLogout } = UseLogout();

  // TODO: change org callback
  function ChangeOrganizationClicked() {
    props.switchOrgCallback();
  }
  function ChangeLanguageClicked() {
    props.changeLanguageCallback();
  }
  function logoutClicked() {
    onLogout();
  }

  return (
    <Popover
      id={props.id}
      open={props.open}
      onClose={() => {}}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: -172,
        horizontal: "left",
      }}
      PaperProps={{
        style: { width: "auto" },
      }}
    >
      <div className="pi-accountpopover-root">
        <div className="pi-accountpopover-content">
          <PiMenuButton text={t("switchOrganization")} onClick={ChangeOrganizationClicked} />
        </div>
        <div className="pi-accountpopover-content">
          <PiMenuButton text={t("changeLanguage")} onClick={ChangeLanguageClicked} />
        </div>
        <div className="pi-accountpopover-content">
          <PiMenuButton text={t("logout")} onClick={logoutClicked} />
        </div>
      </div>
    </Popover>
  );
}
