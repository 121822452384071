import Interaction, {InteractionType, Marker} from "../../models/interactions/Interaction";
import {getInteractionFromFactory} from "../interaction_factory/InteractionFactory";

export default function useInteractionMigration() {
    const migrateInteraction = (from: number, to: number, interaction: Interaction): Interaction => {
        const factoryResult = getInteractionFromFactory(interaction.type);

        return factoryResult ? factoryResult.Migrate(from, to, interaction) : interaction;
    };

    return {migrateInteraction} as const;
}
