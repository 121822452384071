import { Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import "./LoginForm.css";
const logo = require("../../assets/logo_verticaal.png");

interface LoginFormProps {
  isLoading: boolean;
  isAutoLoggingIn: boolean;
  isIncorrectLogin: boolean;
  onSubmit: (email: string, password: string) => void;
}

export function LoginForm(props: LoginFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const emailHelperText = "Please provider a valid email";
  const passwordHelperText = "Password is required";
  const invalidLoginText = "Email or password is incorrect";
  function UpdateEmail(newEmail: string) {
    setEmail(newEmail);
    setIsEmailError(newEmail === "");
  }

  function UpdatePassword(newPassword: string) {
    setPassword(newPassword);
    setIsPasswordError(newPassword === "");
  }

  function GetEmailErrorMessage() {
    if (isEmailError) return emailHelperText;
    return "";
  }

  function GetPasswordErrorMessage() {
    if (isPasswordError) return passwordHelperText;
    if (props.isIncorrectLogin) return invalidLoginText;
    return "";
  }

  if (props.isLoading || props.isAutoLoggingIn) {
    return (
      <div className="loginform-loading">
        <CircularProgress size="15%" />
      </div>
    );
  }

  return (
    <div className="loginform-root">
      <div className="loginform-col-left">
        <img src={logo} alt="Play It Logo" />
      </div>
      <div className="loginform-col-right">
        <header className="loginform-subtitle">
          <p>The number 1 game-based learning platform</p>
        </header>
        <div className="loginform-form">
          <div className="loginform-form-input">
            <TextField
              id="email"
              className="loginform-form-textfield"
              label="Email address"
              variant="standard"
              onChange={(e) => UpdateEmail(e.target.value)}
              disabled={props.isLoading}
              error={isEmailError}
              helperText={GetEmailErrorMessage()}
              inputProps={{ "data-testid": "email" }}
            />
          </div>
          <div className="loginform-form-input">
            <TextField
              id="password"
              className="loginform-form-textfield"
              label="Password"
              type="password"
              variant="standard"
              onChange={(e) => UpdatePassword(e.target.value)}
              disabled={props.isLoading}
              error={isPasswordError || props.isIncorrectLogin}
              helperText={GetPasswordErrorMessage()}
              inputProps={{ "data-testid": "password" }}
            />
          </div>
          <div className="loginform-form-input">
            <Button
              onClick={() => props.onSubmit(email, password)}
              disabled={props.isLoading}
              variant="piLogin"
              id={"loginSubmit"}
            >
              Login
            </Button>
          </div>
          <div className="loginform-form-input loginform-forgot-password">
            <a
              href="https://portal.playitsafe.eu/forgot-password"
              target="_blank"
              rel="noreferrer"
            >
              Forgot password?
            </a>
          </div>
          <div className="loginform-form-input">
            <div className="loginform-copyright">
              © Play It. All rights reserved 2023.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
