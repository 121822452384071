import { useDispatch, useSelector } from "react-redux";
import { renameFolder, setRenameFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export default function useUpdateFolder() {
  const token = useSelector(selectToken);
  const currentOrganization = useSelector(selectCurrentOrganization);
  const dispatch = useDispatch();

  const updateFolder = async (folderId: string, folderName: string) => {
    var didRename = await AssetService.UpdateFolder(currentOrganization!.id, token, folderId, folderName);

    // if no name conflict, update slice w/ new name
    if (didRename) {
      dispatch(renameFolder({ id: folderId, name: folderName }));
    } else {
      dispatch(setRenameFolder(undefined));
    }
  };

  return [updateFolder] as const;
}
