import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {Box, IconButton, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import React from "react";
import {TooltipRenderProps} from "react-joyride";

export const OnboardingTooltip = ({continuous, index, step, backProps, closeProps, primaryProps, tooltipProps, isLastStep, size}: TooltipRenderProps) => {
    const muiTheme = useTheme();
    return (
        <Box
            {...tooltipProps}
            sx={{
                background: muiTheme.palette.background.paper,
                pt: 5,
                pl: 5,
                pr: 5,
                width: 290,
                lineHeight: 1.4,
                textAlign: "center",
                borderRadius: 2,
                position: "relative",
            }}
        >
            <Box>{step.content}</Box>
            <Box sx={{display: "flex", pt: 4, pb: 2}}>
                {/* previous button */}
                {index > 0 && (
                    <IconButton
                        disableFocusRipple
                        {...backProps}
                        sx={{
                            backgroundColor: "#fff",
                            color: "#3AC4FF",
                            border: "2px solid var(--pi-key-blue)",
                            width: "40px",
                            height: "40px",
                            fontSize: 18,
                        }}
                    >
                        <ArrowBackIcon fontSize="inherit"/>
                    </IconButton>
                )}

                {step.showProgress && (
                    <Typography
                        sx={{
                            position: "absolute",
                            bottom: 24,
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: "var(--pi-menu-text-color-1)",
                            fontFamily: "Inter",
                        }}
                    >
                        {index + 1} / {size}
                    </Typography>
                )}

                {/* next button */}
                <IconButton
                    disableFocusRipple
                    {...primaryProps}
                    sx={{
                        marginLeft: "auto",
                        backgroundColor: "var(--pi-key-blue)",
                        color: "white",
                        width: "40px",
                        height: "40px",
                        fontSize: 18,
                        ":hover": {
                            backgroundColor: "var(--pi-key-blue-hover)",
                        },
                    }}
                >
                    {continuous && isLastStep ? <CheckIcon fontSize="inherit"/> : <ArrowForwardIcon fontSize="inherit"/>}
                </IconButton>

                {!step.hideCloseButton && (
                    <IconButton
                        disableRipple
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            p: 2,
                        }}
                        {...closeProps}
                    >
                        <ClearIcon/>
                    </IconButton>
                )}
            </Box>
        </Box>
    );
};
