import { useDispatch, useSelector } from "react-redux";
import { updateAssetInCurrentFolder } from "../app/slices/AssetsSlice";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { AssetService } from "../services/AssetService";

export const useAssetDetails = () => {
  const org = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getAssetDetails = async (assetId: string) => {
    if (!org) return;
    const asset = await AssetService.GetAsset(org.id, token, assetId);
    if (asset) {
      dispatch(updateAssetInCurrentFolder(asset));
    }
  };

  return [getAssetDetails] as const;
};
