import {
  selectAllPropPresets,
  selectSelectedTake3D,
} from "../../../app/slices/TrainingSlice";
import { useSelector } from "react-redux";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import React, { useMemo } from "react";
import {
  selectPropById,
  selectProps,
  selectUsedProps,
} from "../../../app/slices/PropLibrarySlice";
import { RootState } from "../../../app/Store";
import { PropTakeState } from "../../../models/PropTakeState";
import usePropHelper from "../../../hooks/PropHelper";

export default function PropDropdown(props: {
  label: string;
  selectedPropStateId: string;
  onClick: (propStateId: string) => void;
}) {
  const { label, selectedPropStateId, onClick } = { ...props };
  const { getIsPropAttachedToActor } = usePropHelper();
  const propsInTake = useSelector(selectSelectedTake3D)!.props;

  const propsInTakeNotGrabbed = useMemo(() => {
    const result: PropTakeState[] = [];

    propsInTake.forEach((pit) => {
      if (!getIsPropAttachedToActor(pit)) result.push(pit);
    });

    return result;
  }, [propsInTake]);

  const selectedPropState = propsInTake.find(
    (propState) => propState.instanceId === selectedPropStateId
  );
  const allProps = useSelector(selectProps);
  const allPropPresets = useSelector(selectAllPropPresets);

  const selectedPropPreset = allPropPresets.find(
    (prop) => prop.id === selectedPropState?.instanceId
  );

  const selectedProp = allProps.find(
    (prop) => prop.id === selectedPropPreset?.propId
  );

  const propDropdownItems = propsInTakeNotGrabbed.map((prop) => {
    return (
      <PiInteractionDropdown.Item
        key={prop.instanceId}
        onClick={() => onClick(prop.instanceId)}
      >
        <div className={"flex justify-center gap-4 w-full"}>
          <div className={"my-auto truncate"}>
            {allPropPresets.find((p) => p.id === prop.instanceId)?.name}
          </div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const propDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>
          <div className={"my-auto truncate"}>
            {selectedPropPreset?.name ?? "No Prop"}
          </div>
        </div>
      </PiInteractionDropdown.Trigger>
      {propDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{propDropdown}</>;
}
