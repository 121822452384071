import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import Take3D from "../../models/Take3D";
import TakeCard3DContent from "../training_timeline/TakeCard3DContent";
import "./Take3DCard.css";
import Take3DCardHeader from "./Take3DCardHeader";
import classNames from "classnames";

export default function Take3DCard(props: { take: Take3D; index: number; isSelected: boolean; dragHandleProps: DraggableProvidedDragHandleProps | undefined | null }) {
  const { take, isSelected } = { ...props };

  return (
    <div className={classNames({"border-2 border-solid box-border rounded-[5px] hover:border-creator-primary-hover": true,
    "border-creator-primary": isSelected,
    "border-creator-border": !isSelected,})}>
      <Take3DCardHeader {...props} />
      <TakeCard3DContent {...props} />
    </div>
  );
}
