import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFilter, setInteractionPickerFilter } from "../../app/slices/InteractionPickerSlice";
import PiDialog from "../../components/PiDialog";
import PiInputField from "../../components/PiInputField";
import AutoOnboarding from "../onboarding/AutoOnboarding";
import OnboardingButton from "../onboarding/OnboardingButton";
import "./InteractionPicker.css";
import InteractionPickerContent from "./InteractionPickerContent";
import { useTranslation } from "react-i18next";

interface InteractionPickerProps {
  open: boolean;
  onClose: () => void;
}

export default function InteractionPicker(props: InteractionPickerProps) {
  const filter = useSelector(selectFilter);
  const dispatch = useDispatch();
  const { open, onClose } = { ...props };
  const {t} = useTranslation();

  useEffect(() => {
    if (!open) dispatch(setInteractionPickerFilter(""));
  }, [open]);

  const header = <div className="interactionpicker-header">{t("interaction_picker")}</div>;

  // searchbar at the top

  const body = (
    <div className="interactionpicker-body">
      <div className="interactionpicker-search">
        <PiInputField
          label=""
          placeholder={t("filter")}
          value={filter}
          onChange={(newFilter) => {
            dispatch(setInteractionPickerFilter(newFilter));
          }}
          id="interactionpicker-search"
          autoFocus
        />
      </div>
      <div className="interactionpicker-content">
        <InteractionPickerContent onClose={onClose} />
      </div>
      <div className="interactionpicker-onboarding">
        <OnboardingButton context="interactionPicker" position="absolute" />
      </div>
      <AutoOnboarding context="interactionPicker" />
    </div>
  );

  return <PiDialog open={open} onClose={onClose} width="90vw" height="90vh" header={header} body={body} />;
}
