import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import AudioPopupPreview from "../../features/previews/audio_popup/AudioPopupPreview";
import { IAnswer } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {VideoPopup} from "./VideoPopup";

export interface IAudioPopup extends Interaction {
    title: string;
    imageAssetId: string;
    audioAssetId: string;
}

export function CreateAudioPopup(key: string, imageAssetId?: string, audioAssetId?: string): IAudioPopup {
    return {
        id: uuidv4(),
        name: "Audio Popup",
        type: InteractionType.AudioPopup,
        title: key,
        imageAssetId: imageAssetId ?? "",
        audioAssetId: audioAssetId ?? "",
    };
}

export function MigrateAudioPopup(from: number, to: number, interaction: IAudioPopup): IAudioPopup {
    return interaction;
}

export const audioPopupInteraction = (): InteractionFactoryEntity => {

    const component = <AudioPopupPreview />;


    const create = (getKeys: (amount: number) => string[]): IAudioPopup => {
        const keys = getKeys(1);
        return {
            id: uuidv4(),
            name: "Audio Popup",
            type: InteractionType.AudioPopup,
            title: keys[0],
            imageAssetId: "",
            audioAssetId: "",
        };
    }

    const migrate = (from: number, to: number, interaction: Interaction): IAudioPopup => {
        return interaction as IAudioPopup;
    }

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IAudioPopup => {
        const audioPopup = interaction as IAudioPopup;
        const newKeys = getKeys(1);
        replaceKey(audioPopup.title, newKeys[0]);

        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.AudioPopup,
            title: newKeys[0],
            imageAssetId: audioPopup.imageAssetId,
            audioAssetId: audioPopup.audioAssetId,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IAudioPopup;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");
        ValidationHelper.ValidateAssetId(converted.audioAssetId, warnings);
        ValidationHelper.ValidateAssetId(converted.imageAssetId, warnings);

        return warnings;
    }
    
    const filter = (interaction: Interaction): string[] => {
        let results: string[] = [];

        const popup = interaction as IAudioPopup

        results.push(interaction.name);
        results.push(popup.title);

        return results;
    }    

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter,
    }
}
