import {useDispatch, useSelector} from "react-redux";
import {selectFilteredCategory, selectPropCategories, selectPropFilter, setPropCategoryFilter} from "../../app/slices/PropLibrarySlice";
import PropLibraryCategoryButton from "./PropLibraryCategoryButton";
import PiBoxIcon from "../../icons/PiBoxIcon";
import classNames from "classnames";

export default function PropLibraryCategories() {
    const categories = useSelector(selectPropCategories);
    const selectedCategory = useSelector(selectFilteredCategory);

    const categoriesMapped = categories.map(category => <PropLibraryCategoryButton key={category.name} category={category} isSelected={category.name === selectedCategory}/>);
    const allCategoriesButton = <AllCategoriesButton isSelected={selectedCategory === ""}/>;

    return <div className={"w-full h-[calc(100%)] flex flex-col gap-[20px]"}>
        <span className={"text-[16px] h-[24px] font-bold text-creator-text"}>Categories</span>
        <div className={"h-full m-0 max-h-full flex flex-col gap-[10px] box-border overflow-y-auto pit-creator-scrollbar"}>
            {allCategoriesButton}
            {categoriesMapped}
        </div>
    </div>
}

function AllCategoriesButton(props: { isSelected: boolean }) {
    const {isSelected} = {...props};
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setPropCategoryFilter(""));
    }

    return <div className={classNames({
        "h-[56px] bg-creator-border rounded-[10px] flex gap-[10px] px-[20px] py-[16px] cursor-pointer": true,
        "border-2 border-black": isSelected,
    })} onClick={onClick}>
        <PiBoxIcon className={"my-auto"} width={24} height={24}/>
        <div className={"my-auto text-[14px] font-medium"}>
            All
        </div>
    </div>;
}