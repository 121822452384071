import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ReplayIcon from "@mui/icons-material/Replay";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import "./ScrabbleQuizBody.css";
import ScrabbleQuizTextfield from "./ScrabbleQuizTextfield";
export default function ScrabbleQuizBody() {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue, getNewKey } = useTrainingLocalization();
  const dispatch = useDispatch();
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);

  const addAnswer = () => {
    const newAnswer = { id: uuidv4(), correct: false, text: getNewKey() };
    const updatedAnswers = [...quiz.answers, newAnswer];
    const copy = { ...quiz, answers: updatedAnswers };
    dispatch(updateSelectedInteraction(copy));
  };

  const deleteAnswer = (id: string) => {
    const filtered = quiz.answers.filter((answer) => answer.id !== id);
    const copy = { ...quiz, answers: filtered };
    dispatch(updateSelectedInteraction(copy));
  };

  const answers = quiz.answers.map((answer, index) => (
    <ScrabbleQuizTextfield
      key={answer.id}
      index={index}
      answer={answer}
      answersLength={quiz.answers.length}
      canDelete={quiz.answers.length > 1}
      getValue={getValue}
      changeValue={changeValue}
      handleAnswerChange={(e) => {}} // dont need this
      handleDeleteAnswer={deleteAnswer}
      onTextfieldClick={(index) => setCurrentWordIndex(index)}
    />
  ));

  const getScrabbleWord = () => {
    if (currentWordIndex > quiz.answers.length - 1) {
      setCurrentWordIndex(0);
      return <></>;
    }
    var text = getValue(quiz.answers[currentWordIndex].text);
    var letters: string[] = [];
    for (var i = 0; i < text.length; i++) {
      letters.push(text.charAt(i));
    }

    return letters.map((letter, index) => {
      return (
        <span key={index} className="scrabblequizbody-preview-letter">
          {letter}
        </span>
      );
    });
  };
  const scrabblePreview = (
    <div className="scrabblequizbody-preview">
      <div className="scrabblequizbody-preview-question">
        <QuestionMarkIcon fontSize="inherit" color="inherit" />
      </div>
      <div className="scrabblequizbody-preview-replay">
        <ReplayIcon fontSize="inherit" color="inherit" />
      </div>
      <div className="scrabblequizbody-preview-word">{getScrabbleWord()}</div>
    </div>
  );
  const addButton = (
    <div className="scrabblequizbody-add-button">
      <PiBasicQuizAddButton onClick={addAnswer} />
    </div>
  );

  const canAddAnswer = quiz.answers.length < 5;

  const col1 = (
    <div className="scrabblequizbody-col1">
      {answers}
      {canAddAnswer && addButton}
    </div>
  );
  const col2 = <div className="scrabblequizbody-col2">{scrabblePreview}</div>;

  return (
    <div className="scrabblequizbody-root">
      {col1}
      {col2}
    </div>
  );
}
