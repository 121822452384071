import {v4 as uuidv4} from "uuid";
import {ActorState} from "../../../models/ActorState";
import Interaction from "../../../models/interactions/Interaction";
import {InteractionProperty} from "../../../models/InteractionProperty";
import {ISetCamera} from "../../../models/interactions/SetCamera";
import Take3D, {CreateTake3D} from "../../../models/Take3D";
import {
    TrainingState,
    selectInteractionIndexById,
    selectSelectedElementType,
    selectTakeIndexById,
} from "../TrainingSlice";
import {UpdatePropertyOfInteraction} from "../helpers/TrainingSliceHelper";
import {TrainingSliceStrategy} from "./TrainingSliceStrategy";
import {GetNewGuid} from "../../../helpers/GuidHelper";
import {PropTakeState} from "../../../models/PropTakeState";
import {getInteractionFromFactory} from "../../../features/interaction_factory/InteractionFactory";
import {useTrainingLocalization} from "../../../features/trainingLocalization/TrainingLocalizationHooks";
import {TrainingSliceStrategy3DHelper} from "./TrainingSliceStrategy3DHelper";
import {ITransition} from "../../../models/interactions/Transition";
import {Trans} from "react-i18next";

export const TrainingSliceStrategy3D: TrainingSliceStrategy = {
    setSelectedTake(state, takeId) {
        if (!state.takes3D.find((take) => take.id === takeId)) return;

        state.selectedTake = takeId;
        state.selectedElementId = takeId;
        state.selectedElementIsInteraction = false;
        state.selectedElementIsTake = true;
    },
    addNewTake(state, name, assetId, transition?: ITransition) {
        if(!transition) return;
        
        // add actors from previous take
        var actors: ActorState[] = [];
        var props: PropTakeState[] = [];

        if (state.takes3D.length > 0) {
            actors = [...state.takes3D[state.takes3D.length - 1].actors];
            actors = actors.map((actor) => ({...actor, id: uuidv4()}));
            //props = [...state.takes3D[state.takes3D.length - 1].props.map((prop) => ({...prop, id: uuidv4()}))];

            props = [...state.takes3D[state.takes3D.length - 1].props];
            props = props.map((prop) => ({...prop}));
        }

        let newTake = CreateTake3D(name ?? "Unassigned Take", actors, props, transition);
        
        if(state.takes3D.length > 0)
            newTake = TrainingSliceStrategy3DHelper.updateActorProp(state.takes3D[state.takes3D.length - 1], newTake)
        
        state.takes3D.push(newTake);
        state.selectedTake = newTake.id; // automatically select new take
        state.selectedElementId = newTake.id;
        state.isDirty = true;
    },
    addTakeCopy(state, take, transition?: ITransition) {
        if(!transition) return;
        const originalTake = take as Take3D;
        var copiedActors: ActorState[] = originalTake.actors;
        var copiedProps: PropTakeState[] = originalTake.props;

        copiedActors = copiedActors.map((actor) => ({...actor, id: uuidv4()}));

        const newTake = CreateTake3D(
            originalTake.name ?? "Unassigned Take",
            copiedActors,
            copiedProps,
            transition,
            originalTake.interactionPointId ?? undefined,
            originalTake.mainCamera
        );

        const indexSelectedTake: number = state.selectedElementIsTake
            ? state.takes3D.findIndex((t) => t.id === state.selectedElementId)
            : -1;

        if (indexSelectedTake !== -1) {
            state.takes3D.splice(indexSelectedTake + 1, 0, newTake);
        } else {
            state.takes3D.push(newTake);
        }

        state.selectedTake = newTake.id; // automatically select new take
        state.selectedElementId = newTake.id;
        state.isDirty = true;
    },
    setSelectedInteraction(state, interactionId) {
        // edge case for main camera
        const camParent = state.takes3D.find((take) => take.mainCamera.id === interactionId);
        if (camParent) {
            state.selectedTake = camParent.id;
            state.selectedElementId = interactionId;
            state.selectedElementIsTake = false;
            state.selectedElementIsInteraction = true;
            return;
        }

        // interaction
        const parent = state.takes3D.find(
            (take) => take.interactions.findIndex((interaction) => interaction.id === interactionId) !== -1
        );
        if (parent) {
            state.selectedTake = parent.id;
            state.selectedElementId = interactionId;
            state.selectedElementIsTake = false;
            state.selectedElementIsInteraction = true;
            return;
        }

        // TODO: some case for the actor ...
        const actorParent = state.takes3D.find(
            (take) => take.actors.findIndex((actor) => actor.id === interactionId) !== -1
        );
        if (actorParent) {
            state.selectedElementId = interactionId;
            state.selectedTake = actorParent.id;
            state.selectedElementIsTake = false;
            state.selectedElementIsInteraction = true;
        }
    },
    addInteractionToTake(state, interaction: Interaction, takeId) {
        const take = state.takes3D.find((take) => take.id === takeId);
        if (take) {
            take.interactions.push(interaction);
            state.selectedTake = takeId;
            state.selectedElementId = interaction.id;
            state.selectedElementIsTake = false;
            state.selectedElementIsInteraction = true;
            state.isDirty = true;
        }
    },
    addInteractionToSelectedTake(state, interaction) {
        const take: Take3D = state.takes3D[state.takes3D.findIndex((t) => t.id === state.selectedTake)];
        take.interactions.push(interaction);
        state.selectedElementId = interaction.id;
        state.selectedElementIsTake = false;
        state.selectedElementIsInteraction = true;
        state.isDirty = true;
    },
    addInteractionCopy(state, interaction) {
        const take: Take3D | undefined = state.takes3D.find((t) => t.id === state.selectedTake);

        if (!take) {
            return;
        }

        if (state.selectedElementIsInteraction) {
            const interactionIndex = take.interactions.findIndex((i) => i.id === state.selectedElementId);
            take.interactions.splice(interactionIndex + 1, 0, interaction);
        } else {
            take.interactions.push(interaction);
        }

        state.selectedElementId = interaction.id;
        state.selectedElementIsTake = false;
        state.selectedElementIsInteraction = true;
        state.isDirty = true;
    },
    deleteInteraction(state, interaction) {
        // todo: remove keys here
        const parent = state.takes3D.find((take) => take.interactions.find((i) => i.id === interaction.id));
        if (parent) {
            parent.interactions.splice(
                parent.interactions.findIndex((i) => i.id === interaction.id),
                1
            );
            state.isDirty = true;
        }
    },
    updateSelectedTake(state, take) {
        const ti = state.takes3D.findIndex((t) => t.id === take.id);
        state.takes3D[ti] = {...(take as Take3D)};
        state.isDirty = true;
    },
    updateSelectedTakeProperty: function (state: TrainingState, takeProperty: InteractionProperty): void {
        const ti = state.takes3D.findIndex((t) => t.id === state.selectedTake);
        const properties = state.takes3D[ti].properties!;
        const pi = properties.findIndex((p) => p.name === takeProperty.name);
        properties[pi] = takeProperty;
        state.takes3D[ti].properties = [...properties];
        state.isDirty = true;
    },
    setTakes(state, takes) {
        state.takes3D = takes as Take3D[];
        if (state.takes3D.length > 0) {
            state.selectedTake = state.takes3D[0].id;
            state.selectedElementId = state.takes3D[0].id;
        }
    },
    selectSelectedElement(state) {
        const selectedType = selectSelectedElementType(state);

        const take = state.training.takes3D.find((t) => t.id === state.training.selectedTake);
        if (!take) return;

        if (selectedType === "camera") {
            if (take.mainCamera.id === state.training.selectedElementId) return take.mainCamera;
        }

        if (selectedType === "interaction") {
            const interaction = take?.interactions.find((interaction) => interaction.id === state.training.selectedElementId);
            return interaction;
        }

        if (selectedType === "actor") {
            const actor = take?.actors.find((actor) => actor.id === state.training.selectedElementId);
            return actor;
        }
    },
    updateSelectedInteraction(state, interaction) {
        const ti = selectTakeIndexById(state.takes3D, state.selectedTake);
        const si = selectInteractionIndexById(state.takes3D[ti], interaction.id);
        state.takes3D[ti].interactions[si] = {...interaction};
    
        state.isDirty = true;
    },
    updateSelectedInteractionProperty: function (state: TrainingState, interactionProperty: InteractionProperty): void {
        const ti = selectTakeIndexById(state.takes3D, state.selectedTake);

        if (
            state.takes3D[ti].mainCamera.properties &&
            state.takes3D[ti].mainCamera.properties?.find((prop) => prop.id === interactionProperty.id)
        ) {
            state.takes3D[ti].mainCamera = UpdatePropertyOfInteraction(
                state.takes3D[ti].mainCamera,
                interactionProperty
            ) as ISetCamera;
        } else {
            const si = selectInteractionIndexById(state.takes3D[ti], state.selectedElementId);
            state.takes3D[ti].interactions[si] = UpdatePropertyOfInteraction(
                state.takes3D[ti].interactions[si],
                interactionProperty
            );
        }

        state.isDirty = true;
    },
    deleteTake: (state, takeId) => {
        const newTakes = state.takes3D.filter((take) => take.id !== takeId);
        state.takes3D = newTakes;

        // if there is another take, select that one
        if (state.takes3D.length > 0) {
            state.selectedTake = state.takes3D[0].id;
            state.selectedElementId = state.takes3D[0].id;
            state.selectedElementIsTake = true;
            state.selectedElementIsInteraction = false;
        }

        state.isDirty = true;
    },
    reorderTakes: (state, startIndex, endIndex) => {
        const [removed] = state.takes3D.splice(startIndex, 1);
        state.takes3D.splice(endIndex, 0, removed);
        state.isDirty = true;
    },
    reorderInteractions: (state, startTakeId, destinationTakeId, startIndex, endIndex) => {
        const startTake = state.takes3D[selectTakeIndexById(state.takes3D, startTakeId)];
        const destTake = state.takes3D[selectTakeIndexById(state.takes3D, destinationTakeId)];
        const [removed] = startTake.interactions.splice(startIndex, 1);
        destTake.interactions.splice(endIndex, 0, removed);

        state.selectedTake = destTake.id;
        state.isDirty = true;
    },
    selectSelectedElementType: (state: TrainingState) => {
        // take
        const selectedTake = state.takes3D.find((take) => take.id === state.selectedElementId);
        if (selectedTake) return "take";

        // camera edge case
        const selectedCamera = state.takes3D.find((take) => take.mainCamera.id === state.selectedElementId);
        if (selectedCamera) return "camera";

        // interaction
        const selectedInteraction = state.takes3D.find((take) =>
            take.interactions.find((interaction) => interaction.id === state.selectedElementId)
        );
        if (selectedInteraction) return "interaction";

        // actor
        const selectedActor = state.takes3D.find((take) => take.actors.find((a) => a.id === state.selectedElementId));
        if (selectedActor) return "actor";

        return undefined;
    },
    updateSelectedActorState: function (state: TrainingState, actorState: ActorState): void {
        const take = state.takes3D.find((take) => take.id === state.selectedTake);
        if (!take) return;

        const actorIndex = take.actors.findIndex((actor) => actor.id === actorState.id);
        take.actors[actorIndex] = actorState;
    },
    addPropToSelectedTake(state: TrainingState, instanceId: string, positionId: string): void {
        const takeIndex = findTakeIndexById(state.takes3D, state.selectedTake);
        if (takeIndex === -1) return;

        // check if prop is already on the map OR if prop position is already occupied
        var filteredProps = state.takes3D[takeIndex].props.filter(prop => prop.instanceId !== instanceId).filter(prop => prop.positionId !== positionId);

        // create new prop
        const newProp = {id: GetNewGuid(), instanceId: instanceId, positionId: positionId};

        // update the takes
        state.takes3D[takeIndex] = {...state.takes3D[takeIndex], props: [...filteredProps, newProp]};
        state.isDirty = true;
    },
    removePropFromSelectedTake(state: TrainingState, id: string): void {
        const takeIndex = findTakeIndexById(state.takes3D, state.selectedTake);
        if (takeIndex === -1) return;

        state.takes3D[takeIndex] = {
            ...state.takes3D[takeIndex],
            props: state.takes3D[takeIndex].props.filter((prop) => prop.id !== id),
        };

        state.isDirty = true;
    },
};

const findTakeById = (takes: Take3D[], takeId: string) => {
    return takes.find((take) => take.id === takeId);
};
const findTakeIndexById = (takes: Take3D[], takeId: string) => {
    return takes.findIndex((take) => take.id === takeId);
};
