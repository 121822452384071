import React, { ReactNode } from 'react';

type ActorPresetEditorFieldProps = {
    children: ReactNode;
    name: string;
    icon?: React.ElementType | null;
};

export default function ActorPresetEditorField(props: ActorPresetEditorFieldProps) {
    const { name, icon: Icon, children } = props;

    const header = (
        <div className="flex p-[10px] items-center gap-[10px] h-[44px] border-b-2 border-creator-border">
            <div className="text-creator-primary">
                {Icon && <Icon />}
            </div>
            <div className="font-semibold text-creator-text text-14">
                {name}
            </div>
        </div>
    );

    return (
        <div className="flex flex-col gap-[8px] w-full h-full">
            {header}
            <div className="h-full bg-creator-primary-background p-[10px]">
                {children}
            </div>
        </div>
    );
}
