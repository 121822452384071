import ClearIcon from "@mui/icons-material/Clear";
import React from "react";
import { PiQuizAnswerProps } from "../components/PiBasicQuizAnswer";
import PiQuizTextfield from "../components/PiQuizTextfield";
import "./OrderQuizAnswer.css";

export interface OrderQuizAnswerProps extends PiQuizAnswerProps {
  index: number;
}

export default function OrderQuizAnswer(props: OrderQuizAnswerProps) {
  const {
    answer,
    index,
    handleAnswerChange,
    getValue,
    changeValue,
    handleDeleteAnswer,
    answersLength,
    canDelete,
  } = { ...props };

  const number = (
    <div className="orderquiz-number">
      <div>{index + 1}</div>
    </div>
  );

  return (
    <div key={answer.id} className="orderquiz-root" tabIndex={-1}>
      {number}
      <PiQuizTextfield
        value={getValue(answer.text)}
        onChange={(e) => changeValue(answer.text, e)}
      />
      {canDelete && (
        <div
          className="orderquiz-delete-btn"
          onClick={() => handleDeleteAnswer(answer.id)}
        >
          <ClearIcon fontSize="inherit" color="inherit" />
        </div>
      )}
    </div>
  );
}
