import * as ToggleGroup from '@radix-ui/react-toggle-group';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import {ActorState} from "../../models/ActorState";
import {ActorMovementType} from "../../models/ActorMovementType";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export default function ActorStateMovementToggle(props: { actor: ActorState, onChange: (movement: ActorMovementType) => void, disabled: boolean }) {

    const {actor, onChange, disabled} = {...props};
    const {t} = useTranslation();

    const toggleGroupItemClasses =
        '  data-[state=off]:bg-transparent data-[state=on]:bg-white data-[state=on]:drop-shadow-md data-[state=off]:text-creator-text-sub data-[state=on]:text-creator-primary flex h-[35px] w-full' +
        ' items-center' +
        ' justify-center' +
        ' text-base leading-4' +
        ' first:rounded-full' +
        ' last:rounded-full focus:z-10' +
        ' data-[disabled]:bg-transparent data-[disabled]:text-creator-primary-disabled disabled:border-creator-primary-disabled  data-disabled:cursor-not-allowed disabled:drop-shadow-none';


    const toggle = <ToggleGroup.Root
        className={classNames({
            "flex w-full focus:outline-none p-[4px] rounded-full border-2 border-solid border-creator-border": true,
            "bg-white": disabled,
            "bg-creator-childActive": !disabled,
        })}
        type="single"
        value={disabled ? "" : actor.movement.toString()}
        aria-label="Text alignment"
        onValueChange={(value) => {
            if (value) {
                const mt: ActorMovementType = parseInt(value);
                onChange(mt);
            }
        }}
        disabled={disabled}
    >
        <ToggleGroup.Item className={toggleGroupItemClasses} value={ActorMovementType.Instant.toString()} aria-label="Left aligned">
            <BoltOutlinedIcon/>
        </ToggleGroup.Item>
        <ToggleGroup.Item className={toggleGroupItemClasses} value={ActorMovementType.Walk.toString()} aria-label="Right aligned">
            <DirectionsRunOutlinedIcon/>
        </ToggleGroup.Item>
    </ToggleGroup.Root>;

    return <div className={"flex flex-col gap-[8px]"}>
        <div className={"text-creator-text text-[14px] font-medium"}>{t("movement")}</div>
        {toggle}
    </div>;
}