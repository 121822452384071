import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { useTranslation } from 'react-i18next';

interface PiTooltipProps {
    anchor: JSX.Element;
    tooltipLocId?: string;
}

export default function PiTooltip(props: PiTooltipProps) {
    const { anchor, tooltipLocId } = { ...props };
    const { t } = useTranslation();
    const tooltipText = tooltipLocId ? t(tooltipLocId) : "";

    return (
        <>
            {tooltipText ?
                <Tooltip.Provider>
                    <Tooltip.Root>
                        <Tooltip.Trigger asChild>
                            {anchor}
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content className="bg-white rounded-[5px] p-[10px] text-interaction-primary min-w-[117px] max-w-[500px] font-medium text-14 leading-5 shadow-base items-center !z-[15] border-1 border-interaction-border">
                                {tooltipText}
                                <Tooltip.Arrow className="w-4 h-2 fill-white" />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider> : anchor}
        </>
    );




};