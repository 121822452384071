import AddIcon from "@mui/icons-material/Add";
import classNames from "classnames";

export default function PiBasicQuizAddAnswerButtonV2(props: {
  onClick: () => void;
  disabled?: boolean;
  text?: string;
}) {
  const { onClick, disabled, text } = { ...props };

  return (
    <button
      className={classNames({
        "relative w-full h-[68px] flex bg-interaction-softBackground border-dashed border-b-2 border-interaction-primary gap-[12px] justify-center":
          true,
        "hover:bg-interaction-primary-washed cursor-pointer": !disabled,
        "cursor-not-allowed": disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <div
        className={classNames({
          "my-auto w-[36px] h-[36px] flex rounded-full text-white": true,
          "bg-interaction-primary": !disabled,
          "bg-interaction-disabled": disabled,
        })}
      >
        <AddIcon className={"m-auto"} />
      </div>
      {text && (
        <div
          className={"my-auto text-[18px] font-medium text-interaction-primary"}
        >
          {text}
        </div>
      )}
    </button>
  );
}
