import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Creator from "./Creator";
import { store } from "./app/Store";
import TrainingEditor from "./features/TrainingEditor/TrainingEditor";
import Dashboard from "./features/dashboard/Dashboard";
import DashboardPage from "./features/dashboard/DashboardPage";
import "./features/i18n/i18n";
import NewTrainingPage from "./features/new_training/NewTrainingPage";
import { OnboardingProvider } from "./features/onboarding/OnboardingContext";
import "./index.css";
import { PiTheme } from "./theme";
import 'leaflet/dist/leaflet.css';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <ThemeProvider theme={PiTheme}>
      <OnboardingProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Creator />}>
              <Route path="/" element={<Dashboard />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="newTraining" element={<NewTrainingPage />} />
                <Route path="importTraining" element={<div>import training placeholder</div>} />
              </Route>
              <Route path="/TrainingEditor/:trainingId" element={<TrainingEditor />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </OnboardingProvider>
    </ThemeProvider>
  </Provider>
);
