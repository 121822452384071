import { Breadcrumbs } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./PiBreadcrumbs.css";
export interface BreadcrumbItem {
  name: string;
  link?: string;
}

interface PiBreadcrumbsProps {
  breadcrumbs: BreadcrumbItem[];
}

export default function PiBreadcrumbs(props: PiBreadcrumbsProps) {
  const navigate = useNavigate();

  const breadCrumbs = props.breadcrumbs.map((value: BreadcrumbItem, index: number) => {
    if (index !== props.breadcrumbs.length - 1) {
      if (!value.link) {
        return (
          <div className="pi-breadcrumb-element" key={index}>
            {value.name}
          </div>
        );
      } else {
        return (
          <div className="pi-breadcrumb-element-link" onClick={() => onLinkClick(value.link!)} key={index}>
            {value.name}
          </div>
        );
      }
    } else {
      return (
        <div className="pi-breadcrumb-element-highlight" key={index}>
          {value.name}
        </div>
      );
    }
  });

  function onLinkClick(link: string) {
    navigate(link);
  }

  return <Breadcrumbs id={"pi-breadcrumbs"} separator=">">{breadCrumbs}</Breadcrumbs>;
}
