import axios from "axios";
import { AssetContentDTO, AssetDetailsDTO, AssetKey, AssetPreviewDTO, AssetVariantDTO, AssetVersionDTO } from "../dto/AssetDTO";

import { SHA256 } from "crypto-js";
import { AssetFolderDTO } from "../dto/AssetFolderDTO";
var BASE_URL = process.env.REACT_APP_PLAYIT_MAINAPI;
var CREATOR_URL = process.env.REACT_APP_PLAYIT_CREATORAPI;

export class AssetService {
  static async GetAssetPreviews(orgId: string, key: string, token: string) {
    var path = "/v2/organizations/" + orgId + "/assets/preview?key=" + key;
    var url = BASE_URL + path;
    const config = getConfig(token);
    var result: AssetPreviewDTO[] = [];
    await axios
      .get<AssetPreviewDTO[]>(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    return result;
  }

  static async GetAssetDownloadUrl(orgId: string, assetId: string, versionId: string, variantId: string, token: string): Promise<AssetContentDTO | undefined> {
    var path = "/v2/Organizations/" + orgId + "/Assets/" + assetId + "/Versions/" + versionId + "/Content?Platform=" + variantId;
    var url = BASE_URL + path;
    const config = getConfig(token);

    var result: AssetContentDTO | undefined = undefined;

    await axios
      .get<AssetContentDTO>(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    return result;
  }

  static async CreateNewAsset(orgId: string, key: string, name: string, token: string, userId: string): Promise<AssetDetailsDTO | undefined> {
    var path = "/v2/Organizations/" + orgId + "/Assets";
    var url = BASE_URL + path;

    var config = getConfig(token);
    var body = {
      AssetName: name,
      AssetKey: key,
      OwnerUserId: userId,
    };

    var result: AssetDetailsDTO | undefined = undefined;

    await axios
      .post<AssetDetailsDTO>(url, body, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error.response.data);
      });

    return result;
  }

  static async CreateNewAssetVersion(orgId: string, assetId: string, version: string, token: string, username: string): Promise<AssetVersionDTO | undefined> {
    var path = "/v2/Organizations/" + orgId + "/Assets/" + assetId + "/versions";
    var url = BASE_URL + path;
    var config = getConfig(token);
    var now = new Date();
    const formattedDateTime = now.toISOString();

    var body = {
      version: version,
      minBuildVersion: "1.0.0",
      state: 10,
      comment: "new version",
      lastEditBy: username,
      lastEdit: formattedDateTime,
    };
    var result: AssetVersionDTO | undefined = undefined;
    await axios
      .post<AssetVersionDTO>(url, body, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response.data));
    return result;
  }

  static async UploadAssetFile(orgId: string, assetId: string, versionId: string, file: File, name: string, variantId: string, contentType: string, token: string) {
    // TODO: put content type somewhere
    var hash: string = await calculateFileHash(file);
    var path = "/v2/Organizations/" + orgId + "/Assets/" + assetId + "/versions/" + versionId + "/content?hash=" + hash + "&platform=" + variantId;
    var url = BASE_URL + path;
    var config = getConfig(token);
    const formData = new FormData();
    formData.append("file", file);

    var assentVariant: AssetVariantDTO = {
      variantId: variantId,
      blobPath: "",
      hash: hash,
      downloadUri: "",
      variant: "",
      id: "",
    };

    await axios
      .post<string>(url, formData, config)
      .then((response) => {
        assentVariant.blobPath = response.data;
      })
      .catch((error) => console.log(error.response.data));
    return assentVariant;
  }

  static async UpdateAsset(orgId: string, assetId: string, assetName: string, assetKey: string, token: string) {
    var path = "/v2/organizations/" + orgId + "/assets/" + assetId;
    var url = BASE_URL + path;
    var config = getConfig(token);
    var body = {
      assetName: assetName,
      assetKey: assetKey,
    };

    await axios
      .post(url, body, config)
      .then((response) => console.log("successfully edited asset!"))
      .catch((error) => console.log(error.response.data));
  }

  static async GetAssetDetails(orgId: string, assetId: string, token: string): Promise<AssetDetailsDTO | undefined> {
    const path = "/v2/organizations/" + orgId + "/assets/" + assetId;
    const url = BASE_URL + path;
    const config = getConfig(token);
    var result: AssetDetailsDTO | undefined;
    await axios
      .get<AssetDetailsDTO>(url, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.data));
    return result;
  }

  // ==== NEW API ====
  static async GetFolders(orgId: string, token: string, pageSize: number, pageNumber: number): Promise<{ folders: AssetFolderDTO[]; totalCount: number; totalPages: number }> {
    const path = "/organizations/" + orgId + "/folders";
    const url = CREATOR_URL + path;

    const params = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      OrderBy: "id desc",
    };

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: AssetFolderDTO[] = [];
    var totalCount = 0;
    var totalPages = 0;

    await axios
      .get<AssetFolderDTO[]>(url, config)
      .then((response) => {
        result = response.data;
        totalPages = JSON.parse(response.headers["x-pagination"])["totalPages"];
        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => console.log(error.response));

    return {
      folders: result,
      totalCount: totalCount,
      totalPages: totalPages,
    };
  }

  static async AddFolder(orgId: string, token: string, folderName: string): Promise<AssetFolderDTO | undefined> {
    const path = "/organizations/" + orgId + "/folders";
    const url = CREATOR_URL + path;
    var config = getConfig(token);
    var params = {
      Name: folderName,
    };

    var result: AssetFolderDTO | undefined = undefined;

    await axios
      .post(url, params, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  static async UpdateFolder(orgId: string, token: string, folderId: string, folderName: string) {
    const path = "/organizations/" + orgId + "/folders/" + folderId;
    const url = CREATOR_URL + path;
    var config = getConfig(token);
    var params = {
      name: folderName,
    };

    var success = false;
    await axios
      .put(url, params, config)
      .then((response) => {
        console.log("asset has been renamed succesfully");
        success = true;
      })
      .catch((error) => console.log(error.response));

    return success;
  }

  static async DeleteFolder(orgId: string, token: string, folderId: string) {
    const path = "/organizations/" + orgId + "/folders/" + folderId;
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    await axios.delete(url, config);
  }

  static async GetAssetsFromFolder(
    orgId: string,
    token: string,
    folderId: string,
    pageSize: number,
    pageNumber: number,
    assetKey?: AssetKey
  ): Promise<{ assets: AssetDetailsDTO[]; totalPages: number; totalCount: number; folderId: string }> {
    const path = "/organizations/" + orgId + "/assets";
    const url = CREATOR_URL + path;

    // add assetkey to params if it is not undefined
    const params = {
      folderId: folderId,
      PageSize: pageSize,
      PageNumber: pageNumber,
      OrderBy: "id desc",
      Key: assetKey,
    };

    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var assets: AssetDetailsDTO[] = [];
    var totalPages = -1;
    var totalCount = -1;

    await axios
      .get<AssetDetailsDTO[]>(url, config)
      .then((response) => {
        assets = response.data;
        totalPages = JSON.parse(response.headers["x-pagination"])["totalPages"];
        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => {
        console.log(error.response);
      });

    return {
      assets: assets,
      totalPages: totalPages,
      totalCount: totalCount,
      folderId: folderId,
    };
  }

  static async GetFilteredAssets(
    orgId: string,
    token: string,
    pageSize: number,
    pageNumber: number,
    filter: string,
    assetKey?: AssetKey
  ): Promise<{ assets: AssetDetailsDTO[]; totalPages: number; totalCount: number }> {
    const path = "/organizations/" + orgId + "/assets";
    const url = CREATOR_URL + path;

    const params = {
      PageSize: pageSize,
      PageNumber: pageNumber,
      OrderBy: "id desc",
      Key: assetKey,
      Name: filter,
    };
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var assets: AssetDetailsDTO[] = [];
    var totalPages = -1;
    var totalCount = -1;

    await axios
      .get<AssetDetailsDTO[]>(url, config)
      .then((response) => {
        assets = response.data;
        totalPages = JSON.parse(response.headers["x-pagination"])["totalPages"];
        totalCount = JSON.parse(response.headers["x-pagination"])["totalCount"];
      })
      .catch((error) => {
        console.log(error.response);
      });

    return {
      assets: assets,
      totalPages: totalPages,
      totalCount: totalCount,
    };
  }

  static async AddNewAssetToFolder(orgId: string, token: string, folderId: string, file: File): Promise<AssetDetailsDTO | undefined> {
    const path = "/organizations/" + orgId + "/assets";
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    const formData = new FormData();
    formData.append("Name", file.name);
    formData.append("FolderId", folderId);
    formData.append("Variant", "default"); // ?
    formData.append("File", file);

    var result: AssetDetailsDTO | undefined = undefined;
    await axios
      .post<AssetDetailsDTO>(url, formData, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  static async GetAsset(orgId: string, token: string, assetId: string): Promise<AssetDetailsDTO | undefined> {
    const path = "/organizations/" + orgId + "/assets/" + assetId;
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    var result: AssetDetailsDTO | undefined = undefined;
    await axios
      .get<AssetDetailsDTO>(url, config)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => console.log(error.response));

    return result;
  }

  static async GetAssetByGuid(orgId: string, token: string, assetId: string, content: boolean, thumbnail: string): Promise<AssetDetailsDTO | undefined> {
    const path = "/organizations/" + orgId + "/assets/by-guid/" + assetId;
    const url = CREATOR_URL + path;

    const params = {
      Content: content,
      Thumbnail: thumbnail,
    };
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
      params: params,
    };

    var result: AssetDetailsDTO | undefined = undefined;
    await axios
      .get<AssetDetailsDTO>(url, config)
      .then((response) => (result = response.data))
      .catch((error) => console.log(error.response));

    return result;
  }

  static async DeleteAsset(orgId: string, token: string, assetId: string) {
    const path = "/organizations/" + orgId + "/assets/" + assetId;
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    var success = false;
    await axios
      .delete(url, config)
      .then((response) => (success = true))
      .catch((error) => console.log(error.response));
    return success;
  }

  static async UpdateAssetName(orgId: string, token: string, assetId: string, newName: string) {
    const path = "/organizations/" + orgId + "/assets/" + assetId;
    const url = CREATOR_URL + path;
    const config = getConfig(token);
    const data = {
      name: newName,
    };
    await axios
      .put(url, data, config)
      .then((response) => {
        console.log("asset name updated succesfully");
      })
      .catch((error) => console.log(error.response));
  }

  static async CreateVariant(orgId: string, token: string, assetId: string, file: File) {
    const path = "/organizations/" + orgId + "/assets/" + assetId + "/variants";
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    const formData = new FormData();
    formData.append("File", file);

    await axios
      .post(url, formData, config)
      .then((response) => console.log("new variant created"))
      .catch((error) => console.log(error.response));
  }

  static async UpdateVariantId(orgId: string, token: string, assetId: string, variantId: string, variant: string) {
    const path = "/organizations/" + orgId + "/assets/" + assetId + "/variants/" + variantId;
    const url = CREATOR_URL + path;
    const config = getConfig(token);
    const data = {
      variant: variant,
    };

    await axios
      .put(url, data, config)
      .then((response) => console.log("variant id succesfully updated!"))
      .catch((error) => console.log(error.response));
  }

  static async UpdateVariantContent(orgId: string, token: string, assetId: string, variantId: string, file: File) {
    const path = "/organizations/" + orgId + "/assets/" + assetId + "/variants/" + variantId + "/content";
    const url = CREATOR_URL + path;
    const config = getConfig(token);

    const formData = new FormData();
    formData.append("File", file);

    await axios
      .put(url, formData, config)
      .then((response) => console.log("variant was succesfully updated"))
      .catch((error) => console.log(error.response));
  }
}

// === Helper functions ===
// create config w/ auth for api calls
const getConfig = (token: string) => {
  return {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
};

// Function to calculate the hash of a file
const calculateFileHash = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const fileData = reader.result;
      const wordArray = SHA256(fileData as string);
      const hash = wordArray.toString();
      resolve(hash);
    };

    reader.onerror = () => {
      reject(new Error("Error reading file"));
    };

    reader.readAsBinaryString(file);
  });
};
