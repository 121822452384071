import React from "react";
import "./PiPlayButtonPreview.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export default function PiPlayButtonPreview() {
  return (
    <div className="piplaybuttonpreview-root">
      <PlayArrowIcon fontSize="inherit" color="inherit" />
    </div>
  );
}
