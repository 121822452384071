import { v4 as uuidv4 } from "uuid";
import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import WordQuizPreview from "../../features/previews/WordQuiz/WordQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import { InteractionProperty } from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Interaction, { InteractionType } from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {IWordSpinnerQuiz} from "./WordSpinnerQuiz";

export interface IWordQuiz extends IFormQuiz {
    formattedSentence: string;
}

export const requiredWordProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateWordQuiz(keys: string[]): IWordQuiz {
    return {
        id: uuidv4(),
        name: "Word Quiz",
        type: InteractionType.WordQuiz,
        title: keys[0],
        formattedSentence: keys[2],
        answers: [],
        properties: requiredWordProperties,
    };
}

export function CreateWordQuizCopy(
    titleKey: string,
    formattedSentenceKey: string,
    answers: IAnswer[],
    properties: InteractionProperty[] | undefined
): IWordQuiz {
    return {
        id: uuidv4(),
        name: "Word Quiz",
        type: InteractionType.WordQuiz,
        title: titleKey,
        formattedSentence: formattedSentenceKey,
        answers: answers,
        properties: properties,
    };
}

export function MigrateWordQuiz(from: number, to: number, interaction: IWordQuiz): IWordQuiz {
    if (interaction.properties === undefined) return { ...interaction, properties: requiredWordProperties };

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredWordProperties, interaction.properties);
    console.log("[WordQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return { ...interaction, properties: newProperties };
}

export const wordQuizInteraction = (): InteractionFactoryEntity => {
    const component = <WordQuizPreview />;

    const create = (getKeys: (amount: number) => string[]): IWordQuiz => {
        const keys = getKeys(2);
        return {
            id: uuidv4(),
            name: "Word Quiz",
            type: InteractionType.WordQuiz,
            title: keys[0],
            formattedSentence: keys[1],
            answers: [],
            properties: requiredWordProperties,
        };
    }
    const migrate = (from: number, to: number, interaction: Interaction): IWordQuiz => {
        const quizData = interaction as IWordQuiz;
        if (interaction.properties === undefined) return { ...quizData, properties: requiredWordProperties };

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredWordProperties, interaction.properties);
        console.log("[WordQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return { ...quizData, properties: newProperties };
    }

    const copy = (interaction: Interaction,
        getKeys: (amount: number) => string[],
        replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IWordQuiz => {

        const formQuiz = interaction as IWordQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 2);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        replaceKey(formQuiz.formattedSentence, newKeys[newKeys.length - 2]);

        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.WordQuiz,
            title: newKeys[newKeys.length - 1],
            formattedSentence: newKeys[newKeys.length - 2],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            properties: formQuiz.properties,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IWordQuiz;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

        // validate all answers + validate we have at least one answer
        for (let i = 0; i < converted.answers.length; i++) {
            const tempAnswer = converted.answers[i];
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer ${i + 1}`);
        }

        return warnings;
    }
    
    const filter = (interaction: Interaction, getValue: (key: string) => string): string[] => {
        let results: string[] = [];

        const wordQuiz = interaction as IWordQuiz
        const answers = wordQuiz.answers.map(item => item.text);

        results.push(interaction.name);
        results.push(wordQuiz.title);

        let localFormattedSentence = getValue(wordQuiz.formattedSentence);
        const pattern = /[\{\}]/g;
        localFormattedSentence = localFormattedSentence.replace(pattern, '');

        results.push(localFormattedSentence);
        answers.forEach(answer => { results.push(answer); })

        return results;
    }

    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate,
        Filter: filter
    }
}
