import Skeleton from "@mui/material/Skeleton";

export default function CameraPreviewSkeleton() {
    return (
        <>
            <div className="w-full h-[10%] flex">
                <Skeleton variant="text" height={"100%"} animation={"wave"} sx={{ marginLeft: "10px" }} width={"10%"} />
                <Skeleton variant="text" height={"100%"} animation={"wave"} sx={{ marginLeft: "15px" }} width={"50%"} />
            </div>
            <div className="w-full h-full">
                <Skeleton variant="rectangular" height={"100%"} animation={"wave"} />
            </div>

        </>
    );
}
