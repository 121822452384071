import {addPropPreset, selectAllPropPresets} from "../../app/slices/TrainingSlice";
import {useDispatch, useSelector} from "react-redux";
import PiButton from "../../components/PiButton";
import PropLibrary from "../prop_library/PropLibrary";
import React from "react";
import {addSelectedPropAsUsedProp, selectSelectedPropContent, selectSelectedPropId, selectSelectedPropMetadata} from "../../app/slices/PropLibrarySlice";
import usePropHelper from "../../hooks/PropHelper";
import {GetNewGuid} from "../../helpers/GuidHelper";
import PropPresetList from "./PropPresetList";
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
export default function PropPresetsColumn() {

    // 1. we need to get all current props
    // 2. we need an add button
    // 3. when we click the add button the prop picker is opened
    // 4. we add the prop to the prop list on the slice if clicked OK.
    // we need a prop preset card !
    
    const allProps = useSelector(selectAllPropPresets);
    const [isOpen, setIsOpen] = React.useState(false);
    const selectedPropPickerId = useSelector(selectSelectedPropId);
    const {getPropDTO} = usePropHelper();
    const dispatch = useDispatch();
    const selectedPropContent = useSelector(selectSelectedPropContent);
    const selectPropMetadata = useSelector(selectSelectedPropMetadata);
    
    const onAddProp = () => {
        if(!selectedPropContent || !selectPropMetadata) return;

        dispatch(addPropPreset({id: GetNewGuid(), propId: selectedPropContent.id, name: selectedPropContent.name, prefabName: selectPropMetadata.prefab_name}));
        dispatch(addSelectedPropAsUsedProp()); // add prop to cached props
        
        setIsOpen(false);
    }
    
    const addButton =<div className={"px-[18px]"}>
        <PiButton variant={"piBlue"} onClick={() => {setIsOpen(true)}}>
            <div className={"h-full flex justify-center mx-auto justify-items-center"}>
                <div className={"flex gap-[8px] my-auto text-[24px]"}>
                    <PersonAddAltOutlinedIcon className={""} fontSize={"inherit"}/>
                    <div className={"text-[14px] my-auto"}>Add Prop</div>
                </div>
            </div>
        </PiButton>
    </div> ;
    
    return <div className={"w-full h-full flex flex-col gap-y-[10px]"}>
        <PropPresetList />
        {addButton}
        <PropLibrary title={"Prop Library"} isOpen={isOpen} onClose={() => setIsOpen(false)} width={"90vw"} height={"90vh"} onAddProp={onAddProp}/>
    </div>
}