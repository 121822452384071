import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { selectAuthenticated } from "../../app/slices/AuthenticationSlice";
import "./OnboardingButton.css";
import { OnboardingContext } from "./OnboardingContext";

export interface OnboardingButtonProps {
  context: string;
  position: "absolute" | "fixed";
}

export default function OnboardingButton(props: OnboardingButtonProps) {
  const isAuthenticated = useSelector(selectAuthenticated);
  const { state, setState } = useContext(OnboardingContext);
  const { context, position } = { ...props };

  const handleOnboardingButtonClick = () => {
    setState((prevState) => ({
      ...prevState,
      run: true,
      onboardingActive: true,
      context: context,
    }));
  };
  return (
    <div className={"onboardingbutton onboardingbutton-" + position}>
      <HelpOutlineOutlinedIcon
        onClick={handleOnboardingButtonClick}
        color="inherit"
      />
    </div>
  );
}
