import {Actor, ProfilePictures} from "../../models/Actor";
import React from "react";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import classNames from "classnames";

interface ActorAvatarProps {
    actor?: Actor;
    size?: '24' | '36';
    theme?: 'interaction' | 'creator' | 'creator-selected';
}

const ActorAvatar: React.FC<ActorAvatarProps> = ({ actor, size = '36', theme = "interaction" }) => {
    
    return (
        <div
            className={classNames({
                'flex  my-auto rounded-full border-2 border-solid box-border text-[24px]': true,
                'w-[36px] h-[36px] min-w-[36px] min-h-[36px]': size === '36',
                'w-[24px] h-[24px] min-w-[24px] min-h-[24px]': size === '24',
                'border-interaction-primary bg-interaction-primary': theme === 'interaction',
                'border-creator-border bg-creator-primary': theme === 'creator',
                'border-creator-primary bg-creator-primary': theme === 'creator-selected',
            })}
        >
            {actor !== undefined && actor.id !== 'player' && (
                <img
                    src={ProfilePictures[actor?.profilePicture ?? 0]}
                    alt="profile_picture"
                />
            )}
            {actor !== undefined && actor.id === 'player' && (
                <div className={classNames({
                    "m-auto font-medium text-white": true,
                    "text-[18px]": size === '36',
                    "text-[14px]": size === '24',
                })}>
                    P
                </div>
            )}
            {actor === undefined && (
                <PersonOutlinedIcon
                    className="text-[14px] text-white m-auto"
                    fontSize="inherit"
                />
            )}
        </div>
    );
};

export default ActorAvatar;