import {RgbaColor} from "@uiw/react-color";
import {useEffect, useRef, useState} from "react";
import PiColorPicker from "./PiColorPicker";
import {AnimatePresence, motion} from "framer-motion";

export default function PiColorPickerField(props: {
    label: string,
    color: RgbaColor,
    onChange: (color: RgbaColor) => void
}) {
    const {label, color, onChange} = {...props};

    const [open, setOpen] = useState(false);

    const onBlur = () => {
        setOpen(false);
    }

    const myPicker = <PiColorPicker onChange={onChange} color={color}/>

    const divRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
            onBlur();
            // Perform any action when click is outside
        }
    };

    useEffect(() => {
        // Add event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return <div className={"flex flex-col gap-y-[8px]"} ref={divRef}>
        <div className={"w-full flex flex-col gap-y-[6px]"}>
            <div className={"font-medium text-[14px] text-creator-text"}>{label}</div>
            <div className={"px-[4px]"}>
                <div
                    className={"w-full h-[40px] rounded-[5px] border-2 border-solid border-creator-primary cursor-pointer"}
                    style={{backgroundColor: `rgba(${color.r},${color.g},${color.b},${color.a})`}}
                    onClick={() => setOpen(!open)}>
                </div>
            </div>
        </div>
        <AnimatePresence>
            {open &&
                <motion.div initial={{opacity: 0, height: "0%"}}
                            animate={{opacity: 1, height: "auto"}}
                            exit={{opacity: 0, height: "0%"}}
                            transition={{
                                duration: 0.15,
                                ease: "easeInOut"
                            }}>
                    {myPicker}
                </motion.div>
            }
        </AnimatePresence>
    </div>
}