import React from "react";
import "./QuizPromptPreview.css";
import PiInteractionTitlePreview from "./components/PiInteractionTitlePreview";

interface QuizPromptProps {
  onChange: (e: string) => void;
  value: string;
}

export default function QuizPromptPreview(props: QuizPromptProps) {
  const { onChange, value } = { ...props };

  return (
    <div className="w-full h-[36px] px-[96px] pt-[32px] rounded-[5px]">
      <PiInteractionTitlePreview
        placeholder="Quiz Title"
        onChange={onChange}
        value={value}
        showQuizIcon
      />
    </div>
  );
}
