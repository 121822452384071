import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import {motion, AnimatePresence} from "framer-motion"
import classNames from "classnames";

export default function PiCollapsibleSettings(props: { text: string, icon: JSX.Element, children: React.ReactNode, paddingY?: boolean, paddingX?: boolean }) {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    const {text, icon, children, paddingX, paddingY} = {...props};

    const header = <div className={"h-[44px] flex gap-[10px] p-[10px] border-solid border-b-2 border-creator-border cursor-pointer"} onClick={() => setIsOpen(!isOpen)}>
        <div className={classNames({
            "h-full text-[24px] flex": true,
            "transition-transform rotate-180": isOpen,
            "transition-transform rotate-0": !isOpen
        })}>
            <ExpandMoreIcon className={"my-auto"} fontSize={"inherit"}/>
        </div>
        <div className={"flex text-[24px] text-creator-primary"}>
            {icon}
        </div>
        <div className={"font-bold text-[14px] text-creator-text my-auto select-none"}>
            {text}
        </div>
    </div>;

    const content =
        <motion.div className={classNames({
            "flex flex-col gap-[10px] bg-creator-primary-background": true,
            "py-[10px]": paddingY,
            "px-[10px]": paddingX
        })}
                    initial={{visibility: "visible"}}
                    animate={{visibility: "visible", transition: {duration: 2.2}}}
                    exit={{visibility: "hidden", transition: {duration: 2.2}}}>
            {children}
        </motion.div>;

    return <div className={""}>
        {header}
        <AnimatePresence initial={true}>
            {isOpen && content}
        </AnimatePresence>
    </div>
}