import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import ImageSearchOutlinedIcon from "@mui/icons-material/ImageSearchOutlined";
import React, { useState } from "react";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { ConnectQuizAnswerProps } from "../connect_quiz/ConnectQuizAnswer";
import "./ConnectGraphicsQuizAnswer.css";

export default function ConnectQuizGraphicsAnswer(props: ConnectQuizAnswerProps) {
  const { answer, side, isSelected, onConnectionClick, canDelete, onDelete, horizontal, onAssetSelect } = { ...props };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getAssetPreview } = useAssetPreview(answer.assetId);
  const onSelect = (asset: AssetDetailsDTO) => {
    setIsOpen(false);
    //handleAnswerChange(answer.id, "assetId", asset.id);
    if (onAssetSelect) onAssetSelect(answer, side, asset);
  };

  const dotButton = (
    <div className="connectquizgraphicsanswer-dot-button">
      <button className={answer.id + " connectquizanswer-button " + (isSelected ? "connectquizanswer-selected" : "")} onClick={() => onConnectionClick(answer, side)}></button>
    </div>
  );

  const deleteButton = (
    <div className="connectquizgraphicsanswer-delete-button" onClick={() => onDelete(answer, side)}>
      <ClearIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const selectImageBody = (
    <div className="connectquizgraphicsanswer-select-image" onClick={() => setIsOpen(true)}>
      <AddPhotoAlternateOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const imgDisplayBody = (
    <div className="connectquizgraphicsanswer-display-image">
      <img src={getAssetPreview()} />
    </div>
  );

  const selectImageButton = (
    <div className="connectquizgraphicsanswer-select-image-button" onClick={() => setIsOpen(true)}>
      <ImageSearchOutlinedIcon fontSize="inherit" color="inherit" />
    </div>
  );

  const imgBody = answer.assetId ? (
    <>
      {imgDisplayBody}
      {selectImageButton}
    </>
  ) : (
    selectImageBody
  );

  const imgDisplay = (
    <div className="connectquizgraphicsanswer-image-wrapper">
      {canDelete && deleteButton}
      {imgBody}
    </div>
  );

  return (
    <div className="connectquizgraphicsanswer-root">
      {dotButton}
      {imgDisplay}
      <FileExplorerDialog open={isOpen} onClose={() => setIsOpen(false)} type={AssetKey.Image} onSelect={onSelect} />
    </div>
  );
}
