import {IDynamicInteractionProperty} from "../../models/interactions/DynamicInteraction";
import PiBasicQuizAnswerTextfieldV2 from "../previews/components/v2/PiBasicQuizAnswerTextfieldV2";
import {CapitalizeFirstLetter} from "../../helpers/StringHelper";

export default function DynamicInteractionLocalizedTextfield(props: { dynamicProperty: IDynamicInteractionProperty }) {
    const {dynamicProperty} = {...props};

    return <div className={"w-full flex flex-col"}>
        <div className={"text-interaction-primary text-base font-bold h-[30px]"}>
            {CapitalizeFirstLetter(dynamicProperty.name)}
        </div>
        <div
            className={"w-full h-[42px] piXl:h-[68px] piLg:h-[58px] flex bg-interaction-softBackground border-b-2 border-solid border-interaction-primary px-[18px] hover:bg-interaction-primary-washed"}>
            <PiBasicQuizAnswerTextfieldV2 localizationKey={dynamicProperty.value}/>
        </div>
    </div>
}