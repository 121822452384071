import { default as React, useEffect, useRef, useState } from "react";
import LineTo from "react-lineto";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectIsMenuExpanded } from "../../../app/slices/GlobalSlice";
import { selectIsPlaying, selectSelectedElement, updateSelectedInteraction } from "../../../app/slices/TrainingSlice";
import { AssetDetailsDTO } from "../../../dto/AssetDTO";
import { IConnectQuiz, IConnection } from "../../../models/interactions/ConnectQuiz";
import { IAnswer } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicGraphicsQuizAddButton from "../components/PiBasicGraphicsQuizAddButton";
import PiBasicQuizAddButton from "../components/PiBasicQuizAddButton";
import ConnectQuizGraphicsAnswer from "../connect_graphics_quiz/ConnectGraphicsQuizAnswer";
import ConnectQuizAnswer from "./ConnectQuizAnswer";
import "./ConnectQuizBody.css";

interface ConnectQuizBodyProps {
  displayGraphics?: boolean;
}

export default function ConnectQuizBody(props: ConnectQuizBodyProps) {
  const { displayGraphics } = { ...props };
  const { getNewKey } = useTrainingLocalization();
  const quiz = useSelector(selectSelectedElement) as IConnectQuiz;
  const [selectedAnswerA, setSelectedAnswerA] = useState<string>("");
  const [selectedAnswerB, setSelectedAnswerB] = useState<string>("");
  const [connections, setConnections] = useState<React.JSX.Element[]>([]);
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);
  const isPlaying = useSelector(selectIsPlaying);
  const isMenuExpanded = useSelector(selectIsMenuExpanded);

  useEffect(() => {
    generateConnections();
  }, [quiz]);

  useEffect(() => {
    window.addEventListener("resize", regenerateConnectionsWithTimout);

    return () => window.removeEventListener("resize", regenerateConnectionsWithTimout);
  });

  useEffect(() => {
    if (!isPlaying) {
      regenerateConnectionsWithTimout();
    }
  }, [isPlaying]);

  useEffect(() => {
    regenerateConnectionsWithTimout();
  }, [isMenuExpanded]);

  const regenerateConnectionsWithTimout = () => {
    const timeoutId = setTimeout(() => {
      generateConnections();
    }, 100);

    return () => clearTimeout(timeoutId);
  };

  const generateConnections = () => {
    console.log("generateConnections");
    const conns = quiz.connections.map((connection, index) => {
      return <LineTo className="connectquizanswer-connection" zIndex={1000} key={index} from={connection.idA} to={connection.idB} borderColor="var(--pi-game-blue)" borderWidth={8} />;
    });
    setConnections(conns);
  };

  const onConnectionClick = (answer: IAnswer, side: "A" | "B") => {
    if (side === "A") {
      if (selectedAnswerB) {
        createConnection(answer.id, selectedAnswerB);
      } else {
        if (selectedAnswerA === answer.id) setSelectedAnswerA("");
        else setSelectedAnswerA(answer.id);
      }
    }

    if (side === "B") {
      if (selectedAnswerA) {
        createConnection(selectedAnswerA, answer.id);
      } else {
        if (selectedAnswerB === answer.id) setSelectedAnswerB("");
        else setSelectedAnswerB(answer.id);
      }
    }
  };

  const createConnection = (answerA: string, answerB: string) => {
    const quizCopy = { ...quiz };
    var connectionsCopy: IConnection[] = [...quizCopy.connections];

    // check if connection already exists
    var alreadyExists = connectionsCopy.find((conn) => conn.idA === answerA && conn.idB === answerB);

    if (alreadyExists) {
      var index = connectionsCopy.indexOf(alreadyExists);
      connectionsCopy.splice(index, 1);
    } else {
      connectionsCopy.push({ idA: answerA, idB: answerB });
    }

    quizCopy.connections = connectionsCopy;

    setSelectedAnswerA("");
    setSelectedAnswerB("");

    dispatch(updateSelectedInteraction(quizCopy));
  };

  const addAnswerA = () => {
    const quizcopy = { ...quiz };
    const answerscopy = [...quiz.answersA];
    answerscopy.push({
      id: uuidv4(),
      text: getNewKey(),
      correct: false,
      assetId: "",
    });

    quizcopy.answersA = answerscopy;
    dispatch(updateSelectedInteraction(quizcopy));
  };
  const addAnswerB = () => {
    const quizcopy = { ...quiz };
    const answerscopy = [...quiz.answersB];
    answerscopy.push({
      id: uuidv4(),
      text: getNewKey(),
      correct: false,
      assetId: "",
    });

    quizcopy.answersB = answerscopy;
    dispatch(updateSelectedInteraction(quizcopy));
  };

  const onDeleteAnswer = (answer: IAnswer, side: "A" | "B") => {
    const quizcopy = { ...quiz };
    const connectionsCopy = [...quizcopy.connections];

    //delete all connections w/ that answer
    const filtered = connectionsCopy.filter((conn) => conn.idA !== answer.id && conn.idB !== answer.id);

    if (side === "A") {
      const af = quizcopy.answersA.filter((a) => answer.id !== a.id);
      quizcopy.answersA = af;
    } else {
      const af = quizcopy.answersB.filter((b) => answer.id !== b.id);
      quizcopy.answersB = af;
    }

    quizcopy.connections = filtered;
    dispatch(updateSelectedInteraction(quizcopy));
  };

  const onAssetSelect = (answer: IAnswer, side: "A" | "B", asset: AssetDetailsDTO) => {
    const quizcopy = { ...quiz };
    if (side === "A") {
      // side A cannot have image asset
    } else {
      const sideBCopy = [...quizcopy.answersB];
      const index = sideBCopy.indexOf(sideBCopy.find((a) => a.id === answer.id)!);
      const acopy = { ...answer };
      acopy.assetId = asset.assetId;
      sideBCopy[index] = acopy;
      quizcopy.answersB = sideBCopy;
    }

    dispatch(updateSelectedInteraction(quizcopy));
  };

  const answersA = quiz.answersA.map((answer, index) => {
    return (
      <ConnectQuizAnswer
        key={index}
        answer={answer}
        side="A"
        isSelected={selectedAnswerA === answer.id}
        onConnectionClick={onConnectionClick}
        canDelete={quiz.answersA.length > 2}
        onDelete={onDeleteAnswer}
        horizontal={displayGraphics}
      />
    );
  });
  const answersB = quiz.answersB.map((answer, index) => {
    return (
      <ConnectQuizAnswer
        key={index}
        answer={answer}
        side="B"
        isSelected={selectedAnswerB === answer.id}
        onConnectionClick={onConnectionClick}
        canDelete={quiz.answersB.length > 2}
        onDelete={onDeleteAnswer}
      />
    );
  });

  const canAddA = quiz.answersA.length < 5;
  const canAddB = quiz.answersB.length < 5;

  const addButtonA = (
    <div className="connectquizbody-add-button-a">
      <PiBasicQuizAddButton onClick={addAnswerA} />
    </div>
  );
  const addButtonB = (
    <div className="connectquizbody-add-button-b">
      <PiBasicQuizAddButton onClick={addAnswerB} />
    </div>
  );

  // regular connect quiz
  const colA = (
    <div className="connectquizbody-col connectquizbody-col-a">
      {answersA}
      {canAddA && addButtonA}
    </div>
  );
  const colB = (
    <div className="connectquizbody-col connectquizbody-col-b">
      {answersB}
      {canAddB && addButtonB}
    </div>
  );

  // connect graphics quiz
  const rowA = (
    <div className="connectquizbody-row connectquizbody-row-a">
      {answersA}
      {canAddA && <PiBasicQuizAddButton onClick={addAnswerA} />}
    </div>
  );
  const rowB = (
    <div className="connectquizbody-row connectquizbody-row-b">
      {quiz.answersB.map((answer, index) => {
        return (
          <ConnectQuizGraphicsAnswer
            key={answer.id}
            answer={answer}
            side="B"
            isSelected={selectedAnswerB === answer.id}
            onConnectionClick={onConnectionClick}
            canDelete={quiz.answersB.length > 2}
            onDelete={onDeleteAnswer}
            onAssetSelect={onAssetSelect}
          />
        );
      })}
      {canAddB && (
        <div className="connectquizbody-add-answer-gfx">
          <PiBasicGraphicsQuizAddButton onClick={addAnswerB} />
        </div>
      )}
    </div>
  );

  const getBody = () => {
    return !displayGraphics ? (
      <div className="connectquizbody-root" ref={ref}>
        {colA}
        {colB}
        {connections}
      </div>
    ) : (
      <div className="connectquizbody-root connectquizbody-root-row" ref={ref}>
        {rowA}
        {rowB}
        {connections}
      </div>
    );
  };

  return <>{getBody()}</>;
}
