import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../app/slices/AuthenticationSlice";
import { setIsLoading } from "../../app/slices/GlobalSlice";
import { LanguageData, addAutoTranslatedLanguage, addMissingAutoTranslatedKeys, selectTrainingLocalizationState } from "../../app/slices/TrainingLocalizationSlice";
import { selectReferenceLanguage, setIsDirty } from "../../app/slices/TrainingSlice";
import { TranslationService } from "../../services/TranslationService";

export default function useAutotranslation() {
  const locState = useSelector(selectTrainingLocalizationState);
  const token = useSelector(selectToken);
  const referenceLanguage = useSelector(selectReferenceLanguage);
  const dispatch = useDispatch();

  const translateAll = async (lang: LanguageData) => {
    var from = referenceLanguage;
    var to = lang.shortcode;
    var texts: string[] = [];

    for (var key in locState.localizedEntries) {
      texts.push(locState.localizedEntries[key][from].value);
    }

    dispatch(setIsLoading(true));

    var translatedTexts = await TranslationService.TranslateTexts(token, texts, from, to);

    dispatch(
      addAutoTranslatedLanguage({
        lang: lang,
        translatedValues: translatedTexts,
      })
    );

    dispatch(setIsDirty());
    dispatch(setIsLoading(false));
  };

  const translateMissing = async (lang: LanguageData) => {
    var from = referenceLanguage;
    var to = lang.shortcode;
    var texts: string[] = [];

    for (var key in locState.localizedEntries) {
      texts.push(locState.localizedEntries[key][from].value);
    }

    dispatch(setIsLoading(true));
    var translatedTexts = await TranslationService.TranslateTexts(token, texts, from, to);

    dispatch(
      addMissingAutoTranslatedKeys({
        lang: lang,
        translatedValues: translatedTexts,
      })
    );

    dispatch(setIsDirty());
    dispatch(setIsLoading(false));
  };

  return { translateAll, translateMissing } as const;
}
