import { InteractionType } from "./Interaction";

export enum FilterType {
  All = "All",
  Default = "Default",
  TeleportMarker = "TeleportMarker",
  Marker = "Marker",
  LisaMessage = "LisaMessage",
  MessagePopup = "MessagePopup",
  ImagePopup = "ImagePopup",
  VideoPopup = "VideoPopup",
  PdfPopup = "PdfPopup",
  MultipleChoiceQuiz = "MultipleChoiceQuiz",
  MultiselectQuiz = "MultiselectQuiz",
  MultiSelectGraphicsQuiz = "MultiSelectGraphicsQuiz",
  MultipleChoiceGraphicsQuiz = "MultipleChoiceGraphicsQuiz",
  OrderQuiz = "OrderQuiz",
  OrderGraphicsQuiz = "OrderGraphicsQuiz",
  ConnectQuiz = "ConnectQuiz",
  ConnectGraphicsQuiz = "ConnectGraphicsQuiz",
  ScrabbleQuiz = "ScrabbleQuiz",
  WordSpinnerQuiz = "WordSpinnerQuiz",
  ClickRegion = "ClickRegion",
  ClickRegionOrderQuiz = "ClickRegionOrderQuiz",
  ClickRegionMultiSelectQuiz = "ClickRegionMultiSelectQuiz",
  WordQuiz = "WordQuiz",
  PhotoRangeQuiz = "PhotoRangeQuiz",
  AmbientAudio = "AmbientAudio",
  AudioPopup = "AudioPopup",
  Conversation = "Conversation",
  SetCamera = "SetCamera",
  MessageBubble = "MessageBubble",
  EmotionBubble = "EmotionBubble",
  DialogQuiz = "DialogQuiz",
  Grab = "Grab",
  DynamicInteraction = "DynamicInteraction",
  Detach = "Detach",
  Attach = "Attach",
  Transition = "Transition",
  Slideshow = "Slideshow",
  ButtonsQuiz = "ButtonsQuiz",
  Lookat = "LookAt",
  SetPropState = "SetPropState",
  ShowUIProp = "ShowUIProp",
  HideUIProp = "HideUIProp",
  ImageButtonQuiz = "ImageButtonQuiz",
  Take = "Take",
  None = "None",
}

export function getFilterType(type: InteractionType): FilterType {
  switch (type) {
    case InteractionType.Default:
      return FilterType.Default;
    case InteractionType.TeleportMarker:
      return FilterType.TeleportMarker;
    case InteractionType.Marker:
      return FilterType.Marker;
    case InteractionType.LisaMessage:
      return FilterType.LisaMessage;
    case InteractionType.MessagePopup:
      return FilterType.MessagePopup;
    case InteractionType.ImagePopup:
      return FilterType.ImagePopup;
    case InteractionType.VideoPopup:
      return FilterType.VideoPopup;
    case InteractionType.PdfPopup:
      return FilterType.PdfPopup;
    case InteractionType.MultipleChoiceQuiz:
      return FilterType.MultipleChoiceQuiz;
    case InteractionType.MultiselectQuiz:
      return FilterType.MultiselectQuiz;
    case InteractionType.MultiSelectGraphicsQuiz:
      return FilterType.MultiSelectGraphicsQuiz;
    case InteractionType.MultipleChoiceGraphicsQuiz:
      return FilterType.MultipleChoiceGraphicsQuiz;
    case InteractionType.OrderQuiz:
      return FilterType.OrderQuiz;
    case InteractionType.OrderGraphicsQuiz:
      return FilterType.OrderGraphicsQuiz;
    case InteractionType.ConnectQuiz:
      return FilterType.ConnectQuiz;
    case InteractionType.ConnectGraphicsQuiz:
      return FilterType.ConnectGraphicsQuiz;
    case InteractionType.ScrabbleQuiz:
      return FilterType.ScrabbleQuiz;
    case InteractionType.WordSpinnerQuiz:
      return FilterType.WordSpinnerQuiz;
    case InteractionType.ClickRegion:
      return FilterType.ClickRegion;
    case InteractionType.ClickRegionOrderQuiz:
      return FilterType.ClickRegionOrderQuiz;
    case InteractionType.ClickRegionMultiSelectQuiz:
      return FilterType.ClickRegionMultiSelectQuiz;
    case InteractionType.WordQuiz:
      return FilterType.WordQuiz;
    case InteractionType.PhotoRangeQuiz:
      return FilterType.PhotoRangeQuiz;
    case InteractionType.AmbientAudio:
      return FilterType.AmbientAudio;
    case InteractionType.AudioPopup:
      return FilterType.AudioPopup;
    case InteractionType.Conversation:
      return FilterType.Conversation;
    case InteractionType.SetCamera:
      return FilterType.SetCamera;
    case InteractionType.MessageBubble:
      return FilterType.MessageBubble;
    case InteractionType.EmotionBubble:
      return FilterType.EmotionBubble;
    case InteractionType.DialogQuiz:
      return FilterType.DialogQuiz;
    case InteractionType.Grab:
      return FilterType.Grab;
    case InteractionType.DynamicInteraction:
      return FilterType.DynamicInteraction;
    case InteractionType.Detach:
      return FilterType.Detach;
    case InteractionType.Attach:
      return FilterType.Attach;
    case InteractionType.Transition:
      return FilterType.Transition;
    case InteractionType.Slideshow:
      return FilterType.Slideshow;
    case InteractionType.ButtonsQuiz:
      return FilterType.ButtonsQuiz;
    case InteractionType.SetPropState:
      return FilterType.SetPropState;
    case InteractionType.ShowUIProp:
      return FilterType.ShowUIProp;
    case InteractionType.HideUIProp:
      return FilterType.HideUIProp;
    case InteractionType.ImageButtonQuiz:
      return FilterType.ImageButtonQuiz;

    default:
      return FilterType.None;
  }
}
