import React from "react";
import "./DashboardHeader.css";

import { useSelector } from "react-redux";
import { selectUsername } from "../../app/slices/UserSlice";
import PiBreadcrumbs, { BreadcrumbItem } from "../../components/PiBreadcrumbs";
import { Grid } from "@mui/material";
interface DashboardHeaderProps {
  subtitle: string;
  breadcrumbs: BreadcrumbItem[];
  children?: JSX.Element;
}

export default function DashboardHeader(props: DashboardHeaderProps) {
  const username = useSelector(selectUsername);
  return (
    <div id={"dashboard-header"} className="dashboardheader-root">
      <Grid container>
        <Grid item>
          <PiBreadcrumbs breadcrumbs={props.breadcrumbs} />
        </Grid>
      </Grid>
      <Grid container className="dashboardheader-body" wrap="wrap">
        <Grid item xs={12} sm={7} md={10}>
          <div>
            <div className="dashboardheader-greeting-title ">
              Hey, {username}
            </div>
            <div className="dashboardheader-greeting-subtitle">
              {props.subtitle}
            </div>
          </div>
        </Grid>
        <Grid item xs={5} sm={3} md={2}>
          <div className="dashboardheader-children">{props.children}</div>
        </Grid>
      </Grid>
    </div>
  );
}
