import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../app/slices/TrainingSlice";
import { MessagePopup } from "../../models/interactions/MessagePopup";
import { useTrainingLocalization } from "../trainingLocalization/TrainingLocalizationHooks";
import "./MessagePopupPreview.css";
export default function MessagePopupPreview() {
  const messagePopupData = useSelector(selectSelectedElement) as MessagePopup;
  const { getValue, changeValue } = useTrainingLocalization();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    autoGrow();
  }, []);

  useEffect(() => {
    autoGrow();

    // scroll to top
    textareaRef.current?.scrollTo(0, 0);
  }, [messagePopupData]);

  const autoGrow = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "5px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  return (
    <div>
      <div className="messagepopuppreview-root">
        <div className="messagepopuppreview-box">
          <div className="messagepopuppreview-box-body">
            <div className="messagepopuppreview-box-text-area">
              <textarea
                aria-label="messagepopuppreview-text-area"
                value={getValue(messagePopupData.message)}
                onChange={(e) => changeValue(messagePopupData.message, e.target.value)}
                onInput={autoGrow}
                ref={textareaRef}
              />
            </div>
            <div aria-label="messagepopuppreview-arrow" className="messagepopuppreview-box-body-bottom-button">
              <PlayArrowIcon color="inherit" fontSize="inherit" />
            </div>
          </div>
          <div className="messagepopuppreview-box-bottom" />
        </div>

        <div className="messagepopuppreview-arrow">
          <img aria-label="messagepopuppreview-img" src="arrow.png" alt="" />
        </div>
        <div className="messagepopuppreview-lisa">
          <img src="/lisa.png" alt="" />
        </div>
      </div>
    </div>
  );
}
