import { v4 as uuidv4 } from "uuid";
import {
  InteractionFactoryEntity
} from "../../features/interaction_factory/InteractionFactory";
import WordSpinnerQuizPreview from "../../features/previews/word_spinner_quiz/WordSpinnerQuizPreview";
import { GetMigratedProperties } from "../../features/project_migration/InteractionMigrationHelper";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { Actor } from "../Actor";
import { requiredGlobalQuizProperties } from "../GlobalQuizProperty";
import {
  InteractionProperty
} from "../InteractionProperty";
import { IAnswer, IFormQuiz } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface IWordSpinnerQuiz extends IFormQuiz {
  formattedSentence: string;
}

export const requiredWordSpinnerProperties: InteractionProperty[] = [
  ...requiredGlobalQuizProperties,
];

export function CreateWordspinnerQuiz(keys: string[]): IWordSpinnerQuiz {
  return {
    id: uuidv4(),
    name: "Word Spinner Quiz",
    type: InteractionType.WordSpinnerQuiz,
    title: keys[0],
    formattedSentence: keys[1],
    answers: [],
    properties: requiredWordSpinnerProperties,
  };
}

export function CreateWordspinnerQuizCopy(
  titleKey: string,
  sentencePart1Key: string,
  sentencePart2Key: string,
  formattedSentenceKey: string,
  rawSentenceKey: string,
  correctIndex: number,
  answers: IAnswer[],
  properties: InteractionProperty[] | undefined
): IWordSpinnerQuiz {
  return {
    id: uuidv4(),
    name: "Word Spinner Quiz",
    type: InteractionType.WordSpinnerQuiz,
    title: titleKey,
    formattedSentence: formattedSentenceKey,
    answers: answers,
    properties: properties,
  };
}

interface LegacyWordspinner {
  correctAnswer: IAnswer;
}

interface LegacyWordspinner {
  correctAnswer: IAnswer;
}

export const wordSpinnerQuizInteraction = (): InteractionFactoryEntity => {
  const component = <WordSpinnerQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IWordSpinnerQuiz => {
    const keys = getKeys(2);
    return {
      id: uuidv4(),
      name: "Word Spinner Quiz",
      type: InteractionType.WordSpinnerQuiz,
      title: keys[0],
      formattedSentence: keys[1],
      answers: [],
      properties: requiredWordSpinnerProperties,
    };
  };
  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IWordSpinnerQuiz => {
    var converted = interaction as IWordSpinnerQuiz;
    var updatedInteraction: IWordSpinnerQuiz = { ...converted };

    if (from < 4) {
      const oldInteraction: any = JSON.stringify(interaction);
      const legacy: LegacyWordspinner = JSON.parse(oldInteraction);
      const newAnswer = legacy.correctAnswer;

      if (newAnswer !== undefined) {
        updatedInteraction = {
          ...converted,
          answers: [newAnswer, ...converted.answers],
        };
      }
    }

    if (updatedInteraction.properties === undefined)
      return {
        ...updatedInteraction,
        properties: requiredWordSpinnerProperties,
      };

    var newProperties: InteractionProperty[] = GetMigratedProperties(
      requiredWordSpinnerProperties,
      updatedInteraction.properties
    );
    console.log(
      "[WordSpinnerQuiz] migrated " +
      (newProperties.length - updatedInteraction.properties.length) +
      " properties"
    );

    return { ...updatedInteraction, properties: newProperties };
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IWordSpinnerQuiz => {
    const quiz = interaction as IWordSpinnerQuiz;
    const newKeys = getKeys(quiz.answers.length + 2);
    replaceKey(quiz.title, newKeys[newKeys.length - 1]);
    replaceKey(quiz.formattedSentence, newKeys[newKeys.length - 2]);

    return {
      id: uuidv4(),
      name: interaction.name,
      type: InteractionType.WordSpinnerQuiz,
      title: newKeys[newKeys.length - 1],
      formattedSentence: newKeys[newKeys.length - 2],
      answers: getAnswerArray(quiz.answers, newKeys),
      properties: quiz.properties,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IWordSpinnerQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    let hasCorrectAnswer = false;

    // validate all answers + validate we have at least one answer
    for (let i = 0; i < converted.answers.length; i++) {
      const tempAnswer = converted.answers[i];
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer ${i + 1}`
      );
      if (tempAnswer.correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer) warnings.push("No correct answer selected");

    return warnings;
  };

  const filter = (interaction: Interaction, getValue: (key: string) => string): string[] => {
    let results: string[] = [];

    const wordSpinnerQuiz = interaction as IWordSpinnerQuiz
    const answers = wordSpinnerQuiz.answers.map(item => item.text);
    results.push(interaction.name);
    results.push(wordSpinnerQuiz.title);

    let localFormattedSentence = getValue(wordSpinnerQuiz.formattedSentence);
    const pattern = /\{(\d+)\}/
    const match = localFormattedSentence.match(pattern);

    if (match) {
      const index = parseInt(match[1], 10);
      if (index < answers.length) {
        // Replace only the first occurrence 
        localFormattedSentence = localFormattedSentence.replace(match[0], getValue(answers[index]));
      }
    }

    results.push(localFormattedSentence);
    answers.forEach(answer => { results.push(answer); })

    return results;
  }

  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
    Filter: filter,
  };
};
