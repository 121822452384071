import React, { useState } from "react";
import "./ProjectsTableHead.css";

import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSortBy } from "../../app/slices/ProjectsSlice";
import { TrainingDetailsDTO } from "../../dto/TrainingDetailsDTO";
export default function ProjectsTableHead() {
  const [currentSortId, setCurrentSortId] = useState<keyof TrainingDetailsDTO | undefined>();
  const [sortAsc, setSortAsc] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function getDirection(key: keyof TrainingDetailsDTO) {
    if (key === currentSortId) return sortAsc ? "asc" : "desc";
    return "desc";
  }

  const tableHead = (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel direction={getDirection("type")} active={currentSortId === "type"} onClick={() => sortByClicked("type")}>
            <span className="projectstablehead-tablehead">{t("type")}</span>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          {/* <TableSortLabel direction={getDirection("version")} active={currentSortId === "version"} onClick={() => sortByClicked("version")}>
            <span className="projectstablehead-tablehead">{t("version")}</span>
          </TableSortLabel> */}
          <span className="projectstablehead-tablehead">{t("version")}</span>
        </TableCell>
        <TableCell>
          <TableSortLabel direction={getDirection("name")} active={currentSortId === "name"} onClick={() => sortByClicked("name")}>
            <span className="projectstablehead-tablehead">{t("training")}</span>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel direction={getDirection("updatedBy")} active={currentSortId === "updatedBy"} onClick={() => sortByClicked("updatedBy")}>
            <span className="projectstablehead-tablehead">{t("lastEditedBy")}</span>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <TableSortLabel direction={getDirection("updatedAt")} active={currentSortId === "updatedAt"} onClick={() => sortByClicked("updatedAt")}>
            <span className="projectstablehead-tablehead">{t("lastEdit")}</span>
          </TableSortLabel>
        </TableCell>
        <TableCell>
          <span className="projectstablehead-tablehead">{t("state")}</span>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  function sortByClicked(id: keyof TrainingDetailsDTO) {
    var asc = sortAsc;

    if (currentSortId !== id) {
      setCurrentSortId(id);
      asc = true;
    } else {
      asc = !asc;
    }

    var sortBy = id + (asc ? " asc" : " desc");
    dispatch(setSortBy(sortBy));

    setSortAsc(asc);
  }

  return tableHead;
}
