import React from "react";
import { useSelector } from "react-redux";
import { selectIsPlaying } from "../../app/slices/TrainingSlice";
import { TrainingEditorStrategy } from "../training_preview/TrainingEditorStrategy";
import "./TrainingTimeline.css";
import { motion, AnimatePresence } from "framer-motion";
export default function TrainingTimeline(props: {
  strategy: TrainingEditorStrategy;
}) {
  const { strategy } = props;
  const isPlaying = useSelector(selectIsPlaying);
  const getRootClasses = () => {
    if (!isPlaying) return "trainingtimeline-root";
    else return "trainingtimeline-root trainingtimeline-root-disabled";
  };

  const content = (
    <motion.div
      className={
        "h-full bg-white border-solid border-r-2 border-creator-border"
      }
      initial={{ width: 292 }}
      animate={{ width: 292, transition: { duration: 0.1 } }}
      exit={{ width: 0, minWidth: 0, transition: { duration: 0.1 } }}
    >
      <div className="h-full">{strategy.getTimeline()}</div>
    </motion.div>
  );

  return (
    <AnimatePresence initial={true}>{!isPlaying && content}</AnimatePresence>
  );
}
