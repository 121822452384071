import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { AssetContentDTO, AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { Asset, AssetType } from "../../models/Asset";
import { RootState } from "../Store";

const supportedImageTypes = ["image/png", "image/jpg", "image/jpeg"];
const supportedVideoTypes = ["video/mp4"];
const supportedPdfTypes = ["application/pdf"];
const supportedAudioTypes = ["audio/ogg"];
const assetsPerPage = 10;

export interface Folder {
  name: string;
  id: string;
  assets?: Asset[];
}

interface AssetsState {
  selectedFolder?: string;
  editingAssetId?: string;
  editingVariantId?: string;
  renamingFolder?: Folder;
  folders: Folder[];
  supportedImageTypes: string[];
  supportedVideoTypes: string[];
  supportedPdfTypes: string[];

  totalAssetCount: number;

  assetsInCurrentFolder: AssetDetailsDTO[];

  usedAssets: AssetContentDTO[];
  legacyAssets: Asset[];
  cachedAssets: AssetDetailsDTO[];
  cachedAssets_small: AssetDetailsDTO[];
  cachedAssets_medium: AssetDetailsDTO[];
}

const initialState: AssetsState = {
  supportedImageTypes: ["image/png", "image/jpg", "image/jpeg"],
  supportedVideoTypes: ["video/mp4"],
  supportedPdfTypes: ["application/pdf"],
  folders: [],
  totalAssetCount: 0,
  usedAssets: [],
  legacyAssets: [],
  assetsInCurrentFolder: [],
  cachedAssets: [],
  cachedAssets_small: [],
  cachedAssets_medium: [],
};

export function getSelectedFolder(state: AssetsState) {
  const filter = state.folders.filter((folder) => folder.id === state.selectedFolder);

  if (filter.length === 1) return filter[0];
  return null;
}

function addAssetToFolder(folder: Folder | null, asset: Asset) {
  if (folder) {
    if (folder.assets) {
      folder.assets = [asset].concat(folder.assets);
    } else {
      folder.assets = [asset];
    }
  }
}

function getAssetById(folder: Folder, id: string) {
  return folder.assets?.find((asset) => asset.id === id);
}

function getFolderById(folders: Folder[], id: string) {
  var folder = folders.find((f) => f.id === id);
  return folder;
}

export const assetsSlice = createSlice({
  name: "assets",
  initialState: initialState,
  reducers: {
    // === General ===
    resetAssetsSlice: (state) => {
      state.folders = [];
      state.selectedFolder = undefined;
      state.editingAssetId = undefined;
      state.renamingFolder = undefined;
      state.assetsInCurrentFolder = [];
    },
    // === Folders ===
    addFolder: (state, action: PayloadAction<Folder>) => {
      state.folders.push(action.payload);
    },
    selectFolder: (state, action: PayloadAction<string>) => {
      const filtered = state.folders.filter((folder) => folder.id === action.payload);
      if (filtered.length === 1) {
        state.selectedFolder = action.payload;
      }
    },
    setFolders: (state, action: PayloadAction<Folder[]>) => {
      console.log("setting folders");
      state.folders = action.payload;
    },
    renameFolder: (state, action: PayloadAction<Folder>) => {
      var filter: Folder[] = state.folders.filter((folder: Folder) => {
        return folder.id === action.payload.id;
      });

      filter[0].name = action.payload.name;
      state.renamingFolder = undefined;
    },
    addNewFolder: (state) => {
      var newFolder: Folder = { name: "New Folder", id: "newfolder" };
      state.folders = [newFolder, ...state.folders];
      state.selectedFolder = newFolder.id;
      state.renamingFolder = newFolder;
    },
    removeTemporaryFolder: (state) => {
      var filtered = state.folders.filter((folder) => folder.id !== "newfolder");
      state.folders = filtered;
    },
    addNewCreatedFolder: (state, action: PayloadAction<Folder>) => {
      // add new folder, remove temp folder & reset renaming folder
      var filtered = state.folders.filter((folder) => folder.id !== "newfolder");
      state.folders = [action.payload, ...filtered];
      state.renamingFolder = undefined;
      state.selectedFolder = action.payload.id;
    },
    setRenameFolder: (state, action: PayloadAction<Folder | undefined>) => {
      state.renamingFolder = action.payload;
    },
    archiveFolder: (state, action: PayloadAction<Folder>) => {
      var filter = state.folders.filter((folder: Folder) => {
        return folder.id === action.payload.id;
      });

      if (filter.length !== 1) {
        console.log("Something went wrong while archiving folder");
        return;
      }
      state.folders.splice(state.folders.indexOf(filter[0]), 1);
    },
    // === Assets ===
    addUnsupportedAsset: (state, action: PayloadAction<{ name: string; id: string }>) => {
      //const selectedFolder = getSelectedFolder(state);
      const folder = state.folders.find((folder) => folder.id === state.selectedFolder);
      if (!folder) return;

      var unsupportedAsset: AssetDetailsDTO = {
        assetId: "",
        id: action.payload.id,
        key: AssetKey.Unsupported,
        name: action.payload.name,
        updatedAt: "",
        updatedBy: "",
        variants: [],
        version: {
          major: 0,
          minor: 1,
        },
      };

      state.assetsInCurrentFolder = [unsupportedAsset, ...state.assetsInCurrentFolder];
    },

    addAsset: (state, action: PayloadAction<Asset>) => {
      const selectedFolder = getSelectedFolder(state);
      const asset = { ...action.payload };
      asset.parentId = selectedFolder!.id;
      addAssetToFolder(selectedFolder, asset);
    },
    removeUnsupportedFile: (state, action: PayloadAction<string>) => {
      state.assetsInCurrentFolder = state.assetsInCurrentFolder.filter((asset) => asset.id !== action.payload);
    },
    setEditingAsset: (state, action: PayloadAction<string | undefined>) => {
      state.editingAssetId = action.payload;
    },
    updateAssetName: (state, action: PayloadAction<AssetDetailsDTO>) => {
      const updatedAsset = action.payload;
      const asset = state.assetsInCurrentFolder.find((asset) => asset.id === updatedAsset.id);
      if (asset) {
        asset.name = updatedAsset.name;
      }
    },
    updateAsset: (state, action: PayloadAction<Asset>) => {
      const folder = getFolderById(state.folders, action.payload.parentId);
      var updated = action.payload;
      if (folder) {
        var asset = getAssetById(folder, action.payload.id);
        if (asset) {
          //asset = { ...action.payload };
          asset.thumbnailUrl = updated.thumbnailUrl;
          asset.assetType = updated.assetType;
          asset.previewVersionId = updated.previewVersionId;
          asset.previewVariantId = updated.previewVariantId;
          asset.countVersions = updated.countVersions;
          asset.version = updated.version;
        }
      }
    },
    addUsedAsset: (state, action: PayloadAction<AssetContentDTO>) => {
      var newContent = action.payload;
      if (!state.usedAssets.find((content) => content.id === newContent.id)) {
        state.usedAssets.push(newContent);
      }
    },
    updateUsedAsset: (state, action: PayloadAction<AssetContentDTO>) => {
      var asset = state.usedAssets.find((a) => a.id === action.payload.id);
      if (!asset) return;

      var index = state.usedAssets.indexOf(asset);
      state.usedAssets[index] = action.payload;
      console.log("updated used asset");
    },
    setLegacyAssets: (state, action: PayloadAction<Asset[]>) => {
      state.legacyAssets = action.payload;
    },
    addLegacyAsset: (state, action: PayloadAction<Asset>) => {
      if (state.legacyAssets.find((asset) => asset.id === action.payload.id)) return;
      //state.legacyAssets.push(action.payload);
      state.legacyAssets = [action.payload].concat(state.legacyAssets);
    },
    addUnsupportedLegacyAsset: (state, action: PayloadAction<string>) => {
      const newAsset: Asset = {
        id: uuidv4(),
        name: action.payload,
        thumbnailUrl: "",
        version: "1.0",
        uploadedBy: "Willem",
        assetType: AssetType.unsupported,
        orgId: "",
        previewVariantId: "",
        previewVersionId: "",
        parentId: "",
        countVersions: 0,
      };

      state.legacyAssets = [newAsset].concat(state.legacyAssets);
    },
    removeLegacyUnsupportedFile: (state, action: PayloadAction<Asset>) => {
      const index = state.legacyAssets.indexOf(state.legacyAssets.find((a) => a.id === action.payload.id)!);
      state.legacyAssets.splice(index, 1);
    },
    updateLegacyAsset: (state, action: PayloadAction<Asset>) => {
      //const folder = getFolderById(state.folders, action.payload.parentId);
      var updated = action.payload;
      var asset = state.legacyAssets.find((la) => la.id === updated.id);
      if (asset) {
        //asset = { ...action.payload };
        asset.name = updated.name;
        asset.thumbnailUrl = updated.thumbnailUrl;
        asset.assetType = updated.assetType;
        asset.previewVersionId = updated.previewVersionId;
        asset.previewVariantId = updated.previewVariantId;
        asset.countVersions = updated.countVersions;
        asset.version = updated.version;
      }
    },
    setNewFolderDetails: (state, action: PayloadAction<{ assets: AssetDetailsDTO[]; totalCount: number }>) => {
      state.assetsInCurrentFolder = new Array<AssetDetailsDTO>(action.payload.totalCount);
      state.totalAssetCount = action.payload.totalCount;

      for (var i = 0; i < action.payload.assets.length; i++) {
        state.assetsInCurrentFolder[i] = action.payload.assets[i];
      }
    },
    addPagedAssetsToFolder: (state, action: PayloadAction<{ pageNumber: number; assets: AssetDetailsDTO[] }>) => {
      var si = action.payload.pageNumber * assetsPerPage;
      if (si > state.totalAssetCount) return;

      for (var i = 0; i < action.payload.assets.length; i++) {
        state.assetsInCurrentFolder[si + i] = action.payload.assets[i];
      }
    },
    removeAssetFromCurrentFolder: (state, action: PayloadAction<string>) => {
      const newAssets = state.assetsInCurrentFolder.filter((asset) => asset === undefined || asset.id !== action.payload);
      state.assetsInCurrentFolder = newAssets;
    },
    addLoadingAsset: (state, action: PayloadAction<string>) => {
      var tempAsset: AssetDetailsDTO = {
        assetId: "temp",
        id: "temp",
        key: AssetKey.Loading,
        name: action.payload,
        updatedAt: "",
        updatedBy: "",
        variants: [],
        version: {
          major: 0,
          minor: 0,
        },
      };

      state.assetsInCurrentFolder = [tempAsset, ...state.assetsInCurrentFolder];
      state.totalAssetCount += 1;
    },
    finishLoadingAsset: (state, action: PayloadAction<{ name: string; newAsset: AssetDetailsDTO }>) => {
      var index = state.assetsInCurrentFolder.indexOf(state.assetsInCurrentFolder.find((x) => x.name === action.payload.name)!);
      state.assetsInCurrentFolder[index] = action.payload.newAsset;
    },
    updateAssetInCurrentFolder: (state, action: PayloadAction<AssetDetailsDTO>) => {
      var index = state.assetsInCurrentFolder.indexOf(state.assetsInCurrentFolder.find((x) => x.id === action.payload.id)!);
      state.assetsInCurrentFolder[index] = action.payload;
    },
    setEditingVariantId: (state, action: PayloadAction<string | undefined>) => {
      state.editingVariantId = action.payload;
    },
    updateEditingVariant: (state, action: PayloadAction<string>) => {
      var a = state.assetsInCurrentFolder.find((asset) => asset.id === state.editingAssetId);
      var v = a?.variants.find((variant) => variant.id === state.editingVariantId);

      if (v) {
        v.variant = action.payload;
      }
    },
    addCachedAsset: (state, action: PayloadAction<AssetDetailsDTO>) => {
      state.cachedAssets.push(action.payload);
    },
    addCachedAsset_Small: (state, action: PayloadAction<AssetDetailsDTO>) => {
      state.cachedAssets_small.push(action.payload);
    },
    addCachedAsset_Medium: (state, action: PayloadAction<AssetDetailsDTO>) => {
      state.cachedAssets_medium.push(action.payload);
    },
    updateCachedAsset: (state, action: PayloadAction<AssetDetailsDTO>) => {
      var index = state.cachedAssets.indexOf(state.cachedAssets.find((x) => x.assetId === action.payload.assetId)!);
      state.cachedAssets[index] = action.payload;

      console.log("updated cached asset");
    },
    addPagedFolders: (state, action: PayloadAction<{ folders: Folder[]; pageNumber: number }>) => {
      var si = action.payload.pageNumber * 10;
      if (si > state.folders.length) return;

      for (var i = 0; i < action.payload.folders.length; i++) {
        state.folders[si + i] = action.payload.folders[i];
      }
    },
  },
});

export default assetsSlice.reducer;
export const {
  resetAssetsSlice,
  addFolder,
  selectFolder,
  setFolders,
  renameFolder,
  addNewFolder,
  removeTemporaryFolder,
  setRenameFolder,
  archiveFolder,
  addUnsupportedAsset,
  addAsset,
  updateAsset,
  removeUnsupportedFile,
  setEditingAsset,
  updateAssetName,
  addUsedAsset,
  updateUsedAsset,
  setLegacyAssets,
  addLegacyAsset,
  updateLegacyAsset,
  addUnsupportedLegacyAsset,
  removeLegacyUnsupportedFile,
  addNewCreatedFolder,
  setNewFolderDetails,
  addPagedAssetsToFolder,
  removeAssetFromCurrentFolder,
  addLoadingAsset,
  finishLoadingAsset,
  updateAssetInCurrentFolder,
  setEditingVariantId,
  updateEditingVariant,
  addCachedAsset,
  addCachedAsset_Small,
  addCachedAsset_Medium,
  updateCachedAsset,
  addPagedFolders,
} = assetsSlice.actions;

export const selectFolders = (state: RootState) => state.assets.folders;
export const selectSelectedFolder = (state: RootState) => {
  const currentFolder = state.assets.folders.filter((folder) => folder.id === state.assets.selectedFolder);
  if (currentFolder.length === 1) {
    return currentFolder[0];
  }
  return null;
};
export const selectRenamingFolder = (state: RootState) => state.assets.renamingFolder;
export const selectAssets = (state: RootState) => {
  return selectSelectedFolder(state)?.assets;
};
export const selectEditingAsset = (state: RootState) => {
  if (!state.assets.editingAssetId) return undefined;

  return state.assets.assetsInCurrentFolder.find((asset) => asset.id === state.assets.editingAssetId);

  // const folder = selectSelectedFolder(state);
  // if (folder) {
  //   console.log("getting sa");
  //   return getAssetById(folder, state.assets.editingAssetId);
  // }

  // return state.assets.legacyAssets.find((asset) => asset.id === state.assets.editingAssetId);
};

export const selectEditingVariantId = (state: RootState) => state.assets.editingVariantId;

// TODO: redo this when backend is done
export const selectAllFolders = (state: RootState) => state.assets.folders;

export const selectAssetThumbnailUrlFromFolders = (folders: Folder[], assetId: string | undefined) => {
  var url = "";

  if (assetId === undefined || assetId === "") return url;

  folders.forEach((folder) => {
    if (folder.assets) {
      folder.assets.forEach((asset: Asset) => {
        if (asset.id === assetId) {
          url = asset.thumbnailUrl;
        }
      });
    }
  });

  return url;
};
export const selectUsedAssets = (state: RootState) => state.assets.usedAssets;
export const selectCachedAssets = (state: RootState) => state.assets.cachedAssets;
export const selectCachedAssets_small = (state: RootState) => state.assets.cachedAssets_small;
export const selectCachedAssets_medium = (state: RootState) => state.assets.cachedAssets_medium;

export const selectUsedAsset = (usedAssets: AssetContentDTO[], assetId: string | undefined) => {
  if (!assetId) return undefined;
  return usedAssets.find((a) => a.id === assetId);
};

export const selectCachedAsset = (cachedAssets: AssetDetailsDTO[], assetId: string | undefined) => {
  if (!assetId) return undefined;
  return cachedAssets.find((a) => a.assetId === assetId);
};

export const selectLegacyAssets = (state: RootState) => state.assets.legacyAssets;
export const selectAssetsFromCurrentFolder = (state: RootState) => state.assets.assetsInCurrentFolder;

export const selectHasUnsubittedFolder = (state: RootState) => state.assets.folders.find((folder) => folder.id === "newfolder") !== undefined;

// TODO: replace these selectors with normal functions
export const selectSupportedImageTypes = (state: RootState) => supportedImageTypes;

export const selectSupportedVideoTypes = (state: RootState) => supportedVideoTypes;

export const selectSupportedPdfTypes = (state: RootState) => supportedPdfTypes;
export const selectSupportedAudioTypes = (state: RootState) => supportedAudioTypes;

export const selectSupportedFileTypes = (state: RootState) => [...supportedImageTypes, ...supportedVideoTypes, ...supportedPdfTypes, ...supportedAudioTypes];

// ======================================================

// ==== support functions ====
export function GetSupportedFiletypes(filetype: AssetKey) {
  switch (filetype) {
    case AssetKey.Image:
      return supportedImageTypes;
    case AssetKey.Video:
      return supportedVideoTypes;
    case AssetKey.Pdf:
      return supportedPdfTypes;
    case AssetKey.Audio:
      return supportedAudioTypes;
    default:
      return [];
  }
}
