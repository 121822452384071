import React from "react";
import "./Editor.css";
import { Outlet } from "react-router-dom";

function Editor() {
  return (
    <div className="editor-root">
      <Outlet />
    </div>
  );
}

export default Editor;
