import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetSupportedFiletypes, selectEditingAsset, selectEditingVariantId } from "../../app/slices/AssetsSlice";
import { AssetKey } from "../../dto/AssetDTO";
import "./VariantDetailsEdit.css";

interface VariantDetailsEditProps {
  onFileSelected: (file: File) => void;
}

export default function VariantDetailsEdit(props: VariantDetailsEditProps) {
  const selectedVariantId = useSelector(selectEditingVariantId);
  const selectedAsset = useSelector(selectEditingAsset);
  const variant = selectedAsset?.variants.find((x) => x.id === selectedVariantId);
  const { t } = useTranslation();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const supportedFileTypes = selectedAsset ? GetSupportedFiletypes(selectedAsset?.key) : "";
  const [dragActive, setDragActive] = React.useState(false);
  const [selectedFilePreview, setSelectedFilePreview] = useState<string | null>(null);

  const handleFileInput = function (e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files?.length > 0) {
      props.onFileSelected(e.target.files[0]);

      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedFilePreview(e.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Drag&Drop functions
  // handle drag events
  const handleDrag = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (supportedFileTypes.includes(e.dataTransfer.files[0].type)) {
        // at least one file has been dropped so do something
        props.onFileSelected(e.dataTransfer.files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedFilePreview(e.target?.result as string);
        };
        reader.readAsDataURL(e.dataTransfer.files[0]);
      }
    }
  };

  const getPreview = () => {
    if (selectedAsset && variant) {
      if (selectedAsset.key === AssetKey.Image) {
        var url = selectedFilePreview ?? variant.downloadUri;
        return <img src={url} />;
      } else if (selectedAsset.key === AssetKey.Video) {
        return (
          <div className="variantdetailsedit-preview-icon">
            <img src="/asset_icons/video_icon.png" />
          </div>
        );
      } else if (selectedAsset.key === AssetKey.Pdf) {
        return (
          <div className="variantdetailsedit-preview-icon">
            <img src="/asset_icons/pdf_icon.png" />
          </div>
        );
      } else if (selectedAsset.key === AssetKey.Audio) {
        return (
          <div className="variantdetailsedit-preview-icon">
            <img src="/asset_icons/audio_icon.png" />
          </div>
        );
      }
    }

    return <></>;
  };

  const openFileExplorer = () => {
    inputRef.current?.click();
  };

  const rootclasses = dragActive ? "variantdetailsedit-root variantdetailsedit-dragactive" : "variantdetailsedit-root";

  return (
    <div className={rootclasses} onClick={openFileExplorer} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
      <div className="variantdetailsedit-preview">{getPreview()}</div>
      <div className="variantdetailsedit-text">
        <span className="variantdetailsedit-text-highlight">{t("browse_file")} </span>
        <span>{t("or_drag_and_drop")}</span>
      </div>
      <div className="variantdetailsedit-edit-btn">
        <EditOutlinedIcon />
      </div>
      <input className="filedropzone-hide-input" ref={inputRef} type="file" onChange={handleFileInput} accept={supportedFileTypes.toString()} />
    </div>
  );
}
