import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import { IFormQuiz } from "../../../models/Quiz";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import PiInteractionTitlePreview from "../components/PiInteractionTitlePreview";
import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import WordSpinnerQuizBody from "./WordSpinnerQuizBody";

export default function WordSpinnerQuizPreview() {
  const quiz = useSelector(selectSelectedElement) as IFormQuiz;
  const { getValue, changeValue } = useTrainingLocalization();

  const title = <PiInteractionTitlePreview value={getValue(quiz.title)} onChange={(e) => changeValue(quiz.title, e)} placeholder="Quiz Title" showStarIcons={false} />;

  const body = <WordSpinnerQuizBody />;

  return <PiPopupWindowPreview title={title} body={body} />;
}
