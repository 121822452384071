import React, {useMemo} from "react";
import {useDispatch} from "react-redux";
import {SelectedTab, setSelectedTab} from "../../app/slices/GlobalSlice";
import ActorPresetsColumn from "../actor_presets/ActorPresetsColumn";
import Timeline3D from "./../timeline_3d/Timeline3D";
import "./Timeline3DContent.css";
import Timeline3DTab from "./Timeline3DTab";
import PropPresetsColumn from "../prop_presets/PropPresetsColumn";

export default function Timeline3DContent() {
    const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
    const dispatch = useDispatch();

    const activeElement = useMemo(() => {
        switch (selectedTabIndex) {
            case 0:
                return <Timeline3D/>;
            case 1:
                return <ActorPresetsColumn/>;
            case 2:
                return <PropPresetsColumn />;
            default:
                return <Timeline3D/>;
        }
    }, [selectedTabIndex]);

    const onTabSwitch = (index: number) => {
        setSelectedTabIndex(index);

        // set properties column open if tab is actors
        dispatch(setSelectedTab(index as SelectedTab));
    };

    return (
        <div className="trainingtimeline-root timeline3dcontent-root">
            <Timeline3DTab onTabClick={onTabSwitch}/>
            <div className="timeline3dcontent-column">{activeElement}</div>
        </div>
    );
}
