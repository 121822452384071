import AddIcon from "@mui/icons-material/Add";
import React from "react";
import "./PiBasicQuizAddButtonSmall.css";

interface PiBasicQuizAddButtonSmallProps {
  onClick: () => void;
}

export default function PiBasicQuizAddButton(props: PiBasicQuizAddButtonSmallProps) {
  return (
    <div className="pibasicquizaddbuttonsmall-root" onClick={() => props.onClick()}>
      <div className="pibasicquizaddbuttonsmall-icon">
        <AddIcon fontSize="inherit" color="inherit" />
      </div>
    </div>
  );
}
