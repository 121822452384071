import { useDispatch, useSelector } from "react-redux";
import { ListOnItemsRenderedProps } from "react-window";
import { Folder, addPagedFolders, selectFolders } from "../../app/slices/AssetsSlice";
import { selectToken } from "../../app/slices/AuthenticationSlice";
import { selectCurrentOrganization } from "../../app/slices/UserSlice";
import { AssetService } from "../../services/AssetService";

export default function useFolderViewPageLoader() {
  const folders = useSelector(selectFolders);
  const org = useSelector(selectCurrentOrganization);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const onItemsRendered = async (props: ListOnItemsRenderedProps) => {
    var startIndex = props.visibleStartIndex;
    var endIndex = props.visibleStopIndex;

    var startPage = Math.floor(startIndex / 10);
    var endPage = Math.floor(endIndex / 10);

    for (var i = startPage; i <= endPage; i++) {
      if (folders[i * 10].id === "loading") {
        var result = await AssetService.GetFolders(org!.id, token, 10, i + 1);
        console.log(result);
        if (result) {
          const newFolders: Folder[] = result.folders.map((f) => {
            return { id: f.id, name: f.name } as Folder;
          });
          dispatch(addPagedFolders({ folders: newFolders, pageNumber: i }));
        }
      }
    }
  };

  return [onItemsRendered] as const;
}
