import {v4 as uuidv4} from "uuid";
import Interaction, {InteractionType} from "../../models/interactions/Interaction";
import {useTrainingLocalization} from "../trainingLocalization/TrainingLocalizationHooks";
import {DynamicInteraction} from "../../dto/PropMetadata";
import {CreateDynamicInteraction} from "../../models/interactions/DynamicInteraction";
import {getInteractionFromFactory} from "../interaction_factory/InteractionFactory";
import {useState} from "react";

export const useAddInteraction = () => {
    const {GetArrayOfKeys} = useTrainingLocalization();

    const createInteraction = (type: InteractionType): Interaction => {
        var factoryResult = getInteractionFromFactory(type);

        if (!factoryResult) return {id: uuidv4(), name: "No Type", type: InteractionType.Default};

        return factoryResult.Create(GetArrayOfKeys);
    };

    // TODO: change name
    const createPropInteraction = (dynInteraction: DynamicInteraction, sourceInstanceId: string): Interaction => {
        return CreateDynamicInteraction(dynInteraction, sourceInstanceId, GetArrayOfKeys);
    }

    return {createInteraction, createPropInteraction} as const;

};
