import { useMemo, useState } from "react";
import { useAssetPreview } from "../../../hooks/AssetPreviewHook";
import { IAnswer } from "../../../models/Quiz";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import PiBasicQuizCheckboxV2 from "../components/v2/PiQuizCheckboxV2";
import FileExplorerDialog from "../../file_explorer/FileExplorerDialog";
import { AssetDetailsDTO, AssetKey } from "../../../dto/AssetDTO";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CloseIcon from "@mui/icons-material/Close";

export default function ImageButtonQuizAnswer(props: {
  answer: IAnswer;
  onChange: (answer: IAnswer) => void;
  onDelete: () => void;
  canDelete: boolean;
}) {
  const { answer, onChange, onDelete, canDelete } = props;
  const { getAssetPreview } = useAssetPreview(answer.assetId);
  const url = getAssetPreview();

  const [isOpen, setIsOpen] = useState(false);

  const onAssetSelect = (id: string) => {
    onChange({ ...answer, assetId: id });
    setIsOpen(false);
  };

  const previewElement = useMemo(() => {
    if (url) {
      return (
        <div
          className="relative flex max-w-full max-h-full w-full h-full cursor-pointer group text-[32px] py-[16px]"
          onClick={() => setIsOpen(true)}
        >
          <img
            className="object-contain max-w-full max-h-full m-auto group-hover:opacity-50"
            src={url}
            alt="Answer"
          />
          <EditOutlinedIcon
            fontSize="inherit"
            className="absolute invisible -translate-x-1/2 -translate-y-1/2 text-interaction-primary group-hover:visible top-1/2 left-1/2"
          />
        </div>
      );
    } else {
      return (
        <div className="p-[8px] m-auto w-full h-full text-[52px] text-interaction-primary cursor-pointer flex">
          <div
            className="flex w-full h-full hover:bg-interaction-text-hoverBackground rounded-[5px] "
            onClick={() => setIsOpen(true)}
          >
            <AddPhotoAlternateOutlinedIcon
              className="m-auto"
              fontSize="inherit"
              color="inherit"
            />
          </div>
        </div>
      );
    }
  }, [url]);

  const checkbox = (
    <div className="m-auto">
      <PiBasicQuizCheckboxV2
        checked={answer.correct}
        onChange={(checked) => onChange({ ...answer, correct: checked })}
      />
    </div>
  );

  const deleteButton = (
    <div
      className="absolute top-[0px] right-[4px] text-[24px] text-creator-error hover:text-creator-error-hover cursor-pointer"
      onClick={onDelete}
    >
      <CloseIcon fontSize="inherit" color="inherit" />
    </div>
  );

  return (
    <>
      <div className="relative w-[128px] h-[200px] bg-interaction-primary-washed rounded-[5px] drop-shadow-lg flex flex-col">
        <div className="h-[150px] w-full flex m-auto">{previewElement}</div>
        <div className="h-[50px] w-full flex ">{checkbox}</div>
        {canDelete && deleteButton}
      </div>
      <FileExplorerDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        type={AssetKey.Image}
        onSelect={(asset: AssetDetailsDTO) => onAssetSelect(asset.assetId)}
      />
    </>
  );
}
