import {TranslatedEnvironmentDTO} from "../../dto/TranslatedEnvironmentDTO";
import {CSSProperties} from "react";
import PiButton from "../../components/PiButton";
import ImageNotSupportedOutlinedIcon from '@mui/icons-material/ImageNotSupportedOutlined';
export default function TranslatedEnvironmentCard(props: {environment: TranslatedEnvironmentDTO, style: CSSProperties, onSelectEnvironment: (environmentId: string) => void}) {
    const {environment, style, onSelectEnvironment} = {...props};
    
    return <div className={"w-full h-full flex"} style={style}>
        
        <div className={"w-[289px] h-[396px] relative border-[2px] rounded-[10px] shadow-sm m-auto border-solid border-creator-border flex flex-col "}>
            {environment?.thumbnailUri && <img src={environment?.thumbnailUri} alt={environment?.name} className={"w-full h-[188px] object-cover rounded-t-[5px]"} />}
            {environment?.thumbnailUri === null && <div className={"w-full h-[188px] bg-interaction-softBackground rounded-t-[5px] flex flex-col justify-center" +
                " text-[48px]" +
                " gap-[8px]" +
                " text-interaction-disabled"}>
                <ImageNotSupportedOutlinedIcon className={"mx-auto mt-8"} fontSize={"inherit"}/>
                <span className={"text-[14px] mx-auto italic"}>no image</span>
            </div>
            }
            <div className={"px-[20px] py-[16px] flex flex-col gap-y-[4px]"}>
                <div className={"font-semibold text-[16px] text-creator-text"}>{environment?.name}</div>
                <div className={"text-[14px] text-[#5F6D7E]"}>{environment?.description}</div>
            </div>
            <div className={"mt-auto h-[68px] px-[20px] py-[16px] border-solid border-t-2 border-creator-border"}><PiButton onClick={() => {onSelectEnvironment(environment.id)}} variant={"piBlue"}>Select</PiButton>
                <div className={"absolute top-[16px] left-[16px] bg-creator-secondary text-white rounded-[5px] text-[13px] px-[8px] py-[2px]"}>{environment?.name}</div>
            </div>
            
        </div>
      
    </div>
}