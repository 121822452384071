import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedTake3D, setSelectedInteraction, setSelectedTake} from "../../app/slices/TrainingSlice";
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';

export default function ActorStatePropertiesBreadcrumb() {

    const take = useSelector(selectSelectedTake3D);
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(setSelectedTake(take!.id));
    }

    const breadcrumbs = <div className={"flex h-[44px] gap-[4px] p-[10px] border-solid border-b-2 border-creator-border"}>
        <MovieCreationOutlinedIcon className={"my-auto text-[16px] text-creator-text-sub"} color={"inherit"} fontSize={"inherit"}/>
        <div className={"text-[14px] font-medium underline text-creator-text-sub cursor-pointer max-w-[64px] truncate"} onClick={onClick}>{take?.name}</div>
        <KeyboardArrowRightIcon className={"my-auto"}/>
        <div className={"text-creator-primary text-[14px] font-medium"}>Acting Actor</div>
    </div>

    return <>{breadcrumbs}</>

}