import {Button} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {addMostRecentInteraction, selectSelectedInteractionDefinition} from "../../app/slices/InteractionPickerSlice";
import "./InteractionPickerPreview.css";
import useInteractionPicker from "../../hooks/InteractionPicker.hook";

interface InteractionPickerPreviewProps {
    onClose: () => void;
}

export default function InteractionPickerPreview(props: InteractionPickerPreviewProps) {
    const {onClose} = {...props};
    const dispatch = useDispatch();
    const selectedInteractionDefinition = useSelector(selectSelectedInteractionDefinition);
    const {t} = useTranslation();

    const {addNewInteraction} = useInteractionPicker();
    const onClick = () => {
        // // 1. create interaction
        // const interaction: Interaction = createInteraction(selectedInteractionDefinition.type);
        // // 2. add interaction to bubble (training slice)
        // dispatch(addInteractionToSelectedTake(interaction));
        // // 3. add interaction def to "Most recent" on interaction def slice
        // dispatch(addMostRecentInteraction(selectedInteractionDefinition));
        
        addNewInteraction();
        
        // 4. close window
        onClose();
    };

    const imgSrc = `/picker_previews/${selectedInteractionDefinition.previewImage}`;

    return (
        <div className="interactionpickerpreview-root">
            <div className="interactionpickerpreview-title">{selectedInteractionDefinition.name}</div>
            <div className="interactionpickerpreview-image-wrapper">
                <img className={"rounded-[5px] drop-shadow-md"} loading="lazy" src={imgSrc} alt=""/>
            </div>
            <div className="interactionpickerpreview-description">{t("interaction_descriptions." + selectedInteractionDefinition.description)}</div>
            <div className="interactionpickerpreview-add-btn">
                <Button variant="piBlue" onClick={onClick}>
                    {t("add_interaction")}
                </Button>
            </div>
        </div>
    );
}
