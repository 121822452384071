import React from "react";
import "./../../style.css";
import { LoginForm } from "./LoginForm";
import { useLogin } from "./LoginHooks";

function Login() {
  const [loading, autoLoggingIn, isIncorrectCreds, tryLogin] = useLogin();

  async function onLoginClick(email: string, password: string) {
    await tryLogin(email, password);
  }

  return (
    <LoginForm
      onSubmit={onLoginClick}
      isLoading={loading}
      isAutoLoggingIn={autoLoggingIn}
      isIncorrectLogin={isIncorrectCreds}
    />
  );
}

export default Login;
