import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";
import "./PiPopper.css";

interface PiPopperProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLDivElement>;
  onClose: (event: Event | undefined) => void;
  items: PiPopperMenuProps[];
  selectedIndex: number;
  width: string;
  onClick?: (index: number) => void;
  disabled?: boolean;
}

export interface PiPopperMenuProps {
  text: string;
  img?: string;
  onClick?: (index: number) => void;
}

export default function PiPopper(props: PiPopperProps) {
  const { open, anchorRef, onClose, items, width } = { ...props };

  return (
    <Popper
      sx={{
        zIndex: 1002,
        width: { width },
      }}
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      aria-label="pisplitbutton-popper"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            transform: "translate(0, 8px)",
            borderRadius: "10px",
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList
                className="pipopper-menu"
                id="split-button-menu"
                autoFocusItem
                style={{
                  maxHeight: "250px",
                  overflowY: "auto",
                }}
              >
                {items.map((option, index) => (
                  <MenuItem
                    aria-label={option.text}
                    key={option.text}
                    selected={index === props.selectedIndex}
                    onClick={(event) => {
                      option.onClick?.(index);
                      props.onClose(undefined);
                      props.onClick?.(index);
                    }}
                    className="pipopper-menuitem"
                  >
                    {option.text}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
