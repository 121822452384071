import { VersionNumber } from "./TrainingDetailsDTO";
export enum AssetStatus {
  Draft = 0,
  Published = 1,
}
export interface VersionDTO {
  major: number;
  minor: number;
  build: number;
}

export interface AssetPreviewVersionDTO {
  id: string;
  version: VersionDTO;
  variant: AssetVariantDTO;
  lastEditBy: string;
}

export interface AssetPreviewDTO {
  id: string;
  name: string;
  key: string;
  countVersions: number;
  latestVersion: AssetPreviewVersionDTO;
}

export interface AssetContentDTO {
  id: string;
  name: string;
  downloadUri: string;
}

export interface AssetDetailsDTO {
  id: string;
  name: string;
  key: AssetKey;
  version: VersionNumber;

  assetId: string;
  updatedAt: string;
  updatedBy: string;
  variants: AssetVariantDTO[];
}

export enum AssetKey {
  Pdf = 5,
  Image = 10,
  Video = 15,
  Audio = 20,
  Loading = -5,
  Unsupported = -10,
}
export interface AssetVariantDTO {
  variantId: string;
  blobPath: string;
  hash: string;
  variant: string;
  downloadUri: string;
  id: string;
}

export interface AssetVersionDTO {
  id: string;
  version: VersionDTO;
  status: AssetStatus;
  minRequiredBuildVersion: VersionDTO;
  lastEditBy: string;
  lastEdit: Date;
  variants: AssetVariantDTO[];
}

// convert version to a string
export const versionToString = (version: VersionDTO) => {
  return version.major + "." + version.minor + "." + version.build;
};
