import { useSelector } from "react-redux";
import {
  selectSelectedTake3D,
  selectSelectedTakeActors,
} from "../../../app/slices/TrainingSlice";
import usePropHelper from "../../../hooks/PropHelper";
import { PiInteractionDropdown } from "../../../components/PiInteractionDropdown";
import ActorAvatar from "../../../components/actors/ActorAvatar";
import { useEffect } from "react";

export default function ActorPropDropdown(props: {
  label: string;
  selectedObjectId: string;
  onClick: (actorId: string) => void;
  hideActorsWithProp?: boolean;
}) {
  const { label, selectedObjectId, onClick, hideActorsWithProp } = {
    ...props,
  };
  const actors = useSelector(selectSelectedTakeActors);
  const { getAllInvolvedProps, getPropDTO } = usePropHelper();

  const take = useSelector(selectSelectedTake3D);
  const propsInTake = getAllInvolvedProps(take!);

  const { getActorsNotAttachedToProps } = usePropHelper();

  const getSelectedElement = () => {
    const selectedTargetActor = actors.find(
      (actor) => actor.id === selectedObjectId
    );

    if (selectedTargetActor)
      return (
        <>
          <ActorAvatar actor={selectedTargetActor} />
          <div className={"my-auto truncate"}>
            {selectedTargetActor.workingName}
          </div>
        </>
      );

    const prop = propsInTake.find((prop) => prop.id === selectedObjectId);

    if (prop) {
      const dto = getPropDTO(prop.propId);
      return (
        <>
          <img
            className="w-[36px] h-[36px] rounded-full border-interaction-primary border-2 border-solid"
            src={dto.propContent.downloadUri}
            alt={""}
          />
          <div className="my-auto truncate">{prop.name}</div>
        </>
      );
    }

    return <div>No object found</div>;
  };

  const filteredActors = hideActorsWithProp
    ? getActorsNotAttachedToProps()
    : actors;

  const actorsDropdownItems = filteredActors.map((actor) => {
    return (
      <PiInteractionDropdown.Item
        key={actor.id}
        onClick={() => onClick(actor.id)}
      >
        <div className={"flex justify-center gap-4 w-full"}>
          <ActorAvatar actor={actor} />
          <div className={"my-auto truncate"}>
            {actor.workingName ?? "No Actor"}
          </div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const propDropdownItems = propsInTake.map((prop) => {
    const dto = getPropDTO(prop.propId);
    return (
      <PiInteractionDropdown.Item
        key={prop.id}
        onClick={() => onClick(prop.id)}
      >
        <div className={"flex justify-center gap-4 w-full"}>
          <img
            className="w-[36px] h-[36px] rounded-full border-interaction-primary border-2 border-solid"
            src={dto.propContent.downloadUri}
            alt=""
          />
          <div className={"my-auto truncate"}>{prop.name}</div>
        </div>
      </PiInteractionDropdown.Item>
    );
  });

  const allDropdownItems = [...actorsDropdownItems, ...propDropdownItems];

  const actorDropdown = (
    <PiInteractionDropdown label={label}>
      <PiInteractionDropdown.Trigger>
        <div className={"flex gap-x-4 w-full"}>{getSelectedElement()}</div>
      </PiInteractionDropdown.Trigger>
      {allDropdownItems}
    </PiInteractionDropdown>
  );

  return <>{actorDropdown}</>;
}
