import { useRef } from "react";
import { useTrainingLocalization } from "../../trainingLocalization/TrainingLocalizationHooks";
import { useSelector } from "react-redux";
import { IStatementQuiz } from "../../../models/interactions/StatementQuiz";
import { selectSelectedElement } from "../../../app/slices/TrainingSlice";
import CloseIcon from "@mui/icons-material/Close";

export default function StatementQuizPreviewTextArea(props: {
  index: number;
  onDelete: (index: number) => void;
}) {
  const interaction = useSelector(selectSelectedElement) as IStatementQuiz;
  const { index, onDelete } = { ...props };

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { getValue, changeValue } = useTrainingLocalization();

  const autoGrow = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "5px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const counter = (
    <div className="absolute bottom-[8px] right-[16px]">
      <div className="text-[18px] font-medium text-interaction-primary">
        {index + 1} / {interaction.statements.length}
      </div>
    </div>
  );

  // TODO: delete button
  const deleteButton = (
    <div
      className="absolute top-[8px] right-[16px] text-creator-error text-[24px] hover:text-creator-error-hover cursor-pointer"
      onClick={() => onDelete(index)}
    >
      <CloseIcon fontSize="inherit" color="inherit" />
    </div>
  );

  return (
    <div className="relative flex w-full h-full bg-interaction-primary-washed rounded-[5px]">
      <div className="flex items-center w-full h-full my-auto">
        <textarea
          ref={textareaRef}
          className="h-auto w-full text-center bg-transparent text-interaction-primary focus:outline-none text-[20px] flex-grow resize-none max-h-[80%] overflow-y-auto pit-scrollbar"
          placeholder="Type a statement"
          onInput={autoGrow}
          value={getValue(interaction.statements[index])}
          onChange={(e) =>
            changeValue(interaction.statements[index], e.target.value)
          }
        />
      </div>
      {counter}
      {interaction.statements.length > 1 && deleteButton}
    </div>
  );
}
