import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { addActor, selectActors, selectSelectedActorId } from "../../app/slices/TrainingSlice";
import ActorCard from "./ActorCard";
import "./ActorPresetList.css";
import { ShortCuts } from "../../hooks/UseShortcutsHook";
import useShortcut from "../../hooks/UseShortcutsHook";
import { useDispatch } from "react-redux";
import { Actor, Gender, ProfilePictures } from "../../models/Actor";

export default function ActorPresetList() {
  const dispatch = useDispatch();
  const actors = useSelector(selectActors);
  const selectedActorId = useSelector(selectSelectedActorId);

  useShortcut(ShortCuts.Copy, () => {
    navigator.clipboard.writeText(JSON.stringify(actors.find((actor) => selectedActorId === actor.id)));
  });

  useShortcut(ShortCuts.Paste, async () => {
    const text = await navigator.clipboard.readText();

    //Check if string is parseable JSON
    try {
      var pastedActor = JSON.parse(text);
    }
    catch (error) {
      console.log('Not in JSON format');
      return;
    }

    // Check if the pastedActor is of the expected type
    if (!isValidActor(pastedActor)) {
      console.log('Invalid actor format');
      return;
    }

    if (pastedActor.id === "player") {
      pastedActor = { id: uuidv4(), profilePicture: Math.floor(Math.random() * ProfilePictures.length), workingName: "New Actor", gender: Gender.random };
    }
    else {
      pastedActor.id = uuidv4();
    }

    dispatch(addActor(pastedActor));
  });


  const cards = actors.map((actor, index) => {
    return <ActorCard key={index} actor={actor} isSelected={actor.id === selectedActorId} />;
  });

  return <div className="px-[18px] pt-[18px] gap-y-[10px] flex flex-col max-h-[100%] overflow-y-auto pit-creator-scrollbar">{cards}</div>;
}

function isValidActor(actor: Actor): Boolean {
  return actor &&
    typeof actor.id === 'string' &&
    typeof actor.profilePicture === 'number' &&
    typeof actor.workingName === 'string';
}