import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ColumnSizer, Grid } from "react-virtualized";
import { RenderedSection } from "react-virtualized/dist/es/Grid";
import { selectAssets, selectAssetsFromCurrentFolder, selectSelectedFolder } from "../../app/slices/AssetsSlice";
import { AssetDetailsDTO, AssetKey } from "../../dto/AssetDTO";
import { useFileExplorerLoading } from "../../hooks/FileExplorerHooks";
import AssetCard from "./AssetCard";
import AssetCardSkeleton from "./AssetCardSkeleton";
import "./AssetView.css";
import FileDropzone from "./FileDropzone";
export interface AssetViewProps {
  type?: AssetKey;
  filter?: string;
  onSelect?: (asset: AssetDetailsDTO) => void; // return asset id
  fullWidth: boolean;
  showDropzone?: boolean;
}

export default function AssetView(props: AssetViewProps) {
  const selectedAssets = useSelector(selectAssets);
  const assetsInCurrentFolder = useSelector(selectAssetsFromCurrentFolder);

  const selectedFolder = useSelector(selectSelectedFolder);
  const parentRef = useRef<HTMLDivElement>(null);
  const [calculatedWidth, setCalculatedWidth] = useState<number>(0);
  const [amountOfColumns, setAmountOfColumns] = useState<number>(4);
  const [gridHeight, setGridHeight] = useState<number>(0);
  const { onRerender } = useFileExplorerLoading();

  const { type, fullWidth, filter, showDropzone } = { ...props };

  var gridRef = React.createRef<Grid | null>();

  useEffect(() => {
    onWindowResize();
    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  });

  useEffect(() => {
    if (gridRef) {
      console.log("scrolling back to elem 1");
      gridRef.current?.scrollToCell({ columnIndex: 0, rowIndex: 0 });
    }
  }, [selectedFolder]);

  const onWindowResize = () => {
    if (parentRef.current) {
      // manually calculate the width of the grid + calculate the amount of columns that fit on the screen
      var offset = fullWidth ? 64 : 350;
      var newCalcWidth = window.innerWidth * 0.9 - offset;
      var newcols = newCalcWidth / 350;

      setCalculatedWidth(newCalcWidth);
      setAmountOfColumns(Math.round(newcols));
      setGridHeight(parentRef.current.clientHeight);
    }
  };

  const dropzone = selectedFolder ? <FileDropzone /> : "";

  const cellRenderer = ({ columnIndex, key, rowIndex, style }: { columnIndex: number; key: string; rowIndex: number; style: CSSProperties }) => {
    if (!assetsInCurrentFolder) return;
    const index = amountOfColumns * rowIndex + columnIndex;
    return getCell(index, key, style);
  };

  const getCell = (index: number, key: string, style: CSSProperties) => {
    if (showDropzone) {
      if (index === 0) {
        return (
          <div className="assetview-virtualized-cell" key={key} style={style}>
            {dropzone}
          </div>
        );
      } else if (index > 0 && index - 1 < assetsInCurrentFolder!.length)
        return (
          <div className="assetview-virtualized-cell" key={key} style={style}>
            {assetsInCurrentFolder[index - 1] && <AssetCard asset={assetsInCurrentFolder[index - 1]} onSelect={props.onSelect} type={type} />}
            {!assetsInCurrentFolder[index - 1] && <AssetCardSkeleton />}
          </div>
        );
    } else {
      if (index < assetsInCurrentFolder!.length) {
        return (
          <div className="assetview-virtualized-cell" key={key} style={style}>
            {assetsInCurrentFolder[index] && <AssetCard asset={assetsInCurrentFolder[index]} onSelect={props.onSelect} type={type} />}
            {!assetsInCurrentFolder[index] && <AssetCardSkeleton />}
          </div>
        );
      }
    }
  };

  function onSectionRenderer({
    columnOverscanStartIndex,
    columnOverscanStopIndex,
    columnStartIndex,
    columnStopIndex,
    rowOverscanStartIndex,
    rowOverscanStopIndex,
    rowStartIndex,
    rowStopIndex,
  }: RenderedSection) {
    onRerender(amountOfColumns, rowStartIndex, rowStopIndex, type, filter);
  }

  const rowCount = showDropzone ? Math.ceil((assetsInCurrentFolder!.length + 1) / amountOfColumns) : Math.ceil(assetsInCurrentFolder!.length / amountOfColumns);

  const getGrid = () => {
    if (assetsInCurrentFolder) {
      return (
        <ColumnSizer columnMaxWidth={1000} columnMinWidth={0} columnCount={amountOfColumns} width={calculatedWidth}>
          {({ adjustedWidth, getColumnWidth, registerChild }) => (
            <Grid
              ref={registerChild}
              cellRenderer={cellRenderer}
              columnCount={amountOfColumns}
              columnWidth={getColumnWidth}
              height={gridHeight}
              rowCount={rowCount}
              rowHeight={275}
              width={adjustedWidth}
              autoWidth
              onSectionRendered={onSectionRenderer}
            />
          )}
        </ColumnSizer>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div ref={parentRef} className="assetview-root">
      <div className="assetview-scroller">{getGrid()}</div>
    </div>
  );
}
