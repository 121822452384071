import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {IFormQuiz} from "../../../models/Quiz";
import {useTrainingLocalization} from "../../trainingLocalization/TrainingLocalizationHooks";
import PiBasicGraphicsQuizAddButton from "./PiBasicGraphicsQuizAddButton";
import PiBasicGraphicsQuizAnswer from "./PiBasicGraphicsQuizAnswer";
import PiBasicQuizAddButton from "./PiBasicQuizAddButton";
import PiBasicQuizAnswer from "./PiBasicQuizAnswer";
import "./PiBasicQuizBody.css";
import classNames from "classnames";
import PiBasicQuizImageSelectButton from "./PiBasicQuizImageSelectButton";
import {Update} from "@mui/icons-material";

export interface PiQuizesFormPreviewProps {
    multipleCorrectAnswers?: boolean;
    displayGraphics?: boolean;
}

export default function PiBasicQuizBody(props: PiQuizesFormPreviewProps) {
    const {multipleCorrectAnswers, displayGraphics} = {...props};
    const {getValue, changeValue, getNewKey} = useTrainingLocalization();
    const quiz = useSelector(selectSelectedElement) as IFormQuiz;
    const dispatch = useDispatch();
    const canDelete: boolean = quiz.answers.length > 2;
    const canAdd: boolean = quiz.answers.length < 5;

    // check if has "useImage" property
    const useImage = useMemo(() => {
        if (!quiz.properties) return false;
        const result = quiz.properties?.find(p => p.name === "useImage");
        return result !== undefined && result.value === true;
    }, [quiz]);

    const handleAnswerChange = (id: string, field: string, value: boolean | string) => {
        const copy = {...quiz};
        const updatedAnswers = copy.answers.map((answer) => ({...answer}));
        const index = updatedAnswers.findIndex((x) => x.id === id);

        if (field === "checked") {
            const checkedAnswer = updatedAnswers[index];

            if (!multipleCorrectAnswers) {
                updatedAnswers.forEach((answer) => {
                    answer.correct = false;
                });
            }
            checkedAnswer.correct = value as boolean;
        }

        if (field === "assetId") {
            const answer = updatedAnswers[index];
            answer.assetId = value as string;
        }

        copy.answers = updatedAnswers;
        dispatch(updateSelectedInteraction(copy));
    };

    const handleAddAnswer = () => {
        const newAnswer = {id: uuidv4(), correct: false, text: getNewKey()};
        const updatedAnswers = [...quiz.answers, newAnswer];
        const copy = {...quiz, answers: updatedAnswers};
        dispatch(updateSelectedInteraction(copy));
    };

    const handleDeleteAnswer = (id: string) => {
        if (quiz.answers.length > 2) {
            const updatedAnswers = quiz.answers.filter((answer) => answer.id !== id);
            const copy = {...quiz, answers: updatedAnswers};
            dispatch(updateSelectedInteraction(copy));
        }
    };

    const updateField = (field: keyof IFormQuiz, value: any) => {
        dispatch(updateSelectedInteraction({...quiz, [field]: value}));
    }

    const answers = quiz.answers.map((answer) =>
        !displayGraphics ? (
            <PiBasicQuizAnswer
                key={answer.id}
                answer={answer}
                handleAnswerChange={handleAnswerChange}
                getValue={getValue}
                changeValue={changeValue}
                handleDeleteAnswer={handleDeleteAnswer}
                answersLength={quiz.answers.length}
                canDelete={canDelete}
            />
        ) : (
            <PiBasicGraphicsQuizAnswer
                key={answer.id}
                answer={answer}
                quizType={"basic_quiz"}
                handleAnswerChange={handleAnswerChange}
                getValue={getValue}
                changeValue={changeValue}
                handleDeleteAnswer={handleDeleteAnswer}
                answersLength={quiz.answers.length}
                canDelete={canDelete}
            />
        )
    );

    const addButton = canAdd ? <PiBasicQuizAddButton onClick={handleAddAnswer}/> : <></>;
    const addButtonGfx = canAdd ? <PiBasicGraphicsQuizAddButton onClick={handleAddAnswer}/> : <></>;
    const bodyNoGfx = (
        <div className={"flex w-full h-full gap-[24px]"}>
            <div className={classNames({
                "flex flex-col gap-[12px] h-full": true,
                "w-full": !useImage,
                "w-[50%]": useImage
            })}>
                {answers}
                {addButton}
            </div>
            {useImage && <div className={"w-[50%]"}>
                <PiBasicQuizImageSelectButton onChange={(assetId) => updateField("assetId", assetId)}
                                              assetId={quiz.assetId}/>
            </div>}
        </div>
    );

    const bodyGfx = (
        <div className="pibasicquizbody-horz">
            {answers}
            {addButtonGfx}
        </div>
    );

    const body = !displayGraphics ? bodyNoGfx : bodyGfx;

    return <div className="pibasicquizbody-root">{body}</div>;
}
